const design = {
  colors: {
    rausch: '#FF5a5f',
    babu: '#00a699',
    arches: '#fc642d',
    hof: '#484848',
    foggy: '#767676',
    white: '#ffffff',
    gray: '#cccccc',
    graysh: '#9ca1ad',
    lightGray: '#eeeeee',
    lightBlue: '#d8dddf',
    addBtn: '#1abc9c',
    deleteRed: '#de6d77',
    editBlue: '#56a9ef',
    goodGreen: '#4BB543',
    black: '#000000',
    chartPink: '#EB6C7B',
    chartLegend: '#BCBFC7',
    chartGreen: '#a1ebaf',
    chartButtonGreen: '#61CE76',
    chartButtonPink: '#eb6c7b',
    tabBG: '#39435b',
    tabBGFocusedDesktop: 'rgba(0,0,0,0.2)',
    topTabColor: '#101c39',
    headerBorderColor: '#eff1f4',
    clinicScoreColor: '#7bc5e9',
    baseGray: '#f1f3f5',
    clinicBlue: '#7ec6e9',
    infoBlack: '#39435b',
    darkBlue: '#1e2a45',
    addTreatButton: '#ff7075',
    clinicHeader: '#121923',
    clinicSub: '#39435B',
    backdrop: '#39435B',
    backdropRgba: 'rgba(57, 67, 91, 0.8)',
    physicalPink: '#FF8EC8',
    referralPurple: '#B977E1',
    labGreen: '#02B491',
    loginBtn: '#A2E1FF',
    inputBg: '#F1F3F5',
    errRed: '#F85568',
    shadow: '#ced4db',
    physicalShadow: '#aab4c1',
    transparent: 'transparent',
    clinicIconsGray: '#BCBFC7',
    borderImageGray: '#e9ebee',
    borderBottomGoals: '#E7EBEE',
    lineGraphGray: '#9CA1AD',
    labAddTabSelected: '#02B491',
    noActive: '#F5F8FA',
    diagnosis: '#4cd18d',
    tutorialBg: 'rgba(18,25,35,0.9)',
    successOpacity: 'rgba(18,25,35,0.95)',
    successOpacity2: 'rgba(2,180,145,1.0)',
    errorOpacity: 'rgba(248,85,104,1.0)',
    treatmentChartOpacity: 'rgba(2,180,145,0.6)',
    treatmentChartOpacity1: 'rgba(1,141,113,0.9)',
    treatmentChartOpacity2: 'rgb(223,238,212,1)',
    treatmentChartText: '#707969',
    infoOpacity: 'rgba(162,225,255,1.0)',
    warnOpacity: 'rgba(249,220,98,1.0)',
    infoNoOpacity: 'rgba(162,225,255,1.0)',
    warning: '#F9DC62',
    archived: '#F5F8FA',
    orderTag: '#F4F5F7',
    physicalGrayTransparent: 'rgba(233, 235, 238, 0.8)',
    zoomOpacityControls: 'rgba(0, 0, 0, 0.35)',
    disabledTextColor: 'rgba(170, 170, 170, 1)',
    redDot: '#eb0014d9',
  },
  text: {
    huge: 75,
    fifty: 50,
    forty: 40,
    title1: 44,
    title2: 32,
    title3: 24,
    twentyEight: 28,
    twentyTwo: 22,
    title4: 21,
    twenty: 20,
    twentyFive: 25,
    large: 19,
    eightTeen: 18,
    regular: 17,
    semiReg: 16,
    small: 14,
    thirteen: 13,
    midSmall: 12,
    xsmall: 11,
    microPlus: 10,
    nine: 9,
    micro: 8,
  },
  spacing: {
    xxlarge: 96,
    xlarge: 72,
    clinicImage: 65,
    midLarge: 55,
    large: 48,
    base: 20,
    small: 16,
    tiny: 8,
    cardBorderRadius: 5,
  },
};

export default design;
