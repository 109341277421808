/* eslint-disable react/jsx-no-bind */
import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  I18nManager,
  Dimensions,
} from 'react-native';
// import SideMenu from '../../components/general/react-native-side-menu';
// import SideMenu from 'react-native-side-menu-updated';
import SideMenu from '../../components/general/SideMenu';
import More from '../More';
import design from '../../lib/design';

import { global } from '../../lib/general';
import MediaQuery from '../../components/general/MediaQuery';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: design.colors.white,
  },
  icoContainer: {
    paddingHorizontal: 20,
    paddingVertical: 30,
    flexDirection: 'row',
  },
});
const GlobalSideMenu = (props) => {
  const {
    isOpen, children, hideMenu,
  } = props;
  function onChange(open) {
    if (!open) {
      hideMenu();
    }
  }
  function closeMenu() {
    hideMenu();
  }
  const { width } = Dimensions.get('screen');
  return (
    <MediaQuery maxWidth={global.mobileMaxWidth}>
      {(matches) => (
        !matches ? (
          <SideMenu
            openMenuOffset={width * 0.2}
            isOpen={isOpen}
            onChange={onChange}
            menuPosition={I18nManager.isRTL ? 'right' : 'left'}
            menu={(
              <TouchableWithoutFeedback onPress={closeMenu}>
                <View style={styles.container}>
                  <TouchableOpacity onPress={closeMenu} style={styles.icoContainer} />
                  <More hideHeader closeMenu={closeMenu} />
                </View>
              </TouchableWithoutFeedback>
          )}
          >
            {children}
          </SideMenu>
        ) : (
          children
        )
      )}
    </MediaQuery>

  );
};
export default GlobalSideMenu;
