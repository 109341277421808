import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import {
  authActions,
  globalActions,
  referenceActions,
} from '../reducers/actions';
import Header from '../components/Header';
import {
  design, I18n, icons, TextSF, router, global, helper,
} from '../lib/general';
import MoreList from '../components/more/MoreList';
import Alert from '../components/general/Alert';

import YoutubeWebView from '../components/general/YoutubeWebView';
import { analytics } from '../lib/analytics';
import { config, getServerConfig } from '../lib/config';
import { SLIDES } from '../lib/slides';
import simpleShare from '../lib/simpleShare';
import AddToHomeScreen from '../../web/components/AddToHomeScreen';

const {
  GOOGLE_DOCS_URL, LEGAL, PRIVACY, CREDITS,
} = global;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scroll: {},
  down: {
    padding: 10,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  copyright: {
    color: design.colors.clinicSub,
    fontSize: design.text.xsmall,
  },
});

function mapStateToProps(state) {
  return {
    deviceVersion: state.device.version,
    profile: state.profile,
    global: {
      currentState: state.global.currentState,
      currentUser: state.global.currentUser,
      showState: state.global.showState,
      env: state.global.env,
      isDesktop: state.global.isDesktop,
    },
    patient: {
      serverConfig: state.patient.serverConfig,
      currentPatient: state.patient.currentPatient,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...authActions,
        ...globalActions,
        ...referenceActions,
      },
      dispatch,
    ),
  };
}
let timeout = null;

class More extends PureComponent {
  static updateOptions(options, serverConfig) {
    let webOptions = options.map((temp) => {
      const item = temp;
      switch (item.actionVal) {
        case 'legal':
          item.link = serverConfig.termsOfUsePDF || LEGAL;
          break;
        case 'privacy':
          item.link = serverConfig.privacyPolicyPDF || PRIVACY;
          break;
        case 'credits':
          item.link = serverConfig.creditsPDF || CREDITS;
          break;
        default:
          break;
      }
      return item;
    });
    const browserSupportShare = navigator.share;
    if (!browserSupportShare) {
      webOptions = webOptions.filter((opt) => opt.name !== 'share');
    }
    return webOptions;
  }

  static clearTutorial() {
    SLIDES.clearAllMemory();
    helper.safeWindowReloadWithParameters();
  }

  static envToNumber(env) {
    let answer = 1;
    if (env === global.envEnum.PARSE_DEV) {
      answer = 2;
    } else if (env === global.envEnum.PARSE_STAGE) {
      answer = 3;
    }
    return answer;
  }

  constructor(props) {
    super(props);
    const { actions } = props;
    this.state = {
      showYoutube: false,
    };
    this.ActionSheet = null;
    this.Alert = null;
    actions.feedback = this.feedback;
    this.onPressOpenDoc = this.onPressOpenDoc.bind(this);
    this.onPress = this.onPress.bind(this);
  }

  componentWillUnmount() {
    if (timeout) {
      clearTimeout(timeout);
    }
  }

  onPress(item) {
    const { actions, closeMenu } = this.props;
    if (closeMenu) closeMenu(); // close the top menu
    switch (item.action) {
      case 'clearTutorial':
        More.clearTutorial();
        break;
      case 'share':
        simpleShare.shareApp();
        break;
      case 'changeCampaign':
        actions.toggleCampaignSelector(true);
        break;
      case 'action':
        if (item.warning) {
          const header = item.warningHeader || I18n.t('Patient.submitConfirmHeaderDelete');
          const body = item.warningBody || '';
          this.Alert.alert(
            header,
            body,
            [
              {
                text: I18n.t('Patient.cancel'),
              },
              {
                text: I18n.t('Patient.confirm'),
                onPress: () => actions[item.actionVal](),
              },
            ],
          );
        } else {
          actions[item.actionVal]();
        }
        break;
      case 'youtube':
        this.setState({ showYoutube: true });
        break;
      case 'goto':
        router.goToPage(item.actionVal);
        break;
      case 'openDoc':
        this.onPressOpenDoc(item.actionVal);
        break;
      default:
        break;
    }
    return true;
  }

  onPressOpenDoc(type) {
    const { actions, patient } = this.props;
    const { setRefItem } = actions;
    const { serverConfig } = patient;
    let url = serverConfig.termsOfUsePDF || LEGAL;
    let name = I18n.t('Login.legal');
    if (type === 'privacy') {
      url = serverConfig.privacyPolicyPDF || PRIVACY;
      name = I18n.t('Login.privacy');
    }
    if (type === 'credits') {
      url = serverConfig.creditsPDF || CREDITS;
      name = I18n.t('Login.credits');
    }
    setRefItem({
      link: GOOGLE_DOCS_URL + url,
      header: name,
    });
  }

  feedback = () => {
    const { actions } = this.props;
    actions.loaderShow();
    timeout = setTimeout(actions.loaderHide, 1000);
    analytics.openSentryFeedback();
  };

  render() {
    const {
      profile,
      actions,
      deviceVersion,
      patient,
      hideHeader,
    } = this.props;

    const { isDesktop, currentUser } = this.props?.global;
    const {
      isChangeCampaignInMenu, youtubeVideoUrl, youtubeVideoUrlMobile, showYoutubeInMenu,
    } = config;
    const isAnonymous = currentUser?.isAnonymous || currentUser?.result?.isAnonymous || false;
    const OPTIONS = [
      {
        name: 'campaigns',
        title: I18n.t('Edocate.menuChangeCampaign'),
        action: 'changeCampaign',
        actionVal: 'Campaigns',
        iconType: 'image',
        icon: icons.settings,
      },
      {
        name: 'profile',
        title: I18n.t('Edocate.profile'),
        action: 'goto',
        actionVal: 'Profile',
        iconType: 'image',
        icon: icons.settings,
      },
      {
        name: 'faq',
        title: I18n.t('Edocate.faq'),
        action: 'goto',
        actionVal: 'FAQ',
        iconType: 'image',
        icon: icons.FAQs,
      },
      {
        name: 'share',
        title: I18n.t('Share.shareBtnText'),
        action: 'share',
        actionVal: '',
        iconType: 'image',
        icon: icons.share_blue,
      },
      {
        name: 'feedback',
        title: I18n.t('Edocate.feedback'),
        action: 'goto',
        actionVal: 'ContactUs',
        iconType: 'image',
        icon: icons.chat,
      },
      {
        name: 'intro',
        title: I18n.t('Edocate.intro'),
        action: 'goto',
        actionVal: 'Walkthrough',
        iconType: 'image',
        icon: icons.intro,
      },
      {
        name: 'termsOfUse',
        title: I18n.t('Login.legal'),
        action: 'openDoc',
        actionVal: 'legal',
        iconType: 'font',
        icon: icons.legal,
        warning: false,
        adminOnly: false,
      },
      {
        name: 'privacyPolicy',
        title: I18n.t('Login.privacy'),
        action: 'openDoc',
        actionVal: 'privacy',
        iconType: 'font',
        icon: icons.privacy,
        warning: false,
        adminOnly: false,
      },
      {
        name: 'credits',
        title: I18n.t('Login.credits'),
        action: 'openDoc',
        actionVal: 'credits',
        iconType: 'font',
        icon: icons.notices,
        warning: false,
        adminOnly: false,
      },
      {
        name: 'youtube',
        title: I18n.t('Edocate.youtube'),
        action: 'youtube',
        actionVal: 'outline_play_circle',
        iconType: 'image',
        icon: icons.youtube,
      },
      {
        name: 'logout',
        title: I18n.t('Edocate.logout'),
        action: 'action',
        actionVal: 'logout',
        iconType: 'font',
        icon: icons.logout,
        warning: true,
        warningHeader: I18n.t('More.logoutConfirmHeader'),
        warningBody: I18n.t('More.logoutConfirmBody'),
      },
    ];
    const toRemove = [];
    if (!isChangeCampaignInMenu) {
      toRemove.push('campaigns');
    }

    if (!showYoutubeInMenu) {
      toRemove.push('youtube');
    }
    if (isAnonymous) {
      toRemove.push('logout');
      toRemove.push('profile');
    }

    const youTubeUrl = isDesktop ? youtubeVideoUrl : youtubeVideoUrlMobile;
    const { showAddToHomeScreen, appName, skipMoreMenu = [] } = config;
    const filteredOptions = OPTIONS.filter((op) => [...skipMoreMenu, ...toRemove].indexOf(op.name) === -1);
    const webOptions = More.updateOptions(filteredOptions, patient.serverConfig);
    const { showYoutube } = this.state;
    const activeCampaign = profile.form.activeCampaign || profile.form.fields.activeCampaign;
    const cText = activeCampaign ? `${I18n.t('Campaign.id', { locale_en: 'c' })}: ${activeCampaign}` : '';
    return (
      <View style={styles.container}>
        {!hideHeader && (
          <Header
            isFetching={profile.form.isFetching}
            showState={global.showState}
            currentState={global.currentState}
            onGetState={actions.getState}
            onSetState={actions.setState}
            hideImage
            title={I18n.t('Edocate.more')}
          />
        )}
        <Alert
          ref={(alert) => {
            this.Alert = alert;
          }}
        />
        <YoutubeWebView
          uri={youTubeUrl}
          modal
          isDesktop
          isVisible={showYoutube}
          onClose={() => { this.setState({ showYoutube: false }); }}
        />
        <ScrollView style={styles.scroll}>
          <MoreList
            data={webOptions}
            onPress={this.onPress}
            isAdmin={profile.form.fields.isAdmin}
          />
        </ScrollView>
        <View style={styles.down}>

          <TextSF style={styles.copyright}>{I18n.t('More.copyright')}</TextSF>
          <TextSF style={styles.copyright}>
            {`${I18n.t('App.version')}: ${deviceVersion} web `}
          </TextSF>
          <TextSF style={styles.copyright}>{appName}</TextSF>
          {patient.currentPatient && (
          <TextSF style={styles.copyright}>
            {`${I18n.t('Scenario.id')}: ${patient.currentPatient.objectId} `}
          </TextSF>
          )}
          {!!activeCampaign
          && (
          <TextSF style={styles.copyright}>
            {cText}
          </TextSF>
          )}
        </View>
        <AddToHomeScreen />
      </View>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(More);
