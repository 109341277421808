/**
 * # globalActions.js
 *
 * Actions that are global in nature
 */

/**
 * ## Imports
 *
 * The actions supported
 */

import DeviceInfo from '../../lib/deviceInfo';
import memory from '../../lib/Memory';
import { router, global } from '../../lib/general';

const {
  SET_SESSION_TOKEN,
  SET_STORE,
  SET_STATE,
  GET_STATE,
  INTRO_DONE,
  INTRO_SKIP,
  ALERT_ON,
  ALERT_OFF,
  SET_ENV,
  SET_ENV_APP,
  SET_WEB,
  SET_DESKTOP,
  CHANGE_CAMPAIGN_TOGGLE,
  SHOW_TUTORIAL,
  SHOW_CLINICAL_HELP_ICON,
  SHOW_TUTORIAL_ICON,
  SHOW_CLINICAL_HELP_MODAL,
  SET_TABLET,
} = require('../../lib/constants').default;

/**
 * ## set the sessionToken
 *
 */
export function setSessionToken(sessionToken) {
  return { type: SET_SESSION_TOKEN, payload: sessionToken };
}
/**
 * ## set the store
 *
 * this is the Redux store
 *
 * this is here to support Hot Loading
 *
 */
export function setStore(store) {
  return { type: SET_STORE, payload: store };
}
/**
 * ## set state
 *
 */
export function setState(newState) {
  return { type: SET_STATE, payload: newState };
}
/**
 * ## getState
 *
 */
export function getState(toggle) {
  return { type: GET_STATE, payload: toggle };
}

export function introSkip() {
  return { type: INTRO_SKIP, payload: true };
}

export function introDone() {
  return { type: INTRO_DONE, payload: true };
}

export function endOfIntro(isSkip, fromSettings) {
  // debugger
  return (dispatch) => {
    if (isSkip) {
      dispatch(introSkip());
    } else {
      dispatch(introDone());
    }
    memory.setItem('intro', true);
    if (fromSettings) {
      // Actions.pop()
      router.goBack();
    } else {
      //  Actions.App()
      router.goToPage('AuthLoading');
      //  router.replacePage('App')
    }
  };
}
export function setEnvDispatch(env) {
  return { type: SET_ENV, payload: env };
}

export function setEnv(env) {
  return (dispatch) => {
    dispatch(setEnvDispatch(env));
  };
}

export function setApplicationEnvironmentDispatch(environment) {
  return { type: SET_ENV_APP, payload: environment };
}
export function setApplicationEnvironment(envFromStorage) {
  // from memory
  // bundle id
  const bundleId = DeviceInfo.getBundleId();
  let environment = global.envEnum.PARSE_APP;
  switch (envFromStorage) {
    case global.envEnum.PARSE_DEV:
    case global.envEnum.PARSE_STAGE:
    case global.envEnum.PARSE_APP:
      environment = envFromStorage;
      break;
    default:
      // changes to support different apps by the bundle ID
      if (bundleId.indexOf(global.bundleSearchPhrase.PARSE_DEV) > -1) {
        environment = global.envEnum.PARSE_DEV; // take dev as default without any other?
      } else if (bundleId.indexOf(global.bundleSearchPhrase.PARSE_STAGE) > -1) {
        environment = global.envEnum.PARSE_STAGE; // take dev as default without any other?
      } else {
        environment = global.envEnum.PARSE_APP; // take dev as default without any other?
      }
  }
  return (dispatch) => {
    dispatch(setApplicationEnvironmentDispatch(environment));
  };
}
export function setAlertOn(obj) {
  return { type: ALERT_ON, payload: obj };
}

export function setAlertOff(obj) {
  return { type: ALERT_OFF, payload: obj };
}

export function setDesktop(obj) {
  return { type: SET_DESKTOP, payload: obj };
}

export function setTablet(obj) {
  return { type: SET_TABLET, payload: obj };
}
export function toggleCampaignSelector(toggle) {
  return { type: CHANGE_CAMPAIGN_TOGGLE, payload: toggle };
}

export function toggleTutorial(toggle) {
  return { type: SHOW_TUTORIAL, payload: toggle };
}

export function toggleClinicalHelpIconDisplay(toggle) {
  return { type: SHOW_CLINICAL_HELP_ICON, payload: toggle };
}

export function toggleTutorialIconDisplay(toggle) {
  return { type: SHOW_TUTORIAL_ICON, payload: toggle };
}

export function toggleClinicalHelpModal(toggle) {
  return { type: SHOW_CLINICAL_HELP_MODAL, payload: toggle };
}

export function setWeb(obj) {
  return { type: SET_WEB, payload: obj };
}
export function setAlertMessage(isOn, head, sub) {
  if (isOn) {
    return (dispatch) => {
      dispatch(setAlertOn({ head, sub }));
    };
  }
  return (dispatch) => {
    dispatch(setAlertOff({ head, sub }));
  };
}
