import { Record } from 'immutable';

const InitialState = Record(
  {
    notifications: [],
    unRead: { unRead: 0, ref: null },
    loggedIn: false,
  },
  'notification',
);

export default InitialState;
