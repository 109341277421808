import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WebView from 'WebView';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {
  View, Platform, TouchableOpacity, StyleSheet,
} from 'react-native';
import Modal from 'react-native-modal';
import { design } from '../../../lib/general';

const isWeb = Platform.OS === 'web';
const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1,
    backgroundColor: 'rgba(57, 67, 91, 0.95)',
    padding: 30,
  },
  containerMobile: {
    position: 'relative',
    flex: 1,
    backgroundColor: 'rgba(57, 67, 91, 0.95)',
    // paddingTop: isDesktop ? 30 : 90,
    marginTop: 90,
  },
  containerNoBackground: {
    backgroundColor: design.colors.baseGray,
    marginTop: 0,
    // maxHeight: isDesktop ? 720 : 320
  },
  modal: {
    flex: 1,
    margin: 0,
  },
  mobileViewWrap: {
    flex: 0.4,
    // height: isDesktop ? 720 : 320,
  },
  slideWrapper: {
    backgroundColor: design.colors.white,
    paddingTop: 80,
    paddingBottom: 80,
    borderRadius: 3,
    position: 'relative',
  },
  headerContainer: {
    marginBottom: 30,
  },
  header: {
    color: design.colors.black,
    // fontSize: isDesktop ? design.text.twentyFive : design.text.twenty,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  closeView: {
    position: 'absolute',
    right: 2,
    top: 2,
  },
  webView: {
    backgroundColor: 'transparent',
  },
});

export default function YoutubeWebView({
  uri, modal, isVisible, onClose,
}) {
  const isDesktop = useSelector((s) => s.global.isDesktop);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(isVisible);
  }, [isVisible]);

  const close = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  return (modal
    ? (
      <Modal
        onRequestClose={() => setShow(false)}
        backdropColor={design.colors.backdrop}
        backdropOpacity={0.95}
        onBackdropPress={() => setShow(false)}
        supportedOrientations={['portrait', 'landscape']}
        useNativeDriver
        hideModalContentWhileAnimating
        style={[styles.modal]}
        isVisible={show}
        hasBackdrop={false}
        animationInTiming={500}
      >
        <View style={[styles.container, isWeb && styles.containerNoBackground]}>
          {onClose && (
          <View style={styles.closeView}>
            <TouchableOpacity
              onPress={close}
              style={[styles.icoContainer, isDesktop && styles.icoContainerDesktop]}
            >
              <Icon
                name="close"
                size={design.text.title2}
                color={design.colors.clinicHeader}
              />
            </TouchableOpacity>
          </View>
          )}

          <WebView
            originWhitelist={['*']}
            allowsFullscreenVideo
            allowsInlineMediaPlayback
            mediaPlaybackRequiresUserAction
            source={{ uri }}
          />
        </View>
      </Modal>
    )
    : (
      <WebView
        originWhitelist={['*']}
        allowsFullscreenVideo
        allowsInlineMediaPlayback
        mediaPlaybackRequiresUserAction
        source={{ uri }}
      />
    )
  );
}
