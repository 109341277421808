import { useEffect } from 'react';
import { AppState } from 'react-native';
import { useSelector } from 'react-redux';
import { analytics } from '../../../lib/analytics';

let localRouteName = null;
export default function AnalyticsUnMountGlobal() {
  const routes = useSelector((s) => s.routes.currentRouteName);

  useEffect(() => {
    localRouteName = routes;
  }, [routes]);

  const handleAppStateChange = (state) => {
    if (state !== 'active' && localRouteName) {
      localRouteName = localRouteName === 'App' ? 'Clinic' : localRouteName;
      analytics.logCustom(`${localRouteName ? `${localRouteName} ` : ''}DropOff`);
    }
  };

  useEffect(() => {
    const subscription = AppState.addEventListener('change', handleAppStateChange);
    return (() => {
      subscription.remove();
    });
  }, []);

  return null;
}
