import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import Modal from 'modal-enhanced-react-native-web';
import MediaQuery from '../../src/components/general/MediaQuery';
import { global } from '../../src/lib/general';

const styles = StyleSheet.create({
  all: {
    alignSelf: 'center',
    width: '90%',
    // marginHorizontal: 20,
    // flex: 1,
    // alignItems: 'center',
  },
  maxWidth: {
    maxWidth: global.modalMaxWidthDesktop, // 90 percent of max width
    marginVertical: 0,
    marginHorizontal: 0,
    justifyContent: 'center',
  },
});

const ModalWrapper = memo((props) => {
  const {
    isVisible, style, useNativeDriver, noMaxWidth, ...other
  } = props;
  if (!isVisible) return null;

  return (
    <MediaQuery maxWidth={global.mobileMaxWidth}>
      {(matches) => (matches ? (
        <Modal
          isVisible={isVisible}
          style={[styles.all, style]}
          useNativeDriver={false}
          disableAnimation
          animationInTiming={1}
          animationOutTiming={1}
          {...other}
        />
      ) : (
        <Modal
          isVisible={isVisible}
          style={[styles.all, !noMaxWidth && styles.maxWidth, style]}
          useNativeDriver={false}
          disableAnimation
          animationInTiming={1}
          animationOutTiming={1}
          {...other}
        />
      ))}
    </MediaQuery>
  );
});

export default ModalWrapper;
