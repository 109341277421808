/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */
/* eslint-disable no-bitwise */
/* eslint-disable max-len */
/* eslint-disable no-undef */
import each from 'lodash/each';
import find from 'lodash/find';
// import max from 'lodash/max';
// import min from 'lodash/min';
import range from 'lodash/range';
import groupBy from 'lodash/groupBy';

import I18n from 'i18n-js';
import icons from '../images/icons';
import memory from './Memory';
import navigator from './router/navigator';

const helper = {
  firstTime: {
    get: async () => memory.getItem('firstTime'),
    set: async () => {
      const result = await memory.getItem('firstTime');
      if (result === null) {
        await memory.setItem('firstTime', true);
      } else {
        await memory.setItem('firstTime', false);
      }
      return memory.getItem('firstTime');
    },
  },
  getAllColors: () => {
    const colors = [
      '#ffc100',
      '#759cfb',
      '#4cd18d',
      '#ff8ec8',
      '#ff7075',
      '#02b491',
      '#a2e1ff',
    ];
    return colors;
  },
  getRandomColor: () => {
    const colors = helper.getAllColors();
    const color = colors[Math.floor(Math.random() * colors.length)];
    return color;
  },
  guidGenerator: () => {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  },
  randomInt: (min, max) => Math.floor(Math.random() * (max - min + 1) + min),
  permutation: (left = [], right = [], separator = '/') => {
    const result = [];
    if (left.length === 0) return right;
    if (right.length === 0) return left;
    left.forEach((a1) => {
      right.forEach((a2) => {
        result.push(`${a1}${separator}${a2}`);
      });
    });
    return result;
  },
  complaints2Score: (complaints) => {
    let score = 0;
    each(complaints, (complaint) => {
      if (complaint.score) {
        score += complaint.score;
      }
    });
    return score;
  },
  colorToGradient: (selectedColor) => {
    const color = selectedColor?.toLowerCase();
    let updateObj = {
      startColor: '#FF8EC8',
      endColor: '#F166AD',
      x1: '16.06%',
      y1: '-9.21%',
      x2: '83.94%',
      y2: '109.21%',
    };
    switch (color) {
      case '#ffa039':
        updateObj = {
          startColor: '#FFA039',
          endColor: '#EF922D',
          x1: '0.5%',
          y1: '-0.49%',
          x2: '99.5%',
          y2: '100.49%',
        };
        break;
      case '#759cfb':
        updateObj = {
          startColor: '#759CFB',
          endColor: '#6289E8',
          x1: '50%',
          y1: '0%',
          x2: '50%',
          y2: '100%',
        };
        break;
      case '#4cd18d':
        updateObj = {
          startColor: '#4CD18D',
          endColor: '#32B270',
          x1: '16.09%',
          y1: '-9.21%',
          x2: '83.91%',
          y2: '109.21%',
        };
        break;
      case '#ffc100':
        updateObj = {
          startColor: '#FFC100',
          endColor: '#F0B705',
          x1: '16.08%',
          y1: '-9.21%',
          x2: '83.92%',
          y2: '109.21%',
        };
        break;
      case '#ff8ec8':
        updateObj = {
          startColor: '#FF8EC8',
          endColor: '#F166AD',
          x1: '16.06%',
          y1: '-9.21%',
          x2: '83.94%',
          y2: '109.21%',
        };
        break;
      case '#02b491':
        updateObj = {
          startColor: '#02B491',
          endColor: '#019E83',
          x1: '16.09%',
          y1: '-9.21%',
          x2: '83.91%',
          y2: '109.21%',
        };
        break;

      case '#ff7075':
        updateObj = {
          startColor: '#FF7075',
          endColor: '#EE545A',
          x1: '16.09%',
          y1: '-9.21%',
          x2: '83.91%',
          y2: '109.21%',
        };
        break;

      case '#a2e1ff':
        updateObj = {
          startColor: '#7EC6E9',
          endColor: '#5BB1DC',
          x1: '16.06%',
          y1: '-9.21%',
          x2: '83.94%',
          y2: '109.21%',
        };
        break;
      case '#b977e1':
        updateObj = {
          startColor: '#B977E1',
          endColor: '#8037AC',
          x1: '16.09%',
          y1: '-9.21%',
          x2: '83.91%',
          y2: '109.21%',
        };
        break;
      default:
        updateObj = {
          startColor: '#7EC6E9',
          endColor: '#5BB1DC',
          x1: '16.06%',
          y1: '-9.21%',
          x2: '83.94%',
          y2: '109.21%',
        };
        break;
    }
    return updateObj;
  },
  selectOneFromArrayWithFactor: (values) => {
    // debugger;
    let i;
    let pickedValue;
    const randomNr = Math.random() * 100; // i use 30 70 100 etc
    let threshold = 0;
    for (i = 0; i < values.length; i += 1) {
      // if (values[i].factor === '*') {
      //   continue;
      // }
      threshold += values[i].factor;
      if (threshold > randomNr) {
        pickedValue = values[i];
        break;
      }
      if (!pickedValue) {
        // nothing found based on factor value, so pick element marked with wildcard
        pickedValue = values.find((value) => value.factor === '*');
      }
    }

    const answer = pickedValue;
    // console.log('selectOneFromArrayWithFactor', randomNr, pickedValue, pickedValue.text);
    return answer;
  },
  removeUnusedDataPhysical: (item) => {
    // array removal
    const obj = { ...item };
    // debugger;
    return obj;
  },
  interpolate: (str, data) => {
    // add defence
    try {
      if (!str || !str.replace) return str;
      if (!data) return str;
      // str = '{{parameters.cardiovascular.valueText.3}}';
      // str = '{{parameters.rash.valueText.current}}';
      return str.replace(/{{([^{}]*)}}/g, (fullString, value) => {
        const valueArray = value.split('.');
        const [type, prop, extra, spare] = valueArray;
        const selectedData = data[type];
        let val = 0;
        let element = 0; // get the value....
        let numOfFixed = 0;

        switch (type) {
          case 'sim':
          case 'story':
            element = selectedData.data[prop];
            val = element; // in sim for example
            break;
          case 'parameters':
            element = find(selectedData.data, { name: prop });
            if (!element) {
              console.warn('interpolate cant find parameter', str);
              return str;
            }
            if (extra && spare) {
              if (extra === 'valueText' && spare === 'current') {
                // debugger;
                val = element[extra][element.valueAfterMed];
              } else {
                val = element[extra][spare];
              }
            } else {
              val = element[extra || 'valueAfterMed'];
            }
            // val = element[extra || 'valueAfterMed']?.spare;
            ({ numOfFixed } = element);
            break;
          default:
            if (selectedData && selectedData.get) {
              element = selectedData.get(prop);
              val = element; // in sim for example
            }

            break;
        }
        let num = Number(val);
        if (Number.isNaN(num)) {
          return val; // for strings
        }
        num = Number(num.toFixed(numOfFixed));
        return num;
      });
    } catch (error) {
      console.warn(error);
      return str;
    }
  },
  getTabNameAndColor(name) {
    const answer = {
      ico: '',
      color: '',
      imageOn: '',
      imageOff: '',
      width: 20,
      height: 20,
    };
    switch (name) {
      case I18n.t('Screens.history'):
        answer.ico = 'handshake-o';
        answer.color = '#ffa039';
        answer.imageOn = icons.historyOn;
        answer.imageOff = icons.history;
        break;
      case I18n.t('Screens.overview'):
        answer.ico = 'eye';
        answer.color = '#759cfb';
        answer.imageOn = icons.overviewOn;
        answer.imageOff = icons.overviewOff;
        break;
      case I18n.t('Screens.diagnosis'):
        answer.ico = 'sticky-note';
        answer.color = '#4cd18d';
        answer.imageOn = icons.diagnosesOn;
        answer.imageOff = icons.diagnoses;
        break;
      case I18n.t('Screens.interview'):
        answer.ico = 'commenting-o';
        answer.color = '#ffc100';
        answer.imageOn = icons.complaintOn;
        answer.imageOff = icons.complaints; // missing
        break;
      case I18n.t('Screens.physical'):
        answer.ico = 'clipboard';
        answer.color = '#ff8ec8';
        answer.imageOn = icons.physicalOn;
        answer.imageOff = icons.physicalCopy2;
        break;
      case I18n.t('Screens.lab'):
        answer.ico = 'flask';
        answer.color = '#02b491';
        answer.imageOn = icons.labOn;
        answer.imageOff = icons.lab;
        break;

      case I18n.t('Screens.treatments'):
        answer.ico = 'medkit';
        answer.color = '#ff7075';
        answer.imageOn = icons.treatmentsOn;
        answer.imageOff = icons.treatments;
        break;
      // case I18n.t('Screens.notes'):
      //   answer.ico = 'sticky-note-o'
      //   answer.color = '#ff7075'
      //   break
      case I18n.t('Screens.end'):
        answer.ico = 'sign-out';
        answer.color = '#a2e1ff';
        answer.imageOn = icons.endMeetingOn;
        answer.imageOff = icons.endsession;
        break;
      case I18n.t('Screens.referral'):
        answer.ico = 'sign-out';
        answer.color = '#B977E1';
        answer.imageOn = icons.referral_selected;
        answer.imageOff = icons.referral;
        break;
      default:
        answer.ico = 'sign-out';
        answer.color = '#a2e1ff';
        answer.imageOn = icons.historyOn;
        answer.imageOff = icons.history;
        break;
    }
    return answer;
  },
  get_url_extension(url) {
    return url
      .split(/\#|\?/)[0]
      .split('.')
      .pop()
      .trim();
  },
  checkIfGoogleViewer(ext) {
    const isViewer = /.*(jpg|gif|png|doc|docx|pdf|xls|xlsx|ppt|pptx)$/.test(ext.toLowerCase());
    return isViewer;
  },
  checkIfGoogleViewerWeb(ext) {
    const isViewer = /.*(doc|docx|xls|xlsx|ppt|pptx)$/.test(ext.toLowerCase());
    return isViewer;
  },
  cloudinaryThumbnailFormVideo(file) {
    if (!file || file.indexOf('cloudinary') === -1) {
      return null;
    }
    const url = `${file.substr(0, file.lastIndexOf('.'))}.jpg`;
    return url;
  },

  tagsFromFuseMatches(arr, value) {
    const result = arr
      .filter((match) => match.key === 'aliases')
      .map((mat) => mat.value)
      .filter((another) => another !== value);
    return result;
  },
  tagsFromStringArray(arr, value) { return [arr.find((a) => a.includes(value))]; },
  groupByMulti(list, values, context) {
    if (!values.length) {
      return list;
    }
    const byFirst = groupBy(list, values[0], context);
    const rest = values.slice(1);
    Object.keys(byFirst).forEach((prop) => {
      byFirst[prop] = this.groupByMulti(byFirst[prop], rest, context);
    });
    return byFirst;
  },
  getLastSegmentFromString(str, segment) {
    if (!str) return null;
    const parts = str.split(segment);
    const lastSegment = parts.pop() || parts.pop();
    return lastSegment;
  },
  pageToTab(page) {
    let answer = 1;
    switch (page) {
      case 'AddLab':
        answer = 3;
        break;
      case 'AddPhysical':
        answer = 2;
        break;
      case 'AddDiagnosis':
        answer = 5;
        break;
      case 'AddTreatment':
        answer = 6;
        break;
      case 'AddReferral':
        answer = 4;
        break;
      default:
        answer = 1;
    }
    return answer;
  },
  isRtlContainLtr(s) {
    try {
      if (!s) {
        return false;
      }
      const rgxTst = new RegExp(/[a-zA-Z][a-zA-Z0-9]*/);
      return rgxTst.test(s);
    } catch (err) {
      console.log('isRTL catch', s, err);
      return false;
    }
  },
  isRTL(s) {
    try {
      let theString = '';
      if (!s) {
        return false;
      }
      if (s?.hasOwnProperty('$$typeof')) {
        return false;
      }
      if (typeof s === 'string') {
        theString = s;
      } else if (typeof s?.children === 'string') {
        theString = s.children;
      } else if (Array.isArray(s)) { // array
        return false;
      } else {
        theString = JSON.stringify(s);
      }
      const rtlChars = '\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC';
      const rtlDirCheck = new RegExp(`^[^${rtlChars}]*?[${rtlChars}]`);
      const withOutHtml = theString.replace(/<[^>]*>?/gm, '');
      return rtlDirCheck.test(withOutHtml);
    } catch (err) {
      console.log('isRTL catch', s, err);
      return false;
    }
  },
  parseTemplate(template, map, fallback) {
    return template.replace(/\$\{[^}]+\}/g, (match) => match
      .slice(2, -1)
      .trim()
      .split('.')
      .reduce(
        (searchObject, key) => searchObject[key] || fallback || match,
        map,
      ));
  },
  getNumberFromString(str) {
    if (isNaN(str)) {
      return parseFloat(str.replace(/\D/g, ''));
    }
    return str;
  },
  parseDosageString(str) {
    let temp = String(str);
    const spacePosition = temp.indexOf(' ');
    if (spacePosition > -1) {
      temp = temp.substring(0, spacePosition);
    }
    return temp;
  },
  getDosageDisplay({
    oldInterval,
    oldDosage,
    dosagePerInterval,
    dosageText,
    frequency,
  }) {
    if (oldInterval.length && oldDosage.length && !dosagePerInterval.length) {
      const result = [];
      oldDosage.forEach((dosage) => {
        oldInterval.forEach((interval) => {
          result.push(`${dosage} ${dosageText}, ${interval}/${frequency}`);
        });
      });
      return result;
    }

    if (!oldInterval.length) {
      return [`${oldDosage.length ? `${oldDosage.toString()} ${dosageText} ` : ''}${frequency}`];
    }
    return dosagePerInterval.map((dosagePerIntervalItem) => {
      const [dosageForInterval, dosageInterval] = dosagePerIntervalItem?.split(',');
      return `${dosageForInterval} ${dosageText}, ${dosageInterval}/${frequency}`;
    });
  },
  isValidZip(str) {
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(str);
    return isValidZip;
  },
  truncateWithEllipses(text, limit) {
    if (text.length > limit) {
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' '
          && (text.charAt(i - 1) != ','
            || text.charAt(i - 1) != '.'
            || text.charAt(i - 1) != ';')
        ) {
          return `${text.substring(0, i)}...`;
        }
      }
      return `${text.substring(0, limit)}...`;
    }
    return text;
  },

  hashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i += 1) {
      const chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  },
  reverseString(str) {
    // Step 1. Use the split() method to return a new array
    const splitString = str.split(''); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    const reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    const joinArray = reverseArray.join(''); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    // Step 4. Return the reversed string
    return joinArray; // "olleh"
  },
  normalizeArrayOfXYObjects(arr, step = 1) {
    const sortArrByX = arr.sort((a, b) => a.x - b.x);
    // const allX = sortArrByX.map((item) => item.x);
    // let currentX = min(allX);
    const out = [];
    for (let index = 0; index < sortArrByX.length; index += 1) {
      const { x, y } = sortArrByX[index];
      if (index > 0) {
        const prevObj = sortArrByX[index - 1];
        const diffY = y - prevObj.y; // 0.4
        const diffX = x - prevObj.x; // 3
        const stepY = Number((diffY / diffX));
        const rangY = range(prevObj.y, y, stepY);
        rangY.shift();
        // console.log(prevObj, rangY);
        for (let j = 0; j < diffX - step; j += 1) {
          const tempY = rangY[j] || y;
          out.push({ x: prevObj.x + j + step, y: tempY, fake: true });
        }
        // rangY.forEach((tempY, i) => {
        //   out.push({ x: prevObj.x + i + 1, y: tempY, fake: true });
        // });
      }
      out.push({ x, y });
    }
    return out;
  },
  isChrome() {
    try {
      if (!window) return false;
      return /Chrome/.test(window?.navigator?.userAgent) && /Google Inc/.test(window?.navigator?.vendor);
    } catch (error) {
      return false;
    }
  },
  isSafari() {
    try {
      if (!window) return false;
      return /Safari/.test(window?.navigator?.userAgent) && /Apple Computer/.test(window?.navigator?.vendor);
    } catch (error) {
      return false;
    }
  },
  isIos() {
    try {
      if (!window) return false;
      return /iphone|ipad|ipod/.test(window?.navigator?.userAgent?.toLowerCase());
    } catch (error) {
      return false;
    }
  },
  isInStandaloneMode() {
    try {
      if (!window) return false;
      return 'standalone' in navigator && navigator?.standalone;
    } catch (error) {
      return false;
    }
  },
  isFirefox() {
    try {
      if (!window) return false;
      return window?.navigator?.userAgent.toLowerCase().indexOf('firefox') > -1;
    } catch (error) {
      return false;
    }
  },
  generateTemplateString: (function () {
    const cache = {};

    function generateTemplate(template = '') {
      if (!template?.replace) return (a) => a;
      let fn = cache[template];

      if (!fn) {
        // Replace ${expressions} (etc) with ${map.expressions}.
        const sanitized = template
          .replace(/\$\{([\s]*[^;\s\{]+[\s]*)\}/g, (_, match) => `\$\{map.${match.trim()}\}`)
        // Afterwards, replace anything that's not ${map.expressions}' (etc) with a blank string.
          .replace(/(\$\{(?!map\.)[^}]+\})/g, '');
        // eslint-disable-next-line no-new-func
        fn = Function('map={}', `return \`${sanitized}\``);
      }
      return fn;
    }

    return generateTemplate;
  }()),
  isFullParameter: (parameter) => parameter?.valueType, // pointer has objectId and class only
  percentToDecimal: (percent) => {
    const myFloat = parseFloat(percent);
    if (!isNaN(myFloat)) {
      return myFloat / 100.0;
    }
    return 0.5;
  },
  firstFromArrayOrStr: (arrOrStr) => {
    if (Array.isArray(arrOrStr)) {
      return arrOrStr.find(true);
    }
    return arrOrStr;
  },
  mergeObjectsWithNewKeysOnly: (obj1, obj2) => {
    const out = JSON.parse(JSON.stringify(obj1));
    if (typeof obj2 === 'object') {
      Object.entries(obj2).forEach(([key, val]) => {
      // console.log(key, val);
        if (!Object.prototype.hasOwnProperty.call(out, key)) {
        // console.log('adding', key, val);
          out[key] = val;
        }
      });
    }
    return out;
  },
  countCompletedClinics: (clinics) => {
    let completed = 0;
    if (!clinics) return completed;
    clinics.forEach((clinic) => {
      if (clinic.complete) {
        completed += 1;
      }
    });
    return completed;
  },
  calcImageModalMaxWidth: (maxWidth) => maxWidth - 80,
  safeWindowReload: () => {
    if (!window) return;
    window.location.href = window.location.origin;
  },
  safeWindowReloadWithParameters: () => {
    // get params
    const params = navigator.getUrlParameters();
    if (!window) return;
    window.location.href = window.location.origin + (params || '');
  },
  getJustTheText(current, date, showDaysAgo) {
    let text = '';

    const value = current - date;
    if (value === 0) {
      text = I18n.t('Patient.now');
    } else {
      const agoText = showDaysAgo
        ? I18n.t('Patient.monthAgoLocalizedShort', { count: value })
        : I18n.t('Patient.monthAgoLocalized', { count: value });
      text = agoText;
    }
    return text;
  },
  getTreatmentText(data = {}, hideTreatmentBrand) {
    let txt = '';
    const {
      dosage, interval, duration, alias, name, dbObj, hiddenFields,
    } = data;
    const hidden = dbObj?.hiddenFields || hiddenFields || [];
    const isDosageVisible = !hidden?.includes('dosage');
    const isAliasVisible = !hidden.includes('alias');
    const isDurationVisible = !hidden.includes('duration');
    const isIntervalVisible = !hidden.includes('interval');
    // const isHtmlVisible = !hidden.includes('html');

    const durationText = duration && duration !== 'long-term' ? duration : '';
    if (isAliasVisible && !hideTreatmentBrand && (dbObj?.displayName !== alias && name !== alias)) {
      txt = alias ? `${alias}` : '';
    }
    if (isDosageVisible && dosage && dosage.length) txt = `${txt.length ? `${txt} | ` : ''}${dosage}`;
    if (isIntervalVisible && interval && interval.length) txt = `${txt.length ? `${txt} | ` : ''}${interval}`;
    if (isDurationVisible && durationText && durationText.length) txt = `${txt.length ? `${txt} | ` : ''}${durationText}`;
    return txt;
  },
  getTreatmentTextSvg(treatment) {
    const {
      dosage, dbObj, interval, dosagePerInterval,
    } = treatment;
    let txt = `${dbObj?.displayName}`;
    if (dosage) {
      txt = `${txt} ** ${dosage}`;
      if (interval && interval.length) txt = `${txt}, ${interval}`;
    } else if (dosagePerInterval && dosagePerInterval.length) {
      txt = `${txt} ** ${dosagePerInterval}`;
    }
    return txt;
  },
  isLocalhost() {
    try {
      const isLocalhost = Boolean(window.location.hostname === 'localhost' || window.location.hostname === '[::1]'
      || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
      return isLocalhost;
    } catch (error) {
      return false;
    }
  },
  getLocationOrigin() {
    try {
      return window.location.origin;
    } catch (error) {
      return '';
    }
  },
  objectToBase64(obj) {
    try {
      return btoa(JSON.stringify(obj));
    } catch (error) {
      return null;
    }
  },
  base64ToObject(base64Object) {
    try {
      return JSON.parse(atob(base64Object));
    } catch (error) {
      return null;
    }
  },
  isTablet() {
    try {
      const userAgent = window?.navigator?.userAgent?.toLowerCase();
      const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
      return isTablet;
    } catch (error) {
      return false;
    }
  },
  async sha256(source) {
    const sourceBytes = new TextEncoder().encode(source);
    const digest = await crypto.subtle.digest('SHA-256', sourceBytes);
    const resultBytes = [...new Uint8Array(digest)];
    return resultBytes.map((x) => x.toString(16).padStart(2, '0')).join('');
  },
  hashObject(obj) {
    const str = JSON.stringify(obj);
    try {
      const hash = this.hashCode(str);
      return hash;
    } catch (error) {
      console.log('hashObject crash', error);
      return str;
    }
  },
  createDosagePerInterval(dosage, interval, separator = ' ') {
    return `${dosage}${separator}${interval}`;
  },
  createDosageIntervalFromArray(
    dosagePerInterval = [],
    intervalSeparator = '\u00A0',
    dosageText = '',
    dosageSeparator = ' ',
    frequencySeparator = '/',
  ) {
    const arr = [];
    dosagePerInterval.forEach((couple) => {
      const [dosage, interval, frequency] = couple.split(';');
      let txt = `${dosage}`;
      if (dosageText) {
        txt += `${dosageText}`;
      }
      if (interval || frequency) {
        txt += `${intervalSeparator}`;
      }
      if (interval) {
        txt += `${interval}`;
      }
      if (frequency) {
        txt += `${frequencySeparator}${frequency}`;
      }
      arr.push(txt);
    });
    return arr;
  },
};
export default helper;
