/* eslint-disable react-native/no-inline-styles */
/**
 * ## TabIcon
 *
 * Displays the icon for the tab w/ color dependent upon selection
 */
import React, { PureComponent } from 'react';
import {
  StyleSheet, View, Image, Dimensions,
} from 'react-native';
// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {
  design, icons, TextSF, platformStyle,
} from '../../lib/general';

function mapStateToProps(state) {
  return {
    global: {
      isDesktop: state.global.isDesktop,
      isWeb: state.global.isWeb,
    },
    hasOneDone: state.clinic.hasOneDone,
  };
}

const styles = StyleSheet.create({
  focused: {
    height: 2,
    width: 155,
    position: 'absolute',
    bottom: 0,
  },
  tabContainer: {
    alignItems: 'center',
    height: 55,
  },
  tabWrap: {
    alignItems: 'center',
    marginTop: 13,
  },
  tabIcoStyle: {
    color: design.colors.white,
  },
  tabImageStyle: {
    height: 20,
    width: 20,
  },
  text: {
    flex: 1,
    marginTop: 5,
    fontWeight: '600',
    color: design.colors.white,
    fontSize: design.text.nine,
  },
  textDesktop: {
    fontSize: design.text.midSmall,
    marginTop: 15,
  },
  tabContainerDesktop: {
    height: 110,
    width: 110,
    justifyContent: 'center',
  },
  tabContainerDesktopActive: {
    backgroundColor: design.colors.tabBGFocusedDesktop,
  },
  tabContainerDesktopActiveLight: {
    backgroundColor: design.colors.baseGray,
  },
  redDot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: design.colors.redDot,
    position: 'absolute',
    top: 5,
    right: 20,
  },
  redDotDesktop: {
    top: 25,
    right: 30,
    height: 12,
    width: 12,
    borderRadius: 6,
  },
  shadow: {
    shadowOffset: { width: 0, height: 2 },
    shadowColor: design.colors.redDot,
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 1,
  },
});

class TabIcon extends PureComponent {
  render() {
    const {
      selected,
      unreadImageName,
      imageName,
      iconName,
      title,
      global,
      focused = selected,
      numOfIcons = 5,
      showRedDot,
      hasOneDone,
      lightMode,
      icoSize,
      tabStyle,
    } = this.props;
    // const unRead = notification.unRead.unRead && sceneKey === 'Notification';
    const unRead = false;
    const { width } = Dimensions.get('window');
    const calculatedWidth = width / numOfIcons;
    const isDesktop = global.isDesktop && global.isWeb;
    // // Put your default font styles here.
    return (
      <View
        style={[
          styles.tabContainer,
          { width: calculatedWidth },
          isDesktop && styles.tabContainerDesktop,
          isDesktop && focused && styles.tabContainerDesktopActive,
          isDesktop && focused && lightMode && styles.tabContainerDesktopActiveLight,
        ]}
      >
        <View style={[styles.tabWrap, tabStyle]}>
          {imageName ? (
            <Image
              source={unRead ? icons[unreadImageName] : icons[imageName]}
              style={styles.tabImageStyle}
              resizeMode="contain"
            />
          ) : (
            <Icon
              style={[styles.tabIcoStyle, lightMode && { color: design.colors.clinicHeader }]}
              name={iconName}
              size={icoSize || design.text.twenty}
            />
          )}
          <TextSF style={[styles.text, isDesktop && styles.textDesktop, lightMode && { color: design.colors.clinicHeader }]}>
            {title?.toUpperCase()}
          </TextSF>
        </View>
        {!isDesktop && (
          <View
            style={[
              styles.focused,
              platformStyle.tabIconFocus,
              {
                backgroundColor: focused
                  ? design.colors.white
                  : design.colors.tabBG,
                width: (title?.length ? title?.length * 7 : 50),
              },
            ]}
          />
        )}
        {showRedDot && (
          <View style={[styles.redDot, styles.shadow, isDesktop && styles.redDotDesktop]} />
        )}
      </View>
    );
  }
}
export default connect(mapStateToProps, null)(TabIcon);
