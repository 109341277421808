/**
 * # globalReducer.js
 *
 *
 */

/**
 * ## Imports
 * The InitialState for auth

 * formValidation for setting the form's valid flag
 */
import InitialState from './clinicInitialState';

const {
  GET_CLINIC_SUCCESS,
  GET_POTENTIAL_STORY_SUCCESS,
  CLEAR_CLINIC,
  CLINIC_HAS_ONE_DONE,
} = require('../../lib/constants').default;

const initialState = new InitialState();
/**
 * ## globalReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function clinicReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.merge(state);

  switch (action.type) {
    case GET_CLINIC_SUCCESS:
      return state.set('currentClinics', action.payload);
    case CLEAR_CLINIC:
      return state.set('currentClinics', null);
    case GET_POTENTIAL_STORY_SUCCESS:
      return state.set('potentialDefaultStories', action.payload);
    case CLINIC_HAS_ONE_DONE: {
      const { hasOneDone, completedCases, completedVisits } = action.payload;
      return state.set('hasOneDone', hasOneDone)
        .set('completedCases', completedCases)
        .set('completedVisits', completedVisits);
    }
    default:
      return state;
  }
  // return state;
}
