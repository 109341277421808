const {
  SET_NEW_ROUTE_STATE,
  SET_NEW_ROUTE_NAME,
} = require('../../lib/constants').default;

const InitialState = require('./routesInitialState').default;

const initialState = new InitialState();

export default function routesReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case SET_NEW_ROUTE_STATE:
      return state.set('currentRoute', action.payload);
    case SET_NEW_ROUTE_NAME:
      return state.set('currentRouteName', action.payload);
    default:
      return state;
  }
}
