const { SET_NEW_ROUTE_STATE, SET_NEW_ROUTE_NAME } = require('../../lib/constants').default;

export function setNewRoute(json) {
  return {
    type: SET_NEW_ROUTE_STATE,
    payload: json,
  };
}

export function setNewRoute2(json) {
  return {
    type: SET_NEW_ROUTE_STATE,
    payload: json,
  };
}

export function setCurrentRoutName(route) {
  return {
    type: SET_NEW_ROUTE_NAME,
    payload: route,
  };
}
