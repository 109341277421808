import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { globalAlert } from '../../src/components/general/Toast';
import {
  I18n, design, helper, defaultStyle,
} from '../../src/lib/general';
import { config } from '../../src/lib/config';
import memory from '../../src/lib/Memory';
import HTMLWithStyle from '../../src/components/general/HTMLWithStyle';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 10,
    // left: 0,
    width: 320,
    backgroundColor: design.colors.baseGray,
    borderRadius: 3,
    // height: 50,
    zIndex: 1000,
    // margin: 20,
    padding: 10,
    alignSelf: 'center',
    alignContent: 'center',
    // borderColor: design.colors.clinicSub,
    // borderWidth: 1,
  },
  wrapper: {
    padding: 20,
    backgroundColor: design.colors.white,
    borderRadius: 3,
  },
  icoContainer: {
    position: 'absolute',
    right: -10,
    top: -10,
  },
  text: {
    fontSize: design,
  },
  titleStyle: {
    color: design.colors.clinicHeader,
    ...defaultStyle.textMontserrat,
    fontWeight: 500,
  },
  buttonStyle: { backgroundColor: design.colors.loginBtn },
});
let deferredPrompt = null;
const AddToHomeScreen = () => {
  // console.log('AddToHomeScreen');
  const [showIOS, setShowIos] = useState(false);
  const [showAndroid, setShowAndroid] = useState(false);
  // const [shouldHide, setShouldHide] = useState(true);

  const onDonePress = () => {
    memory.setItem('hideAddToHomeScreen', true).then(() => {});
    setShowIos(false);
    setShowAndroid(false);
  };

  useEffect(() => {
    setTimeout(() => {
      memory.getItem('hideAddToHomeScreen').then((answer) => {
        if (answer) return;
        if (config.showAddToHomeScreen) {
          setShowIos(helper.isIos() && !helper.isInStandaloneMode());
        }
      });
    }, 5000);
  }, []);

  const onPressAndroid = () => {
    if (!deferredPrompt) return;
    deferredPrompt?.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
  };

  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    memory.getItem('hideAddToHomeScreen').then((answer) => {
      if (config.showAddToHomeScreen) {
        setShowAndroid(!answer);
      }
    });
    // Update UI to notify the user they can add to home screen
    // addBtn.style.display = 'block';
  });

  const iosElement = (
    <View style={styles.container}>
      <View
        style={styles.wrapper}
      >
        <TouchableOpacity onPress={onDonePress}>
          <View style={styles.icoContainer}>
            <Icon
              name="close"
              size={design.text.twentyTwo}
              color={design.colors.infoBlack}
              onPress={onDonePress}
            />
          </View>
          <HTMLWithStyle
            id="add-button"
            style={styles.text}
            html={I18n.t('AddToHomeScreen.bottomHtml')}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
  if (showIOS) return iosElement;
  if (showAndroid) {
    globalAlert.alert(
      I18n.t('AddToHomeScreen.androidAddToHomeHeader'),
      I18n.t('AddToHomeScreen.androidAddToHomeBody'),
      [
        {
          text: I18n.t('Patient.cancel'),
          onPress: onDonePress,
          style: 'cancel',
        },
        { text: I18n.t('Patient.confirm'), onPress: onPressAndroid },
      ],
      { cancelable: true },
    );
  }
  return null;
};
export default AddToHomeScreen;
