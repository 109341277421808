
import { appAuthToken } from '../../lib/AppAuthToken';

const {
  GET_FAQ_REQUEST,
  GET_FAQ_SUCCESS,
  GET_FAQ_FAILURE,
} = require('../../lib/constants').default;

const BackendFactory = require('../../lib/BackendFactory').default;

export function getFAQRequest() {
  return {
    type: GET_FAQ_REQUEST,
  };
}
export function getFAQSuccess(json) {
  // debugger
  return {
    type: GET_FAQ_SUCCESS,
    payload: json,
  };
}
export function getFAQFailure(json) {
  return {
    type: GET_FAQ_FAILURE,
    payload: json,
  };
}

export function getFAQ(sessionToken) {
  return (dispatch) => {
    dispatch(getFAQRequest());
    // store or get a sessionToken
    return appAuthToken
      .getSessionToken(sessionToken)
      .then(token => BackendFactory(token).getFAQ())
      .then((json) => {
        //  console.log('getFAQSuccess', json)
        dispatch(getFAQSuccess(json.results));
      })
      .catch((error) => {
        console.log('getFAQ', error);
        dispatch(getFAQFailure(error));
      });
  };
}
