import React, { PureComponent } from 'react';
import {
  StyleSheet, View, TouchableOpacity, ActivityIndicator,
} from 'react-native';
import Svg, { Rect } from 'react-native-svg';
import { design, TextMontserrat, defaultStyle } from '../../lib/general';
import LinearGradientEdocate from './LinearGradientEdocate';
import HoverView from './hover/HoverView';
import { analytics } from '../../lib/analytics';

const styles = StyleSheet.create({
  inputsContainer: {
    // ...StyleSheet.flatten(defaultStyle.buttonWidthWeb),
  },
  fullWidthButton: {
    backgroundColor: design.colors.clinicBlue,
    height: design.spacing.midLarge,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullWidthButtonTablet: {
    height: 70,
  },
  fullWidthButtonText: {
    fontSize: design.text.small,
    color: design.colors.clinicHeader,
    fontWeight: 'bold',
  },
  fullWidthButtonTextTablet: {
    fontSize: design.text.semiReg,
  },
  disabled: {
    backgroundColor: design.colors.gray,
  },
  flexWrap: {
    display: 'flex', flexDirection: 'row', alignItems: 'center',
  },
  disabledText: {
    color: design.colors.lineGraphGray,
  },
  svg: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
  },
  hover: {
    opacity: 0.85,
  },
});

class ButtonFull extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onPress = () => {
    const { onPress, analyticsData } = this.props;
    if (onPress) {
      if (analyticsData) {
        analytics.logCustom(analyticsData, {});
      }
      onPress();
    }
  };

  render() {
    const {
      containerStyle,
      buttonStyle,
      textStyle,
      onPress,
      disabled,
      title,
      text,
      gradient,
      hideOnDesktop,
      isLoading,
      isTablet,
      ...other
    } = this.props;
    const combineContainer = StyleSheet.flatten([styles.inputsContainer, containerStyle]);
    const combineButton = StyleSheet.flatten([styles.fullWidthButton, isTablet && styles.fullWidthButtonTablet, buttonStyle]);
    const combineButtonDisabled = StyleSheet.flatten([combineButton, styles.disabled]);
    if (hideOnDesktop) return null;
    return (
      <HoverView
        hoverStyle={styles.hover}
        style={[combineContainer]}
        {...other}
      >
        <TouchableOpacity
          style={[
            disabled ? combineButtonDisabled : combineButton,
            gradient ? { backgroundColor: design.colors.transparent } : {},
          ]}
          onPress={this.onPress}
          disabled={disabled || isLoading}
        >
          {gradient && (
            <Svg height="100%" width="100%" style={styles.svg}>
              <LinearGradientEdocate />
              <Rect x="0" y="0" height="100%" width="100%" fill="url(#grad)" />
            </Svg>
          )}
          <View style={styles.flexWrap}>
            <TextMontserrat
              style={[
                styles.fullWidthButtonText,
                isTablet && styles.fullWidthButtonTextTablet,
                textStyle,
                disabled || isLoading ? styles.disabledText : {}]}
            >
              {text || title || 'Submit'}
            </TextMontserrat>
            {isLoading && <ActivityIndicator style={{ marginLeft: 5 }} />}
          </View>
        </TouchableOpacity>
      </HoverView>
    );
  }
}

export default ButtonFull;
