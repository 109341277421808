

const {
  // GET_FAQ_REQUEST,
  GET_FAQ_SUCCESS,
  // GET_FAQ_FAILURE
} = require('../../lib/constants').default;

/**
 * ## Initial State
 *
 */
const InitialState = require('./faqInitialState').default;

const initialState = new InitialState();

/**
 * ## profileReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function settingsReducer(state = initialState, action) {
  //  let nextProfileState = null

  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    // case SETTINGS_UPDATE_SUCCESS:
    case GET_FAQ_SUCCESS:
      return state.set('faqs', action.payload);
  } // switch
  /**
   * # Default
   */
  return state;
}
