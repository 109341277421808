class SessionMemory {
  setItem(key, item) {
    return window?.sessionStorage?.setItem(key, item);
  }

  getItem(key) {
    return window?.sessionStorage?.getItem(key);
  }

  deleteItem(key) {
    return window?.sessionStorage?.removeItem(key);
  }

  deleteArray(arr) {
    arr.forEach((element) => {
      this.deleteItem(element);
    });
  }
}

const sessionMemory = new SessionMemory();
export default sessionMemory;
export { sessionMemory };
