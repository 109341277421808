/* eslint-disable default-case */
/**
 * # globalReducer.js
 *
 *
 */

/**
 * ## Imports
 * The InitialState for auth

 * formValidation for setting the form's valid flag
 */
import InitialState from './globalInitialState';

const {
  SET_SESSION_TOKEN,

  GET_PROFILE_SUCCESS,
  SIGNUP_SUCCESS,
  LOGIN_SUCCESS,
  SESSION_TOKEN_SUCCESS,

  LOGOUT_SUCCESS,

  GETupdatedState,
  SETupdatedState,
  SET_STORE,
  INTRO_DONE,
  INTRO_SKIP,
  ALERT_ON,
  ALERT_OFF,
  SET_ENV,
  SET_ENV_APP,
  SET_WEB,
  SET_DESKTOP,
  SET_TABLET,
  CHANGE_CAMPAIGN_TOGGLE,
  SHOW_TUTORIAL,
  SHOW_TUTORIAL_ICON,
  SHOW_CLINICAL_HELP_ICON,
  SHOW_CLINICAL_HELP_MODAL,
} = require('../../lib/constants').default;

const initialState = new InitialState();
/**
 * ## globalReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function globalReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.merge(state);

  switch (action.type) {
    case ALERT_ON:
      return state
        .set('showGlobalAlert', true)
        .set('globalAlertMessageHead', action.payload.globalAlertMessageHead)
        .set('globalAlertMessageSub', action.payload.globalAlertMessageSub);

    case ALERT_OFF:
      return state
        .set('showGlobalAlert', false)
        .set('globalAlertMessageHead', '')
        .set('globalAlertMessageSub', '');
    /**
     * ### Save the sessionToken
     */
    case SET_SESSION_TOKEN:
      return state.set('sessionToken', action.payload);

    case SET_ENV:
      return state.set('env', action.payload);

    case SET_ENV_APP:
      return state.set('applicationEnvironment', action.payload);
    /**
     * ### Save the payload in the store
     *
     * This payload is the ```currentUser``` object returned by
     * the server.  It contains the ```sessionToken``` and the user's
     * ```objectId``` which will be needed for some calls to the server
     */
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
    case GET_PROFILE_SUCCESS:
      return state.set('currentUser', action.payload);

    case SESSION_TOKEN_SUCCESS:
      return state.set('currentUser', action.payload.sessionToken);

    /**
     * ### Clear currentUser
     *
     *
     *
     *
     */
    case LOGOUT_SUCCESS:
      return state.set('currentUser', null);

    /**
     * ### sets the payload into the store
     *
     * *Note* this is for support of Hot Loading - the payload is the
     * ```store``` itself.
     *
     */
    case SET_STORE:
      return state.set('store', action.payload);

    /**
     * ### Get the current state from the store
     *
     * The Redux ```store``` provides the state object.
     * We convert each key to JSON and set it in the state
     *
     * *Note*: the global state removes the ```store```, otherwise,
     * when trying to convert to JSON, it will be recursive and fail
     */
    case CHANGE_CAMPAIGN_TOGGLE: {
      return state
        .set('showCampaignSelection', action.payload);
    }

    case SHOW_TUTORIAL: {
      return state
        .set('showTutorial', action.payload);
    }

    case SHOW_CLINICAL_HELP_ICON: {
      return state
        .set('showClinicalHelpIcon', action.payload);
    }

    case SHOW_TUTORIAL_ICON: {
      return state
        .set('showGlobalTutorialIcon', action.payload);
    }

    case SHOW_CLINICAL_HELP_MODAL: {
      return state
        .set('clinicalHelpVisible', action.payload);
    }

    case GETupdatedState: {
      const updatedState = state.store.getState();

      if (action.payload) {
        const newState = {};
        newState.auth = updatedState.auth.toJS();
        newState.device = updatedState.device.toJS();
        newState.profile = updatedState.profile.toJS();

        // Make sure global doesn't have the previous currentState
        // let _noCurrentState =  updatedState.global.set('currentState',null);
        // let _noStore = _noCurrentState.set('store',null);

        newState.global = updatedState.global
          .set('currentState', null)
          .set('store', null)
          .toJS();

        return state
          .set('showState', action.payload)
          .set('currentState', newState);
      }
      return state.set('showState', action.payload);
    }

    /**
     * ### Set the state
     *
     * This is in support of Hot Loading
     *
     */
    case SETupdatedState: {
      const { global } = JSON.parse(action.payload);
      const next = state
        .set('currentUser', global.currentUser)
        .set('showState', false)
        .set('currentState', null);
      return next;
    }
    case INTRO_DONE:
    case INTRO_SKIP:
      //  TODO: should i save it here?
      return state.set('intro', action.payload);
    case SET_DESKTOP:
      //  TODO: should i save it here?
      return state.set('isDesktop', action.payload);
    case SET_WEB:
      //  TODO: should i save it here?
      return state.set('isWeb', action.payload);
    case SET_TABLET:
      //  TODO: should i save it here?
      return state.set('isTablet', action.payload);
  }

  return state;
}
