/* eslint-disable react-native/no-inline-styles */
import React, {
  useEffect, useState, memo,
} from 'react';
import {
  View, Platform, Dimensions, Image,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { globalActions, routActions } from '../../../reducers/actions';
import HoverTouchableOpacity from '../hover/HoverTouchableOpacity';

import styleFunction from './style';
import {
  design, global, defaultStyle, icons,
} from '../../../lib/general';
import { config } from '../../../lib/config';

const isWeb = Platform.OS === 'web';

const items = [
  {
    icon: 'clinicalHelp', action: 'toggleClinicalHelpModal',
  },
  {
    icon: 'tutorial', action: 'toggleTutorial',
  },
];
let timeout = null;
function ActionGroup({ fromHeader, disabled }) {
  if (disabled) return null;
  const { width } = Dimensions.get('window');
  const dispatch = useDispatch();
  const routes = useSelector((s) => s.routes.currentRouteName);
  const showGlobalTutorialIcon = useSelector((s) => s.global.showGlobalTutorialIcon);
  const showClinicalHelpIcon = useSelector((s) => s.global.showClinicalHelpIcon);
  const isDesktop = useSelector((s) => s.global.isDesktop);
  const [windowWidth, setWindowWidth] = useState(width);
  const [prevInnerPatientTab, setPrevInnerPatientTab] = useState(width);
  const [itemsArray, setItems] = useState([]);

  const WIDTH = fromHeader ? 80 : 100;
  // GlobalTutorialState => 'disabled', enabled, enabledAndAutoPop
  const globalTutorialState = config.GlobalTutorialState;

  const handleResize = () => {
    const w = Dimensions.get('window').width;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => { setWindowWidth(w); }, 200);
  };
  useEffect(() => {
    const ignoreMainTabsArray = ['More', 'CreditTab', 'FullCredit', 'Reference']; // take from config or somewhere? => Ziv
    if (prevInnerPatientTab !== routes) {
      if (!ignoreMainTabsArray.includes(routes)) {
        if (routes === 'Clinic_tab' || routes === 'ClinicTab') {
          dispatch(routActions.setCurrentRoutName(prevInnerPatientTab));
        } else {
          setPrevInnerPatientTab(routes);
        }
      }
    }
  }, [routes, prevInnerPatientTab]);

  useEffect(() => {
    Dimensions.addEventListener('change', handleResize);
    return () => Dimensions.removeEventListener('change', handleResize);
  }, [handleResize]);

  useEffect(() => {
    const toShow = [];
    if (showClinicalHelpIcon) toShow.push(items[0]);
    if (globalTutorialState !== 'disabled') {
      if (showGlobalTutorialIcon) toShow.push(items[1]);
    }
    setItems(toShow);
  }, [showClinicalHelpIcon, showGlobalTutorialIcon]);

  const handleItemPress = (action) => (action === 'toggleClinicalHelpModal'
    ? dispatch(globalActions.toggleClinicalHelpModal(true))
    : dispatch(globalActions.toggleTutorial(true)));

  const styles = styleFunction(design, isDesktop, fromHeader);
  const right = ((windowWidth - global.mobileMaxWidth) / 2) + WIDTH;
  // const middle = ((windowWidth) / 2) + WIDTH;
  const renderActionGroupComponent = () => {
    if (!config.showActionGroupButton || !itemsArray.length) return null;
    return fromHeader ? (
      <View style={styles.horizontalWrapper}>
        {itemsArray.map((item) => <ButtonItem key={item.icon} iconName={item.icon} action={item.action} />)}
      </View>
    )
      : (
        <View style={[
          styles.icon,
          isDesktop ? styles.iconDesktop : styles.iconWeb,
          !isWeb && styles.nativeIcon,
          isDesktop && { left: right },
          isDesktop && config.hideTopBar && { top: 10, left: right - 210 },
        ]}
        >
          <View style={styles.innerWrapper}>
            {itemsArray.map((item) => <ButtonItem key={item.icon} iconName={item.icon} action={item.action} />)}
          </View>
        </View>
      );
  };

  const ButtonItem = ({ iconName, action }) => (
    <HoverTouchableOpacity
      style={[defaultStyle.shadow, styles.hoverButtonStyle]}
      hoverStyle={styles.hover}
      onPress={() => handleItemPress(action)}
    >
      <Image resizeMode="contain" source={icons[iconName]} style={styles.innerIcon} />
    </HoverTouchableOpacity>
  );

  return renderActionGroupComponent();
}

export default memo(ActionGroup);
