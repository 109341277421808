/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '../../reducers/actions';
import { config } from '../../lib/config';

const KeepAlive = () => {
  const dispatch = useDispatch();
  let interval = null;
  useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      if (config.keepAlive) {
        dispatch(authActions.keepAlive());
      }
    }, config.keepAliveInterval || 60000); // every minute
  }, []);

  return null;
};

export default KeepAlive;
