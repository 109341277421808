/* eslint-env browser */
// firefox android
import 'regenerator-runtime/runtime';
import ResizeObserver from 'resize-observer-polyfill';
// IE 11
// import 'core-js/stable';
// import 'react-app-polyfill/ie11';
// import 'raf/polyfill';
// eslint-disable-next-line no-unused-vars
import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { Platform, AppRegistry } from 'react-native';
import edocate from 'Src/edocate';
// import fonts from './style/fonts';
// import iconFonts from './style/iconFonts';
import * as serviceWorker from './workers/service-worker-loader';

// must be added if we import the polyfill
window.ResizeObserver = ResizeObserver;

if (!Object?.fromEntries) {
  // eslint-disable-next-line global-require
  Object.fromEntries = require('fromentries');
}

edocate(Platform.OS);
serviceWorker.register();
document.getElementById('root').innerHTML = '';
AppRegistry.runApplication('edocate', { rootTag: document.getElementById('root') });
