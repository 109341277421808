import React from 'react';
import { View, StyleSheet } from 'react-native';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';

const EnhancedSwipeableViews = virtualize(SwipeableViews);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  swiperContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  swiper: {
    flex: 1,
    position: 'relative',
  },
  slide: {
    height: '100%',
  },
});

class Swiper extends React.Component {
  constructor(props) {
    super(props);
    // debugger;
    this.swipe = null;
    this.state = {
      currentPage: props.index || 0,
    };
    this.goToPage = this.goToPage.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { currentPage } = this.state;
    return nextState.currentPage !== currentPage;
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.index && props.index !== state.currentPage
    ) {
      return {
        currentPage: props.index,
      };
    }
    return null;
  }

  scrollBy=(index) => {
    const { currentPage } = this.state;
    const page = currentPage + index;
    this.goToPage(page);
  }

  slideRenderer=(params) => {
    const { index } = params;
    const { children } = this.props;
    return children[index];
  }

  goToPage(page) {
    const { onIndexChanged } = this.props;
    this.setState({
      currentPage: page,
    });
    if (onIndexChanged) {
      onIndexChanged(page);
    }
  }

  renderContent() {
    const { currentPage } = this.state;
    const {
      scrollWithoutAnimation, scrollEnabled, webCancelAbsolute,
    } = this.props;
    const disabled = scrollEnabled === false;
    const contentProps = {
      disabled,
      animateTransitions: !scrollWithoutAnimation,
    };
    const containerStyle = webCancelAbsolute ? {} : styles.swiperContainer;
    return (
      <EnhancedSwipeableViews
        overscanSlideAfter={0}
        overscanSlideBefore={0}
      // slideCount={children.length}
        style={styles.swiper}
        containerStyle={containerStyle}
        slideStyle={styles.slide}
        index={currentPage}
        onChangeIndex={this.goToPage}
        {...contentProps}
        slideRenderer={this.slideRenderer}
        animateTransitions={false}
      >
        {/* {children} */}
      </EnhancedSwipeableViews>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderContent()}
      </View>
    );
  }
}

export default Swiper;
