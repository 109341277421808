/**
 * # authInitialState.js
 *
 * This class is a Immutable object
 * Working *successfully* with Redux, requires
 * state that is immutable.
 * In my opinion, that can not be by convention
 * By using Immutable, it's enforced.  Just saying....
 *
 */

/**
 * ## Import
 */
import { Record } from 'immutable';

import edocateGlobal from '../../lib/global';

const { REGISTER } = require('../../lib/constants').default;

/**
 * ## Form
 * This Record contains the state of the form and the
 * fields it contains.
 */
const Form = Record(
  {
    state: REGISTER,
    disabled: false,
    error: null,
    isValid: false,
    isFetching: false,
    isFetchingExternal: false,
    fields: new (Record({
      username: '',
      usernameHasError: false,
      usernameErrorMsg: '',
      email: '',
      emailHasError: false,
      emailErrorMsg: '',
      password: '',
      passwordHasError: false,
      passwordErrorMsg: '',
      passwordAgain: '',
      passwordAgainHasError: false,
      passwordAgainErrorMsg: '',
      showPassword: false,
    }))(),
    register: new (Record({
      fullName: '',
      email: '',
      username: '',
      password: '',
      passwordAgain: '',
      specialty: '',
      education: '',
      workplace: '',
      country: '',
      yearsInPractice: '',
      zipCode: '',
      selectedCampaign: '',
      doctorId: '',
      didUserTermsAgree: false,
    }))(),
    yearsInPracticeList: [],
    specialtyList: [],
    educationList: [],
    countriesList: [],
    onBoardingSlides: [],
    tutorialSlides: [],
    firebaseToken: '',
    firstTime: false,
    passwordRegEx: edocateGlobal.PASSWORD_REGEX,
    fullNameRegEx: edocateGlobal.FULL_NAME_REGEX,
    doctorIdRegEx: edocateGlobal.DOCTOR_ID_REGEX,
    campaign: null,
    userInvited: false,
    showBrand: false,
    brandLogo: '',
    brandSlogan: '',
    isProjectOver: false,
    isProjectOverHeader: '',
    isProjectOverText: '',
    activeCampaignList: [],
    treatmentDurationList: [],
    licenseList: [],
    urlParameters: null,
    promotions: [],
  },
  'auth',
);

/**
 * ## InitialState
 * The form is set
 */
const InitialState = Record(
  {
    form: new Form(),
  },
  'authForm',
);
export default InitialState;
