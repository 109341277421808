

import React, { PureComponent } from 'react';
import { LinearGradient, Defs, Stop } from 'react-native-svg';

class LinearGradientEdocate extends PureComponent {
  render() {
    return (
      <Defs>
        <LinearGradient
          id="grad"
          x1="100.99%"
          y1="98.97%"
          x2="-0.99%"
          y2="1.03%"
        >
          <Stop offset="0" stopColor="#69C6F3" />
          <Stop offset="0.57" stopColor="#8AD6FA" />
          <Stop offset="1" stopColor="#A2E1FF" />
        </LinearGradient>
      </Defs>
    );
  }
}

export default LinearGradientEdocate;
