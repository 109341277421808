/**
 * # profileActions.js
 *
 * The actions to support the users profile
 */

/**
 * ## Imports
 *
 * The actions for profile
 */

import each from 'lodash/each';
import { appAuthToken } from '../../lib/AppAuthToken';
// check if ok
import * as authActions from '../auth/authActions';

const {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,

  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,

  ON_PROFILE_FORM_FIELD_CHANGE,

  GET_CREDITS_REQUEST,
  GET_CREDITS_SUCCESS,
  GET_CREDITS_FAILURE,

  SET_PROFILE_CAMPAIGN,

} = require('../../lib/constants').default;

/**
 * BackendFactory - base class for server implementation
 * AppAuthToken for localStorage sessionToken access
 */
const BackendFactory = require('../../lib/BackendFactory').default;

/**
 * ## retreiving profile actions
 */

export function getCreditsRequest() {
  return {
    type: GET_CREDITS_REQUEST,
  };
}
export function getCreditsSuccess(json) {
  return {
    type: GET_CREDITS_SUCCESS,
    payload: json,
  };
}
export function getCreditsFailure(json) {
  return {
    type: GET_CREDITS_FAILURE,
    payload: json,
  };
}

export function getProfileRequest() {
  return {
    type: GET_PROFILE_REQUEST,
  };
}
export function getProfileSuccess(json) {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: json,
  };
}
export function getProfileFailure(json) {
  return {
    type: GET_PROFILE_FAILURE,
    payload: json,
  };
}
/**
 * ## State actions
 * controls which form is displayed to the user
 * as in login, register, logout or reset password
 */
export function getProfile(sessionToken) {
  return (dispatch) => {
    dispatch(getProfileRequest());
    // store or get a sessionToken
    return appAuthToken
      .getSessionToken(sessionToken)
      .then((token) => BackendFactory(token).getProfile())
      .then((json) => dispatch(getProfileSuccess(json)))
      .catch((error) => {
        if (error.error === 'unauthorized') {
          dispatch(authActions.logout());
        }
        return dispatch(getProfileFailure(error));
      });
  };
}

export function getCredits(sessionToken) {
  return (dispatch) => {
    dispatch(getCreditsRequest());
    // store or get a sessionToken
    return appAuthToken
      .getSessionToken(sessionToken)
      .then((token) => BackendFactory(token).getCredits())
      .then((json) => {
        let amount = 0;
        each(json.result, (res) => {
          amount += res.amount;
        });
        dispatch(getCreditsSuccess(amount));
      })
      .catch((error) => {
        dispatch(getCreditsFailure(error));
      });
  };
}
/**
 * ## State actions
 * controls which form is displayed to the user
 * as in login, register, logout or reset password
 */
export function profileUpdateRequest() {
  return {
    type: PROFILE_UPDATE_REQUEST,
  };
}
export function profileUpdateSuccess(json) {
  return {
    type: PROFILE_UPDATE_SUCCESS,
    payload: json,
  };
}
export function profileUpdateFailure(json) {
  return {
    type: PROFILE_UPDATE_FAILURE,
    payload: json,
  };
}
/**
 * ## updateProfile
 * @param {string} userId -  objectId
 * @param {string} username - the users name
 * @param {string} email - user's email
 * @param {Object} sessionToken - the sessionToken
 *
 * The sessionToken is provided when Hot Loading.
 *
 * With the sessionToken, the server is called with the data to update
 * If successful, get the profile so that the screen is updated with
 * the data as now persisted on the serverx
 *
 */
/**
 * ## onProfileFormFieldChange
 *
 */
export function onProfileFormFieldChange(field, value) {
  return {
    type: ON_PROFILE_FORM_FIELD_CHANGE,
    payload: { field, value },
  };
}

export function updateUserProfile(body) {
  return (dispatch) => {
    dispatch(profileUpdateRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).updateUserProfile(body)
        .then((data) => {
          dispatch(profileUpdateSuccess(data.result));
          return true;
        })
        .catch((error) => {
          dispatch(profileUpdateFailure(error));
          return false;
        }));
  };
}

export function setActiveCampaign(campaignId) {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILE_CAMPAIGN,
      payload: { campaignId },
    });
  };
}
