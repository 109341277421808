import React, { memo, useState } from 'react';
import { Text, StyleSheet } from 'react-native';
import helper from '../../lib/helper';
import { config } from '../../lib/config';
import TextBaseComponent from './TextBaseComponent';

const TextSF = memo((props) => {
  const [hover, setHover] = useState(false);
  const {
    style,
    children,
    skipRTLTest,
    hoverActive,
    onMouseEnter,
    onMouseLeave,
    hoverStyle,
    ...rest
  } = props;
  const mouseEnter = () => {
    if (!hoverActive) return;
    setHover(true);
    if (typeof onMouseEnter === 'function') {
      onMouseEnter();
    }
  };
  const mouseLeave = () => {
    if (!hoverActive) return;
    setHover(false);
    if (typeof onMouseLeave === 'function') {
      onMouseLeave();
    }
  };
  const isSafari = helper.isSafari();
  const isRTL = helper.isRTL(children);
  delete rest.style;
  delete rest.children;
  const flattenStyle = StyleSheet.flatten(style);
  let fontFamily = 'SFUIText-Regular';
  let fontWeight = 'normal';
  if (flattenStyle) {
    switch (flattenStyle.fontWeight) {
      case '300':
        fontFamily = 'SFUIText-Light';
        fontWeight = 'normal';
        break;
      case '500':
        // console.log('SFUIText-Medium', flattenStyle.fontWeight);
        fontFamily = 'SFUIText-Regular';
        fontWeight = '500';
        break;
      case '600':
        fontFamily = 'SFUIText-Semibold';
        fontWeight = '500';
        break;
      case '700':
      case 'bold':
        fontFamily = 'SFUIText-Bold';
        fontWeight = 'bold';
        break;
      case '800':
      case '900':
        fontFamily = 'SFUIText-Heavy';
        fontWeight = 'bold';
        break;
      default:
        // console.log('SFUIText fall to default', flattenStyle.fontWeight);
        fontFamily = 'SFUIText-Regular';
        fontWeight = 'normal';
        break;
    }
  }
  if (isSafari && isRTL) {
    fontFamily = 'Arial, Helvetica, sans-serif';
  }
  if (config.detectRtlOnText && !skipRTLTest) {
    if (isRTL) {
      if (helper.isRtlContainLtr(children)) {
        const stringArray = children.split(' ');
        const InnerTextsComponents = () => stringArray.map((str, index) => (
          <TextBaseComponent
            fontWeight={fontWeight}
            fontFamily={fontFamily}
            key={`tc-${helper.hashCode(`${str}${index}`)}`}
          >
            {`${str}${index !== stringArray.length - 1 ? ' ' : ''}`}
          </TextBaseComponent>
        ));
        return (
          <Text
            hover={hover}
            mouseEnter={mouseEnter}
            mouseLeave={mouseLeave}
            hoverStyle={hoverStyle}
            style={style}
            key={`t-${helper.hashObject(`${children}`)}`}
            {...rest}
          >
            <div translate="no">
              <InnerTextsComponents />
            </div>
          </Text>
        );
      }
      return (
        <TextBaseComponent
          fontWeight={fontWeight}
          hover={hover}
          fontFamily={fontFamily}
          mouseEnter={mouseEnter}
          mouseLeave={mouseLeave}
          style={style}
          hoverStyle={hoverStyle}
          {...rest}
          key={`tbc-${helper.hashObject(`${children}`)}`}
          hash={helper.hashObject(`${children}`)}

        >
          {children}
        </TextBaseComponent>
      );
    }
  }

  return (
    <TextBaseComponent
      fontWeight={fontWeight}
      hover={hover}
      fontFamily={fontFamily}
      mouseEnter={mouseEnter}
      mouseLeave={mouseLeave}
      style={style}
      hoverStyle={hoverStyle}
      {...rest}
    >
      {children}
    </TextBaseComponent>
  );
});

export default TextSF;
