import React, { Component } from 'react';
import {
  StyleSheet, Image, View, ScrollView,
} from 'react-native';

// import HTML from 'react-native-render-html';
import isEqual from 'lodash/isEqual';
import HTMLWithStyle from '../../general/HTMLWithStyle';
import {
  design,
  I18n,
  TextMontserrat,
  icons,
  defaultStyle,
} from '../../../lib/general';
import ReferenceSingle from '../../reference/ReferenceSingle';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
    padding: 20,
    backgroundColor: design.colors.white,
  },
  header: {
    fontSize: design.text.semiReg,
    fontWeight: '500',
    color: design.colors.clinicHeader,
    // marginRight: 10,
    marginBottom: 10,
  },
  top: {
    // flex: 1,
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    // alignItems: 'flex-start',
    borderBottomWidth: 1,
    borderBottomColor: design.colors.borderBottomGoals,
    paddingBottom: 15,
    marginBottom: 15,
    // flex: 1
  },
  image: {
    width: 20,
    height: 20,
  },
  mid: {},
  articlesContainer: {
    paddingTop: 20,
    marginTop: 20,
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: design.colors.borderBottomGoals,
  },
  suggestedReading: {
    fontSize: design.text.semiReg,
    fontWeight: '500',
    color: design.colors.clinicHeader,
    marginBottom: 15,
  },
  headerDesktop: {
    fontSize: design.text.eightTeen,
  },
  refContainer: {
    paddingBottom: 20,
  },
  noMargin: { margin: 0 },
});

class SingleGoal extends Component {
  constructor() {
    super();
    this.renderArticle = this.renderArticle.bind(this);
    this.state = {
      goal: null,
      ids: [],
      // articles: [],
    };
  }

  componentDidMount() {
    this.resolveData();
  }

  componentDidUpdate(prevProps) {
    const { goal } = this.props;
    if (!isEqual(prevProps.goal, goal)) {
      this.resolveData();
    }
  }

  componentWillUnmount() {
    const { setArticlesEmpty } = this.props;
    if (setArticlesEmpty) {
      setArticlesEmpty();
    }
  }

  onPress() {
    // debugger
    const { onPress } = this.props;
    const { goal } = this.state;
    if (goal.articles && goal.articles.length > 0) {
      const article = goal.articles[0];
      if (article.objectId) {
        onPress(article.objectId);
      }
    }
  }

  resolveData() {
    const { goal, getArticles } = this.props;
    // check if articles empty
    let ids = [];
    if (goal.articles) {
      ids = goal.articles.map((item) => item.objectId);
      // console.log('inside', ids, getArticles);
      getArticles(ids);
    }
    this.setState({ goal, ids });
  }

  renderImage() {
    const { goal } = this.props;
    let icon = icons.star_empty;
    if (goal.isDone) {
      icon = icons.starfull;
    }
    // doesnt work, no flag here
    if (goal.flag) {
      icon = icons.goalError; // change to real
    }
    if (goal.pearl) {
      icon = icons.lamp; // change to real
    }
    return <Image source={icon} resizeMode="contain" style={styles.image} />;
  }

  renderArticle(art, i) {
    const { ids } = this.state;
    const {
      setRefItem, setPDFDocStart, setPDFDocEnd,
    } = this.props;
    if (ids.indexOf(art.objectId) === -1) return null;
    return (
      <View style={styles.refContainer} key={art.objectId}>
        <ReferenceSingle
          data={art}
          setRefItem={setRefItem}
          setPDFDocStart={setPDFDocStart}
          setPDFDocEnd={setPDFDocEnd}
          num={i}
        />
      </View>
    );
  }

  render() {
    const { isDesktop, articles = [], noMargin } = this.props;
    const { goal, ids } = this.state;
    if (!goal) return null;
    const {
      title, text, notAchievedTitle, value, notAchievedText,
    } = goal;
    const actualTitle = value === 0 && notAchievedTitle ? notAchievedTitle : title;
    const actualText = value === 0 && notAchievedText ? notAchievedText : text;
    let sortedArticles = articles.map((art) => {
      const element = art;
      element.index = ids.indexOf(art.objectId);
      return element;
    });
    sortedArticles = sortedArticles.sort((a, b) => a.index - b.index);
    return (
      <ScrollView>
        {goal && (
          <View style={[styles.container, noMargin && styles.noMargin]}>
            <View style={[styles.top]}>
              <TextMontserrat
                style={[styles.header, isDesktop && styles.headerDesktop]}
              >
                {actualTitle}
              </TextMontserrat>
            </View>
            <View style={[styles.mid]}>
              <HTMLWithStyle
                style={StyleSheet.flatten(
                  isDesktop && defaultStyle.htmlBaseTextDesktop,
                )}
                html={actualText}
              />
            </View>
            {sortedArticles && sortedArticles.length > 0 ? (
              <View style={styles.articlesContainer}>
                <TextMontserrat style={styles.suggestedReading}>
                  {`${I18n.t('End.suggestedReading')}`}
                </TextMontserrat>
                {sortedArticles.map((art, i) => (this.renderArticle(art, i)))}
              </View>
            ) : (
              <View />
            )}
          </View>
        )}
      </ScrollView>
    );
  }
}

export default SingleGoal;
