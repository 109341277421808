/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable func-names */
/* eslint-disable security/detect-object-injection */
/* eslint-disable import/first */
/* eslint-disable no-tabs */

import merge from 'lodash/merge';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import localStorageMemory from 'localstorage-memory';

let isIframeIncognito = false;
if (Platform.OS === 'web') {
  try {
    const t = typeof window.localStorage.setItem;
  } catch (error) {
    isIframeIncognito = true;
  }
}

const deviceStorage = {
  /**
	 * Get a one or more value for a key or array of keys from AsyncStorage
	 * @param {String|Array} key A key or array of keys
	 * @return {Promise}
	 */
  get(key) {
    if (!Array.isArray(key)) {
      return AsyncStorage.getItem(key).then((value) => JSON.parse(value));
    }
    return AsyncStorage.multiGet(key).then((values) => values.map((value) => JSON.parse(value[1])));
  },

  /**
	 * Save a key value pair or a series of key value pairs to AsyncStorage.
	 * @param  {String|Array} key The key or an array of key/value pairs
	 * @param  {Any} value The value to save
	 * @return {Promise}
	 */
  save(key, value) {
    if (!Array.isArray(key)) {
      return AsyncStorage.setItem(key, JSON.stringify(value));
    }
    const pairs = key.map((pair) => [pair[0], JSON.stringify(pair[1])]);
    return AsyncStorage.multiSet(pairs);
  },

  /**
	 * Updates the value in the store for a given key in AsyncStorage. If the value is a string it will be replaced. If the value is an object it will be deep merged.
	 * @param  {String} key The key
	 * @param  {Value} value The value to update with
	 * @return {Promise}
	 */
  update(key, value) {
    return deviceStorage.get(key).then((item) => {
      const updatedValue = typeof value === 'string' ? value : merge({}, item, value);
      return AsyncStorage.setItem(key, JSON.stringify(updatedValue));
    });
  },

  /**
	 * Delete the value for a given key in AsyncStorage.
	 * @param  {String|Array} key The key or an array of keys to be deleted
	 * @return {Promise}
	 */
  async delete(key) {
    let ans = null;
    if (Array.isArray(key)) {
      ans = await AsyncStorage.multiRemove(key);
    }
    ans = await AsyncStorage.removeItem(key);
    return ans;
  },

  /**
	 * Get all keys in AsyncStorage.
	 * @return {Promise} A promise which when it resolves gets passed the saved keys in AsyncStorage.
	 */
  keys() {
    return AsyncStorage.getAllKeys();
  },

  /**
	 * Push a value onto an array stored in AsyncStorage by key or create a new array in AsyncStorage for a key if it's not yet defined.
	 * @param {String} key They key
	 * @param {Any} value The value to push onto the array
	 * @return {Promise}
	 */
  push(key, value) {
    return deviceStorage.get(key).then((currentValue) => {
      if (currentValue === null) {
        // if there is no current value populate it with the new value
        return deviceStorage.save(key, [value]);
      }
      if (Array.isArray(currentValue)) {
        return deviceStorage.save(key, [...currentValue, value]);
      }
      throw new Error(`Existing value for key "${key}"
       must be of type null or Array, received ${typeof currentValue}.`);
    });
  },
};

// for incognito iframe support
const memoryStorage = {
  get(key) {
    try {
      return Promise.resolve(JSON.parse(localStorageMemory.getItem(key)));
    } catch (error) {
      return Promise.resolve(undefined);
    }
  },

  save(key, value) {
    return Promise.resolve(localStorageMemory.setItem(key, JSON.stringify(value)));
  },

  update(key, value) {
    return Promise.resolve(localStorageMemory.setItem(key, JSON.stringify(value)));
  },

  async delete(key) {
    return Promise.resolve(localStorageMemory.removeItem(key));
  },
  keys() {
    return Promise.resolve(Object.keys(localStorageMemory.getItem()));
  },

  /**
	 * Push a value onto an array stored in AsyncStorage by key or create a new array in AsyncStorage for a key if it's not yet defined.
	 * @param {String} key They key
	 * @param {Any} value The value to push onto the array
	 * @return {Promise}
	 */
  push(key, value) {
    return Promise.resolve(() => { this.mem[key] = JSON.stringify(value); return true; });
  },
};

export class Memory {
  constructor() {
    if (isIframeIncognito) {
      this.st = memoryStorage;
    } else {
      this.st = deviceStorage;
    }
  }

  setItem(key, item) {
    return this.st.save(key, item);
  }

  getItem(key) {
    return this.st.get(key);
  }

  deleteItem(key) {
    return this.st.delete(key);
  }

  deleteArray(arr) {
    arr.forEach((element) => {
      this.deleteItem(element);
    });
  }
}
// The singleton variable
const memory = new Memory();
export default memory;
const store = memory.st;
export { store };
