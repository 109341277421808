import { StyleSheet, Platform, Dimensions } from 'react-native';
import {
  design,
} from '../../../lib/general';

const os = Platform.OS;
const topPosition = os === 'ios' ? 40 : 20;
export default function styles() {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: 'rgba(57, 67, 91, 0.95)',
      marginTop: 55, // (55 is the hight of the header) os === 'ios' ? 0.1 * height : 55,
    },
    containerNoBackground: {
      backgroundColor: design.colors.transparent,
      marginTop: 0,
    },
    modal: {
      top: 0,
      flex: 1,
      margin: 0,
    },
    wrapper: {
      // height: 285,
    },
    slide: {
      position: 'relative',
      padding: 20,
      marginBottom: 50,
    },
    slideContainer: {
      marginTop: 45,
    },
    headerContainer: {
      marginTop: 50,
    },
    header: {
      color: design.colors.white,
      fontSize: design.text.twenty,
      alignSelf: 'center',
      fontWeight: 'bold',
    },
    text: {
      color: design.colors.white,
      fontSize: design.text.semiReg,
      alignSelf: 'center',
      flex: 1,
    },
    numberWrapper: {
      width: 35,
      height: 35,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: design.colors.white,
      borderRadius: 17.5,
      marginRight: 15,
      marginLeft: 20,
    },
    number: {
      fontWeight: 'bold',
      fontSize: design.text.semiReg,
    },
    imageContainer: {
      marginTop: 30,
      marginLeft: 0,
    },
    image: {
      height: 86,
      width: 86,
    },
    buttonStyle: {
      backgroundColor: design.colors.loginBtn,
      marginBottom: os === 'android' || os === 'ios' ? 20 : 50,
    },
    activeDot: {
      borderColor: design.colors.loginBtn,
      borderWidth: 2,
      borderRadius: 6,
      backgroundColor: design.colors.loginBtn,
      width: 10,
      height: 10,
      marginLeft: 3,
      marginRight: 3,
      marginTop: 3,
      marginBottom: 3,
    },
    nonActiveDot: {
      borderColor: design.colors.loginBtn,
      borderWidth: 2,
      borderRadius: 6,
      backgroundColor: 'transparent',
      width: 10,
      height: 10,
      marginLeft: 3,
      marginRight: 3,
      marginTop: 3,
      marginBottom: 3,
    },
    sideBySide: {
      flexDirection: 'row',
      marginTop: 20,
      marginBottom: 20,
    },
    sideBySideReverse: {
      flexDirection: 'row-reverse',
    },
    sideBySideBtn: {
      flexDirection: 'row',
    },
    flexBtn: {
      flex: 1,
    },
    closeContainer: {
      position: 'absolute',
      top: topPosition,
      right: 20,
      padding: 0,
      zIndex: 1000,
    },
    skip: {
      color: design.colors.white,
      fontSize: design.text.small,
      textDecorationLine: 'underline',
    },
    dotContainer: {
      flexDirection: 'row', alignSelf: 'center',
    },
  });
}
