/**
 * ## Imports
 *
 * Redux  & the config file
 */
import { store } from './Memory';
import CONFIG from './config';

export class AppAuthToken {
  constructor() {
    this.SESSION_TOKEN_KEY = CONFIG.SESSION_TOKEN_KEY;
    this.FIREBASE_TOKEN = 'firebaseToken';
  }

  /**
   * ### storeSessionToken
   * Store the session key
   */
  storeSessionToken(sessionToken) {
    return store.save(this.SESSION_TOKEN_KEY, {
      sessionToken,
    });
  }

  deleteFirebaseToken() {
    return store.delete(this.FIREBASE_TOKEN);
  }

  saveFirebaseToken(sessionToken) {
    // debugger
    if (sessionToken) {
      return store
        .save(this.FIREBASE_TOKEN, sessionToken)
        .then(() => store.get(this.FIREBASE_TOKEN));
    }
    return store.get(this.FIREBASE_TOKEN);
  }

  getSessionToken(sessionToken) {
    if (sessionToken) {
      return store
        .save(this.SESSION_TOKEN_KEY, {
          sessionToken,
        })
        .then(() => store.get(this.SESSION_TOKEN_KEY));
    }
    return store.get(this.SESSION_TOKEN_KEY);
  }

  /**
   * ### deleteSessionToken
   * Deleted during log out
   */
  deleteSessionToken() {
    return store.delete(this.SESSION_TOKEN_KEY);
  }
}
// The singleton variable
export const appAuthToken = new AppAuthToken();
