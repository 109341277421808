import config from './config';

export class MyDeviceInfo {
  constructor() {
    this.bundleId = config.bundle;
    this.timeZone = null;
    this.getTimezone();
    this.country = null;
    // debugger;
  }

  getBundleId() {
    return this.bundleId;
  }

  getTimezone() {
    try {
      if (this.timeZone) {
        return this.timeZone;
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
      // console.log('timeZone', timeZone);
      this.timeZone = timeZone;
      return timeZone;
    } catch (err) {
      console.log('getTimezone', err);
      return null;
    }
  }

  getCountry() {
    return this.country;
  }
}

const deviceInfo = new MyDeviceInfo();
export default deviceInfo;
