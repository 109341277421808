import { appAuthToken } from '../../lib/AppAuthToken';
import { toast } from '../../components/general/Toast';
import { I18n } from '../../lib/general';
import retentionService from '../../lib/RetentionService';
import { config } from '../../lib/config';
import store from '../store';

const {
  GET_CLINIC_REQUEST,
  GET_CLINIC_SUCCESS,
  GET_CLINIC_FAILURE,
  FF_CLINIC_REQUEST,
  FF_CLINIC_SUCCESS,
  FF_CLINIC_FAILURE,
  RESTART_SCENARIO_REQUEST,
  RESTART_SCENARIO_SUCCESS,
  RESTART_SCENARIO_FAILURE,
  NEXT_SCENARIO_REQUEST,
  NEXT_SCENARIO_SUCCESS,
  NEXT_SCENARIO_FAILURE,
  GET_POTENTIAL_STORY_REQUEST,
  GET_POTENTIAL_STORY_SUCCESS,
  GET_POTENTIAL_STORY_FAILURE,
  ADD_STORY_REQUEST,
  ADD_STORY_SUCCESS,
  ADD_STORY_FAILURE,
  REMOVE_SCENARIO_REQUEST,
  REMOVE_SCENARIO_SUCCESS,
  REMOVE_SCENARIO_FAILURE,
  CLEAR_CLINIC,
  CLINIC_HAS_ONE_DONE,
} = require('../../lib/constants').default;

const BackendFactory = require('../../lib/BackendFactory').default;

export function removeScenarioFromUserRequest() {
  return {
    type: REMOVE_SCENARIO_REQUEST,
  };
}
export function removeScenarioFromUserSuccess(payload) {
  return {
    type: REMOVE_SCENARIO_SUCCESS,
    payload,
  };
}
export function removeScenarioFromUserFailure(json) {
  return {
    type: REMOVE_SCENARIO_FAILURE,
    payload: json,
  };
}

export function addStoryToUserRequest() {
  return {
    type: ADD_STORY_REQUEST,
  };
}
export function addStoryToUserSuccess(payload) {
  return {
    type: ADD_STORY_SUCCESS,
    payload,
  };
}
export function addStoryToUserFailure(json) {
  return {
    type: ADD_STORY_FAILURE,
    payload: json,
  };
}

export function clinicGetRequest() {
  return {
    type: GET_CLINIC_REQUEST,
  };
}
export function clinicGetSuccess(payload) {
  return {
    type: GET_CLINIC_SUCCESS,
    payload,
  };
}
export function clinicHasOneDone(payload) {
  return {
    type: CLINIC_HAS_ONE_DONE,
    payload,
  };
}

export function clinicGetFailure(json) {
  return {
    type: GET_CLINIC_FAILURE,
    payload: json,
  };
}

export function getPotentialDefaultStoriesRequest() {
  return {
    type: GET_POTENTIAL_STORY_REQUEST,
  };
}
export function getPotentialDefaultStoriesSuccess(payload) {
  return {
    type: GET_POTENTIAL_STORY_SUCCESS,
    payload,
  };
}
export function getPotentialDefaultStoriesFailure(json) {
  return {
    type: GET_POTENTIAL_STORY_FAILURE,
    payload: json,
  };
}

export function restartScenarioRequest() {
  return {
    type: RESTART_SCENARIO_REQUEST,
  };
}
export function restartScenarioSuccess(payload) {
  return {
    type: RESTART_SCENARIO_SUCCESS,
    payload,
  };
}
export function restartScenarioFailure(json) {
  return {
    type: RESTART_SCENARIO_FAILURE,
    payload: json,
  };
}

export function nextScenarioRequest() {
  return {
    type: NEXT_SCENARIO_REQUEST,
  };
}
export function nextScenarioSuccess(payload) {
  return {
    type: NEXT_SCENARIO_SUCCESS,
    payload,
  };
}
export function nextScenarioFailure(json) {
  return {
    type: NEXT_SCENARIO_FAILURE,
    payload: json,
  };
}

export function clinicFastForwardRequest() {
  return {
    type: FF_CLINIC_REQUEST,
  };
}
export function clinicFastForwardSuccess(payload) {
  return {
    type: FF_CLINIC_SUCCESS,
    payload,
  };
}
export function clinicFastForwardFailure(json) {
  return {
    type: FF_CLINIC_FAILURE,
    payload: json,
  };
}
export function clearClinic() {
  return {
    type: CLEAR_CLINIC,
  };
}

export function getClinics() {
  // const { shouldChooseCampaign } = config;
  return (dispatch) => {
    dispatch(clinicGetRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getClinics({ shouldChooseCampaign: true }))
      .then((data) => {
        dispatch(clinicGetSuccess(data.result));
        // check if 1 is finished
        let hasOneDone = false;
        let completedCases = 0;
        let completedVisits = 0;
        data.result.forEach((d) => {
          if (d.complete) {
            hasOneDone = true;
            completedCases += 1;
          }
          if (d.isStart && d?.story?.data?.numOfEncounters) {
            completedVisits += d.story.data.numOfEncounters;
          }
        });
        dispatch(clinicHasOneDone({ hasOneDone, completedCases, completedVisits }));
        return data.result;
      })
      .catch((error) => {
        dispatch(clinicGetFailure(error));
      });
  };
}

export function getAllScenarios() {
  const state = store.getState();
  const { activeCampaign } = state.profile.form;
  return (dispatch) => {
    dispatch(clinicGetRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getAllScenarios({ activeCampaign }))
      .then((data) => {
        dispatch(clinicGetSuccess(data.result));
        // check if 1 is finished
        let hasOneDone = false;
        let completedCases = 0;
        let completedVisits = 0;
        data.result.forEach((d) => {
          if (d.complete) {
            hasOneDone = true;
            completedCases += 1;
          }
          if (d.isStart && d?.story?.data?.numOfEncounters) {
            completedVisits += d.story.data.numOfEncounters;
          }
        });
        dispatch(clinicHasOneDone({ hasOneDone, completedCases, completedVisits }));
        return data.result;
      })
      .catch((error) => {
        dispatch(clinicGetFailure(error));
      });
  };
}

export function fastForwardClinic(clinicId) {
  return (dispatch) => {
    dispatch(clinicFastForwardRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).fastForwardClinic(clinicId))
      .then((data) => {
        dispatch(clinicFastForwardSuccess(data));
        // getClinics();
      })
      .catch((error) => {
        dispatch(clinicFastForwardFailure(error));
      });
  };
}

export function restartScenario(clinicId) {
  return (dispatch) => {
    dispatch(restartScenarioRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).restartScenario(clinicId))
      .then(
        (data) => dispatch(restartScenarioSuccess(data)),
        // getClinics();
      )
      .catch((error) => dispatch(restartScenarioFailure(error)));
  };
}

export function nextScenario(clinicId) {
  return (dispatch) => {
    dispatch(nextScenarioRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).nextScenario(clinicId))
      .then(
        (data) => dispatch(nextScenarioSuccess(data)),
        // getClinics();
      )
      .catch((error) => dispatch(nextScenarioFailure(error)));
  };
}

export function getPotentialDefaultStories() {
  return (dispatch) => {
    dispatch(getPotentialDefaultStoriesRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getPotentialDefaultStories())
      .then((data) => {
        dispatch(getPotentialDefaultStoriesSuccess(data.result));
      })
      .catch((error) => {
        dispatch(getPotentialDefaultStoriesFailure(error));
      });
  };
}

export function addStoryToUser(defaultStoryId) {
  return (dispatch) => {
    dispatch(addStoryToUserRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).addStoryToUser(defaultStoryId))
      .then((data) => {
        // test
        toast.alertWithType('success', I18n.t('Clinic.success'), I18n.t('Clinic.caseAdded'));
        retentionService.cancelUserDidNotAddCase();
        dispatch(addStoryToUserSuccess(data.result));
        return data.result;
      })
      .catch((error) => {
        dispatch(addStoryToUserFailure(error));
      });
  };
}

export function addScenarioToUser(defaultStoryId) {
  return (dispatch) => {
    dispatch(addStoryToUserRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).addScenarioToUser(defaultStoryId))
      .then((data) => {
        dispatch(addStoryToUserSuccess(data.result));
        return data.result;
      })
      .catch((error) => {
        dispatch(addStoryToUserFailure(error));
      });
  };
}

export function removeScenarioFromUser(scenarioId) {
  return (dispatch) => {
    dispatch(removeScenarioFromUserRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).removeScenarioFromUser(scenarioId))
      .then((data) => {
        dispatch(removeScenarioFromUserSuccess(data.result));
      })
      .catch((error) => {
        dispatch(removeScenarioFromUserFailure(error));
      });
  };
}
