import { StyleSheet, Platform } from 'react-native';

const isAndroid = Platform.OS === 'android';

export default function Styles(design, isDesktop, fromHeader) {
  const HEIGHT = fromHeader ? 30 : 40;
  const WIDTH = fromHeader ? 30 : 40;
  const RADIUS = fromHeader ? 15 : 20;
  const DESKTOP_HEIGHT = 40;
  const DESKTOP_WIDTH = 40;
  const DESKTOP_RADIUS = 20;

  return StyleSheet.create({
    horizontalWrapper: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: 20,
    },
    containerNoBackground: {
      backgroundColor: design.colors.transparent,
      marginTop: 0,
    },
    innerWrapper: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    hoverButtonStyle: {
      padding: 0,
      margin: 0,
      marginLeft: 10,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: isDesktop ? DESKTOP_RADIUS : RADIUS,
      width: isDesktop ? DESKTOP_WIDTH : WIDTH,
      height: isDesktop ? DESKTOP_HEIGHT : HEIGHT,
      backgroundColor: design.colors.clinicBlue,
    },
    icon: {
      position: 'absolute',
      zIndex: 1,
    },
    nativeIcon: {
      bottom: isAndroid ? 76 : 106,
      left: 20,
    },
    iconWeb: {
      bottom: 81,
      left: 20,
    },
    iconDesktop: {
      top: 10,
    },
    wrapper: {
      height: '100%',
      width: '100%',
    },
    incomingStyle: {
      bottom: 77,
      left: 10,
    },
    container: {
      padding: 9,
      margin: 0,
      width: 50,
      height: 50,
      position: 'absolute',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    containerDesktop: {
      padding: 9,
      margin: 0,
      width: 60,
      height: 60,
      position: 'absolute',
      // backgroundColor: 'yellow',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    modal: {
      flex: 1,
      margin: 0,
    },
    headerContainer: {
      marginTop: 10,
      marginBottom: 10,
    },
    header: {
      color: design.colors.clinicHeader,
      fontSize: design.text.twenty,
      alignSelf: 'center',
      fontWeight: 'bold',
    },
    innerIcon: {
      width: isDesktop ? 20 : 18,
      height: isDesktop ? 20 : 18,
    },
    subHeader: {
      color: design.colors.clinicHeader,
      fontSize: design.text.semiReg,
      alignSelf: 'center',
      fontWeight: 'bold',
    },
    imageContainer: {
      alignItems: 'center',
      marginBottom: 20,
      marginLeft: 0,
    },
    campaignsWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      justifyContent: 'space-between',
    },
    scrollViewStyle: {
      padding: 5,
      // borderRadius: 3,
      // maxHeight: isDesktop ? height * 0.5 : height * 0.4,
    },
    scrollViewContentStyle: {
      justifyContent: 'center',
    },
    deskTopScrollInnerWrap: {
      position: 'relative',
      flexDirection: isDesktop ? 'row' : 'column',
      alignContent: 'space-around',
      flex: 1,
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    indicatorWrap: {
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255,.04)',
      left: 0,
      top: 0,
      flex: 1,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeCampaignStyle: {
      margin: 10,
      padding: 5,
      paddingBottom: 15,
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      borderRadius: 3,
      shadowOffset: { height: 0, width: 0 },
    },
    campaignsWrapDesktop: {
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      justifyContent: 'space-between',
    },
    campaignsWrapMobile: {
      flexDirection: 'column',
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      justifyContent: 'space-between',
    },
    campaignCardMobile: {
      marginBottom: 10,
      marginTop: 10,
      width: '100%',
      minWidth: '30%',
      padding: 15,
      backgroundColor: design.colors.loginBtn,
      borderRadius: 3,
      shadowOffset: { height: 0, width: 0 },
    },
    campaignCardDeskTop: {
      marginRight: '1%',
      marginLeft: '2%',
      marginBottom: '1%',
      width: isDesktop ? '30%' : '100%',
      minWidth: '30%',
      padding: 15,
      backgroundColor: design.colors.loginBtn,
      borderRadius: 3,
      shadowOffset: { height: 0, width: 0 },
    },
    image: {
      height: 100,
      width: '100%',
    },
    buttonStyle: {
      backgroundColor: design.colors.loginBtn,
      padding: 20,
    },
    imageStyle: {
      width: 18,
      height: 12,
    },
    sideBySide: {
      marginTop: 20,
      marginBottom: 20,
    },
    slideWrapper: {
      backgroundColor: design.colors.baseGray,
      padding: 20,
      borderRadius: 3,
      position: 'relative',
      // alignItems: 'center',
    },
    dividerCard: {
      marginTop: 10,
      marginBottom: 10,
    },
    bottomCardWrap: {
      flex: 1,
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    bottomCardWrapNoImage: {
      flex: 1,
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
    },
    cardTextWrap: {
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
      flex: 1,
    },
    campaignName: {
      writingDirection: 'ltr',
      textAlign: 'left',
      color: design.colors.clinicHeader,
      fontSize: design.text.semiReg,
      fontWeight: 'bold',
    },
    brandSlogan: {
      color: design.colors.clinicHeader,
      fontSize: design.text.semiReg,
      alignSelf: 'flex-start',
    },
    deskTopCardWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      flexDirection: 'row',
    },
  });
}
