/**
 * # globalReducer.js
 *
 *
 */


/**
 * ## Imports
 * The InitialState for auth

 * formValidation for setting the form's valid flag
 */
import InitialState from './goalInitialState';

const { SET_GOAL } = require('../../lib/constants').default;

const initialState = new InitialState();
/**
 * ## globalReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function clinicReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.merge(state);

  switch (action.type) {
    case SET_GOAL:
      return state
        .set('goal', action.payload.goal)
        .set('articles', action.payload.articles);
    default:
      break;
  }
  return state;
}
