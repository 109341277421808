import React from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, {
  LinearGradient, Defs, Stop, Rect,
} from 'react-native-svg';
import isNumber from 'lodash/isNumber';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextMontserrat, design, I18n, helper, enums,
} from '../../lib/general';

const HEIGHT = 20;
const WIDTH = 80;
const WIDTH_WITH_TIME_STATUS = 80; // 120;
const styles = StyleSheet.create({
  container: {
    borderRadius: 3,
    overflow: 'hidden',
  },
  svg: {},
  textContainer: {
    position: 'relative',
    top: 0,
    left: 0,
    height: HEIGHT,
    width: WIDTH,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  textContainerShort: {
    width: WIDTH_WITH_TIME_STATUS,
  },
  text: {
    // backgroundColor: design.colors.transparent,
    color: design.colors.white,
    fontSize: design.text.midSmall,
    fontWeight: 'bold',
  },
});
const HeaderMonths = (props) => {
  const { months, selectedColor } = props;
  const sim = useSelector((s) => s.patient?.currentPatient?.data?.sim?.data);
  if (!sim) return null;
  const { timeStatus, scenarioTicks, shortScenarioTicks } = sim;
  const obj = helper.colorToGradient(selectedColor);
  const {
    x1, x2, y1, y2, startColor, endColor,
  } = obj;
  if (!isNumber(scenarioTicks)) return null;
  const isShort = timeStatus === enums.timeStatusEnum.SHORT_TERM;
  const updatedWidth = isShort ? WIDTH_WITH_TIME_STATUS : WIDTH;
  let text = I18n.t('Edocate.headerMonthsLocalized', { count: scenarioTicks });
  if (isShort) {
    text = I18n.t('Edocate.headerMonthsLocalizedShort', { count: shortScenarioTicks });
  }
  return (
    <View style={styles.container}>
      {/* <Svg height={HEIGHT} width={updatedWidth} style={styles.svg}>
        <Defs>
          <LinearGradient id="grad" x1={x1} y1={y1} x2={x2} y2={y2}>
            <Stop offset="0" stopColor={startColor} />
            <Stop offset="1" stopColor={endColor} />
          </LinearGradient>
        </Defs>
        <Rect x="0" y="0" height="100%" width="100%" fill="url(#grad)" />
      </Svg> */}
      <View style={[styles.textContainer, isShort && styles.textContainerShort, { backgroundColor: selectedColor }]}>
        <TextMontserrat style={styles.text}>
          {text}
        </TextMontserrat>
      </View>
    </View>
  );
};

export default HeaderMonths;
