/* eslint-disable no-undef */
import isNumber from 'lodash/isNumber';
import { config } from './config';
import version from '../../version.json';
import store from '../reducers/store';
import enums from './enums';
import * as notificationActions from '../reducers/notification/notificationActions';

let firebase = {};
let database = {};
let firebaseAfterInit = {};
let firebaseAnalytics = {
  logEvent: () => {
    // console.log('mock logEvent');
  },
  setCurrentScreen: () => {
    // console.log('mock setCurrentScreen');
  },
  setUserId: () => {
    // console.log('mock setUserId');
  },
  setUserProperty: () => {
    // console.log('mock setUserProperty');
  },
  setUserProperties: () => {
    // console.log('mock setUserProperties');
  },
};
let Sentry = {
  init: () => {
    // console.log('mock init');
  },
  captureException: () => {
    // console.log('mock captureException');
  },
  captureMessage: () => {
    // console.log('mock captureMessage');
  },
  showReportDialog: () => {
    // console.log('mock showReportDialog');
  },
  setUser: () => {
    // console.log('mock showReportDialog');
  },
};
let LogRocket = {
  init() {
    // console.log('mock logrocket init');
  },
  identify() {
    // console.log('mock logrocket identify');
  },
};
let LogRocketReact = {
  setupLogRocketReact() {
    // console.log('mock logrocket-react setupLogRocketReact');
  },
};
let Bugsnag = {
  setUser: () => {
    // console.log('mock setUser');
  },
  notify: () => {
    // console.log('mock notify');
  },
};
const IS_DEV = window.location.href.indexOf('localhost') > -1;
export class Analytics {
  constructor() {
    this.isActive = !IS_DEV;
    this.lastSetCaseSegmentIndex = -1;
    this.fbAnalytics = null;
  }

  init() {
    this.initBugsnag();
    // this.initSentry();
    this.initFirebase();
    this.initLogRocket();
  }

  initBugsnag() {
    try {
      if (this.isActive) {
        import('@bugsnag/js')
          .then((loadedBugsnag) => {
            Bugsnag = loadedBugsnag.default;
            Bugsnag.start({
              apiKey: config.bugsnagKey,
              appVersion: version.version,
            });
          })
          .catch((err) => {
            console.warn('bugsnag dynamic import error', err);
          });
      }
    } catch (error) {
      console.warn('couldnt initSentry Analytics web');
    }
  }

  initFirebase() {
    try {
      if (this.isActive) {
        const all = [import('firebase/app'),
          import('firebase/analytics'),
          import('firebase/database'),
          import('firebase/auth')];
        Promise.all(all)
          .then(async ([app, fbAnalytics, db, authModule]) => {
            firebase = app.default || app;
            const isSupported = await fbAnalytics.isSupported();
            if (!isSupported) {
              console.log('firebase is not supported');
              return;
            }
            firebaseAfterInit = firebase.initializeApp(config.firebase);
            this.fbAnalytics = fbAnalytics.getAnalytics();
            firebaseAnalytics = fbAnalytics;
            if (db) {
              database = db;// firebase.database();
            }
            if (authModule) {
              const auth = authModule.getAuth();
              authModule.signInAnonymously(auth).then(() => {});
            }
          })
          .catch((err) => {
            console.warn('dynamic firebase analytics err', err);
          });
      }
    } catch (error) {
      console.warn('couldnt initFirebase web');
    }
  }

  initSentry() {
    try {
      if (this.isActive) {
        import('@sentry/browser')
          .then((loadedSentry) => {
            Sentry = loadedSentry;
            Sentry.init({
              dsn: config.sentryKey,
              release: version.version,
              debug: IS_DEV,
            });
          })
          .catch((err) => {
            console.warn('sentry dynamic import error', err);
          });
      }
    } catch (error) {
      console.warn('couldnt initSentry Analytics web');
    }
  }

  initLogRocket() {
    try {
      if (this.isActive) {
        let myAppId = config.PARSE.appId;
        if (config.disableLogRocket) return;
        if (myAppId === 'cea') {
          myAppId = 'cea-wgxpl';
        } else if (myAppId?.length === 3) {
          myAppId = `edocate-${myAppId}`;
        }

        const LOGROCKET_KEY = `km7wyy/${myAppId}`;
        const all = [import('logrocket'), import('logrocket-react')];
        Promise.all(all)
          .then(([logrocketLoaded, logrocketReactLoaded]) => {
            LogRocket = logrocketLoaded.default;
            LogRocketReact = logrocketReactLoaded.default;
            LogRocket.init(LOGROCKET_KEY, {
              release: version.version,
              shouldAugmentNPS: false, // disables survey report
              // TODO: Check if we want this for multi domains - this seperates the session
              // rootHostname: 'example.com',
              network: {
                requestSanitizer: (request) => {
                  const requestTofilter = request.url.indexOf('/functions/secureLogin') > -1
                  || request.url.indexOf('/functions/specialSignUp') > -1;

                  if (requestTofilter) {
                    // ignore the request response pair
                    return null;
                  }
                  return request;
                },
              },
            });
            LogRocketReact(LogRocket);
          })
          .catch((err) => {
            console.warn('dynamic logrocket, logrocket-react err', err);
          });
      }
    } catch (error) {
      console.warn('Couldn\'t initLogRocket web');
    }
  }

  logCustom(title, obj = {}) {
    try {
      if (this.isActive) {
        firebaseAnalytics.logEvent(this.fbAnalytics, title, obj);
      }
    } catch (err) {
      console.log('error analytics logCustom', err);
    }
  }

  logScenarioAction(action, name) {
    try {
      const LABEL = 'scenario';
      if (this.isActive) {
        this.logCustom(LABEL, { action, label: name });
        const reportTxt = action.replace(/\s+/g, '_').toLowerCase();
        this.realTimeReports('userStatus', reportTxt, 1, reportTxt);
      }
    } catch (err) {
      console.log('error analytics logScenarioAction ', err);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  realTimeReports(action, name, label, val, item) {
    if (!config.enableRealTimeReport) return;
    const state = store.getState();
    const scenarioName = state?.patient?.currentPatient?.defaultScenario?.story?.name || '';
    const scenarioObjectId = state?.patient?.currentPatient?.defaultScenario?.objectId || '';
    const activeCampaign = state?.profile?.form?.activeCampaign || state?.patient?.currentPatient?.activeCampaign || '';
    const rtData = {
      action,
      item: name,
      data: label === 'selectAll' ? val : item?.displayName || label,
      value: name === 'referral' ? item.item.valueText : val,
      scenarioName,
      scenarioObjectId,
      activeCampaign,
      t: Date.now(),
    };
    if (config.useServerForRealTimeReport) {
      notificationActions.sendEventsToFirebase(rtData);
    } else {
      try {
        const {
          getDatabase, ref, set, push,
        } = database;
        const db = getDatabase(firebaseAfterInit);
        const postListRef = ref(db, 'userActions/');
        const newPostRef = push(postListRef);
        set(newPostRef, rtData);
      } catch (err) {
        console.log('error analytics realTimeReports ', err);
      }
    }
  }

  logAddClick(name, label = '', val = 1, item = null) {
    try {
      const LABEL = `add_click_${name.replace(/ /g, '_')}`;
      if (this.isActive) {
        this.realTimeReports('add', name, label, val, item);
        const value = parseInt(val, 10);
        this.logCustom(LABEL, { name, label, value });
      }
    } catch (err) {
      console.log('error analytics logAddClick ', err);
    }
  }

  logButtonClick(name, label, val) {
    try {
      const LABEL = `button_click_${name.replace(/ /g, '_')}`;
      if (this.isActive) {
        let value = val || 1;
        value = parseInt(value, 10);
        this.logCustom(LABEL, { name, label, value });
      }
    } catch (err) {
      console.log('error analytics logButtonClick', err);
    }
  }

  logDrugSelected(generic, brand, size) {
    try {
      const LABEL = 'drug_select';
      if (this.isActive) {
        if (!generic || !brand) return;
        let value = isNumber(size) ? size : 1;
        if (value < 1 && value > -1) {
          value *= 100;
        }
        value = parseInt(value, 10);
        this.logCustom(LABEL, {
          generic,
          brand,
          value,
        });
      }
    } catch (err) {
      console.log('error analytics logDrugSelected ', err);
    }
  }

  logContentView(screenName) {
    try {
      if (this.isActive) {
        firebaseAnalytics.setCurrentScreen(this.fbAnalytics, screenName);
        firebaseAnalytics.logEvent(this.fbAnalytics, 'screen_view',
          { screen_name: screenName, screen_class: screenName });
        firebaseAnalytics.logEvent(this.fbAnalytics, 'edocate_screen_view',
          { screen_name: screenName, screen_class: screenName });
      }
    } catch (err) {
      console.log('error analytics logContentView ', err);
    }
  }

  logLogin(provider, is) {
    try {
      if (this.isActive) {
        this.logCustom('login', { provider, is });
      }
    } catch (err) {
      console.log('error analytics logLogin ', err);
    }
  }

  logSearch(name) {
    try {
      if (this.isActive) {
        this.logCustom('search', { name });
      }
    } catch (err) {
      console.log('error analytics logSearch ', err);
    }
  }

  logSignUp(provider, is) {
    try {
      if (this.isActive) {
        this.logCustom('sign_up', { provider, is });
      }
    } catch (err) {
      console.log('error analytics logSignUp ', err);
    }
  }

  setUserIdentifier(UserIdentifier, isEdocate, campaign) {
    try {
      if (this.isActive) {
        firebaseAnalytics.setUserId(this.fbAnalytics, UserIdentifier);
        if (campaign) {
          firebaseAnalytics.setUserProperties(this.fbAnalytics, { campaign });
        }
        // Sentry.setUser({ userID: UserIdentifier });
        Bugsnag.setUser(UserIdentifier);
        LogRocket.identify(UserIdentifier, {
          name: config.appName,
          // Add custom user variables here
          appName: config.appName,
          appVersion: version.version,
          isEdocate,
          campaign,
        });
      }
    } catch (err) {
      console.error('error analytics setUserIdentifier ', err);
    }
  }

  setUserName(name) {
    try {
      if (this.isActive) {
        firebaseAnalytics.setUserProperties(this.fbAnalytics, { username: name });
        // Sentry.setUser({ username: name });
      }
    } catch (err) {
      console.log('error analytics setUserName ', err);
    }
  }

  setCaseSegment(val) {
    if (enums.caseStateSegment[val] > this.lastSetCaseSegmentIndex) {
      // console.log('case_state_segment', val);
      this.setUserProperty('case_state_segment', val);
      this.lastSetCaseSegmentIndex = enums.caseStateSegment[val];
    }
  }

  setUserProperty(key, val) {
    try {
      if (this.isActive) {
        if (!key || !val) return;
        const reportObj = {};
        reportObj[key] = val;
        // console.log('setUserProperty', reportObj);
        firebaseAnalytics.setUserProperties(this.fbAnalytics, reportObj);
        // Sentry.setUser(reportObj);
      }
    } catch (err) {
      console.log('FB error analytics setUserName ', err);
    }
  }

  setInstabugDefaultMailAndUser() {
    try {
      return this.isActive;
    } catch (error) {
      return this.isActive;
    }
  }

  logException(message) {
    try {
      if (this.isActive && message) {
        // Sentry.captureException(message);
        Bugsnag.notify(message);
      }
    } catch (err) {
      console.log('error analytics logException ', err);
    }
  }

  recordError(message) {
    try {
      if (this.isActive) {
        Bugsnag.notify(message);
        // Sentry.captureMessage(message);
      }
    } catch (err) {
      console.log('error analytics recordError ', err);
    }
  }

  openSentryFeedback() {
    try {
      if (this.isActive) {
        // const id = helper.guidGenerator();
        // const state = store.getState();
        // const { fullName, email } = state.profile.form.fields;
        // Sentry.showReportDialog({
        //   eventId: id,
        //   dsn: config.sentryKey,
        //   title: I18n.t('Feedback.sentryFeedbackTitle'),
        //   subtitle: I18n.t('Feedback.sentryFeedbackSubtitle'),
        //   labelComments: I18n.t('Feedback.sentryFeedbackLabelComments'),
        //   labelSubmit: I18n.t('Feedback.sentryFeedbackLabelSubmit'),
        //   user: {
        //     email,
        //     name: fullName,
        //   },
        // });
      }
    } catch (err) {
      console.log('error analytics openSentryFeedback ', err);
    }
  }
}
// The singleton variable
export const analytics = new Analytics();
// window.addEventListener('shake', analytics.openSentryFeedback, false);
