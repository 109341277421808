import filter from 'lodash/filter';

import clinicalApi from '../../lib/ClinicalApi';
import userUpdateHelper from '../../lib/userUpdateHelper';
import { appAuthToken } from '../../lib/AppAuthToken';
import { runOverConfigWithServerConfig } from '../../lib/config';
// import patientReducer from '../index';

const BackendFactory = require('../../lib/BackendFactory').default;

const {
  GET_PATIENT_REQUEST,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_FAILURE,
  GET_AUTOICD10_REQUEST,
  GET_AUTOICD10_SUCCESS,
  GET_AUTOICD10_FAILURE,
  GET_AUTODRUGS_REQUEST,
  GET_AUTODRUGS_SUCCESS,
  GET_AUTODRUGS_FAILURE,
  SET_PATIENT_ID,
  SET_CLINIC_ID,
  GET_TREATMENTS_REQUEST,
  GET_TREATMENTS_SUCCESS,
  GET_TREATMENTS_FAILURE,
  GET_GLOBAL_REQUEST,
  GET_GLOBAL_SUCCESS,
  GET_GLOBAL_FAILURE,
  GET_MY_PEER_STATS_REQUEST,
  GET_MY_PEER_STATS_SUCCESS,
  GET_MY_PEER_STATS_FAILURE,
  SET_UPDATES_TEMP,
  SET_UPDATES_REQUEST,
  SET_UPDATES_SUCCESS,
  SET_UPDATES_FAILURE,
  SET_UPDATES_INITIAL,
  CREATE_NOTES,
  GET_DRUGS_INFO_REQUEST,
  GET_DRUGS_INFO_SUCCESS,
  GET_DRUGS_INFO_FAILURE,
  SET_UPDATES_MODAL,
  SET_UPDATES_COLOR,
  SET_READ_ONLY,
  SET_DATA_READ_ONLY,
  START_SCENARIO_REQUEST,
  START_SCENARIO_SUCCESS,
  START_SCENARIO_FAILURE,
  GET_GOALS_ARRAY_REQUEST,
  GET_GOALS_ARRAY_SUCCESS,
  GET_GOALS_ARRAY_FAILURE,
  SET_FEEDBACK_INITIAL,
  GET_ICD_LINK_REQUEST,
  GET_ICD_LINK_SUCCESS,
  GET_ICD_LINK_FAILURE,
  SET_PROFILES_ARRAY,
  SET_FILTERED_ARRAY_BY_PROFILE,
  SET_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_REQUEST,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_FAILURE,
  UPDATE_QUESTIONNAIRE_REQUEST,
  UPDATE_QUESTIONNAIRE_SUCCESS,
  UPDATE_QUESTIONNAIRE_FAILURE,
  GET_MY_CREDITS_REQUEST,
  GET_MY_CREDITS_SUCCESS,
  GET_MY_CREDITS_FAILURE,
  SET_SELECTED_CREDIT,
  SEND_CERTIFICATE_REQUEST,
  SEND_CERTIFICATE_SUCCESS,
  SEND_CERTIFICATE_FAILURE,

  GET_EDOCATE_WAY_REQUEST,
  GET_EDOCATE_WAY_SUCCESS,
  GET_EDOCATE_WAY_FAILURE,
  SET_PARAMETERS_WITH_SCENARIO_DATA,
  CLEAR_PATIENT,
  SET_DEFAULT_SCENARIO,
  SET_DEFAULT_STORY,
} = require('../../lib/constants').default;

export function sendCertificateToMyEmailRequest() {
  return { type: SEND_CERTIFICATE_REQUEST };
}
export function sendCertificateToMyEmailSuccess(payload) {
  return { type: SEND_CERTIFICATE_SUCCESS, payload };
}
export function sendCertificateToMyEmailFailure(payload) {
  return { type: SEND_CERTIFICATE_FAILURE, payload };
}

export function getMyCreditsRequest() {
  return { type: GET_MY_CREDITS_REQUEST };
}
export function getMyCreditsSuccess(payload) {
  return { type: GET_MY_CREDITS_SUCCESS, payload };
}
export function getMyCreditsFailure(payload) {
  return { type: GET_MY_CREDITS_FAILURE, payload };
}

export function updateQuestionnaireRequest() {
  return { type: UPDATE_QUESTIONNAIRE_REQUEST };
}
export function updateQuestionnaireSuccess() {
  return { type: UPDATE_QUESTIONNAIRE_SUCCESS };
}
export function updateQuestionnaireFailure() {
  return { type: UPDATE_QUESTIONNAIRE_FAILURE };
}

export function getQuestionnaireRequest() {
  return { type: GET_QUESTIONNAIRE_REQUEST };
}
export function getQuestionnaireSuccess(payload) {
  return { type: GET_QUESTIONNAIRE_SUCCESS, payload };
}
export function getQuestionnaireFailure(json) {
  return { type: GET_QUESTIONNAIRE_FAILURE, payload: json };
}

export function updatesInitial(payload) {
  return { type: SET_UPDATES_INITIAL, payload };
}
export function feedbackInitial() {
  return { type: SET_FEEDBACK_INITIAL, payload: {} };
}

export function updateSelectedColorDispatch(payload) {
  return { type: SET_UPDATES_COLOR, payload };
}

export function updatePatientModal(payload) {
  return { type: SET_UPDATES_MODAL, payload };
}

export function updatesSetTemp(payload) {
  return { type: SET_UPDATES_TEMP, payload };
}

export function startScenarioRequest() {
  return { type: START_SCENARIO_REQUEST };
}
export function startScenarioSuccess(payload) {
  return { type: START_SCENARIO_SUCCESS, payload };
}
export function startScenarioFailure(json) {
  return { type: START_SCENARIO_FAILURE, payload: json };
}

export function updatesSetRequest() {
  return { type: SET_UPDATES_REQUEST };
}
export function updatesSetSuccess(payload) {
  return { type: SET_UPDATES_SUCCESS, payload };
}
export function updatesSetFailure(json) {
  return { type: SET_UPDATES_FAILURE, payload: json };
}

export function treatmentsGetRequest() {
  return { type: GET_TREATMENTS_REQUEST };
}
export function treatmentsGetSuccess(payload) {
  return { type: GET_TREATMENTS_SUCCESS, payload };
}
export function treatmentsGetFailure(json) {
  return { type: GET_TREATMENTS_FAILURE, payload: json };
}

export function globalGetRequest() {
  return { type: GET_GLOBAL_REQUEST };
}
export function globalGetSuccess(payload) {
  return { type: GET_GLOBAL_SUCCESS, payload };
}
export function globalGetFailure(json) {
  return { type: GET_GLOBAL_FAILURE, payload: json };
}

export function getMyPeerStatsRequest() {
  return { type: GET_MY_PEER_STATS_REQUEST };
}
export function getMyPeerStatsSuccess(payload) {
  return { type: GET_MY_PEER_STATS_SUCCESS, payload };
}
export function getMyPeerStatsFailure(json) {
  return { type: GET_MY_PEER_STATS_FAILURE, payload: json };
}

export function patientGetRequest() {
  return { type: GET_PATIENT_REQUEST };
}
export function patientGetSuccess(payload) {
  return { type: GET_PATIENT_SUCCESS, payload };
}
export function patientGetFailure(json) {
  return { type: GET_PATIENT_FAILURE, payload: json };
}

export function autoGetRequest() {
  return { type: GET_AUTOICD10_REQUEST };
}
export function autoGetSuccess(payload) {
  return { type: GET_AUTOICD10_SUCCESS, payload };
}
export function autoGetFailure(json) {
  return { type: GET_AUTOICD10_FAILURE, payload: json };
}

export function autoDrugsGetRequest() {
  return { type: GET_AUTODRUGS_REQUEST };
}
export function autoDrugsGetSuccess(payload) {
  return { type: GET_AUTODRUGS_SUCCESS, payload };
}
export function autoDrugsGetFailure(json) {
  return { type: GET_AUTODRUGS_FAILURE, payload: json };
}

export function getICD10linkFromCodeRequest() {
  return { type: GET_ICD_LINK_REQUEST };
}
export function getICD10linkFromCodeSuccess(payload) {
  return { type: GET_ICD_LINK_SUCCESS, payload };
}
export function getICD10linkFromCodeFailure(json) {
  return { type: GET_ICD_LINK_FAILURE, payload: json };
}

export function getGoalsArrayRequest() {
  return { type: GET_GOALS_ARRAY_REQUEST };
}
export function getGoalsArraySuccess(payload) {
  return { type: GET_GOALS_ARRAY_SUCCESS, payload };
}
export function getGoalsArrayFailure(json) {
  return { type: GET_GOALS_ARRAY_FAILURE, payload: json };
}

export function getDrugInfoRequest() {
  return { type: GET_DRUGS_INFO_REQUEST };
}
export function getDrugInfoSuccess(payload) {
  return { type: GET_DRUGS_INFO_SUCCESS, payload };
}
export function getDrugInfoFailure(json) {
  return { type: GET_DRUGS_INFO_FAILURE, payload: json };
}

export function updatePatientId(json) {
  return { type: SET_PATIENT_ID, payload: json };
}
export function updateDataReadOnly(json) {
  return { type: SET_DATA_READ_ONLY, payload: json };
}
export function updateReadOnly(json) {
  return { type: SET_READ_ONLY, payload: json };
}
export function updateClinicId(json) {
  return { type: SET_CLINIC_ID, payload: json };
}

export function setCurrentDefaultScenario(json) {
  return { type: SET_DEFAULT_SCENARIO, payload: json };
}

export function setCurrentDefaultStory(json) {
  return { type: SET_DEFAULT_STORY, payload: json };
}

export function createAutoNotesUpdate(json) {
  return { type: CREATE_NOTES, payload: json };
}

export function getTreatments() {
  return (dispatch) => {
    dispatch(treatmentsGetRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getTreatments())
      .then((data) => {
        dispatch(treatmentsGetSuccess(data.results));
      })
      .catch((error) => {
        dispatch(treatmentsGetFailure(error));
      });
  };
}

export function getGlobal() {
  return (dispatch) => {
    dispatch(globalGetRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getGlobal())
      .then((data) => {
        dispatch(globalGetSuccess(data.result));
        runOverConfigWithServerConfig();
      })
      .catch((error) => {
        dispatch(globalGetFailure(error));
      });
  };
}

export function getMyPeerStats(scenarioId) {
  return (dispatch) => {
    dispatch(getMyPeerStatsRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getMyPeerStats(scenarioId))
      .then((data) => {
        dispatch(getMyPeerStatsSuccess(data.result));
      })
      .catch((error) => {
        dispatch(getMyPeerStatsFailure(error));
      });
  };
}

export function getEdocateWay(scenarioId) {
  return (dispatch) => {
    dispatch({ type: GET_EDOCATE_WAY_REQUEST });
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getEdocateWay(scenarioId))
      .then((data) => {
        dispatch({ type: GET_EDOCATE_WAY_SUCCESS, payload: data.result });
      })
      .catch((error) => {
        dispatch({ type: GET_EDOCATE_WAY_FAILURE, payload: error });
      });
  };
}

export function addToUserUpdate(
  currentUserUpdate,
  action,
  parent,
  data,
  // number
) {
  return (dispatch, getState) => {
    // should i provide current???
    const update = userUpdateHelper.addToUserUpdate(currentUserUpdate.toJS(), action, parent, data, getState);
    dispatch(updatesSetTemp(update));
  };
}

export function setUserUpdatesToInitialState(currentUserUpdate) {
  return (dispatch) => {
    const update = userUpdateHelper.setUserUpdatesToInitialState(currentUserUpdate.toJS());
    dispatch(updatesInitial(update));
  };
}

export function setFeedbackToInitialState() {
  return (dispatch) => {
    dispatch(feedbackInitial());
  };
}

export function removeFromUserUpdate(currentUserUpdate, action, parent, nameOfField, valueOfField) {
  return (dispatch) => {
    const update = userUpdateHelper.removeFromUserUpdate(
      currentUserUpdate.toJS(),
      action,
      parent,
      nameOfField,
      valueOfField,
    );
    dispatch(updatesSetTemp(update));
  };
}

export function startScenario(id) {
  return (dispatch) => {
    dispatch(startScenarioRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).startScenario(id))
      .then((data) => dispatch(startScenarioSuccess(data.result)))
      .catch((error) => {
        dispatch(startScenarioFailure(error));
        throw error;
      });
  };
}

export function setUserUpdates(currentUserUpdate, scenarioId, storyId) {
  const updates = userUpdateHelper.cleanDataAfterScenario(currentUserUpdate);
  return (dispatch) => {
    dispatch(updatesSetRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).setUserUpdates(updates, scenarioId, storyId))
      .then((data) => dispatch(updatesSetSuccess(data.result)))
      .catch((error) => dispatch(updatesSetFailure(error)));
  };
}

export function getScenarioByStoryId(id) {
  return (dispatch, getState) => {
    dispatch(patientGetRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getScenarioByStoryId(id))
      .then((data) => {
        // prepare data
        const scenario = data.result;
        const { patient } = getState();
        const preparedUserData = userUpdateHelper.prepareUserDataBeforeScenario(scenario, patient);
        const preparedGlobalData = userUpdateHelper.prepareGlobalDataForScenario(scenario, patient);
        dispatch(patientGetSuccess({ preparedUserData, preparedGlobalData }));
      })
      .catch((error) => {
        // debugger
        dispatch(patientGetFailure(error));
      });
  };
}

export function setPatientId(id) {
  return (dispatch) => {
    dispatch(updatePatientId(id));
  };
}

export function setClinic(obj) {
  return (dispatch) => dispatch(updateClinicId(obj));
}
export function setReadOnly(obj) {
  return (dispatch) => dispatch(updateReadOnly(obj));
}
// 3rd party api for autocomplete

export function getIcd10AutoComplete(str, profiles) {
  return (dispatch) => {
    dispatch(autoGetRequest());
    return clinicalApi
      .getIcd10AutoComplete(str, profiles)
      .then((data) => {
        dispatch(autoGetSuccess(data));
      })
      .catch((error) => {
        dispatch(autoGetFailure(error));
      });
  };
}

export function getDrugsAutoComplete(str) {
  return (dispatch) => {
    dispatch(autoDrugsGetRequest());
    return clinicalApi
      .getDrugsAutoComplete(str)
      .then((data) => {
        dispatch(autoDrugsGetSuccess(data));
      })
      .catch((error) => {
        dispatch(autoDrugsGetFailure(error));
      });
  };
}

export function getICD10linkFromCode(code) {
  return (dispatch) => {
    dispatch(getICD10linkFromCodeRequest());
    return clinicalApi
      .getICD10linkFromCode(code)
      .then((data) => dispatch(getICD10linkFromCodeSuccess(data)))
      .catch((error) => dispatch(getICD10linkFromCodeFailure(error)));
  };
}

export function getGoalsArray(arr) {
  return (dispatch) => {
    dispatch(getGoalsArrayRequest());
    return appAuthToken.getSessionToken().then((token) => BackendFactory(token)
      .getGoalsArray(arr)
      .then((data) => dispatch(getGoalsArraySuccess(data)))
      .catch((error) => dispatch(getGoalsArrayFailure(error))));
  };
}

export function getDrugInfo(name, displayName) {
  return (dispatch) => {
    dispatch(getDrugInfoRequest());
    return clinicalApi
      .getDrugInfo(name, displayName)
      .then((data) => {
        let answer = {};
        if (data.results && data.results.length > 0) {
          ([answer] = data.results);
        }
        return dispatch(getDrugInfoSuccess(answer));
      })
      .catch((error) => {
        // report sentry on not found
        console.log('getDrugInfo catch', error);
        dispatch(getDrugInfoFailure(error));
        throw error;
      });
  };
}

export function createAutoNotes(currentUserUpdate, complaints, time) {
  return (dispatch) => {
    const update = userUpdateHelper.createAutoNotes(currentUserUpdate, complaints, time);
    return dispatch(createAutoNotesUpdate(update));
  };
}

export function showPatientModal(isOpen) {
  return (dispatch) => {
    dispatch(updatePatientModal(isOpen));
  };
}

export function updateSelectedColor(color) {
  return (dispatch) => {
    dispatch(updateSelectedColorDispatch(color));
  };
}

export function setFilteredArrayByProfile(payload) {
  return { type: SET_FILTERED_ARRAY_BY_PROFILE, payload };
}

export function setProfilesArray(payload) {
  return { type: SET_PROFILES_ARRAY, payload };
}

function filterByProfilesArray(arr, profilesArray) {
  let filtered = [];
  if (!profilesArray || profilesArray.length === 0) {
    filtered = arr;
  } else {
    filtered = filter(arr, (v) => profilesArray.some((r) => v?.profiles?.includes(r)));
  }
  return filtered;
  // return (dispatch) => {
  //   dispatch(
  //     setFilteredArrayByProfile({
  //       arr: filtered,
  //       propertyName,
  //     }),
  //   );
  // };
}

function filterByRemoveList(arr, removeList = []) {
  let filtered = arr;
  filtered = filter(filtered, (v) => removeList.indexOf(v.objectId) === -1 && removeList.indexOf(v.name) === -1);
  return filtered;
}

export function setDiseaseProfileAndFilterByRemoveList(profilesArray) {
  return (dispatch, getState) => {
    const state = getState();
    const { physicals, labsFull, treatments } = state.patient;
    const { hideLabs = [], hidePhysical = [], hideTreatments = [] } = state.patient?.currentPatient?.data?.sim?.data || {};
    dispatch(setProfilesArray(profilesArray));
    let filteredPhysical = filterByProfilesArray(physicals, profilesArray);
    filteredPhysical = filterByRemoveList(filteredPhysical, hidePhysical);
    dispatch(
      setFilteredArrayByProfile({ arr: filteredPhysical, propertyName: 'physicalsFilteredProfiles' }),
    );
    // dispatch(filterByProfilesArray(physicals, profilesArray, 'physicalsFilteredProfiles'));
    // state.patient.physicals ==> state.patient.physicalsFilteredProfiles
    let filteredLabs = filterByProfilesArray(labsFull, profilesArray);
    filteredLabs = filterByRemoveList(filteredLabs, hideLabs);
    dispatch(
      setFilteredArrayByProfile({ arr: filteredLabs, propertyName: 'labsFilteredProfiles' }),
    );
    // dispatch(filterByProfilesArray(labsFull, profilesArray, 'labsFilteredProfiles'));
    // state.patient.labsFull ==> state.patient.labsFilteredProfiles
    let filteredTreatments = filterByProfilesArray(treatments, profilesArray);
    filteredTreatments = filterByRemoveList(filteredTreatments, hideTreatments);
    dispatch(
      setFilteredArrayByProfile({ arr: filteredTreatments, propertyName: 'treatmentsProfiles' }),
    );
    // dispatch(filterByProfilesArray(treatments, profilesArray, 'treatmentsProfiles'));
  };
}

export function updateParametersWithScenarioData() {
  return (dispatch, getState) => {
    const state = getState();
    const { parametersCopy, currentPatient } = state.patient;
    const clonedParameters = JSON.parse(JSON.stringify(parametersCopy));
    const parametersFromData = currentPatient?.defaultScenario?.data?.parameters || [];
    const ignoreList = ['name', 'value', 'valueAfterMed', 'objectId'];
    parametersFromData.forEach((parameter) => {
      const parameterToUpdate = clonedParameters.find((p) => p.name === parameter.name || p.objectId === parameter.objectId);
      if (parameterToUpdate) {
        // merge object
        Object.entries(parameter).forEach(([key, val]) => {
          if (ignoreList.indexOf(key) === -1) {
            parameterToUpdate[key] = val;
          }
        });
      }
    });
    dispatch({ type: SET_PARAMETERS_WITH_SCENARIO_DATA, payload: clonedParameters });
  };
}

export function getQuestionnaire(questionnaireId) {
  return (dispatch) => {
    dispatch(getQuestionnaireRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getQuestionnaire(questionnaireId))
      .then((data) => dispatch(getQuestionnaireSuccess(data)))
      .catch((error) => dispatch(getQuestionnaireFailure(error)));
  };
}

export function setQuestionnaire(payload) {
  return { type: SET_QUESTIONNAIRE, payload };
}

export function setSelectedCredit(payload) {
  return { type: SET_SELECTED_CREDIT, payload };
}

export function clearPatient(payload) {
  return { type: CLEAR_PATIENT, payload };
}

export function updateQuestionnaire(questionnaire, creditId) {
  return (dispatch) => {
    dispatch(updateQuestionnaireRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).updateQuestionnaire(questionnaire, creditId))
      .then((data) => dispatch(updateQuestionnaireSuccess(data)))
      .catch((error) => dispatch(updateQuestionnaireFailure(error)));
  };
}

export function getMyCredits() {
  return (dispatch) => {
    dispatch(getMyCreditsRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getMyCredits())
      .then((data) => {
        dispatch(getMyCreditsSuccess(data));
      })
      .catch((error) => {
        dispatch(getMyCreditsFailure(error));
      });
  };
}

export function sendCertificateToMyEmail(creditId) {
  return (dispatch) => {
    dispatch(sendCertificateToMyEmailRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).sendCertificateToMyEmail(creditId))
      .then((data) => dispatch(sendCertificateToMyEmailSuccess(data)))
      .catch((error) => {
        throw dispatch(sendCertificateToMyEmailFailure(error));
      });
  };
}
