import React from 'react';
import {
  StyleSheet, View, ScrollView,
} from 'react-native';
import MediaQuery from '../components/general/MediaQuery';
import { global } from '../lib/general';
// import config from '../lib/config';

const styles = StyleSheet.create({
  pagePadding: {
    padding: 50,
    paddingTop: 20,
    paddingBottom: 0,
    flex: 1,
  },
});

function withPadding(WrappedComponent, props = {}) {
  return (
    <MediaQuery
      maxWidth={global.mobileMaxWidth}
    >
      {(matches) => (
        !matches ? (
          <View style={styles.pagePadding}>
            <WrappedComponent {...props} />
          </View>
        ) : <WrappedComponent {...props} />
      )}
    </MediaQuery>
  );
}

export default withPadding;
