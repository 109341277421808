import memory from './Memory';
import store from '../reducers/store';
import { Tutorial } from '../components/general/react-native-swiper-tutorial';
import analyticsDictionary from '../containers/virtual/Analytics/analyticsMap';
import { config } from './config';
const { tutorialScreensMAp } = analyticsDictionary;
const globalTutorialState = config.GlobalTutorialState;

const SLIDES = {
  checkIfShow: async (page,globalTutorialState) => {
    const pageToCheck = tutorialScreensMAp[page] || page;
    // if (!pageToCheck) return false;
    const answer = await memory.getItem(pageToCheck);
    if (!answer) memory.setItem(pageToCheck, true).then(() => {});
    if(globalTutorialState === 'disabled' && !answer){
      return false;
    }else if(globalTutorialState === 'enabled' && !answer){
        return false;
    }
    return !answer; // false needs to show
  },
  setShown: (page) => {
    memory.setItem(page, true).then(() => {});
  },
  clearAllMemory: () => {
    memory.deleteArray(Object.keys(tutorialScreensMAp));
  },
  getSlidesFromAPi(screen) {
    const state = store.getState();
    const allSlides = state.auth.form.tutorialSlides;
    const screenToCheck = tutorialScreensMAp[screen] || screen;
    const current = allSlides.filter((item) => item.screen === screenToCheck);
    return current.sort((a, b) => a.orderInScreen - b.orderInScreen);
  },
};

export default SLIDES;
export { SLIDES, tutorialScreensMAp as SCREENS, Tutorial };
