import React, {
  useState, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getServerConfig } from '../../../lib/config';
import { getGoalsArray } from '../../../reducers/patient/patientActions';
import { setGoal } from '../../../reducers/goal/goalActions';
import GoalModal from '../../../components/general/GoalModal';
import { globalActions } from '../../../reducers/actions';

export default function ClinicalHelp() {
  const { clinicalHelpEnabled } = getServerConfig();
  const dispatch = useDispatch();
  const showClinicalHelpIcon = useSelector((s) => s.global.showClinicalHelpIcon);
  const clinicalHelpVisible = useSelector((s) => s.global.clinicalHelpVisible);
  const route = useSelector((s) => s.routes.currentRouteName);
  const [prevRoute, setPrevRoute] = useState(null);
  const [allowRoutsArray, setAllowRoutsArray] = useState([]);
  const [goalInternal, setGoalInternal] = useState({});
  const clinicalHelp = useSelector((s) => s?.patient?.currentPatient?.data?.clinicalHelp?.data);
  const goal = useSelector((s) => s?.patient?.goalsArray);
  const getGoals = async (currentClinicalSearch) => {
    if (currentClinicalSearch) {
      dispatch(getGoalsArray([currentClinicalSearch.nameOrId]));
    }
  };
  // data structure
  // {
  //   "pages": [
  //     "treatments"
  //   ],
  //   "nameOrId": "kMEBjJBdst",
  //   "objectId": "adjuvantTherapy"
  // }
  const resetInfoButton = () => {
    dispatch(globalActions.toggleClinicalHelpModal(false));
  };

  useEffect(() => {
    dispatch(globalActions.toggleClinicalHelpIconDisplay(showClinicalHelpIcon));
  }, [showClinicalHelpIcon]);

  useEffect(() => {
    if (route !== 'Patient') {
      if (clinicalHelpEnabled && clinicalHelp && route !== prevRoute) {
        setPrevRoute(route);
        if (route === 'App' || !route) {
          resetInfoButton();
        } else {
          const pages = clinicalHelp.filter((r) => r.pages.includes(route));
          if (pages?.length) {
            setAllowRoutsArray(pages[0].pages);
            getGoals(pages[0]);
          } else {
            setAllowRoutsArray([]);
          }
        }
      } else {
        resetInfoButton();
      }
      if (goal && goal[0]) {
        setGoalInternal({ goal: goal[0], articles: goal[0].articles });
        dispatch(globalActions.toggleClinicalHelpIconDisplay(allowRoutsArray.includes(route)));
      }
    }
  }, [route, clinicalHelp, goal]);

  useEffect(() => {
    if (clinicalHelpVisible) {
      dispatch(setGoal(goalInternal));
    }
  }, [clinicalHelpVisible]);

  const closeInfo = () => dispatch(globalActions.toggleClinicalHelpModal(false));

  return clinicalHelpEnabled ? (
    <GoalModal isVisible={clinicalHelpVisible} closeModal={closeInfo} />
  ) : null;
}
