/* eslint class-methods-use-this: 0 */ // --> OFF
import NavigatorService from './navigator';

export class RouterHelper {
  goBack(key = null) {
    NavigatorService.back(key);
  }

  goToPage(page, params) {
    NavigatorService.navigate(page, params);
  }

  reset(page, param) {
    NavigatorService.reset(page, param);
  }

  init(page, param) {
    NavigatorService.init(page, param);
  }

  replacePage(page, param) {
    NavigatorService.replace(page, param);
  }

  getActiveTab(page) {
    return NavigatorService.getActiveTab(page);
  }

  getNavigatorService() {
    return NavigatorService;
  }
}
// The singleton variable
const routerHelper = new RouterHelper();
export default routerHelper;
