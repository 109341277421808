/**
 * # profileActions.js
 *
 * The actions to support the users profile
 */

/**
 * ## Imports
 *
 * The actions for profile
 */
import DeviceInfo from '../../lib/deviceInfo';
import { appAuthToken } from '../../lib/AppAuthToken';
import { config } from '../../lib/config';
import { analytics } from '../../lib/analytics';
import store from '../store';

const {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,

  SETTINGS_UPDATE_REQUEST,
  SETTINGS_UPDATE_SUCCESS,
  SETTINGS_UPDATE_FAILURE,

  ON_SETTINGS_FORM_FIELD_CHANGE,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
} = require('../../lib/constants').default;

/**
 * BackendFactory - base class for server implementation
 * AppAuthToken for localStorage sessionToken access
 */
const BackendFactory = require('../../lib/BackendFactory').default;

/**
 * ## retreiving profile actions
 */
export function getSettingsRequest() {
  return {
    type: GET_SETTINGS_REQUEST,
  };
}
export function getSettingsSuccess(json) {
  // debugger
  return {
    type: GET_SETTINGS_SUCCESS,
    payload: json,
  };
}
export function getSettingsFailure(json) {
  return {
    type: GET_SETTINGS_FAILURE,
    payload: json,
  };
}
/**
 * ## State actions
 * controls which form is displayed to the user
 * as in login, register, logout or reset password
 */
export function getSettings(sessionToken) {
  return (dispatch) => {
    dispatch(getSettingsRequest());
    // store or get a sessionToken
    return appAuthToken
      .getSessionToken(sessionToken)
      .then((token) => BackendFactory(token).getSettings())
      .then((json) => {
        //  console.log('getSettingsSuccess', json)
        dispatch(getSettingsSuccess(json));
      })
      .catch((error) => {
        //    console.log('getSettingsFailure', error)
        dispatch(getSettingsFailure(error));
      });
  };
}
/**
 * ## State actions
 * controls which form is displayed to the user
 * as in login, register, logout or reset password
 */
export function settingsUpdateRequest() {
  return {
    type: SETTINGS_UPDATE_REQUEST,
  };
}
export function settingsUpdateSuccess(json) {
  return {
    type: SETTINGS_UPDATE_SUCCESS,
    payload: json,
  };
}
export function settingsUpdateFailure(json) {
  return {
    type: SETTINGS_UPDATE_FAILURE,
    payload: json,
  };
}
export function contactUsRequest() {
  return {
    type: CONTACT_US_REQUEST,
  };
}
export function contactUsSuccess(json) {
  return {
    type: CONTACT_US_SUCCESS,
    payload: json,
  };
}
export function contactUsFail(json) {
  return {
    type: CONTACT_US_FAILURE,
    payload: json,
  };
}

/**
 * ## updateProfile
 * @param {string} userId -  objectId
 * @param {string} username - the users name
 * @param {string} email - user's email
 * @param {Object} sessionToken - the sessionToken
 *
 * The sessionToken is provided when Hot Loading.
 *
 * With the sessionToken, the server is called with the data to update
 * If successful, get the profile so that the screen is updated with
 * the data as now persisted on the serverx
 *
 */
export function updateSettings(objectId, isPaused, receivePush, allowFastForward, sessionToken) {
  return (dispatch) => {
    dispatch(settingsUpdateRequest());
    return appAuthToken
      .getSessionToken(sessionToken)
      .then((token) => BackendFactory(token).updateSettings(objectId, {
        isPaused,
        receivePush,
        allowFastForward,
      }))
      .then((settings) => {
        dispatch(settingsUpdateSuccess(settings));
        dispatch(getSettings());
      })
      .catch((error) => {
        dispatch(settingsUpdateFailure(error));
      });
  };
}

export function updateTimeZone() {
  return () => {
    // dispatch(updateTimeZoneRequest())
    const timeZone = DeviceInfo.getTimezone();
    const { version } = config;
    const state = store.getState();
    const { activeCampaign } = state.profile.form;
    if (activeCampaign) {
      analytics.setUserProperty('activeCampaign', activeCampaign);
    }
    const { country } = state.auth.form.register;
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).updateTimeZone({
        timeZone, version, country, activeCampaign,
      }))
      .then(() => {
        // dispatch(updateTimeZoneSucsess());
      })
      .catch((error) => {
        console.log(error);
        // dispatch(updateTimeZoneFail(error))
      });
  };
}

export function contactUs(msg) {
  return (dispatch) => {
    dispatch(contactUsRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).contactUs(msg))
      .then(() => {
        dispatch(contactUsSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(contactUsFail(error));
      });
  };
}
/**
 * ## onProfileFormFieldChange
 *
 */
export function onSettingsFormFieldChange(field, value) {
  return {
    type: ON_SETTINGS_FORM_FIELD_CHANGE,
    payload: { field, value },
  };
}
