import React, { Component } from 'react';
import {
  StyleSheet, View, TouchableOpacity, Image, Platform,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {
  design,
  I18n,
  TextMontserrat,
  TextSF,
  global,
  icons,
  helper,
} from '../../lib/general';
import { analytics } from '../../lib/analytics';
import HoverTouchableOpacity from '../general/hover/HoverTouchableOpacity';

const { GOOGLE_DOCS_URL } = global;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: design.colors.white,
    paddingBottom: 0,
  },
  containerDesktop: {
    borderBottomColor: design.colors.headerBorderColor,
    borderBottomWidth: 2,
    paddingHorizontal: 0,
    // backgroundColor: 'pink',
    flexDirection: 'row',
  },
  sideBysideDesktop: {
    flex: 1,
    paddingVertical: 25,
    // paddingTop: 25,
    // paddingBottom: 40,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 15,
    // flex: 1
  },
  image: {
    width: 14,
    height: 18,
    // marginTop: 6,
    // marginRight: 8,
    // paddingRight: 10,
  },
  header: {
    color: design.colors.clinicSub,
    fontSize: design.text.semiReg,
    fontWeight: 'normal',
    // marginBottom: 15,
    marginRight: 20,
    marginLeft: 10,
  },
  text: {
    color: design.colors.clinicSub,
    fontSize: design.text.small,
  },
  headerDesktop: {
    // fontSize: design.text.eightTeen,
    // fontWeight: 'bold',
  },
  textDesktop: {
    // fontSize: design.text.semiReg,
  },
  read: {
    color: design.colors.clinicBlue,
    fontSize: design.text.small,
  },
  readContainer: {
    // flex: 1,
    paddingRight: 10,
  },
  icoContainer: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icoContainerDesktop: {
    marginTop: 0,
    marginLeft: 20,
  },
  hover: {
    color: design.colors.clinicHeader,
    textDecorationLine: 'underline',
  },
});

class ReferenceSingle extends Component {
  constructor(props) {
    super(props);
    this.onPress = this.onPress.bind(this);
    this.state = {
      header: '',
      text: '',
      hover: false,
    };
  }

  componentDidMount() {
    this.resolveData();
  }

  setRefItem=(obj) => {
    this.setState(obj);
  }

  onPress() {
    const {
      // setRefItem,
      data,
      addReferenceToUserUpdates,
    } = this.props;
    const item = data;
    if (addReferenceToUserUpdates) {
      const lessDataItem = {
        objectId: item.objectId,
        header: item.header,
        category: item.category,
      };
      addReferenceToUserUpdates(lessDataItem);
    }
    const fileType = helper.get_url_extension(item.url);
    switch (item.type) {
      case 'doc':
        // setPDFDocStart();
        this.setRefItem({
          link: GOOGLE_DOCS_URL + item.url,
          header: item.header,
        });
        break;
      case 'pdf':
        this.setRefItem({ link: item.url, header: item.header });
        break;
      case 'link':
        this.setRefItem({ link: item.url, header: item.header });
        // Actions.WebViewer()
        // router.goToPage('WebViewer');
        break;
      case 'specialPdf':
        this.setRefItem({ link: item.url, header: item.header });
        // router.goToPage('WebViewer', { isPDF: true });
        break;
      case 'googleDoc':
        this.setRefItem({
          link: GOOGLE_DOCS_URL + item.url,
          header: item.header,
        });
        // router.goToPage('WebViewer');
        break;
      case 'image':
        this.setRefItem({ link: item.url, header: item.header });
        // Actions.WebViewer()
        // router.goToPage('WebViewer', { isImage: true });
        break;
      default:
        this.setRefItem({ link: item.url, header: item.header });
        // Actions.WebViewer()
        // router.goToPage('WebViewer');

        break;
    }
  }

  resolveData(prop) {
    const props = prop || this.props;
    const { header, text } = props.data;
    this.setState({ header, text });
    this.onPress();
  }

  renderIco(data) {
    const that = this;
    let answer = 'picture-as-pdf';
    switch (data.type) {
      case 'pdf':
      case 'specialPdf':
        answer = 'picture-as-pdf';
        break;
      case 'link':
        answer = 'link';
        break;
      case 'doc':
      case 'googleDoc':
        answer = 'web';
        break;
      case 'image':
        answer = 'image';
        break;
      default:
        answer = 'picture-as-pdf';
        break;
    }
    return (
      <View style={styles.readContainer}>
        <Icon name={answer} size={design.text.twenty} color={design.colors.clinicScoreColor} />
      </View>
    );
  }

  render() {
    const {
      data, hideHeader, hideText, clickText, isDesktop,
    } = this.props;
    const {
      header, text, link, hover,
    } = this.state;
    let readMoreText = Platform.OS === 'web'
      ? I18n.t('Reference.readMore') : I18n.t('Reference.readMoreMobile');
    if (!readMoreText || readMoreText === '') {
      readMoreText = I18n.t('Reference.readMore');
    }
    if (clickText) {
      readMoreText = clickText;
    }

    return (
      <View style={[styles.container, isDesktop && styles.containerDesktop]}>

        <View style={isDesktop && styles.sideBysideDesktop}>
          {!hideHeader && (
          <View style={styles.topContainer}>
            <Image source={icons.referenceBlack} resizeMode="contain" style={styles.image} />
            <TextMontserrat style={[styles.header, isDesktop && styles.headerDesktop]} ellipsizeMode="tail">
              {header}
            </TextMontserrat>
          </View>
          )}
          {!hideText && (
          <TextSF style={[styles.text, isDesktop && styles.textDesktop]} ellipsizeMode="tail">
            {text}
          </TextSF>
          )}
        </View>
        {link && link !== ''
        && (
        <View style={[styles.icoContainer, isDesktop && styles.icoContainerDesktop]}>
          {
            !isDesktop && <View>{this.renderIco(data)}</View>
          }

          <HoverTouchableOpacity
            onPress={() => analytics.logButtonClick('reference read more', header)}
            onMouseEnter={() => { this.setState({ hover: true }); }}
            onMouseLeave={() => { this.setState({ hover: false }); }}
          >
            <TextSF
              accessibilityRole="link"
              href={link}
              hrefAttrs={{
                target: '_blank',
              }}
              style={[styles.text, styles.read, hover && styles.hover]}
            >
              {readMoreText}
            </TextSF>
          </HoverTouchableOpacity>
        </View>
        )}
      </View>
    );
  }
}

export default ReferenceSingle;
