import { StyleSheet } from 'react-native';
// import design from './design'

const platformStyle = StyleSheet.create({
  moreScreenAlignIcon: {},
  tabIconFocus: {},
  tabShadow: {},
});

export default platformStyle;
