import React, { useState } from 'react';
import { View } from 'react-native';
// import {design} from '../../../lib/general';

const HoverView = (props) => {
  const [hover, setHover] = useState(false);
  const {
    onMouseEnter, onMouseLeave, style, hoverStyle, disable, ...rest
  } = props;
  const mouseEnter = () => {
    if (disable) return;
    setHover(true);
    if (typeof onMouseEnter === 'function') {
      onMouseEnter();
    }
  };
  const mouseLeave = () => {
    if (disable) return;
    setHover(false);
    if (typeof onMouseLeave === 'function') {
      onMouseLeave();
    }
  };
  // const backgroundColor = hoverColor || design.colors.baseGray;
  return (
    <View
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      {...rest}
      style={[style, hover && hoverStyle]}
    />
  );
};
export default HoverView;
