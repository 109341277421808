/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { helper } from '../../lib/general';

const IndexHTMLUpdater = () => {
  const serverConfig = useSelector((state) => state.patient.serverConfig);
  const [config, setConfig] = useState(null);
  const updateAllLinks = (currentConfig) => {
    const allLinks = document.getElementsByTagName('link');
    const allMeta = document.getElementsByTagName('meta');
    const root = document.getElementById('root');
    if (allLinks.length) {
      const arr = Array.from(allLinks);
      arr.forEach((element) => {
        if (Object.prototype.hasOwnProperty.call(currentConfig, element?.id)) {
          element.href = currentConfig[element.id];
        }
      });
    }
    if (allMeta.length) {
      const arr = Array.from(allMeta);
      arr.forEach((element) => {
        if (Object.prototype.hasOwnProperty.call(currentConfig, element?.name)) {
          element.content = currentConfig[element.id];
        }
      });
    }
    if (currentConfig?.title) {
      document.title = currentConfig?.title;
    }
    if (currentConfig?.disableBrowserTranslation) {
      root.translate = false;
    }
  };
  useEffect(() => {
    if (helper.hashObject(serverConfig) !== helper.hashObject(config)) {
      setConfig(serverConfig);
      updateAllLinks(serverConfig);
    }
  }, [serverConfig]);

  return null;
};

export default IndexHTMLUpdater;
