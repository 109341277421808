import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { useSelector } from 'react-redux';
import { icons } from '../../lib/general';

const WIDTH = 35;
const styles = StyleSheet.create({
  container: {
    marginRight: 10,
  },
});
const HeaderIsClinic = ({ disableCaseImage }) => {
  if (disableCaseImage) return null;
  const sim = useSelector((s) => s.patient?.currentPatient?.data?.sim?.data);
  if (!sim) return null;
  if (!sim.isShortTickEnabled) return null;
  const { timeStatus } = sim;
  return (
    <View style={styles.container}>
      <Image source={timeStatus ? icons.hospitalIconSmall : icons.clinicIconSmall} style={{ width: WIDTH, height: WIDTH }} />
    </View>
  );
};

export default HeaderIsClinic;
