/**
 * ## States
 * Snowflake explicitly defines initial state
 *
 */
import AuthInitialState from './auth/authInitialState';
import DeviceInitialState from './device/deviceInitialState';
import GlobalInitialState from './global/globalInitialState';
import ProfileInitialState from './profile/profileInitialState';
import ClinicInitialState from './clinic/clinicInitialState';
import SettingsInitialState from './settings/settingsInitialState';
import PatientInitialState from './patient/patientInitialState';
import ReferenceInitialState from './reference/referenceInitialState';
import NotificationInitialState from './notification/notificationInitialState';
import FaqInitialState from './FAQ/faqInitialState';
import GoalInitialState from './goal/goalInitialState';
import RoutesInitialState from './routes/routesInitialState';

/**
 *
 * ## Initial state
 * Create instances for the keys of each structure in snowflake
 * @returns {Object} object with 4 keys
 */
function getInitialState() {
  const initState = {
    auth: new AuthInitialState(),
    device: new DeviceInitialState().set('isMobile', true),
    global: new GlobalInitialState(),
    profile: new ProfileInitialState(),
    clinic: new ClinicInitialState(),
    settings: new SettingsInitialState(),
    patient: new PatientInitialState(),
    reference: new ReferenceInitialState(),
    notification: new NotificationInitialState(),
    faq: new FaqInitialState(),
    goal: new GoalInitialState(),
    routes: new RoutesInitialState(),
  };
  return initState;
}
const initialState = getInitialState();
export default initialState;
