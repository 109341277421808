import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    margin: 4,
  },
  directionRTL: {
    writingDirection: 'rtl',
  },
  hover: {
    textDecorationLine: 'underline',
  },
});
