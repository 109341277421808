/* eslint-disable max-classes-per-file */
import React, {
  Component, PureComponent, Suspense, lazy,
} from 'react';
import {
  StyleSheet,
  AppRegistry,
  View,
  Dimensions,
  SafeAreaView,
  Platform,
} from 'react-native';
import {
  Router, Route, Redirect, withRouter, Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import dayjs from 'dayjs';

import navigator from './lib/router/navigator';
import store from './reducers/store';
import { config } from './lib/config';
import design from './lib/design';
import global from './lib/global';
import { analytics } from './lib/analytics';
import helper from './lib/helper';
// import { setNewRoute } from './reducers/routes/routesActions';
import { setPlatform, setVersion } from './reducers/device/deviceActions';
import { setUrlParameters } from './reducers/auth/authActions';
import {
  setStore, setDesktop, setWeb, setTablet,
} from './reducers/global/globalActions';

import pack from '../version.json';

import WebFrame from '../web/containers/WebFrame';
import TabFrame from '../web/containers/TabFrame';
import withPatientImageDesktop from './hoc/withPatientImageDesktop';
import withPadding from './hoc/withPadding';
import withMaxWidthCenter from './hoc/withMaxWidthCenter';
import LoaderModalDelay from './components/general/LoaderModalDelay';
import AnalyticsUnMountGlobal from './containers/virtual/Analytics/analyticsUnMountGlobal';
// import LoaderModal from './components/general/LoaderModal';
// import ProjectOver from './containers/auth/ProjectOver';
// import Error from './containers/auth/Error';
// import Toast from './components/general/Toast';
// import AuthLoading from './containers/AuthLoading';
// import WhyDidYouUpdate from './containers/virtual/WhyDidYouUpdate';
// import MyBackHandler from './containers/virtual/MyBackHandler';
import PageAnalytics from './containers/virtual/Analytics/PageAnalytics';
import ClinicalHelp from './containers/virtual/ClinicalHelp';
import GlobalTutorial from './components/general/GlobalTutorial';
import GlobalNetwork from './containers/virtual/GlobalNetwork';
import KeepAlive from './containers/virtual/KeepAlive';

// import More from './containers/More';
import CampaignSelection from './components/clinic/campaignSelection';
import IndexHTMLUpdater from './containers/virtual/IndexHTMLUpdater';
// const AddToHomeScreen = lazy(() => import('../web/components/AddToHomeScreen'));
const Toast = lazy(() => import('./components/general/Toast'));
const LoaderModal = lazy(() => import('./components/general/LoaderModal'));
const ProjectOver = lazy(() => import('./containers/auth/ProjectOver'));
const Error = lazy(() => import('./containers/auth/Error'));
const AuthLoading = lazy(() => import('./containers/AuthLoading'));
const GlobalAlert = lazy(() => import('./containers/global/GlobalAlert'));
const More = lazy(() => import('./containers/More'));

// Auth routes
const Auth = lazy(() => import('./containers/auth/Auth'));
const NewLogin = lazy(() => import('./containers/auth/NewLogin'));
const AzureAD = lazy(() => import('./containers/auth/AzureAD'));
const Signup = lazy(() => import('./containers/auth/DynamicSignup'));
// const DynamicSignup = lazy(() => import('./containers/auth/DynamicSignup'));
const NewForgotPassword = lazy(() => import('./containers/auth/NewForgotPassword'));

const Walkthrough = lazy(() => import('./containers/Walkthrough'));
const Clinic = lazy(() => import('./containers/clinic/Clinic'));
const Reference = lazy(() => import('./containers/reference/Reference'));
const Patient = lazy(() => import('./containers/patient/Patient'));
const AddClinic = lazy(() => import('./containers/clinic/AddClinic'));
const ModuleIntro = lazy(() => import('./containers/patient/ModuleIntro'));
const EndEncounterFull = lazy(() => import('./containers/patient/end/EndEncounterFull'));
const Goal = lazy(() => import('./containers/goal/Goal'));
const EndModuleGoalScreen = lazy(() => import('./components/patient/end/endModule/EndModuleGoalScreen'));
const EndModuleEdocateWay = lazy(() => import('./components/patient/end/endModule/EndModuleEdocateWay'));
const EndModulePeerScreen = lazy(() => import('./components/patient/end/endModule/EndModulePeerScreen'));
const EndModuleScoreScreen = lazy(() => import('./components/patient/end/endModule/EndModuleScoreScreen'));
const EndModuleChartScreen = lazy(() => import('./components/patient/end/endModule/EndModuleChartScreen'));

const AddDiagnosis = lazy(() => import('./containers/patient/add/AddDiagnosis'));
const AddTreatment = lazy(() => import('./containers/patient/add/AddTreatment'));
const AddLab = lazy(() => import('./containers/patient/add/AddLab'));
const AddPhysical = lazy(() => import('./containers/patient/add/AddPhysical'));
const AddReferral = lazy(() => import('./containers/patient/add/AddReferral'));
const FullQuestionnaire = lazy(() => import('./containers/questionnaire/FullQuestionnaire'));
const FullCredit = lazy(() => import('./containers/credit/FullCredit'));
const WebViewer = lazy(() => import('./containers/reference/WebViewer'));

const FAQ = lazy(() => import('./containers/FAQ'));
// const Profile = lazy(() => import('./containers/Profile'));
const DynamicProfile = lazy(() => import('./containers/DynamicProfile'));
const ContactUs = lazy(() => import('./containers/ContactUs'));
const VERSION = pack.version;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: design.colors.baseGray,
  },
  show: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  safe: {
    flex: 1,
    backgroundColor: design.colors.tabBG,
    // maxHeight: 60,
  },
});

let localFirstTime = true;
let lastComponentNameInTab = null;
let lastComponentRenderedInTab = null;
// let lastFullPath = null;
class ClinicInnerPages extends Component {
  render() {
    const { props } = this;
    const { location, history } = props;
    const parts = location.pathname.split('/');
    const lastSegment = parts.pop() || parts.pop();

    let comp = null;
    switch (lastSegment) {
      case 'ClinicTab':
        if (history.action === 'POP') {
          comp = withPadding(Clinic);
        } else {
          comp = null;
        }
        break;
      case 'Clinic':
        comp = withPadding(Clinic);
        break;
      case 'App':
        comp = withPadding(Clinic);
        break;
      case 'Patient':
        comp = <Patient />;
        break;
      case 'AddClinic':
        comp = <AddClinic />;
        // comp = <AddClinic />;
        break;
      case 'ModuleIntro':
        comp = withPadding(ModuleIntro);
        break;
      case 'AddPhysical':
        // comp = <AddPhysical />;
        comp = withPatientImageDesktop(AddPhysical);
        break;
      case 'AddReferral':
        // comp = <AddPhysical />;
        comp = withPatientImageDesktop(AddReferral);
        break;
      case 'AddLab':
        // comp = <AddLab />;
        comp = withPatientImageDesktop(AddLab);
        break;
      case 'AddDiagnosis':
        // comp = <AddDiagnosis />;
        comp = withPatientImageDesktop(AddDiagnosis);
        break;

      case 'AddTreatment':
        // comp = <AddTreatment />;
        comp = withPatientImageDesktop(AddTreatment);
        break;
      case 'EndEncounterFull':
        comp = withPadding(EndEncounterFull);
        // comp = <EndEncounterFull />;
        break;
      case 'Goal':
        // comp = <Goal />;
        comp = withPadding(Goal);
        break;
      case 'WebViewer':
        comp = <WebViewer />;
        break;
      case 'EndModuleFull':
        // comp = <EndModuleScoreScreen />;
        comp = withPadding(EndModuleScoreScreen);

        break;
      case 'EndModuleEdocateWay':
        // comp = <EndModuleEdocateWay />;
        comp = withPadding(EndModuleEdocateWay);
        break;
      case 'EndModuleGoalScreen':
        // comp = <EndModuleGoalScreen />;
        comp = withPadding(EndModuleGoalScreen);
        break;
      case 'EndModulePeerScreen':
        // comp = <EndModulePeerScreen />;
        comp = withPadding(EndModulePeerScreen);
        break;
      case 'EndModuleChartScreen':
        // comp = <EndModulePeerScreen />;
        comp = withPadding(EndModuleChartScreen);
        break;

      // case 'FullCredit':
      //   // comp = <FullCredit />;
      //   comp = withPadding(FullCredit);
      //   break;
      // case 'FullQuestionnaire':
      //   // comp = <FullQuestionnaire />;
      //   comp = withPadding(FullQuestionnaire);
      //   break;
      default:
        comp = null;
        break;
    }
    // console.log('ClinicInnerPages', lastComponentNameInTab, lastSegment);

    if (comp === null && lastComponentNameInTab === null) {
      return <Clinic />;
    }
    if (lastComponentNameInTab === lastSegment || comp === null) {
      return lastComponentRenderedInTab;
    }
    lastComponentRenderedInTab = comp;
    lastComponentNameInTab = lastSegment;
    // lastFullPath = location.pathname;
    return comp;
  }
}

class Tabs extends PureComponent {
  // const Tabs = (props) => {
  // console.log('Tabs function');

  render() {
    const { props } = this;
    const { location } = props;
    const showRef = location.pathname.indexOf('Reference') > -1;
    const showCredit = location.pathname.indexOf('FullCredit') > -1;
    const showExternalCredit = location.pathname.indexOf('ExternalCredit') > -1;
    const showMore = location.pathname.indexOf('More') > -1;
    const showClinic = !showRef && !showCredit && !showMore && !showExternalCredit;
    const FullCreditPadding = withPadding(FullCredit, props);

    const FullErrorPadding = withPadding(Error, props);
    const { showCredits, creditsToExternal } = config;

    // debugger;
    // const MorePadding = withPadding(More);
    // debugger;
    return (
      <TabFrame {...props} location={location}>
        <View
          style={[styles.container, showClinic ? styles.show : styles.hide]}
          key="clinicTab"
        >
          <ClinicInnerPages {...props} location={location} />
        </View>
        <View
          style={[styles.container, showRef ? styles.show : styles.hide]}
          key="referenceTab"
        >
          {showRef && <Reference {...props} location={location} />}
        </View>
        {showCredits === true && !creditsToExternal && (
          <View
            style={[styles.container, showCredit ? styles.show : styles.hide]}
            key="FullCredit"
          >
            {showCredit && FullCreditPadding}
          </View>
        )}
        {showCredits === true && creditsToExternal && (
          <View
            style={[
              styles.container,
              showExternalCredit ? styles.show : styles.hide,
            ]}
            key="FullCreditError"
          >
            {showExternalCredit && FullErrorPadding}
          </View>
        )}
        {showMore && (
        <View
          style={[styles.container, showMore ? styles.show : styles.hide]}
          key="More"
        >
          <More {...props} location={location} />
        </View>
        )}
      </TabFrame>
    );
  }
}
// let isDesktop = false;
export default function native(platform) {
  const onInitUrlParameters = window?.location?.search;
  class Edocate extends Component {
    static onStartShouldSetResponder(e) {
      const { width } = Dimensions.get('window');
      const { nativeEvent } = e;
      if (nativeEvent.pageX > 20 && nativeEvent.pageX < width - 20) return true;
      // prevent swipe to navigate back gesture
      e.preventDefault();
      return false;
    }

    componentDidMount() {
      store.dispatch(setUrlParameters(onInitUrlParameters));
      navigator.setUrlParameters(onInitUrlParameters);
      store.dispatch(setPlatform(platform));
      store.dispatch(setVersion(VERSION));
      store.dispatch(setStore(store));
      localFirstTime = false;
      this.setIsDesktop();
      const time = dayjs().format('HH');
      setTimeout(() => analytics.logCustom('localHour', { time }), 2500);
      if (helper.isLocalhost()) {
        window.store = store;
      }
    }

    setIsDesktop = (event) => {
      let { width } = Dimensions.get('window');
      if (event) {
        width = event.nativeEvent.layout.width === 0
          ? Dimensions.get('window').width
          : event.nativeEvent.layout.width;
      }
      const isDesktop = width >= global.mobileMaxWidth;
      const isTablet = width >= global.tabletMinWidth && !isDesktop;
      const isWeb = Platform.OS === 'web';
      store.dispatch(setWeb(isWeb));
      store.dispatch(setDesktop(isWeb && isDesktop));
      store.dispatch(setTablet(isTablet || helper.isTablet()));
    };

    handleLayout = (event) => {
      this.setIsDesktop(event);
    };

    render() {
      const history = navigator.getHistory();
      // const WithRouterWebFrame = withRouter(WebFrame);
      const WithRouterWebFrame = WebFrame;
      // eslint-disable-next-line no-undef
      if (localFirstTime && window.location.pathname !== '/') {
        history.push('/');
        localFirstTime = false;
      }
      return (
        <SafeAreaView style={styles.safe}>
          <View
            style={styles.container}
            onStartShouldSetResponder={Edocate.onStartShouldSetResponder}
            onLayout={this.handleLayout}
          >
            <Provider store={store}>
              {/* <MyBackHandler /> */}
              <Suspense fallback={<LoaderModalDelay />}>
                <KeepAlive />
                <Toast />
                <GlobalAlert />
                <LoaderModal />
                <GlobalNetwork />
                <CampaignSelection />
                <PageAnalytics />
                <ClinicalHelp />
                <GlobalTutorial />
                <AnalyticsUnMountGlobal />
                <IndexHTMLUpdater />
                {/* <WhyDidYouUpdate /> */}
                <Router history={history}>
                  <Switch>
                    <WithRouterWebFrame>
                      <Route
                        path="/"
                        exact
                        component={() => withMaxWidthCenter(AuthLoading)}
                        key="home"
                      />
                      <Route
                        path="/Walkthrough"
                        exact
                        component={() => withMaxWidthCenter(Walkthrough)}
                        key="Walkthrough"
                      />
                      <Route
                        path="/AuthLoading"
                        exact
                        component={() => withMaxWidthCenter(AuthLoading)}
                        key="AuthLoading"
                      />
                      <Route
                        path="/Login"
                        exact
                        component={() => withMaxWidthCenter(NewLogin)}
                        key="Login"
                      />
                      <Route
                        path="/AzureAD"
                        exact
                        component={() => withMaxWidthCenter(AzureAD)}
                        key="AzureAD"
                      />
                      <Route
                        path="/AzureADLogout"
                        exact
                        component={() => withMaxWidthCenter(AzureAD, { logout: true })}
                        key="AzureADLogout"
                      />
                      <Route
                        path="/Signup"
                        exact
                        component={() => withMaxWidthCenter(Signup)}
                        key="Signup"
                      />
                      <Route
                        path="/Forgot"
                        exact
                        component={() => withMaxWidthCenter(NewForgotPassword)}
                        key="Forgot"
                      />
                      <Route
                        path="/AuthStack"
                        exact
                        component={() => withMaxWidthCenter(Auth)}
                        key="AuthStack"
                      />
                      <Route path="/Auth" exact component={() => withMaxWidthCenter(Auth)} key="Auth" />
                      <Route path="/App" component={Tabs} key="App" />
                      <Route path="/FAQ" component={() => withMaxWidthCenter(FAQ)} key="FAQ" />
                      <Route
                        path="/FullQuestionnaire"
                        component={() => withMaxWidthCenter(FullQuestionnaire)}
                        key="FullQuestionnaire"
                      />
                      <Route
                        path="/Profile"
                        component={() => withMaxWidthCenter(DynamicProfile)}
                        key="Profile"
                      />
                      <Route
                        path="/ContactUs"
                        component={() => withMaxWidthCenter(ContactUs)}
                        key="ContactUs"
                      />
                      <Route
                        path="/ProjectOver"
                        component={() => withMaxWidthCenter(ProjectOver)}
                        key="ProjectOver"
                      />
                      <Route path="/Error" component={() => withMaxWidthCenter(Error)} key="Error" />
                      <Route path="/Logout" component={() => withMaxWidthCenter(Error)} key="Logout" />
                      <Redirect to="/" key="404" />
                    </WithRouterWebFrame>
                  </Switch>
                </Router>
              </Suspense>
            </Provider>
          </View>
        </SafeAreaView>
      );
    }
  }
  AppRegistry.registerComponent('edocate', () => Edocate);
}
