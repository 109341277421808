/**
 * # profileReducer.js
 *
 * The reducer user profile actions
 */

/**
 * ## Imports
 *

 * formValidation for setting the form's valid flag
 */
const formValidation = require('./profileFormValidation').default;

/**
 * ## Actions
 *
 */
const {
  ON_PROFILE_FORM_FIELD_CHANGE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,

  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,

  LOGOUT_SUCCESS,
  SET_STATE,
  GET_CREDITS_SUCCESS,

  SET_PROFILE_CAMPAIGN,
} = require('../../lib/constants').default;

/**
 * ## Initial State
 *
 */
const InitialState = require('./profileInitialState').default;

const initialState = new InitialState();

/**
 * ## profileReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function profileReducer(state = initialState, action) {
  let nextProfileState = null;

  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    /**
     * ### Request starts
     * set the form to fetching and clear any errors
     */
    case GET_PROFILE_REQUEST:
    case PROFILE_UPDATE_REQUEST:
      return state
        .setIn(['form', 'isFetching'], true)
        .setIn(['form', 'error'], null);

    case GET_CREDITS_SUCCESS:
      return state.setIn(['form', 'credits'], action.payload);
    /**
     * ### Request ends successfully
     *
     * the fetching is done, set the UI fields and the originalProfile
     *
     * Validate the data to make sure it's all good and someone didn't
     * mung it up through some other mechanism
     */
    case GET_PROFILE_SUCCESS:
    case PROFILE_UPDATE_SUCCESS:
      nextProfileState = state
        .setIn(['form', 'isFetching'], false)
        .setIn(['form', 'fields', 'username'], action.payload.username)
        .setIn(['form', 'fields', 'email'], action.payload.email)
        .setIn(['form', 'fields', 'lastName'], action.payload.lastName)
        .setIn(['form', 'fields', 'firstName'], action.payload.firstName)
        .setIn(['form', 'fields', 'imageUrl'], action.payload.imageUrl)
        .setIn(['form', 'fields', 'specialty'], action.payload.specialty)
        .setIn(['form', 'fields', 'fullName'], action.payload.fullName)
        .setIn(['form', 'fields', 'education'], action.payload.education)
        .setIn(['form', 'fields', 'workplace'], action.payload.workplace)
        .setIn(['form', 'fields', 'isAdmin'], action.payload.isAdmin)
        .setIn(
          ['form', 'fields', 'practiceField'],
          action.payload.practiceField,
        )
        .setIn(['form', 'fields', 'phone'], action.payload.phone)
        .setIn(
          ['form', 'fields', 'emailVerified'],
          action.payload.emailVerified,
        )
        .setIn(
          ['form', 'fields', 'authData'],
          action.payload.authData,
        )
        .setIn(
          ['form', 'fields', 'selectedCampaign'],
          action.payload.campaign?.[0],
        )
        .setIn(['form', 'fields', 'createdAt'], action.payload.createdAt)
        .setIn(['form', 'fields', 'updatedAt'], action.payload.updatedAt)
        .setIn(['form', 'fields', 'SSOData'], action.payload.SSOData)
        .setIn(['form', 'fields', 'activeCampaign'], action.payload.activeCampaign)
        .setIn(['form', 'activeCampaign'], action.payload.activeCampaign)
        .setIn(['form', 'originalProfile', 'username'], action.payload.username)
        .setIn(['form', 'originalProfile', 'email'], action.payload.email)
        .setIn(
          ['form', 'originalProfile', 'emailVerified'],
          action.payload.emailVerified,
        )
        .setIn(['form', 'originalProfile', 'objectId'], action.payload.objectId)
        .setIn(['form', 'originalProfile', 'lastName'], action.payload.lastName)
        .setIn(
          ['form', 'originalProfile', 'firstName'],
          action.payload.firstName,
        )
        .setIn(['form', 'originalProfile', 'imageUrl'], action.payload.imageUrl)
        .setIn(
          ['form', 'originalProfile', 'specialty'],
          action.payload.specialty,
        )
        .setIn(
          ['form', 'originalProfile', 'practiceField'],
          action.payload.practiceField,
        )
        .setIn(['form', 'originalProfile', 'phone'], action.payload.phone)
        .setIn(['form', 'error'], null);

      return nextProfileState;
    /**
     * User logged out, so reset form fields and original profile.
     *
     */
    case LOGOUT_SUCCESS:
      nextProfileState = state
        .setIn(['form', 'fields', 'username'], '')
        .setIn(['form', 'fields', 'email'], '')
        .setIn(['form', 'fields', 'emailVerified'], false)
        .setIn(['form', 'fields', 'isAdmin'], false)
        .setIn(['form', 'originalProfile', 'username'], '')
        .setIn(['form', 'originalProfile', 'email'], '')
        .setIn(['form', 'originalProfile', 'emailVerified'], false)
        .setIn(['form', 'originalProfile', 'objectId'], null)
        .setIn(['form', 'error'], null);
      return formValidation(nextProfileState, action);
    /**
     * ### Request fails
     * we're done fetching and the error needs to be displayed to the user
     */
    case GET_PROFILE_FAILURE:
    case PROFILE_UPDATE_FAILURE:
      return state
        .setIn(['form', 'isFetching'], false)
        .setIn(['form', 'error'], action.payload);
    /**
     * ### form fields have changed
     *
     * Set the state with the fields, clear the form error
     * and perform field and form validation
     */
    case ON_PROFILE_FORM_FIELD_CHANGE: {
      const { field, value } = action.payload;
      return state
        .setIn(['form', 'fields', field], value)
        .setIn(['form', 'error'], null);
    }
    case SET_PROFILE_CAMPAIGN: {
      return state
        .setIn(['form', 'activeCampaign'], action.payload.campaignId);
    }
    /**
     * ### set the state
     *
     * This is in support of Hot Loading - take the payload
     * and set the values into the state
     *
     */
    case SET_STATE: {
      const profile = JSON.parse(action.payload).profile.form;
      const next = state
        .setIn(['form', 'disabled'], profile.disabled)
        .setIn(['form', 'error'], profile.error)
        .setIn(['form', 'isValid'], profile.isValid)
        .setIn(['form', 'isFetching'], profile.isFetching)
        .setIn(
          ['form', 'originalProfile', 'username'],
          profile.originalProfile.username,
        )
        .setIn(
          ['form', 'originalProfile', 'email'],
          profile.originalProfile.email,
        )
        .setIn(
          ['form', 'originalProfile', 'objectId'],
          profile.originalProfile.objectId,
        )
        .setIn(
          ['form', 'originalProfile', 'emailVerified'],
          profile.originalProfile.emailVerified,
        )
        .setIn(['form', 'fields', 'username'], profile.fields.username)
        .setIn(
          ['form', 'fields', 'usernameHasError'],
          profile.fields.usernameHasError,
        )
        .setIn(['form', 'fields', 'email'], profile.fields.email)
        .setIn(
          ['form', 'fields', 'emailHasError'],
          profile.fields.emailHasError,
        )
        .setIn(
          ['form', 'fields', 'emailVerified'],
          profile.fields.emailVerified,
        );
      return next;
    }
    default:
      return state;
      break;
  } // switch
  /**
   * # Default
   */
  return state;
}
