/**
 * # profileInitialState.js
 *
 * This class is a Immutable object
 * Working *successfully* with Redux, requires
 * state that is immutable.
 * In my opinion, that can not be by convention
 * By using Immutable, it's enforced.  Just saying....
 *
 */

import { Record } from 'immutable';

/**
 * ## Form
 * This Record contains the state of the form and the
 * fields it contains.
 *
 * The originalProfile is what the server provided and has the objectId
 * The fields are what display on the UI
 */
const Form = Record(
  {
    credits: 0,
    originalProfile: new (Record({
      username: null,
      firstName: null,
      lastName: null,
      imageUrl: null,
      practiceField: null,
      specialty: null,
      phone: null,
      email: null,
      objectId: null,
      emailVerified: null,
      isAdmin: false,
    }))(),
    disabled: false,
    error: null,
    isValid: false,
    isFetching: false,
    activeCampaign: '',
    fields: new (Record({
      username: '',
      firstName: '',
      lastName: '',
      imageUrl: '',
      practiceField: '',
      specialty: '',
      phone: '',
      usernameHasError: false,
      usernameErrorMsg: '',
      email: '',
      emailHasError: false,
      emailErrorMsg: '',
      emailVerified: false,
      fullName: '',
      education: '',
      workplace: '',
      isAdmin: false,
      authData: null,
      selectedCampaign: '',
      createdAt: '',
      updatedAt: '',
      SSOData: null,
      activeCampaign: '',
    }))(),
  },
  'profile',
);

const InitialState = Record(
  {
    form: new Form(),
  },
  'form',
);

export default InitialState;
