import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import router from '../../../lib/router/routerHelper';
import { analytics } from '../../../lib/analytics';
import store from '../../../reducers/store';
import { setCurrentRoutName } from '../../../reducers/routes/routesActions';
import analyticsDictionary from './analyticsMap';

const { AnalyticsMap } = analyticsDictionary;
const navigatorService = router.getNavigatorService();
const history = navigatorService.getNavigator();

export default function PageAnalytics() {
  useEffect(() => { // THIS WILL COVER NAVIGATION ROUTS AS WELL - IN WEB VIEW!
    const unListen = history.listen((location, action) => {
      let screen = location.pathname.split('/');
      screen = screen[screen.length - 1];
      store.dispatch(setCurrentRoutName(screen));
    });
    return () => unListen();
  }, []);

  const routes = useSelector((s) => s.routes.currentRouteName);
  useEffect(() => {
    if (routes) {
      const reportVal = AnalyticsMap[routes] || routes;
      // console.log(`%c Analytics report: %c ${reportVal}`, 'color:#bada55;', 'font-size:12px;');
      analytics.logContentView(reportVal);
    }
  }, [routes]);

  return null;
}
