/* eslint-disable import/no-extraneous-dependencies */
import { createBrowserHistory } from 'history';
import global from '../global';

const browserHistory = createBrowserHistory();
const entries = [];
let lastPatientTab = null;
const withTabs = global.TAB_SCREENS;
const patientTabs = global.PATIENT_ADD_SCREENS;
// console.log('browserHistory', browserHistory);
let _container = browserHistory; // eslint-disable-line
let urlParams = null;
function setContainer(container: Object) {
  _container = container;
}

function getHistory() {
  return _container;
}

function getNavigator() {
  return _container;
}

function reset(routeName, params) {
  const location = {
    pathname: `/${routeName}`,
    state: params,
  };
  if (withTabs.indexOf(routeName) > -1) {
    location.pathname = `${global.CLINIC_TAB_URL}${routeName}`;
  }
  if (patientTabs.indexOf(routeName) > -1) {
    lastPatientTab = routeName;
  }
  // console.log('reset', location, entries);
  entries.length = 0;
  // entries.push(location.pathname);
  browserHistory.replace(location);
}

function replace(routeName, params) {
  const location = {
    pathname: `/${routeName}`,
    state: params,
  };
  if (withTabs.indexOf(routeName) > -1) {
    location.pathname = `${global.CLINIC_TAB_URL}${routeName}`;
  }
  if (patientTabs.indexOf(routeName) > -1) {
    lastPatientTab = routeName;
  }
  browserHistory.replace(location);
}

function navigate(routeName, params) {
  const location = {
    pathname: `/${routeName}`,
    state: params,
  };
  if (withTabs.indexOf(routeName) > -1) {
    location.pathname = `${global.CLINIC_TAB_URL}${routeName}`;
  }
  if (patientTabs.indexOf(routeName) > -1) {
    lastPatientTab = routeName;
  }

  browserHistory.push(location.pathname, location.state);
}

function init(routeName, params) {
  const location = {
    pathname: `/${routeName}`,
    state: params,
  };
  entries.length = 0;
  // entries.push(location.pathname);
  const backlen = browserHistory.length - 1;
  const goToMin = Math.max(0, -backlen + 1);
  browserHistory.go(goToMin); // Return at the beginning
  browserHistory.replace('/', null, location);
}
function back() {
  browserHistory.goBack();
}

function getCurrentRoute() {
  return browserHistory.location;
}

function getActiveTab() {
  return null;
}

function getCurrentRouteName() {
  return browserHistory.location;
}

function getLastPatientTab() {
  return lastPatientTab;
}
function setLastPatientTabToInitial() {
  lastPatientTab = null;
}

function pushToEntries(ent) {
  if (ent) {
    entries.push(ent);
  }
}

function setUrlParameters(params) {
  urlParams = params;
}

function getUrlParameters() {
  return urlParams;
}

export default {
  setContainer,
  navigate,
  reset,
  getCurrentRoute,
  back,
  getActiveTab,
  getCurrentRouteName,
  replace,
  getNavigator,
  init,
  getHistory,
  entries,
  getLastPatientTab,
  setLastPatientTabToInitial,
  pushToEntries,
  getUrlParameters,
  setUrlParameters,
};
