import { Record } from 'immutable';

// this is clinic
const InitialState = Record(
  {
    goal: {},
    articles: [],
  },
  'goal',
);
export default InitialState;
