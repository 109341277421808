import { appAuthToken } from '../../lib/AppAuthToken';
import clinicalApi from '../../lib/ClinicalApi';

const {
  GET_REFERENCE_REQUEST,
  GET_REFERENCE_SUCCESS,
  GET_REFERENCE_FAILURE,
  SET_REF_ITEM,
  PDF_DOC_START,
  PDF_DOC_END,
  GET_HTML_REQUEST,
  GET_HTML_SUCCESS,
  GET_HTML_FAILURE,
  CLEAR_HTML_SUCCESS,
  GET_ARTICLES_REQUEST,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAILURE,
} = require('../../lib/constants').default;

const BackendFactory = require('../../lib/BackendFactory').default;

export function clearStaticHtmlSuccess() {
  return {
    type: CLEAR_HTML_SUCCESS,
  };
}
export function getStaticHtmlRequest() {
  return {
    type: GET_HTML_REQUEST,
  };
}
export function getStaticHtmlSuccess(json) {
  // debugger
  return {
    type: GET_HTML_SUCCESS,
    payload: json,
  };
}
export function getStaticHtmlFailure(json) {
  return {
    type: GET_HTML_FAILURE,
    payload: json,
  };
}

export function getArticlesRequest() {
  return {
    type: GET_ARTICLES_REQUEST,
  };
}
export function getArticlesSuccess(json) {
  // debugger
  return {
    type: GET_ARTICLES_SUCCESS,
    payload: json,
  };
}
export function getArticlesFailure(json) {
  return {
    type: GET_ARTICLES_FAILURE,
    payload: json,
  };
}

export function getReferenceRequest() {
  return {
    type: GET_REFERENCE_REQUEST,
  };
}
export function getReferenceSuccess(json) {
  // debugger
  return {
    type: GET_REFERENCE_SUCCESS,
    payload: json,
  };
}
export function getReferenceFailure(json) {
  return {
    type: GET_REFERENCE_FAILURE,
    payload: json,
  };
}

export function setRefItemDispatch(json) {
  return {
    type: SET_REF_ITEM,
    payload: json,
  };
}

export function PDFDocStart() {
  return {
    type: PDF_DOC_START,
  };
}

export function PDFDocEnd() {
  return {
    type: PDF_DOC_END,
  };
}

export function getReference(sessionToken) {
  return (dispatch) => {
    dispatch(getReferenceRequest());
    // store or get a sessionToken
    return appAuthToken
      .getSessionToken(sessionToken)
      .then((token) => BackendFactory(token).getReference())
      .then((json) => {
        //  console.log('getReferenceSuccess', json)
        dispatch(getReferenceSuccess(json.results));
      })
      .catch((error) => {
        console.log('getReference', error);
        dispatch(getReferenceFailure(error));
      });
  };
}

export function getArticles(objectId) {
  return (dispatch) => {
    dispatch(getArticlesRequest());
    // store or get a sessionToken
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getArticles(objectId))
      .then((json) => {
        //  console.log('getReferenceSuccess', json)
        dispatch(getArticlesSuccess(json.results));
      })
      .catch((error) => {
        dispatch(getArticlesFailure(error));
      });
  };
}

export function setArticlesEmpty() {
  return (dispatch) => {
    dispatch(getArticlesSuccess([]));
  };
}

export function setRefItem(item) {
  return (dispatch) => dispatch(setRefItemDispatch(item));
}

export function setPDFDocStart() {
  return (dispatch) => dispatch(PDFDocStart());
}

export function setPDFDocEnd() {
  return (dispatch) => dispatch(PDFDocEnd());
}

export function getStaticHtml(url) {
  return (dispatch) => {
    dispatch(getStaticHtmlRequest());
    // store or get a sessionToken
    return appAuthToken
      .getSessionToken()
      .then((token) => clinicalApi.getStaticHtml(url))
      .then((json) => dispatch(getStaticHtmlSuccess(json)))
      .catch((error) => {
        console.log('getStaticHtml', error);
        dispatch(getStaticHtmlFailure(error));
      });
  };
}

export function clearStaticHtml() {
  return (dispatch) => {
    dispatch(clearStaticHtmlSuccess());
  };
}
