/* eslint-disable class-methods-use-this */
// empty polyfills for web local notification
class RetentionService {
  setUserDidNotFinishRegistration() {
  }

  cancelUserDidNotFinishRegistration() {
  }

  setUserDidNotAddCase() {
  }

  cancelUserDidNotAddCase() {
  }

  setUserDidNotFinishCase() {
  }

  cancelUserDidNotFinishCase() {
  }

  cancelAll() {
  }
}
const retentionService = new RetentionService();
export default retentionService;
