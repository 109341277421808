import React, { Component } from 'react';
import {
  StyleSheet, View, TouchableOpacity, Image, Linking,
} from 'react-native';
import { design, TextMontserrat, platformStyle } from '../../lib/general';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // height: 78,
    backgroundColor: design.colors.white,
    borderBottomWidth: 1,
    borderColor: design.colors.baseGray,
    marginTop: 2,
    padding: 30,
    paddingLeft: 20,
    justifyContent: 'center',
  },
  opacity: {
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    color: design.colors.clinicHeader,
    fontSize: design.text.semiReg,
    fontWeight: '500',
  },
  imageContainer: {
    //  flex: 1
    marginRight: 12,
  },
  image: {
    width: 19,
    height: 21,
  },
});

class MoreSingle extends Component {
  constructor(props) {
    super(props);
    this.onPress = this.onPress.bind(this);
    this.state = {};
    this.link = null;
  }

  onPress() {
    const { data, onPress } = this.props;

    if (!data.link) {
      onPress(data);
    }
  }

  renderLink=() => {
    const { data } = this.props;
    return (
      <TouchableOpacity>
        <View
          style={styles.container}
          accessibilityRole="link"
          href={data.link}
          hrefAttrs={{
            target: '_blank',
          }}
        >
          <View style={styles.opacity}>
            {data.icon !== '' && (
            <View style={styles.imageContainer}>
              <Image
                style={[styles.image, platformStyle.moreScreenAlignIcon]}
                source={data.icon}
                resizeMode="contain"
              />
            </View>
            )}
            <TextMontserrat style={styles.title}>{`${data.title}`}</TextMontserrat>
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  render() {
    const { data } = this.props;
    if (data.link && data.link !== '') {
      return this.renderLink();
    }
    return (
      <TouchableOpacity style={styles.container} onPress={this.onPress}>
        <View style={styles.opacity} href={data.link}>
          {data.icon !== '' && (
            <View style={styles.imageContainer}>
              <Image
                style={[styles.image, platformStyle.moreScreenAlignIcon]}
                source={data.icon}
                resizeMode="contain"
              />
            </View>
          )}
          <TextMontserrat style={styles.title}>{data.title}</TextMontserrat>

        </View>
      </TouchableOpacity>
    );
  }
}

export default MoreSingle;
