import React, { Component } from 'react';
import {
  View, Image, StyleSheet, TouchableOpacity, Dimensions,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {
  design, icons, TextMontserrat, global, defaultStyle, I18n,
} from '../lib/general';
import HeaderMonths from './general/HeaderMonths';
import ActionGroup from './general/ActionGroup';

import { analytics } from '../lib/analytics';
import HoverTouchableOpacity from './general/hover/HoverTouchableOpacity';

const styles = StyleSheet.create({
  creditImageStyle: {
    width: 18,
    height: 18,
  },
  imageStyle: {
    width: 18,
    height: 12,
  },
  text: {
    color: design.colors.clinicHeader,
    fontSize: design.text.semiReg,
    fontWeight: 'bold',
    alignSelf: 'center',
    textAlign: 'center',
  },
  textHeader: {
    width: '100%',
  },
  textDesktop: {
    fontSize: design.text.twentyTwo,
  },
  icoContainer: {
    paddingVertical: 20,
    // paddingHorizontal: 10,
    paddingLeft: 20,
    paddingRight: 10,
    marginLeft: 0,
    height: 5,
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
  // loaderContainer: {
  //   position: 'absolute',
  //   left: width / 2,
  //   top: 30,
  //   // backgroundColor: 'red',
  //   // top:70 // IOS
  //   marginLeft: -100,
  //   alignItems: 'center',
  // },
  leftBack: {
    marginLeft: 5,
  },
  leftNoBack: {
    marginLeft: 20,
  },
  leftNoBackDesktop: {
    marginLeft: 100,
  },
  leftBackAndCenter: {
    //  marginLeft: width / 2 - 70
    marginLeft: 20,
  },

  credits: {
    marginRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'center'
  },
  creditsText: {
    color: design.colors.clinicBlue,
    fontSize: design.text.eightTeen,
    fontWeight: 'bold',
    // alignSelf: 'center'
    marginRight: 5,
  },
  sideBySide: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  left: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,

    // backgroundColor: 'red'
    //  left: 53
  },
  right: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    // backgroundColor: 'yellow',
    flex: 1,
  },
  center: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'blue'

    //  flex: 1
  },
  header: {
    // width: 370,
    height: 55,
    justifyContent: 'center',
    backgroundColor: design.colors.white,
    borderBottomColor: design.colors.headerBorderColor,
    borderBottomWidth: 2,
  },
  readOnly: {
    backgroundColor: design.colors.chartLegend,
  },
  transparent: {
    backgroundColor: design.colors.transparent,
    borderBottomWidth: 0,
    borderBottomColor: design.colors.transparent,
  },
  months: {
    flexDirection: 'row',
    marginRight: 20,

    // overflow: 'hidden'
  },
  lockContainer: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: design.colors.white,
    borderRadius: 3,
    marginLeft: 5,
  },
  lockImage: {
    width: 12,
    height: 12,
  },
  backgroundColorGray: {
    backgroundColor: design.colors.baseGray,
  },
  marginHorizontal: {
    ...StyleSheet.flatten(defaultStyle.margin20HorizontalWeb),
  },
  desktopHeight: {
    height: 50,
  },
  backContainer: {
    flexDirection: 'row',
    paddingRight: 20,
  },
  backText: {
    color: design.colors.clinicHeader,
    fontSize: design.text.small,
    fontWeight: 'normal',
    alignSelf: 'center',
    textAlign: 'center',
  },
  hover: {
    backgroundColor: design.colors.baseGray,
  },
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.changeListener = this.changeListener.bind(this);
    this.backPress = this.backPress.bind(this);
    this.state = {
      isDesktop: Dimensions.get('window').width >= global.mobileMaxWidth,
    };
    this.listener = null;
  }

  componentDidMount() {
    this.addBackHandler();
  }

  componentWillUnmount() {
    this.removeBackHandler();
  }

  changeListener() {
    const windowDimensions = Dimensions.get('window');
    const isDesktop = windowDimensions.width >= global.mobileMaxWidth;
    // console.log('Header changeListener', isDesktop, windowDimensions.width);
    this.setState({ isDesktop });
  }

  removeBackHandler() {
    Dimensions.removeEventListener('change', this.changeListener);
  }

  addBackHandler() {
    this.removeBackHandler();
    Dimensions.addEventListener('change', this.changeListener);
  }

  backPress() {
    const { backPress } = this.props;
    if (backPress) {
      analytics.logButtonClick('back');
      backPress();
    }
  }

  render() {
    const {
      headerLeft,
      showBack,
      transparent,
      title,
      showCredits,
      credits,
      scenarioTicks,
      selectedColor,
      readOnly,
      showMonths,
      isFetching,
      hideHeader,
      maxWidth,
      backgroundColor,
      addMarginHorizontal,
      isReferences,
      backText,
    } = this.props;
    const { isDesktop } = this.state;
    let correctStyle = {};
    let isCenter = true; // just center
    // debugger
    correctStyle = styles.center;
    if (headerLeft && showBack) {
      correctStyle = styles.leftBack;
      isCenter = false;
    } else if (headerLeft && !showBack) {
      isCenter = false;
      correctStyle = isReferences ? styles.leftNoBackDesktop : styles.leftNoBack;
    } else if (showBack) {
      // center and back
      correctStyle = styles.leftBackAndCenter;
    }
    if (hideHeader) return null;
    return (
      <View
        style={[
          styles.header,
          styles.marginTop,
          readOnly ? styles.readOnly : {},
          transparent ? styles.transparent : {},
          isDesktop && styles.backgroundColorGray,
          maxWidth && { width: maxWidth },
          backgroundColor && { backgroundColor },
          addMarginHorizontal && styles.marginHorizontal,
          isDesktop && styles.desktopHeight,
        ]}
      >
        <View style={[styles.sideBySide]}>
          <View style={[styles.left]}>
            {showBack && (
              <HoverTouchableOpacity
                style={styles.icoContainer}
                onPress={this.backPress}
                hoverStyle={styles.hover}
                testID="backButton"
              >
                {isDesktop
                  ? (
                    <View style={styles.backContainer}>
                      <Icon
                        name="chevron-left"
                        size={30}
                        color={design.colors.clinicHeader}
                      />
                      <TextMontserrat style={styles.backText}>
                        {backText || I18n.t('Clinic.headerBack')}
                      </TextMontserrat>
                    </View>
                  ) : (
                    <Image
                      source={icons.back}
                      style={[styles.imageStyle, styles.icon]}
                      resizeMode="contain"
                    />
                  )}
              </HoverTouchableOpacity>
            )}
            {!isCenter && (
              <View style={[correctStyle]}>
                <TextMontserrat
                  style={[
                    styles.text, styles.textHeader, isDesktop && styles.textDesktop,
                  ]}
                  numberOfLines={1}
                >
                  {title}
                </TextMontserrat>
              </View>
            )}
          </View>
          <View style={[styles.center]}>
            {isCenter && (
              <View style={[]}>
                <TextMontserrat style={[styles.text, isDesktop && styles.textDesktop]} numberOfLines={1}>
                  {title}
                </TextMontserrat>
              </View>
            )}
          </View>
          <View style={styles.right}>
            {/* {showCredits && (
              <View style={[styles.credits]}>
                <TextMontserrat style={[styles.creditsText]}>{credits || 0}</TextMontserrat>
                <Image
                  source={icons.credits}
                  style={styles.creditImageStyle}
                  resizeMode="contain"
                />
              </View>
            )} */}
            <ActionGroup fromHeader disabled={isDesktop} />
            {showMonths && (
              <View style={styles.months}>
                <HeaderMonths selectedColor={selectedColor} months={scenarioTicks} />
                {readOnly && (
                  <View style={styles.lockContainer}>
                    <Image
                      source={icons.lock_reverse}
                      style={styles.lockImage}
                      resizeMode="cover"
                    />
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
        {/* <View style={[styles.loaderContainer, styles.loader]}>
          <Text>Animation</Text>
          <Loader type={'scroll'} />
        </View> */}
        {/* {isFetching && true ? (
          <View style={[styles.loaderContainer, styles.loader]}>
            <Loader type={"scroll"} />
          </View>
        ) : null} */}
      </View>
    );
  }
}

module.exports = Header;
