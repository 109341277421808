// import DeviceInfo from './deviceInfo';
// const bundleId = DeviceInfo.getBundleId();
import {
  MOBILE_MAX_WIDTH,
// eslint-disable-next-line import/no-unresolved
} from '@env';

const global = Object.freeze({
  FULL_NAME_REGEX: /^([a-zA-Z0-9 ,.'-]{3,})+$/i,
  PASSWORD_REGEX: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/, // new regex at least 1 letter 1 number 8 character minimum
  DOCTOR_ID_REGEX: /^[0-9]{5,}$/,
  GOOGLE_DOCS_URL: 'https://docs.google.com/gview?embedded=true&url=',
  SEARCH_DELAY: 100,
  PRIVACY: 'https://res.cloudinary.com/edocate/docs/eDocatePrivacyPolicy.pdf',
  LEGAL: 'https://res.cloudinary.com/edocate/docs/eDocateTermsofUse.pdf',
  CREDITS: 'https://res.cloudinary.com/edocate/docs/eDocateCredits.pdf',
  NUMBER_OF_LABS_TO_SHOW: 3,
  ROUTER_USE_FLEX: false,
  envEnum: {
    PARSE_DEV: 'PARSE_DEV',
    PARSE_STAGE: 'PARSE_STAGE',
    PARSE_APP: 'PARSE',
    ENV_KEY: 'env',
    DEFAULT_ENV: 'PARSE',
  },
  bundleSearchPhrase: {
    PARSE_DEV: 'edge',
    PARSE_STAGE: 'stage',
  },
  // bundleId,
  CLINIC_TAB_URL: '/App/ClinicTab/',
  NO_HEADER_SCREENS: [
    'AuthStack', 'AuthLoading',
    'Auth', 'Signup', 'Login',
    'Forgot', 'Walkthrough',
    'ProjectOver', 'Error', '',
    'Logout', 'AzureAD', 'AzureADLogout',
  ],
  PATIENT_ADD_SCREENS: [
    'AddLab', 'AddPhysical',
    'AddDiagnosis', 'AddTreatment',
    'AddReferral',
  ],
  TAB_SCREENS: [
    'Clinic', 'Patient', 'AddClinic', 'AddLab', 'AddPhysical',
    'AddDiagnosis', 'AddTreatment', 'ModuleIntro', 'AddReferral',
    'EndModulePeerScreen',
    'EndModuleGoalScreen', 'EndModuleEdocateWay', 'EndModuleFull', 'WebViewer',
    'Goal', 'EndEncounterFull', 'EndModuleChartScreen',
  ],
  TAB_SCREENS_WITH_PADDING: [
    'App', 'Clinic', 'AddClinic', 'ClinicTab',
    'ModuleIntro',
    'FullCredit', 'EndModulePeerScreen',
    'EndModuleGoalScreen', 'EndModuleEdocateWay', 'EndModuleFull', 'WebViewer',
    'Goal', 'EndEncounterFull', 'EndModuleChartScreen',
  ],
  mobileMaxWidth: parseInt(MOBILE_MAX_WIDTH, 10) || 1224, // MOBILE_MAX_WIDTH || 1224,
  desktopRecommendedWidth: 1224, // MOBILE_MAX_WIDTH || 1224,
  modalMaxWidthDesktop: 900,
  physicalImageDesktopWidth: 323,
  physicalReferralGroupName: 'referral',
  maxFontSizeMultiplier: 1.2,
  promotionsMemoryKey: 'promotions',
  showGoToCredits: 'showGoToCredits',
  hdScreenWidth: 1920,
  alertMaxWidth: 540,
  numOfPatientTabs: 7,
  appIdThatDisableAnalytics: ['edocate-dev', 'edocate-server-2017'],
  tabletMinWidth: 768,
  // buttonMaxWidthDesktop: 335,
});
export default global;
