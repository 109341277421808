import React from 'react';
import { Text, StyleSheet, Platform } from 'react-native';
import global from '../../lib/global';
import styles from './pages/src/components/indicator/styles';
import helper from '../../lib/helper';
import defaultStyle from '../../lib/defaultStyle';

const isWeb = Platform.OS === 'web';
const isAndroid = Platform.OS === 'android';
const fontFamilyRtl = StyleSheet.flatten(defaultStyle.webRTLFontFamily).fontFamily;

export default function TextBaseComponent({
  children,
  fontWeight,
  fontFamily,
  style,
  ...rest
}) {
  const shouldRTL = helper.isRTL(children);
  const isLtrWord = helper.isRtlContainLtr(children);
  const comboFontFamily = isLtrWord ? fontFamily : isWeb ? fontFamilyRtl : fontFamily;
  /* Hades up => comboFontFamily in native canot have Arial font - unless we add it to native */
  const compStyleMobile = [
    style,
    isAndroid && { fontWeight },
    shouldRTL && styles.directionRTL,
    { fontFamily: comboFontFamily, fontWeight },
  ];
  const compStyleWeb = [
    style,
    {
      fontFamily: comboFontFamily,
      fontWeight,
    },
  ];

  const innerProps = {
    style: isWeb ? compStyleWeb : compStyleMobile,
  };

  if (!isWeb) {
    innerProps.dir = shouldRTL ? 'rtl' : 'ltr';
  }

  return (
    <Text
      maxFontSizeMultiplier={global.maxFontSizeMultiplier}
      {...innerProps}
      {...rest}
    >
      {children}
    </Text>
  );
}
