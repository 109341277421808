// import { I18nManager } from 'react-native';
// import I18n from 'i18n-js';
// import Translations from './Translations.json';
import design from './design';
// import images from '../images';
import icons from '../images/icons';
import helper from './helper';
import defaultStyle from './defaultStyle';
import platformStyle from './platformStyle'; // it has .ios.android extension
import TextSF from '../components/general/TextSF';
import TextMontserrat from '../components/general/TextMontserrat';
import TextOswald from '../components/general/TextOswald';
import global from './global';
import router from './router/routerHelper';
import I18n from './i18n';
import enums from './enums';

export {
  I18n,
  design,
  // images,
  helper,
  defaultStyle,
  TextSF,
  TextMontserrat,
  icons,
  platformStyle,
  TextOswald,
  global,
  router,
  enums,
};
