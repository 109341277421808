/**
 * # profileReducer.js
 *
 * The reducer user profile actions
 */

/**
 * ## Imports
 *

 * formValidation for setting the form's valid flag
 */

// const formValidation = require('../profile/profileFormValidation').default

/**
 * ## Actions
 *
 */
const {
  GET_REFERENCE_SUCCESS,
  SET_REF_ITEM,
  GET_HTML_SUCCESS,
  CLEAR_HTML_SUCCESS,
  GET_ARTICLES_SUCCESS,
} = require('../../lib/constants').default;

/**
 * ## Initial State
 *
 */
const InitialState = require('./referenceInitialState').default;

const initialState = new InitialState();

/**
 * ## profileReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function settingsReducer(state = initialState, action) {
  //  let nextProfileState = null

  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    // case SETTINGS_UPDATE_SUCCESS:
    case GET_ARTICLES_SUCCESS:
      return state.set('articles', action.payload);
    case GET_REFERENCE_SUCCESS:
      return state.set('references', action.payload);
    case GET_HTML_SUCCESS:
      return state.set('html', action.payload);
    case CLEAR_HTML_SUCCESS:
      return state.set('html', '');
    case SET_REF_ITEM:
      return state
        .set('link', action.payload.link)
        .set('header', action.payload.header)
        .set('objectId', action.payload.objectId);
    default:
      return state;
  } // switch
  /**
   * # Default
   */
  // return state;
}
