import React, { useState, useEffect } from 'react';
import { Dimensions } from 'react-native';

const MediaQuery = (props) => {
  const windowDimensions = Dimensions.get('window');
  const screenDimensions = Dimensions.get('screen');
  const [dimensions, setDimensions] = useState({ window: windowDimensions, screen: screenDimensions });
  const { onLayout } = props;
  const onChange = ({ window, screen }) => {
    setDimensions({ window, screen });
    if (typeof onLayout === 'function') {
      onLayout({ window, screen });
    }
  };

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  });
  // console.log('MediaQuery inside');
  const {
    minWidth, maxWidth, children, isScreen,
  } = props;
  let match = false;
  const selectedWidth = isScreen ? dimensions.screen.width : dimensions.window.width;
  if (minWidth && selectedWidth > minWidth) {
    match = true;
  }
  if (maxWidth && selectedWidth < maxWidth) {
    match = true;
  }
  // console.log('MediaQuery match', match, minWidth, maxWidth, isScreen, dimensions);
  if (typeof children === 'function') {
    return children(match);
  } if (match) {
    return children;
  }
  return null;
};

export default MediaQuery;
