import React from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import MediaQuery from '../components/general/MediaQuery';
import { design, global } from '../lib/general';
// import config from '../lib/config';
import PatientImageName from '../components/patient/intro/PatientImageName';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: design.colors.baseGray,
  },
  patientContainer: {
    height: '100%',
    backgroundColor: design.colors.white,
    borderRightColor: design.colors.baseGray,
    borderRightWidth: 2,
    // marginRight: 50,
    width: 370,
  },
});

function withPatientImageDesktop(WrappedComponent) {
  return (
    <MediaQuery
      maxWidth={global.mobileMaxWidth}
    >
      {(matches) => (
        !matches ? (
          <View style={styles.container}>
            <View style={styles.patientContainer}>
              <PatientImageName
                width={370}
                showMonths
                fromScreen="withPatientImageDesktop"
                hideDiagnosis
                smaller
                key="PatientImageName-withPatientImageDesktop"
              />
            </View>
            <WrappedComponent />
          </View>
        ) : <WrappedComponent />
      )}
    </MediaQuery>
  );
}

export default withPatientImageDesktop;
