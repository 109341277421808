import { I18nManager, Platform } from 'react-native';
import I18n from 'i18n-js';
import * as RNLocalize from 'react-native-localize';
import { config } from './config';
import memory from './Memory';
import helper from './helper';
import Translations from './Translations.json';
import { appAuthToken } from './AppAuthToken';

const BackendFactory = require('./BackendFactory').default;

const LAST_LANGUAGE_DIRECTION_MEMORY = 'lastLanguageDirectionMemory';
const checkIfNeedRestartAfterChangingRTL = (isRTL) => {
  if (Platform.OS === 'web') return;
  memory.getItem(LAST_LANGUAGE_DIRECTION_MEMORY).then((direction) => {
    console.log(LAST_LANGUAGE_DIRECTION_MEMORY, isRTL, direction);
    if (((!direction || direction === 'ltr') && isRTL) || (direction === 'rtl' && !isRTL)) {
      import('react-native-code-push').then((codePush) => {
        codePush.restartApp();
      });
    }
    memory.setItem(LAST_LANGUAGE_DIRECTION_MEMORY, isRTL ? 'rtl' : 'ltr');
  });
};

const loadTranslationFromAPi = async () => {
  const {
    shouldForceRtl, allowRtl, shouldForceLanguage, languageToForce,
  } = config;
  appAuthToken
    .getSessionToken()
    .then((token) => BackendFactory(token).getTranslation().then((answer) => {
      let languageTag = 'en';
      let isRTL = false;
      const { result } = answer;
      const availableLocales = Object.keys(result);
      const fallback = { languageTag, isRTL };
      if (shouldForceLanguage && languageToForce) {
        languageTag = languageToForce;
        isRTL = shouldForceRtl;
      } else {
        const bestAvailableLanguage = RNLocalize.findBestAvailableLanguage(availableLocales) || fallback;
        languageTag = bestAvailableLanguage.languageTag;
        isRTL = bestAvailableLanguage.isRTL;
      }
      // for web at the moment
      if (shouldForceRtl) {
        I18nManager.forceRTL(true);
        I18nManager.allowRTL(true);
        I18nManager.swapLeftAndRightInRTL(true);
      } else if (allowRtl) {
        I18nManager.forceRTL(isRTL);
        I18nManager.allowRTL(true);
        I18nManager.swapLeftAndRightInRTL(true);
      } else {
        I18nManager.forceRTL(false);
        I18nManager.allowRTL(false);
        I18nManager.swapLeftAndRightInRTL(false);
      }
      checkIfNeedRestartAfterChangingRTL(isRTL);
      console.log('I18n', languageTag, isRTL, shouldForceRtl, allowRtl, shouldForceLanguage, languageToForce);
      I18n.locale = languageTag;
      I18n.translations = result;
      I18n.start = isRTL ? 'right' : 'left';
      I18n.end = isRTL ? 'left' : 'right';
      I18nManager.isRTL = isRTL;
    })).catch((err) => {
    // we will take english
      console.warn('loadTranslationFromAPi error', err);
      I18nManager.forceRTL(shouldForceRtl);
      I18nManager.allowRTL(allowRtl);
      I18nManager.swapLeftAndRightInRTL(true);
      I18n.translations = Translations;
      I18n.start = I18nManager.isRTL ? 'right' : 'left';
      I18n.end = I18nManager.isRTL ? 'left' : 'right';
    });
};

const init = () => {
  I18n.defaultLocale = 'en';
  I18n.fallbacks = true;
  I18n.translations = Translations;
  if (helper.isLocalhost()) {
    I18n.enableFallback = true;
    I18n.fallbacks = false;
    I18n.missingTranslation = (str, obj) => {
      if (obj.locale_en && config.addTranslation) {
        BackendFactory().addTranslation({ str, locale_en: obj.locale_en })
          .then(() => {})
          .catch(() => {});
        return obj.locale_en;
      }
      return null;
    };
  } else {
    I18n.missingTranslation = () => null;
  }
};

init();
export default I18n;
export { loadTranslationFromAPi };
