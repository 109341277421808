import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { design } from '../../lib/general';

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  activityIndicatorWrapper: {
    backgroundColor: 'transparent',
    height: 100,
    width: 100,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100000,
    backgroundColor: design.colors.transparent,
  },
});

const LoaderModalDelay = () => {
  const [isFetching, setIsFetching] = useState(false);
  let timer = null;
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => { setIsFetching(true); }, 100);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      setIsFetching(false);
    };
  }, []);

  if (!isFetching) return null;
  return (
    <View style={styles.container}>
      <View style={styles.modalBackground}>
        <View style={[styles.activityIndicatorWrapper]}>
          <ActivityIndicator animating={isFetching} size="large" color={design.colors.clinicSub} />
        </View>
      </View>
    </View>
  );
};
export default LoaderModalDelay;
