const { SET_GOAL } = require('../../lib/constants').default;

export function setGoalDispatch(payload) {
  return {
    type: SET_GOAL,
    payload,
  };
}

export function setGoal(goal) {
  return (dispatch) => {
    dispatch(setGoalDispatch(goal));
  };
}
