import { Platform } from 'react-native';
import {
  APP_ID, REST_API_KEY, APP_URL,
  SHOW_CREDITS, SESSION_TOKEN_KEY,
  SHOULD_ENFORCE_ENV, USER_INVITATION_ONLY, USE_PERSIST,
  FORCE_ENV, OPEN_FDA_API_KEY, ONE_SIGNAL_KEY, INSTABUG_KEY,
  SENTRY_KEY, APP_BUNDLE, WEB_GOOGLE_ANALYTICS,
  FIREBASE_PROJECT_ID, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_APP_ID,
  FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_DATABASE_URL,
  FIREBASE_STORAGE_BUCKET, CODE_PUSH_STAGING_ANDROID, CODE_PUSH_PRODUCTION_ANDROID,
  CODE_PUSH_STAGING_IOS, CODE_PUSH_PRODUCTION_IOS, APP_NAME, DETECT_RTL_ON_TEXT,
  SHOW_INFO_DIAGNOSIS, HIDE_SCORE_AND_FEEDBACK, SHOW_SPLASH_ON_AUTH_LOAD, BUGSNAG_KEY,
  DEFAULT_COUNTRY_CODE_FALLBACK, FIREBASE_MEASUREMENT_ID, FIREBASE_APP_ID_ANDROID,
  FIREBASE_APP_ID_IOS, SHOULD_FORCE_RTL, ALLOW_RTL, SHOULD_FORCE_LANGUAGE,
  LANGUAGE_TO_FORCE, SHOW_CME, SHOW_COST, FORCE_DEFAULT_COUNTRY_CODE,
  SHOULD_CHOOSE_CAMPAIGN, ACTIVE_LOCAL_NOTIFICATION, HIDE_TREATMENT_BRAND,
  USE_SLIM_PARAMETER_PROPS, RESTART_POLICY, ALLOW_TOKEN_AUTHENTICATION,
  ALLOWED_TOKEN_PROVIDER, HIDE_ADD_CLINIC, CREDITS_TO_EXTERNAL,
  SHOW_ADD_TO_HOME_SCREEN, SHOULD_VALIDATE_DOCTOR_ID, SHOULD_CHOOSE_EDUCATION, SHOULD_CHOOSE_SPECIALTY,
  SHOW_CREDITS_RED_DOT, USE_PROVIDER_CONNECT, USE_DYNAMIC_SSO_SERVICE,
  SKIP_WALKTHROUGH, USER_AGREE_CHECKBOX, USER_EMAIL_EXISTS, NEW_AUTH_PAGES,
  SHOW_ACTION_GROUP_BUTTON, ENABLE_REALTIME_REPORT, SHOW_LOGIN_ONLY, AZURE_LOGIN_ONLY,
  MOBILE_MAX_WIDTH, ALLOW_EDOCATE_REGISTRATION, HIDE_TOP_BAR, TAB_BAR_LIGHT_MODE, HIDE_NAVIGATION_TABS,
// eslint-disable-next-line import/no-unresolved
} from '@env';
import store from '../reducers/store';
import pack from '../../version.json';
import { mergeWithSuperConfig } from './utility/superConfigHelper';

const superConfig = require('./superConfig.json');

const isWeb = Platform.OS === 'web';
// firebase has 3 different id's
let FirebaseAppId = FIREBASE_APP_ID;
if (Platform.OS === 'ios') {
  FirebaseAppId = FIREBASE_APP_ID_IOS;
}
if (Platform.OS === 'android') {
  FirebaseAppId = FIREBASE_APP_ID_ANDROID;
}

let dotEnvConfig = {
  version: pack.version,
  shouldForceEnv: SHOULD_ENFORCE_ENV === 'true', // if true it will update the env
  forceEnv: FORCE_ENV,
  usePersist: USE_PERSIST === 'true',
  SESSION_TOKEN_KEY,
  userInvitationOnly: USER_INVITATION_ONLY === 'true',
  backend: {
    parseLocal: false,
  },
  PARSE: {
    appId: APP_ID, // match APP_ID in parse-server's index.js
    restAPIKey: REST_API_KEY,
    local: {
      url: 'http://localhost:8080/parse', // match SERVER_URL in parse-server's index.js
    },
    remote: {
      url: APP_URL,
    },
  },
  isWeb,
  shouldForceMaxWidthMobileDesktop: true,
  showCredits: SHOW_CREDITS === 'true',
  firebase: {
    projectId: FIREBASE_PROJECT_ID,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FirebaseAppId,
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_DATABASE_URL,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    measurementId: FIREBASE_MEASUREMENT_ID,
  },
  codePush: {
    android: {
      staging: CODE_PUSH_STAGING_ANDROID,
      production: CODE_PUSH_PRODUCTION_ANDROID,
    },
    ios: {
      staging: CODE_PUSH_STAGING_IOS,
      production: CODE_PUSH_PRODUCTION_IOS,
    },
  },
  openFdaApiKey: OPEN_FDA_API_KEY,
  oneSignalKey: ONE_SIGNAL_KEY,
  instaBugKey: INSTABUG_KEY,
  sentryKey: SENTRY_KEY,
  bundle: APP_BUNDLE,
  webGoogleAnalytics: WEB_GOOGLE_ANALYTICS,
  appName: APP_NAME,
  showInfoDiagnosis: SHOW_INFO_DIAGNOSIS === 'true',
  hideScoreAndFeedback: HIDE_SCORE_AND_FEEDBACK === 'true',
  showSplashOnAuthLoad: SHOW_SPLASH_ON_AUTH_LOAD === 'true',
  bugsnagKey: BUGSNAG_KEY,
  defaultCountryCodeFallback: DEFAULT_COUNTRY_CODE_FALLBACK,
  forceDefaultCountryCode: FORCE_DEFAULT_COUNTRY_CODE === 'true',
  detectRtlOnText: DETECT_RTL_ON_TEXT === 'true',
  shouldForceRtl: SHOULD_FORCE_RTL === 'true',
  allowRtl: ALLOW_RTL === 'true',
  shouldForceLanguage: SHOULD_FORCE_LANGUAGE === 'true',
  languageToForce: LANGUAGE_TO_FORCE,
  showCme: SHOW_CME === 'true',
  showCost: SHOW_COST === 'true',
  shouldChooseCampaign: SHOULD_CHOOSE_CAMPAIGN === 'true',
  shouldChooseEducation: SHOULD_CHOOSE_EDUCATION === 'true',
  shouldChooseSpecialty: SHOULD_CHOOSE_SPECIALTY === 'true',
  shouldValidateDoctorId: SHOULD_VALIDATE_DOCTOR_ID === 'true',
  activeLocalNotification: ACTIVE_LOCAL_NOTIFICATION === 'true',
  hideTreatmentBrand: HIDE_TREATMENT_BRAND === 'true',
  useSlimParameterProps: USE_SLIM_PARAMETER_PROPS === 'true',
  restartPolicy: RESTART_POLICY,
  allowTokenAuthentication: ALLOW_TOKEN_AUTHENTICATION === 'true',
  allowEdocateRegistration: ALLOW_EDOCATE_REGISTRATION === 'true',
  allowedTokenProvider: ALLOWED_TOKEN_PROVIDER,
  hideAddClinic: HIDE_ADD_CLINIC === 'true',
  creditsToExternal: CREDITS_TO_EXTERNAL === 'true',
  showAddToHomeScreen: SHOW_ADD_TO_HOME_SCREEN === 'true',
  showCreditsRedDot: SHOW_CREDITS_RED_DOT === 'true',
  useProviderConnect: USE_PROVIDER_CONNECT === 'true',
  useDynamicSSOService: USE_DYNAMIC_SSO_SERVICE === 'true',
  skipWalkthrough: SKIP_WALKTHROUGH === 'true',
  userAgreeCheckbox: USER_AGREE_CHECKBOX === 'true',
  userEmailExists: USER_EMAIL_EXISTS === 'true',
  newAuthPages: NEW_AUTH_PAGES === 'true',
  showActionGroupButton: SHOW_ACTION_GROUP_BUTTON === 'true',
  enableRealTimeReport: ENABLE_REALTIME_REPORT === 'true',
  showLoginOnly: SHOW_LOGIN_ONLY === 'true',
  azureLoginOnly: AZURE_LOGIN_ONLY === 'true',
  mobileMaxWidth: MOBILE_MAX_WIDTH,
  hideTopBar: HIDE_TOP_BAR === 'true',
  tabBarLightMode: TAB_BAR_LIGHT_MODE === 'true',
  hideNavigationTabs: HIDE_NAVIGATION_TABS === 'true',
};
dotEnvConfig.PARSE_DEV = dotEnvConfig.PARSE;
dotEnvConfig.PARSE_STAGE = dotEnvConfig.PARSE;

const getServerConfig = () => {
  const state = store.getState();
  const { serverConfig } = state?.patient || {};
  const scenarioConfig = state?.patient?.currentPatient?.data?.sim?.data?.config || {};
  // lets add scenario config as well
  const merged = { ...serverConfig, ...scenarioConfig };
  return merged;
};

if (isWeb) {
  const source = new URLSearchParams(window?.location?.search);
  const env = source?.get('env');
  dotEnvConfig = mergeWithSuperConfig(dotEnvConfig, superConfig, env);
}

const config = dotEnvConfig;
const runOverConfigWithServerConfig = () => {
  const myServerConfig = getServerConfig();
  Object.keys(myServerConfig).forEach((key) => {
    config[key] = myServerConfig[key];
  });
};
const getConfigParameter = (name) => config[name];

export default config;
export {
  config, getServerConfig, runOverConfigWithServerConfig, getConfigParameter,
};
// edge
// edocate
// edocate