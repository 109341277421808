/**
 * # reducers
 *
 * This class combines all the reducers into one
 *
 */

/**
 * ## Imports
 *
 * our 4 reducers
 */
import { combineReducers } from 'redux';
import auth from './auth/authReducer';
import device from './device/deviceReducer';
import global from './global/globalReducer';
import profile from './profile/profileReducer';
import clinic from './clinic/clinicReducer';
import settings from './settings/settingsReducer';
import patient from './patient/patientReducer';
import routes from './routes/routesReducer';
import reference from './reference/referenceReducer';
import notification from './notification/notificationReducer';
import faq from './FAQ/faqReducer';
import goal from './goal/goalReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */

const rootReducer = combineReducers({
  auth,
  device,
  global,
  profile,
  clinic,
  settings,
  patient,
  routes,
  reference,
  notification,
  faq,
  goal,
});

export default rootReducer;
