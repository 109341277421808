/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-template-curly-in-string */
import extend from 'lodash/extend';
import I18n from 'i18n-js';
import { config, getServerConfig } from './config';
import helper from './helper';
import { appAuthToken } from './AppAuthToken';

const BackendFactory = require('./BackendFactory').default;

class ClinicalApi {
  constructor() {
    this.API_BASE_URL = 'https://clin-table-search.lhc.nlm.nih.gov/api';
    this.ICD10linkFromCode = 'https://connect.medlineplus.gov/service?mainSearchCriteria.v.c=${code}&mainSearchCriteria.v.cs=2.16.840.1.113883.6.90&mainSearchCriteria.v.dn=&informationRecipient.languageCode.c=en&knowledgeResponseType=application/json';
  }

  async getIcd10AutoComplete(str, profiles) {
    const shortLink = `/conditions/v3/search?sf=primary_name,consumer_name,icd10cm_codes,synonyms&ef=info_link_data,icd10cm_codes&terms=${str}&maxList=20&df=icd10cm_codes,primary_name`;
    const longLink = `/icd10cm/v3/search?sf=code,name&ef=icd10cm_codes,info_link_dat&maxList=20&terms=${str}`;
    const serverConfig = getServerConfig();
    const url = serverConfig?.useShortICd10 ? shortLink : longLink;
    const promise1 = appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).icdSearch({ str, profiles }));
    const promise2 = this.privateFetch({
      method: 'GET',
      url,
    });
    const allPromises = Promise.allSettled([promise1, promise2]).then(async (values) => {
      const [externalIcdPhase1, icdResultsPhase1] = values;
      const externalIcdResults = externalIcdPhase1.status === 'fulfilled' ? externalIcdPhase1.value : [];
      const icdResults = icdResultsPhase1.status === 'fulfilled' && icdResultsPhase1.value.json
        ? await icdResultsPhase1.value.json() : [0, [], {}, []];
      const parsedExternalResults = externalIcdResults.map((item) => [item.num, item.text]);
      if (Array.isArray(icdResults[3])) {
        icdResults[3] = parsedExternalResults.concat(icdResults[3]);
      }
      return icdResults;
    });
    return allPromises;
  }

  async getICD10linkFromCode(code) {
    const serverConfig = getServerConfig();
    const ICD10linkFromCodeString = serverConfig.ICD10linkFromCode && serverConfig.ICD10linkFromCode !== ''
      ? serverConfig.ICD10linkFromCode : this.ICD10linkFromCode;
    const theLink = helper.parseTemplate(ICD10linkFromCodeString, { code });
    return this.privateFetch(
      {
        method: 'GET',
        url: theLink,
      },
      true,
    )
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getDrugsAutoComplete(str) {
    return this.privateFetch({
      method: 'GET',
      url: `/icd10cm/v3/search?sf=name&terms=${str}`,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res[3] || [];
          // return res[2] || [] // in the new API its 2
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  //
  async getDrugInfo(displayName, str) {
    const BASE_URL = `https://api.fda.gov/drug/label.json?api_key=${config.openFdaApiKey}&search=`;
    const searchStr = str;// isStr ? str : '';
    // const n = displayName || name;
    const url = BASE_URL + searchStr;
    return this.privateFetch(
      {
        method: 'GET',
        url,
      },
      true,
    )
      .then((response) => response.json().then((res) => res))
      .catch((error) => {
        throw error;
      });
  }

  async getCountriesAutoComplete(countryName) {
    const { locale = 'en' } = { I18n };
    const url = `https://raw.githubusercontent.com/umpirsky/country-list/master/data/${locale}/country.json`;
    return this.privateFetch(
      {
        method: 'GET',
        url,
      },
      true,
    )
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          const answer = Object.entries(res).map(([code, name]) => ({
            name,
            flag: '',
            code,
          }));

          return answer;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getCountryByIp() {
    const url = 'https://ip2c.org/self';
    return this.privateFetch(
      {
        method: 'GET',
        url,
      },
      true,
    )
      .then((response) => response.text().then((res) => {
        if (response.status === 200 || response.status === 201) {
          const [success, twoLetter, threeLetter, fullName] = res.split(';');
          if (success) return fullName;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  /**
   * ### privateFetch
   * A generic function that prepares the request
   * @returns object:
   *  {code: response.code
   *   status: response.status
   *   json: reponse.json()
   */
  async getStaticHtml(url) {
    return this.privateFetch(
      {
        method: 'GET',
        url,
      },
      true,
    )
      .then((response) => response.text().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async validateDoctorIdCancel(idObj) {
    // eslint-disable-next-line no-template-curly-in-string
    const { doctorId, prefix } = idObj;
    const urlStr = getServerConfig().validateDoctorIdURL || 'https://practitionersapi.health.gov.il/Practitioners/api/Practitioners/GetProfessionsLicense?licenseNum=${prefix}-${doctorId}';
    const urlStrNoPrefix = getServerConfig().validateDoctorIdURLNoPrefix || 'https://practitionersapi.health.gov.il/Practitioners/api/Practitioners/GetProfessionsLicense?licenseNum=${prefix}${doctorId}';
    // race
    const urlTemplate = helper.generateTemplateString(urlStr);
    const urlTemplateNoPrefix = helper.generateTemplateString(urlStrNoPrefix);
    const url = urlTemplate({ doctorId, prefix });
    const urlNoPrefix = urlTemplateNoPrefix({ doctorId, prefix });
    const all = [
      this.privateFetch({ method: 'GET', url }, true).then((response) => response.json()),
      this.privateFetch({ method: 'GET', url: urlNoPrefix }, true).then((response) => response.json()),
    ];
    const [res, resNoPrefix] = await Promise.all(all);
    return res?.[0]?.licenseDetails?.status?.statusId === 0
       || resNoPrefix?.[0]?.licenseDetails?.status?.statusId === 0;
  }

  async privateFetch(options, changeBase) {
    const opts = extend(
      {
        method: 'GET',
        url: null,
        body: null,
        callback: null,
      },
      options,
    );
    const reqOpts = {
      method: opts.method,
      headers: {},
    };

    if (opts.method === 'POST' || opts.method === 'PUT') {
      reqOpts.headers.Accept = 'application/json';
      reqOpts.headers['Content-Type'] = 'application/json';
    }

    if (opts.body) {
      reqOpts.body = JSON.stringify(opts.body);
    }

    const url = changeBase ? opts.url : this.API_BASE_URL + opts.url;
    return fetch(url, reqOpts);
  }
}
// The singleton variable\
const clinicalApi = new ClinicalApi();
export default clinicalApi;
