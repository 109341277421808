import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Image,
  StatusBar,
  TouchableOpacity,
  Platform,
  Dimensions,
  ScrollView,
} from 'react-native';
import Modal from 'react-native-modal';
import Swiper from 'react-native-swiper';
import {
  design,
  I18n,
  TextMontserrat,
  TextSF,
  helper,
} from '../../../../lib/general';
import { config } from '../../../../lib/config';
import ButtonFull from '../../ButtonFull';

const { height } = Dimensions.get('screen');

const topPosition = Platform.OS === 'ios' ? 40 : 20;
const isWeb = Platform.OS === 'web';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignSelf: 'flex-end',
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // bottom: 0,
    // top: 0,
    // backgroundColor: 'white',
    // backgroundColor: design.colors.clinicSub,
    // height: '75%',
    backgroundColor: 'rgba(57, 67, 91, 0.95)',
    marginTop: 0.1 * height,
  },
  containerNoBackground: {
    backgroundColor: design.colors.transparent,
    marginTop: 0,
  },
  modal: {
    flex: 1,
    margin: 0,
  },
  wrapper: {
    // height: 285,
  },
  slide: {
    // flex: 1,
    position: 'relative',
    padding: 20,
    marginBottom: 50,
  },
  slideContainer: {
    marginTop: 45,
  },
  headerContainer: {
    marginTop: 50,
  },
  header: {
    color: design.colors.white,
    fontSize: design.text.twenty,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  text: {
    color: design.colors.white,
    fontSize: design.text.semiReg,
    alignSelf: 'center',
    flex: 1,
  },
  numberWrapper: {
    width: 35,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: design.colors.white,
    borderRadius: 17.5,
    marginRight: 15,
    marginLeft: 20,
  },
  number: {
    fontWeight: 'bold',
    fontSize: design.text.semiReg,
  },
  imageContainer: {
    marginTop: 30,
    marginLeft: 0,
  },
  image: {
    height: 86,
    width: 86,
  },
  buttonStyle: {
    backgroundColor: design.colors.loginBtn,
  },
  activeDot: {
    borderColor: design.colors.loginBtn,
    borderWidth: 2,
    borderRadius: 6,
    backgroundColor: design.colors.loginBtn,
    width: 10,
    height: 10,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  nonActiveDot: {
    borderColor: design.colors.loginBtn,
    borderWidth: 2,
    borderRadius: 6,
    backgroundColor: 'transparent',
    width: 10,
    height: 10,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  sideBySide: {
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
  },
  sideBySideReverse: {
    flexDirection: 'row-reverse',
  },
  sideBySideBtn: {
    flexDirection: 'row',
  },
  flexBtn: {
    flex: 1,
  },
  closeContainer: {
    position: 'absolute',
    top: topPosition,
    right: 20,
    padding: 0,
    zIndex: 1000,
  },
  skip: {
    color: design.colors.white,
    fontSize: design.text.small,
    textDecorationLine: 'underline',
  },
  dotContainer: {
    flexDirection: 'row', alignSelf: 'center',
  },
});

class Tutorial extends Component {
  static justADot(isActive) {
    return <View>{Tutorial.getDot(isActive)}</View>;
  }

  static getDot(isActive) {
    if (isActive) {
      return <View style={styles.activeDot} />;
    }
    return <View style={styles.nonActiveDot} />;
  }

  constructor(props) {
    super(props);
    this.scrollView = null;
    this.swiper = null;
    this.state = {
      isLast: false,
      show: false,
    };
    this.onPress = this.onPress.bind(this);
    // this.justADot = this.justADot.bind(this);
    this.onIndexChanged = this.onIndexChanged.bind(this);
    this.onPressFinish = this.onPressFinish.bind(this);
  }

  componentDidMount() {
    this.onIndexChanged();
    this.setState({ show: true });
  }

  componentWillUnmount() {
    this.setState({ show: true });
  }

  onIndexChanged(index = 0) {
    const { slides } = this.props;
    const isLast = index === slides.length - 1;
    console.log('onIndexChanged', index, isLast);
    this.setState({
      isLast,
    });
  }

  onPress() {
    const { isLast } = this.state;
    if (isLast) {
      this.onPressFinish();
    } else {
      this.swiper.scrollBy(1, true);
    }
  }

  onPressFinish() {
    const { onFinish } = this.props;
    this.setState({ show: false });
    if (onFinish) {
      onFinish.call();
    }
  }

  onRequestClose = () => true;

  render() {
    const { slides } = this.props;
    const { show, isLast } = this.state;
    return (
      slides !== null
      && slides.length > 0
      && show && (
        <Modal
          onRequestClose={this.onRequestClose}
          backdropColor={design.colors.backdrop}
          backdropOpacity={0.95}
          onBackdropPress={this.onRequestClose}
          supportedOrientations={['portrait', 'landscape']}
          useNativeDriver
          hideModalContentWhileAnimating
          style={[styles.modal]}
          isVisible
          hasBackdrop={false}
          animationInTiming={500}
        >
          <View
            style={[styles.container, isWeb && styles.containerNoBackground]}
            onPress={this.onRequestClose}
          >
            <View>
              <StatusBar
                backgroundColor={design.colors.tutorialBg}
                barStyle="light-content"
              />
            </View>
            <TouchableOpacity
              style={styles.closeContainer}
              onPress={this.onPressFinish}
            >
              <TextSF style={styles.skip}>
                {I18n.t('Tutorial.skipSmall')}
              </TextSF>
            </TouchableOpacity>
            <Swiper
              ref={(swiper) => {
                this.swiper = swiper;
              }}
              style={styles.wrapper}
              showsButtons={false}
              loop={false}
              dot={Tutorial.getDot(false)}
              activeDot={Tutorial.getDot(true)}
              onIndexChanged={this.onIndexChanged}
            >
              {slides.map((slide, index) => (
                <ScrollView
                  key={`slide${slide.screen}${slide.orderInScreen}`}
                  style={styles.slide}
                >
                  <View style={styles.headerContainer}>
                    <TextMontserrat style={styles.header}>
                      {slide.header}
                    </TextMontserrat>
                  </View>
                  <View style={[styles.imageContainer, slide.imageContainer]}>
                    {slide.image !== '' && (
                      <Image
                        source={{ uri: slide.image }}
                        style={[styles.image, slide.imageStyle]}
                      />
                    )}
                  </View>

                  <View style={[styles.slideContainer, slide.slideContainer]}>
                    {slide.text.map((txt, i) => {
                      const isRTl = config.detectRtlOnText && helper.isRTL(txt);
                      return (
                        <View
                          key={txt}
                          style={[
                            styles.sideBySide,
                            isRTl && styles.sideBySideReverse,
                          ]}
                        >
                          <View style={styles.numberWrapper}>
                            <TextSF style={styles.number}>{i + 1}</TextSF>
                          </View>
                          <TextSF style={styles.text}>{txt}</TextSF>
                        </View>
                      );
                    })}
                  </View>
                  {isWeb && (
                    <View style={styles.dotContainer}>
                      {slides.map((s, dotIndex) => {
                        if (dotIndex === index) {
                          return Tutorial.getDot(true);
                        }
                        return Tutorial.getDot(false);
                      })}
                    </View>
                  )}
                </ScrollView>
              ))}
            </Swiper>
            {isLast === true ? (
              <ButtonFull
                buttonStyle={styles.buttonStyle}
                onPress={this.onPress}
                textStyle={styles.btnText}
                title={I18n.t('Tutorial.finish')}
              />
            ) : (
              <View style={styles.sideBySideBtn}>
                <ButtonFull
                  buttonStyle={[styles.buttonStyle]}
                  containerStyle={styles.flexBtn}
                  onPress={this.onPress}
                  textStyle={styles.btnText}
                  title={I18n.t('Tutorial.next')}
                />
              </View>
            )}
          </View>
        </Modal>
      )
    );
  }
}

export default Tutorial;
