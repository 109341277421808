/**
 * # BackendFactory
 *
 * This class sets up the backend by checking the config.js
 *
 */

// import CONFIG from './config';
import { parse } from './Parse';

export default function BackendFactory(token = null) {
  parse.initialize(token);
  return parse;
}
