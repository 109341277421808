/* eslint-disable no-restricted-globals */
import React, { PureComponent } from 'react';
import {
  View, StyleSheet, TouchableOpacity, Image,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialIcons';
import MediaQuery from '../../src/components/general/MediaQuery';
import ActionGroup from '../../src/components/general/ActionGroup';

import { config, getServerConfig } from '../../src/lib/config';
import { alert } from '../../src/containers/global/GlobalAlert';
import GlobalSideMenu from '../../src/containers/global/GlobalSideMenu';
import {
  design, I18n, helper, global,
} from '../../src/lib/general';
import { globalActions, authActions } from '../../src/reducers/actions';
import userUpdateHelper from '../../src/lib/userUpdateHelper';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...globalActions,
        ...authActions,
      },
      dispatch,
    ),
  };
}

function mapStateToProps(state) {
  return {
    globalState: {
      currentUser: state.global.currentUser,
      isWeb: state.global.isWeb,
      isDesktop: state.global.isDesktop,
    },
    patient: {
      serverConfig: state.patient.serverConfig,
    },
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: design.colors.baseGray,
  },
  main: {
    flex: 1,
  },

  header: {
    padding: 15,
    height: 60,
    zIndex: 100,
    backgroundColor: design.colors.white,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  headImage: {
    width: '100%',
    maxWidth: 400,
    height: 30,
  },
  imageNarrow: {
    width: 300,
  },
  imageCenter: {
    alignSelf: 'center',
  },
  leftContainer: {
    alignSelf: 'center',
    flex: 1,
    height: 30,
  },
  imageMidContainer: {
    alignSelf: 'center',
    height: 30,
    flex: 1,
    alignContent: 'center',
  },
  rightContainer: {
    alignSelf: 'center',
    height: 30,
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
});
let exportWebFrame = null;
class WebFrame extends PureComponent {
  constructor() {
    super();
    exportWebFrame = this;
    this.state = {
      open: false,
      openSideMenu: false,
    };
  }

  hideMenu = () => {
    setTimeout(() => {
      this.setState({ open: false });
    });
  };

  showMenu = () => {
    this.setState({ open: true });
  };

  toggleMenu = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  toggleSideMenu = () => {
    const { openSideMenu } = this.state;
    this.setState({ openSideMenu: !openSideMenu });
  };

  hideSideMenu = () => {
    this.setState({ openSideMenu: false });
  };

  showSideMenu = () => {
    this.setState({ openSideMenu: true });
  };

  logout = () => {
    const { actions } = this.props;
    this.hideMenu();
    actions.logout();
  };

  clickOnImage = () => {
    const { patient } = this.props;
    const isPatient = location.href.indexOf('ClinicTab') > -1;
    if (
      isPatient
      && userUpdateHelper?.checkUserUpdatesForChanges(patient.currUpdates)
      && !patient.readOnly
    ) {
      alert.alert(
        I18n.t('Patient.submitConfirmHeader'),
        I18n.t('Patient.patientBackBtnMsg'),
        [
          {
            text: I18n.t('Patient.cancel'),
            onPress: () => console.log('submitEncounter Cancel Pressed!'),
          },
          {
            text: I18n.t('Patient.confirm'),
            onPress: () => {
              location.pathname = '/';
            },
          },
        ],
      );
    } else {
      location.pathname = '/';
    }
  };

  render() {
    const {
      children,
      patient,
      location,
      globalState,
      actions,
      ...rest
    } = this.props;
    const { isDesktop } = globalState;
    const { openSideMenu } = this.state;
    const pathName = helper.getLastSegmentFromString(location.pathname, '/');
    const serverConfig = getServerConfig();
    const { hideTopBar } = config;
    const hideHeader = global.NO_HEADER_SCREENS.indexOf(pathName) > -1 || hideTopBar;
    return (
      <GlobalSideMenu
        isOpen={openSideMenu}
        hideMenu={this.hideSideMenu}
        toggleMenu={this.toggleSideMenu}
        isDesktop={isDesktop}
      >
        <ActionGroup disabled={!isDesktop} />
        <View style={styles.container} activeOpacity={1}>
          {!hideHeader && (
            <MediaQuery minWidth={global.mobileMaxWidth}>
              <View style={styles.header}>
                <TouchableOpacity onPress={this.toggleSideMenu}>
                  <Icon
                    name={openSideMenu ? 'close' : 'menu'}
                    size={design.text.twentyEight}
                    color={design.colors.infoBlack}
                  />
                </TouchableOpacity>
                <View style={styles.leftContainer} />
                <View style={styles.imageMidContainer}>
                  {serverConfig.webDesktopMiddleLogoUrl
                    && serverConfig.webDesktopMiddleLogoUrl !== '' && (
                      <Image
                        style={[styles.headImage, styles.imageCenter]}
                        source={serverConfig.webDesktopMiddleLogoUrl}
                        resizeMode="contain"
                      />
                  )}
                </View>
                <View style={styles.rightContainer}>
                  {serverConfig.webDesktopLogoUrl
                    && serverConfig.webDesktopLogoUrl !== '' && (
                      <TouchableOpacity onPress={this.clickOnImage}>
                        <Image
                          style={[styles.headImage, styles.imageNarrow]}
                          source={serverConfig.webDesktopLogoUrl}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                  )}
                </View>
              </View>
            </MediaQuery>
          )}
          <View
            style={[
              styles.main,
              config.shouldForceMaxWidthMobileDesktop
              && isDesktop
              && styles.mainForcedDesktop,
            ]}
          >
            {children.map((child) => React.cloneElement(child), rest)}
          </View>
        </View>
      </GlobalSideMenu>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebFrame);
export { exportWebFrame };
