// placeholder for web
export class Push {
  registerMyIdToOneSignalPushOnLogin() {
  }

  // use this on logout
  unregisterMyIdToOneSignalPushOnLogout() {

  }
}
// The singleton variable
export const push = new Push();
