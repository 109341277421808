import React, { useEffect, useState } from 'react';
import {
  View,
  StatusBar,
  Platform,
  ScrollView,
  ActivityIndicator,
  Image,
} from 'react-native';

import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-native-modal';
import HoverView from '../../general/hover/HoverTouchableOpacity';
import memory from '../../../lib/Memory';
import { toast } from '../../general/Toast';
import {
  design, I18n, TextMontserrat, router, icons,
} from '../../../lib/general';
import { getServerConfig } from '../../../lib/config';
import styleFunction from './style';
import {
  authActions, profileActions, globalActions, clinicActions, patientActions,
} from '../../../reducers/actions';
import { sessionMemory } from '../../../lib/sessionMemory';
import { loadTranslationFromAPi } from '../../../lib/i18n';

const allowRouteArray = ['ClinicTab', 'App'];

export default function CampaignSelection() {
  const isWeb = Platform.OS === 'web';
  const [loadingSelectedCampaign, setLoadingSelectedCampaign] = useState(false);
  const [CurrentCampaignsState, setCurrentCampaignsState] = useState({});
  const dispatch = useDispatch();

  const { isChangeCampaignEnabled, campaignPopupTimeInMillisecond } = getServerConfig();

  const routes = useSelector((s) => s.routes.currentRouteName);
  const showCampaignSelection = useSelector((s) => s.global.showCampaignSelection);
  const isDesktop = useSelector((s) => s.global.isDesktop);
  const activeCampaigns = useSelector((s) => s.auth.form.activeCampaignList) || [];
  const selectedCampaign = useSelector((s) => s.profile.form.fields.activeCampaign || s.profile.form.fields.selectedCampaign) || false;

  const styles = styleFunction(design, isDesktop);
  const updateCampaignChangedTime = async () => memory.setItem('campaignChanged', Date.now());
  const RouteBackToClinic = () => router.reset(!isWeb ? 'ClinicTabs' : 'App');

  const checkInnerTrigger = async (activeCampaignIds) => {
    if (isChangeCampaignEnabled && allowRouteArray.includes(routes)) {
      const timeToCheckInHour = campaignPopupTimeInMillisecond || (24 * 60) * 60 * 1000; // 1 day
      const currTime = Date.now();
      const campaignChangedStorage = await memory.getItem('campaignChanged');
      if (campaignChangedStorage) {
        if (((currTime) - campaignChangedStorage) < timeToCheckInHour) { // check if time past from last time by timeToCheck const
          dispatch(globalActions.toggleCampaignSelector(false));
          RouteBackToClinic();
        } else {
          setCurrentCampaignsState(selectedCampaign);
          if (activeCampaigns.length === 1) { // Check if different from user current campaign
            if (activeCampaignIds[0] !== selectedCampaign) {
              await memory.setItem('campaignChanged', currTime);
              dispatch(globalActions.toggleCampaignSelector(activeCampaignIds[0] !== selectedCampaign));
            }
          } else {
            await memory.setItem('campaignChanged', currTime);
            dispatch(globalActions.toggleCampaignSelector(true));
          }
        }
      } else {
        dispatch(globalActions.toggleCampaignSelector(true));
        updateCampaignChangedTime();
      }
    }
  };

  useEffect(() => {
    const { getActiveCampaign } = authActions;
    dispatch(getActiveCampaign());
  }, []);

  useEffect(() => {
    if (!loadingSelectedCampaign && selectedCampaign) {
      if (showCampaignSelection) {
        setCurrentCampaignsState(selectedCampaign);
      } else {
        const activeCampaignIds = activeCampaigns.map((camp) => camp.objectId);
        if (activeCampaignIds.length) checkInnerTrigger(activeCampaignIds);
      }
    }
  }, [activeCampaigns, selectedCampaign, showCampaignSelection]);

  const onRequestClose = () => {};

  const changeUserCampaign = (objectId) => {
    if (objectId !== selectedCampaign) {
      setLoadingSelectedCampaign(true);
      setCurrentCampaignsState(objectId);
      dispatch(profileActions.setActiveCampaign(objectId));
      sessionMemory.setItem('campId', objectId);
      loadTranslationFromAPi();
      dispatch(profileActions.updateUserProfile({
        activeCampaign: objectId,
      })).then(() => {
        toast.alertWithType(
          'success',
          I18n.t('Profile.updateUserProfileSuccess'),
          I18n.t('Profile.updateUserProfileSuccessBody'),
        );
        dispatch(clinicActions.getAllScenarios());
        dispatch(globalActions.toggleCampaignSelector(false));
        dispatch(patientActions.getGlobal());
        setLoadingSelectedCampaign(false);
        RouteBackToClinic();
      });
    } else {
      setLoadingSelectedCampaign(false);
      dispatch(globalActions.toggleCampaignSelector(false));
      RouteBackToClinic();
    }
    updateCampaignChangedTime();
  };

  const CampaignItem = ({ data }) => (
    <HoverView
      disable={loadingSelectedCampaign}
      onPress={() => changeUserCampaign(data.objectId)}
      style={[styles.campaignCardDeskTop,
        CurrentCampaignsState === data.objectId && styles.selectedCampaign,
      ]}
    >
      <View style={styles.bottomCardWrap}>
        <Image
          source={data.iconURL || icons.referral}
          style={styles.creditImageStyle}
          resizeMode="contain"
        />
        <View style={styles.cardTextWrap}>
          <TextMontserrat style={styles.campaignName}>{data.name}</TextMontserrat>
        </View>
      </View>
    </HoverView>
  );

  return (
    <Modal
      onRequestClose={onRequestClose}
      backdropColor={design.colors.backdrop}
      backdropOpacity={0.95}
      onBackdropPress={onRequestClose}
      supportedOrientations={['portrait', 'landscape']}
      useNativeDriver
      hideModalContentWhileAnimating
      style={[styles.modal]}
      isVisible={showCampaignSelection}
      hasBackdrop={false}
      animationInTiming={500}
    >
      <View style={[styles.container, isWeb && styles.containerNoBackground]}>
        <View style={styles.slideWrapper}>
          <View>
            <StatusBar
              backgroundColor={design.colors.tutorialBg}
              barStyle="light-content"
            />
          </View>
          <View style={styles.headerContainer}>
            {I18n.t('Campaign.campaignModalHeader')
            && I18n.t('Campaign.campaignModalHeader').length > 0
            && <TextMontserrat style={styles.header}>{I18n.t('Campaign.campaignModalHeader')}</TextMontserrat>}
            {I18n.t('Campaign.campaignModalSubHeader')
            && I18n.t('Campaign.campaignModalSubHeader').length > 0
            && (
            <TextMontserrat style={styles.subHeader}>
              {I18n.t('Campaign.campaignModalSubHeader')}
            </TextMontserrat>
            )}
          </View>
          <ScrollView contentContainerStyle={styles.scrollViewStyle}>
            <View style={styles.deskTopScrollInnerWrap}>
              {activeCampaigns.map((campaign, i) => (
                <CampaignItem
                  data={campaign}
                  i={i}
                  isLast={activeCampaigns.length - 1}
                  key={`campaign_${campaign.objectId}`}
                />
              ))}
              {loadingSelectedCampaign && (
              <View style={styles.indicatorWrap}>
                <ActivityIndicator size="small" />
              </View>
              )}
            </View>
          </ScrollView>

        </View>
      </View>
    </Modal>
  );
}
