import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import React, { Component } from 'react';
import NetInfo from '@react-native-community/netinfo';
// import codePush from 'react-native-code-push';
import { I18n } from '../../lib/general';
import { globalActions, patientActions } from '../../reducers/actions';
import Alert from '../../components/general/Alert';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...globalActions,
        ...patientActions,
      },
      dispatch,
    ),
  };
}
class GlobalNetwork extends Component {
  constructor() {
    super();
    this.handleFirstConnectivityChange = this.handleFirstConnectivityChange.bind(this);
    this.Alert = null;
    this.unsubscribe = null;
    this.afterOnline = false;
  }

  componentDidMount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.unsubscribe = NetInfo.addEventListener(this.handleFirstConnectivityChange);
    // setTimeout(this.handleFirstConnectivityChange, 5000);
  }

  handleFirstConnectivityChange(state) {
    const { isConnected } = state;
    // console.log(state, `Then, is ${isConnected ? 'online' : 'offline'}`);
    // const that = this
    if (!isConnected && this.Alert) {
      this.afterOnline = true;
      this.Alert.alert(I18n.t('Global.NetworkOfflineHead'), I18n.t('Global.NetworkOfflineSub'), [
        {
          text: I18n.t('Patient.restartApp'),
          onPress: () => {
            // restart the app
            setTimeout(() => {
              window.location.reload();
              // codePush.restartApp();
            }, 1000);
          },
        },
        {
          text: I18n.t('Patient.close'),
          onPress: () => {},
        },
      ]);
    } else if (this.afterOnline) { // this.afterOnline means that it will only happens after first time offline
      // console.log('GlobalNetwork', 'offline and this.afterOnline');
      this.afterOnline = false;
      const { actions } = this.props;
      actions.getGlobal();
      this?.Alert?.close();
    }
  }

  render() {
    // virtual for events only
    return (
      <Alert
        ref={(alert) => {
          this.Alert = alert;
        }}
        onBackdropPress={() => {}}
      />
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalNetwork);
