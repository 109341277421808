import { Record } from 'immutable';

const InitialState = Record(
  {
    isPaused: null,
    receivePush: null,
    objectId: null,
    allowFastForward: null,
  },
  'settings',
);

export default InitialState;
