import React from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import MediaQuery from '../components/general/MediaQuery';
import { global } from '../lib/general';
// import config from '../lib/config';

const styles = StyleSheet.create({
  mainForcedDesktop: {
    width: global.mobileMaxWidth + 1,
    alignSelf: 'center',
    flex: 1,
  },
});

function withMaxWidthCenter(WrappedComponent, props = {}) {
  return (
    <MediaQuery
      maxWidth={global.mobileMaxWidth}
    >
      {(matches) => (
        !matches ? (
          <View style={styles.mainForcedDesktop}>
            <WrappedComponent {...props} />
          </View>
        ) : <WrappedComponent {...props} />
      )}
    </MediaQuery>
  );
}

export default withMaxWidthCenter;
