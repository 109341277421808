import React from 'react';
import { TextInput, StyleSheet, View } from 'react-native';
import helper from '../../lib/helper';
import { config } from '../../lib/config';
import { defaultStyle, TextSF, design } from '../../lib/general';

const styles = StyleSheet.create({
  rtl: {
    textAlign: 'right',
  },
  err: {
    color: design.colors.errRed,
    fontSize: design.text.microPlus,
    marginTop: 3,
    marginBottom: 6,
    // marginLeft: 15,
    fontWeight: '500',
  },
});
const { isWeb } = config;
const TextInputWithRTL = (props) => {
  const {
    value, placeholder, style, containerStyle, error, ...rest
  } = props;
  // console.log('TextSF selected font', fontFamily);
  let shouldRTL = false;
  if (config.detectRtlOnText) {
    if (!value || value === '') {
      shouldRTL = helper.isRTL(placeholder);
    } else {
      shouldRTL = helper.isRTL(value);
    }
  }
  return (
    <View style={containerStyle}>
      <TextInput
        value={value}
        placeholder={placeholder}
        style={[
          style,
          shouldRTL && styles.rtl,
          isWeb && shouldRTL && defaultStyle.webRTLFontFamily,
        ]}
        {...rest}
      />
      {error !== '' && <TextSF style={styles.err}>{error}</TextSF>}
    </View>

  );
};
export default TextInputWithRTL;
