

const {
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_UNREAD,
} = require('../../lib/constants').default;

/**
 * ## Initial State
 *
 */
const InitialState = require('./notificationInitialState').default;

const initialState = new InitialState();

/**
 * ## profileReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function settingsReducer(state = initialState, action) {
  //  let nextProfileState = null

  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    // case SETTINGS_UPDATE_SUCCESS:
    case SET_NOTIFICATIONS:
      return state.set('notifications', action.payload);

    case SET_NOTIFICATIONS_UNREAD:
      return state.set('unRead', action.payload);
  } // switch
  /**
   * # Default
   */
  return state;
}
