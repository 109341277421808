import { StyleSheet, Platform, Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');
const isWeb = Platform.OS === 'web';

export default function Styles(design, isDesktop) {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: 'rgba(57, 67, 91, 0.95)',
      padding: 20,
      paddingVertical: 60,

    },
    containerNoBackground: {
      backgroundColor: design.colors.transparent,
      marginTop: 0,
    },
    modal: {
      flex: 1,
      margin: 0,
    },
    headerContainer: {
      marginBottom: 30,
    },
    header: {
      color: design.colors.clinicHeader,
      fontSize: isDesktop ? design.text.twentyFive : design.text.twenty,
      alignSelf: 'center',
      fontWeight: 'bold',
    },
    subHeader: {
      color: design.colors.clinicHeader,
      fontSize: isDesktop ? design.text.semiReg : design.text.small,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginBottom: 20,
      marginLeft: 0,
    },
    campaignsWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      // justifyContent: 'space-between',
    },
    lastCampaignCard: {
      // marginRight: 'auto',
    },
    scrollViewStyle: {
      padding: 5,
      // borderRadius: 3,
      // maxHeight: isDesktop ? height * 0.5 : height * 0.4,
    },
    scrollViewContentStyle: {
      justifyContent: 'center',
    },
    deskTopScrollInnerWrap: {
      position: 'relative',
      flexDirection: 'column',
      flex: 1,
      flexWrap: 'wrap',
    },
    indicatorWrap: {
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255,.04)',
      left: 0,
      top: 0,
      flex: 1,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeCampaignStyle: {
      margin: 10,
      padding: 5,
      paddingBottom: 15,
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      borderRadius: 3,
      shadowOffset: { height: 0, width: 0 },
    },
    campaignsWrapDesktop: {
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      justifyContent: 'space-between',

    },
    campaignCardMobile: {
      marginBottom: 10,
      marginTop: 10,
      width: '100%',
      padding: 15,
      backgroundColor: design.colors.loginBtn,
      borderRadius: 3,
      shadowOffset: { height: 0, width: 0 },
    },
    campaignCardDeskTop: {
      width: '100%',
      paddingTop: 16,
      paddingBottom: 16,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      borderBottomWidth: 2,
      borderBottomColor: '#E7EBEE',
    },
    bottomCardWrap: {
      flex: 1,
      width: isDesktop ? '30%' : '90%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
    },
    selectedCampaign: {
      backgroundColor: '#E7EBEE',
    },
    image: {
      height: 100,
      width: '100%',
    },
    buttonStyle: {
      backgroundColor: design.colors.loginBtn,
      padding: 20,
    },
    imageStyle: {
      width: 18,
      height: 12,
    },
    sideBySide: {
      marginTop: 20,
      marginBottom: 20,
    },
    slideWrapper: {
      backgroundColor: design.colors.white,
      paddingTop: 80,
      paddingBottom: 80,
      borderRadius: 3,
      position: 'relative',
      borderBottomWidth: 20,
      borderBottomColor: '#A2E1FF',
    },
    dividerCard: {
      marginTop: 10,
      marginBottom: 10,
    },
    creditImageStyle: {
      width: 20,
      height: 20,
    },
    bottomCardWrapNoImage: {
      flex: 0.6,
      justifyContent: 'center',
      flexDirection: 'row',
    },
    cardTextWrap: {
      alignItems: 'flex-start',
      alignContent: 'center',
      justifyContent: 'flex-start',
      flex: 1,
      paddingLeft: 20,
    },
    campaignName: {
      writingDirection: 'ltr',
      textAlign: 'left',
      // color: design.colors.clinicHeader,
      fontSize: isDesktop ? design.text.twenty : design.text.semiReg,
      fontWeight: '400',
      color: '#39435B',

    },
    brandSlogan: {
      color: design.colors.clinicHeader,
      fontSize: design.text.semiReg,
      alignSelf: 'flex-start',
    },
    deskTopCardWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      flexDirection: 'row',
    },
  });
}
