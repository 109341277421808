/**
 * # globalInitialState.js
 *
 * This class is a Immutable object
 * Working *successfully* with Redux, requires
 * state that is immutable.
 * In my opinion, that can not be by convention
 * By using Immutable, it's enforced.  Just saying....
 *
 */

/**
 * ## Import
 */
import { Record } from 'immutable';

/**
 * ## InitialState
 *
 * * currentUser - object returned from server when validated
 * * showState - toggle for Header to display state
 * * currentState - object in Json format of the entire state
 * * showCampaignSelection - is campaign modal is visible
 * * store - the Redux store which is an object w/ 4 initial states
 *   * device
 *   * auth
 *   * global
 *   * profile
 *
 */
const InitialState = Record(
  {
    currentUser: null,
    showState: false,
    currentState: null,
    store: null,
    intro: false,
    showGlobalAlert: false,
    globalAlertMessageHead: '',
    globalAlertMessageSub: '',
    env: null,
    applicationEnvironment: null,
    isWeb: false,
    isDesktop: false,
    showCampaignSelection: false,
    showTutorial: false,
    showGlobalTutorialIcon: false,
    showClinicalHelpIcon: false,
    clinicalHelpVisible: false,
    isTablet: false,
  },
  'global',
);
export default InitialState;
