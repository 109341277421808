import { Record } from 'immutable';


const InitialState = Record(
  {
    faqs: [],
  },
  'faq',
);

export default InitialState;
