import { Platform } from 'react-native';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';

import { appAuthToken } from '../../lib/AppAuthToken';
import { push } from '../../lib/Push';
import memory from '../../lib/Memory';
import { analytics } from '../../lib/analytics';
import { router, helper } from '../../lib/general';
import * as settingsActions from '../settings/settingsActions';
import * as profileActions from '../profile/profileActions';
import * as patientActions from '../patient/patientActions';
import * as clinicActions from '../clinic/clinicActions';
import clinicalApi from '../../lib/ClinicalApi';
import retentionService from '../../lib/RetentionService';
import { config } from '../../lib/config';
import navigator from '../../lib/router/navigator';
import store from '../store';
import { loadTranslationFromAPi } from '../../lib/i18n';
// import { signOutAll } from '../../containers/auth/AzureAD';
// import * as notificationActions from '../notification/notificationActions'

let timeout = null;
const {
  SESSION_TOKEN_REQUEST,
  SESSION_TOKEN_SUCCESS,
  SESSION_TOKEN_FAILURE,

  DELETE_TOKEN_REQUEST,
  DELETE_TOKEN_SUCCESS,

  LOGOUT,
  REGISTER,
  LOGIN,
  FORGOT_PASSWORD,

  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  ON_AUTH_FORM_FIELD_CHANGE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,

  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,

  SET_VALUE_FORM,
  SET_COMPLETE_FORM,

  GET_SPECIALTY_REQUEST,
  GET_SPECIALTY_SUCCESS,
  GET_SPECIALTY_FAILURE,

  GET_EDUCATION_REQUEST,
  GET_EDUCATION_SUCCESS,
  GET_EDUCATION_FAILURE,

  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,

  GET_PASS_REGEX_REQUEST,
  GET_PASS_REGEX_SUCCESS,
  GET_PASS_REGEX_FAILURE,

  GET_FIREBASE_REQUEST,
  GET_FIREBASE_SUCCESS,
  GET_FIREBASE_FAILURE,
  SET_FIRST_TIME,
  CHECK_USER_INVITE_REQUEST,
  CHECK_USER_INVITE_SUCCESS,
  CHECK_USER_INVITE_FAILURE,
  CHECK_USER_EMAIL_EXISTS_REQUEST,
  CHECK_USER_EMAIL_EXISTS_SUCCESS,
  CHECK_USER_EMAIL_EXISTS_FAILURE,

  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_REQUEST,
  GET_CAMPAIGN_FAILURE,
  DELETE_FIREBASE_SUCCESS,
  GET_CONFIG_REQUEST,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
  GET_ON_BOARDING_REQUEST,
  GET_ON_BOARDING_SUCCESS,
  GET_ON_BOARDING_FAILURE,
  GET_YEARS_PRACTICE_REQUEST,
  GET_YEARS_PRACTICE_SUCCESS,
  GET_YEARS_PRACTICE_FAILURE,
  GET_TUTORIAL_REQUEST,
  GET_TUTORIAL_SUCCESS,
  GET_TUTORIAL_FAILURE,
  IS_PROJECT_OVER_SUCCESS,
  IS_PROJECT_OVER_REQUEST,
  IS_PROJECT_OVER_FAILURE,
  GET_ACTIVE_CAMPAIGN_REQUEST,
  GET_ACTIVE_CAMPAIGN_SUCCESS,
  GET_ACTIVE_CAMPAIGN_FAILURE,
  GET_TREATMENT_DURATION_REQUEST,
  GET_TREATMENT_DURATION_SUCCESS,
  GET_TREATMENT_DURATION_FAILURE,
  GET_LICENSE_REQUEST,
  GET_LICENSE_SUCCESS,
  GET_LICENSE_FAILURE,
  PROVIDER_CONNECT_SUCCESS,
  PROVIDER_CONNECT_REQUEST,
  PROVIDER_CONNECT_FAILURE,
  SET_URL_PARAMS,
  GET_MY_PROMOTIONS_REQUEST,
  GET_MY_PROMOTIONS_FAILURE,
  GET_MY_PROMOTIONS_SUCCESS,
  SINGLE_SIGN_ON_SUCCESS,
  SINGLE_SIGN_ON_REQUEST,
  SINGLE_SIGN_ON_FAILURE,
  LOADER_HIDE,
  LOADER_SHOW,
  DYNAMIC_SSO_SUCCESS,
  DYNAMIC_SSO_REQUEST,
  DYNAMIC_SSO_FAILURE,
  GET_COUNTRY_BY_IP_REQUEST,
  GET_COUNTRY_BY_IP_SUCCESS,
  GET_COUNTRY_BY_IP_FAILURE,
} = require('../../lib/constants').default;

/**
 * Project requirements
 */
const BackendFactory = require('../../lib/BackendFactory').default;

/**
 * ## State actions
 * controls which form is displayed to the user
 * as in login, register, logout or reset password
 */
const isWeb = Platform.OS === 'web';
export function logoutState() {
  return { type: LOGOUT };
}
export function registerState() {
  return { type: REGISTER };
}

export function loginState() {
  return { type: LOGIN };
}

export function forgotPasswordState() {
  return { type: FORGOT_PASSWORD };
}

export function getCountryByIpRequest() {
  return { type: GET_COUNTRY_BY_IP_REQUEST };
}
export function getCountryByIpSuccess(payload) {
  return { type: GET_COUNTRY_BY_IP_SUCCESS, payload };
}
export function getCountryByIpFailure(json) {
  return { type: GET_COUNTRY_BY_IP_FAILURE, payload: json };
}
/**
 * ## Logout actions
 */
export function logoutRequest() {
  return { type: LOGOUT_REQUEST };
}

export function logoutSuccess() {
  return { type: LOGOUT_SUCCESS };
}
export function logoutFailure(error) {
  return { type: LOGOUT_FAILURE, payload: error };
}

/**
 * ## DeleteToken actions
 */
export function deleteTokenRequest() {
  return { type: DELETE_TOKEN_REQUEST };
}
export function deleteTokenRequestSuccess() {
  return { type: DELETE_TOKEN_SUCCESS };
}

export function deleteFirebaseTokenSuccess() {
  return { type: DELETE_FIREBASE_SUCCESS };
}

/**
 * ## Delete session token
 *
 * Call the AppAuthToken deleteSessionToken
 */
export function deleteSessionToken() {
  return async (dispatch) => {
    dispatch(deleteTokenRequest());
    await appAuthToken.deleteSessionToken();
    return dispatch(deleteTokenRequestSuccess());
  };
}

export function deleteFirebaseToken() {
  appAuthToken.deleteFirebaseToken();
  return (dispatch) => dispatch(deleteFirebaseTokenSuccess());
}

export function deleteUrlParameters() {
  console.log('deleteUrlParameters');
  return {
    type: SET_URL_PARAMS,
    payload: null,
  };
}

export function onSetValueForm(parent, field, value) {
  return {
    type: SET_VALUE_FORM,
    payload: {
      parent,
      field,
      value,
    },
  };
}

export function onSetCompleteForm(parent, values) {
  return {
    type: SET_COMPLETE_FORM,
    payload: {
      parent,
      values,
    },
  };
}

export function setValueForm(parent, field, value) {
  return (dispatch) => {
    dispatch(onSetValueForm(parent, field, value));
  };
}

export function setCompleteForm(parent, values) {
  return (dispatch) => {
    dispatch(onSetCompleteForm(parent, values));
  };
}

export function getMyCampaignRequest(json) {
  return { type: GET_CAMPAIGN_REQUEST, payload: json };
}

export function getMyCampaignFailure(json) {
  return { type: GET_CAMPAIGN_FAILURE, payload: json };
}

export function getMyCampaignSuccess(json) {
  return { type: GET_CAMPAIGN_SUCCESS, payload: json };
}

export function getMyCampaign() {
  return (dispatch) => {
    dispatch(getMyCampaignRequest());
    return BackendFactory()
      .getMyCampaign()
      .then((data) => {
        if (data.result.length > 0) {
          const { campaign } = data.result[0];
          dispatch(getMyCampaignSuccess(campaign));
        } else {
          dispatch(getMyCampaignSuccess(null));
        }
        return true;
      })
      .catch((error) => {
        dispatch(getMyCampaignFailure(error));
        return false;
      });
  };
}

export function registerUserToServicesLogin(j, from) {
  return (dispatch) => {
    const json = j.sessionToken ? j.sessionToken : j; // to make it compatible from login and signup
    let objectId;
    if (json?.isAnonymous || json?.result?.isAnonymous) {
      setTimeout(() => {
        dispatch(settingsActions.updateTimeZone());
      }, 1000);

      // dispatch(getFirebaseToken());
      dispatch(profileActions.getProfile());
      // dispatch(patientActions.getGlobal());
      dispatch(getMyCampaign());
      return;
    }
    if (json.objectId) {
      ({ objectId } = json);
    } else if (json.result) {
      ({ objectId } = json.result);
    } else {
      objectId = null;
    }
    const {
      fullName, email, workplace, specialty, education, yearsInPractice, activeCampaign,
    } = json?.result;
    const isEdocate = email?.toLowerCase().includes('@edocate.com');
    if (objectId) {
      push.registerMyIdToOneSignalPushOnLogin(objectId);
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => { analytics.setUserIdentifier(objectId, isEdocate, activeCampaign); }, 10000);
    }
    if (fullName) {
      // analytics.setUserName(fullName);
      analytics.setInstabugDefaultMailAndUser(email, fullName);
    }
    // no need to check, validation in method

    analytics.setUserProperty('workplace', workplace);
    analytics.setUserProperty('specialty', specialty);
    analytics.setUserProperty('education', education);
    analytics.setUserProperty('yearsInPractice', yearsInPractice);
    analytics.setUserProperty('after_register', true);
    analytics.setUserProperty('activeCampaign', activeCampaign);
    if (from === 'login') {
      analytics.logLogin('email', true);
      retentionService.cancelUserDidNotFinishRegistration();
    } else if (from === 'signup') {
      retentionService.cancelUserDidNotFinishRegistration();
      retentionService.setUserDidNotFinishCase(); // cases are added automatically
      analytics.logSignUp('email', true);
    }
    // debugger
    setTimeout(() => {
      dispatch(settingsActions.updateTimeZone());
    }, 1000);

    // dispatch(getFirebaseToken());
    dispatch(profileActions.getProfile());
    // dispatch(patientActions.getGlobal());
    dispatch(getMyCampaign());
  };
}

/**
 * ## Login
 * After dispatching the logoutRequest, get the sessionToken
 *
 *
 * When the response is received and it's valid
 * change the state to register and finish the logout
 *
 * But if the call fails, like expired token or
 * no network connection, just send the failure
 *
 * And if you fail due to an invalid sessionToken, be sure
 * to delete it so the user can log in.
 *
 * How could there be an invalid sessionToken?  Maybe they
 * haven't used the app for a long time.  Or they used another
 * device and logged out there.
 */
export function logout() {
  let tempToken;
  return (dispatch) => {
    dispatch(logoutRequest());
    // this is example of how to retrive session token in all the app, it actually
    // retuen current User

    return appAuthToken
      .getSessionToken()
      .then((token) => {
        tempToken = token;
        return BackendFactory(token).logout();
      })
      .then(() => {
        dispatch(loginState());
        dispatch(logoutSuccess());
        dispatch(deleteSessionToken());
        dispatch(deleteFirebaseToken());
        dispatch(deleteUrlParameters());
        navigator.setUrlParameters('');
        dispatch(clinicActions.clearClinic());
        push.unregisterMyIdToOneSignalPushOnLogout(tempToken.sessionToken.result.objectId);
        // Actions.InitialLoginForm()
        if (config.azureLoginOnly) {
          router.goToPage('AzureADLogout');
          return;
        }
        if (isWeb) {
          if (config.allowEdocateRegistration) {
            setTimeout(() => {
              helper.safeWindowReloadWithParameters();
            }, 1000);
            // window.location.reload();
          } else if (config.showLoginOnly) {
            router.goToPage('Login');
          } else {
            router.goToPage('Logout');
          }
        } else if (config.newAuthPages) {
          router.init('Login');
        } else {
          router.init('Auth');
        }
      })
      .catch((error) => {
        dispatch(loginState());
        dispatch(logoutFailure(error));
        //  ziv added remove even if fail
        dispatch(deleteSessionToken());
        navigator.setUrlParameters('');
        // Actions.InitialLoginForm()
        if (config.azureLoginOnly) {
          router.goToPage('AzureADLogout');
          return;
        }
        if (isWeb) {
          if (config.allowEdocateRegistration) {
            helper.safeWindowReloadWithParameters();
          } else if (config.showLoginOnly) {
            router.goToPage('Login');
          } else {
            router.goToPage('Logout');
          }
        } else if (config.newAuthPages) {
          router.init('Login');
        } else {
          router.init('Auth');
        }
      });
  };
}

/**
 * ## onAuthFormFieldChange
 * Set the payload so the reducer can work on it
 */
export function onAuthFormFieldChange(field, value) {
  return {
    type: ON_AUTH_FORM_FIELD_CHANGE,
    payload: {
      field,
      value,
    },
  };
}
/**
 * ## Signup actions
 */
export function signupRequest() {
  return { type: SIGNUP_REQUEST };
}
export function signupSuccess(json) {
  return { type: SIGNUP_SUCCESS, payload: json };
}
export function signupFailure(error) {
  return { type: SIGNUP_FAILURE, payload: error };
}
/**
 * ## SessionToken actions
 */
export function sessionTokenRequest() {
  return { type: SESSION_TOKEN_REQUEST };
}
export function sessionTokenRequestSuccess(token) {
  return { type: SESSION_TOKEN_SUCCESS, payload: token };
}
export function sessionTokenRequestFailure(error) {
  return {
    type: SESSION_TOKEN_FAILURE,
    payload: isUndefined(error) ? null : error,
  };
}

/**
 * ## saveSessionToken
 * @param {Object} response - to return to keep the promise chain
 * @param {Object} json - object with sessionToken
 */
export function saveSessionToken(json) {
  return appAuthToken.storeSessionToken(json);
}

export function anonymousLogin() {
  const state = store.getState();
  const activeCampaign = state.profile.form.activeCampaign || state.profile.form.fields.activeCampaign;
  return (dispatch) => {
    dispatch(signupRequest());
    return BackendFactory()
      .anonymousLogin({ activeCampaign })
      .then((json) => saveSessionToken(
        {
          ...json,
        },
      ).then(() => {
        dispatch(
          signupSuccess(
            {
              ...json,
            },
          ),
        );
        dispatch(logoutState());
        analytics.logSignUp('anonymousLogin', true);
        router.goToPage('App');
        return json;
      }))
      .catch((error) => {
        dispatch(signupFailure(error));
      });
  };
}

/**
 * ## Token
 * If AppAuthToken has the sessionToken, the user is logged in
 * so set the state to logout.
 * Otherwise, the user will default to the login in screen.
 */
export function getSessionToken() {
  return (dispatch) => {
    dispatch(sessionTokenRequest());
    return appAuthToken
      .getSessionToken()
      .then(async (token) => {
        // console.log('getSessionToken', token);
        if (token && !isEmpty(token)) {
          dispatch(sessionTokenRequestSuccess(token));
          dispatch(logoutState());
          dispatch(registerUserToServicesLogin(token, 'getSessionToken'));
          // login() // it does nothing without dispatch nad username and password
          router.goToPage('App', { transition: false });
          // Actions.Tabbar()
          return true;
        }
        // try anonymous
        const anonymousLoginResponse = await dispatch(anonymousLogin());
        if (anonymousLoginResponse?.result?.isAnonymous) return true;

        dispatch(sessionTokenRequestFailure());
        dispatch(loginState());
        if (config.azureLoginOnly) {
          return router.goToPage('AzureAD');
        }
        if (config.allowEdocateRegistration) {
        //  Actions.InitialLoginForm()
          if (config.newAuthPages) {
            const res = await helper.firstTime.set();
            if (res) {
              router.goToPage('Signup');
            } else {
              router.goToPage('Login');
            }
          } else {
            router.goToPage('AuthStack');
          }
        } else if (config.showLoginOnly) {
          router.goToPage('Login');
        } else {
          router.goToPage('Error');
        }

        return false;
      })
      .catch((error) => {
        console.log('getSessionToken error', error);
        dispatch(sessionTokenRequestFailure(error));
        dispatch(loginState());
        //  Actions.InitialLoginForm()
        if (config.azureLoginOnly) {
          return router.goToPage('AzureAD');
        }
        if (config.allowEdocateRegistration) {
          //  Actions.InitialLoginForm()
          if (config.newAuthPages) {
            router.goToPage('Login');
          } else {
            router.goToPage('AuthStack');
          }
        } else if (config.showLoginOnly) {
          router.goToPage('Login');
        } else {
          router.goToPage('Error');
        }
        return false;
      });
  };
}

/**
 * ## signup
 * @param {string} username - name of user
 * @param {string} email - user's email
 * @param {string} password - user's password
 *
 * Call the server signup and if good, save the sessionToken,
 * set the state to logout and signal success
 *
 * Otherwise, dispatch the error so the user can see
 */

export function signup(signUpObj) {
  return (dispatch) => {
    dispatch(signupRequest());
    return BackendFactory()
      .signup(signUpObj)
      .then((json) => saveSessionToken(
        {
          ...json,
          username: signUpObj.username,
          email: signUpObj.email,
        },
      ).then(() => {
        dispatch(registerUserToServicesLogin(json, 'signup'));
        // debugger
        // push.registerMyIdToOneSignalPushOnLogin(
        //   json.objectId ? json.objectId : json.result.objectId
        // ) // need to check
        dispatch(
          signupSuccess(
            {
              ...json,
              username: signUpObj.username,
              email: signUpObj.email,
            },
          ),
        );
        dispatch(logoutState());
        memory.setItem('campaignChanged', Date.now());
        router.goToPage('App');
      }))
      .catch((error) => {
        dispatch(signupFailure(error));
      });
  };
}

export function isProjectOverSuccess(json) {
  return { type: IS_PROJECT_OVER_SUCCESS, payload: json };
}
export function isProjectOverRequest(json) {
  return { type: IS_PROJECT_OVER_REQUEST, payload: json };
}
export function isProjectOverFailure(json) {
  return { type: IS_PROJECT_OVER_FAILURE, payload: json };
}

export function singleSignOnSuccess(json) {
  return { type: SINGLE_SIGN_ON_SUCCESS, payload: json };
}
export function singleSignOnRequest(json) {
  return { type: SINGLE_SIGN_ON_REQUEST, payload: json };
}
export function singleSignOnFailure(json) {
  return { type: SINGLE_SIGN_ON_FAILURE, payload: json };
}

export function dynamicSSOSuccess(json) {
  return { type: DYNAMIC_SSO_SUCCESS, payload: json };
}
export function dynamicSSORequest(json) {
  return { type: DYNAMIC_SSO_REQUEST, payload: json };
}
export function dynamicSSOFailure(json) {
  return { type: DYNAMIC_SSO_FAILURE, payload: json };
}

export function providerConnectSuccess(json) {
  return { type: PROVIDER_CONNECT_SUCCESS, payload: json };
}
export function providerConnectRequest(json) {
  return { type: PROVIDER_CONNECT_REQUEST, payload: json };
}
export function providerConnectFailure(json) {
  return { type: PROVIDER_CONNECT_FAILURE, payload: json };
}

export function getMyPromotionsRequest(json) {
  return { type: GET_MY_PROMOTIONS_REQUEST, payload: json };
}

export function getMyPromotionsFailure(json) {
  return { type: GET_MY_PROMOTIONS_FAILURE, payload: json };
}

export function getMyPromotionsSuccess(json) {
  return { type: GET_MY_PROMOTIONS_SUCCESS, payload: json };
}

export function checkUserInviteRequest() {
  return { type: CHECK_USER_INVITE_REQUEST };
}

export function checkUserInviteSuccess(json) {
  return { type: CHECK_USER_INVITE_SUCCESS, payload: json };
}

export function checkUserInviteFailure(error) {
  return { type: CHECK_USER_INVITE_FAILURE, payload: error };
}

export function checkUserEmailExistsRequest() {
  return { type: CHECK_USER_EMAIL_EXISTS_REQUEST };
}

export function checkUserEmailExistsSuccess(json) {
  return { type: CHECK_USER_EMAIL_EXISTS_SUCCESS, payload: json };
}

export function checkUserEmailExistsFailure(error) {
  return { type: CHECK_USER_EMAIL_EXISTS_FAILURE, payload: error };
}

export function getFirebaseTokenRequest() {
  return { type: GET_FIREBASE_REQUEST };
}

export function getFirebaseTokenSuccess(json) {
  return { type: GET_FIREBASE_SUCCESS, payload: json };
}

export function getFirebaseTokenFailure(error) {
  return { type: GET_FIREBASE_FAILURE, payload: error };
}

export function configRequest() {
  return { type: GET_CONFIG_REQUEST };
}

export function configSuccess(json) {
  return { type: GET_CONFIG_SUCCESS, payload: json };
}

export function configFailure(error) {
  return { type: GET_CONFIG_FAILURE, payload: error };
}

export function getActiveCampaignRequest() {
  return { type: GET_ACTIVE_CAMPAIGN_REQUEST };
}

export function getActiveCampaignSuccess(json) {
  return { type: GET_ACTIVE_CAMPAIGN_SUCCESS, payload: json };
}

export function getActiveCampaignFailure(error) {
  return { type: GET_ACTIVE_CAMPAIGN_FAILURE, payload: error };
}

export function TutorialRequest() {
  return { type: GET_TUTORIAL_REQUEST };
}

export function TutorialSuccess(json) {
  return { type: GET_TUTORIAL_SUCCESS, payload: json };
}

export function TutorialFailure(error) {
  return { type: GET_TUTORIAL_FAILURE, payload: error };
}

export function onBoardingRequest() {
  return { type: GET_ON_BOARDING_REQUEST };
}

export function onBoardingSuccess(json) {
  return { type: GET_ON_BOARDING_SUCCESS, payload: json };
}

export function onBoardingFailure(error) {
  return { type: GET_ON_BOARDING_FAILURE, payload: error };
}

export function specialtyRequest() {
  return { type: GET_SPECIALTY_REQUEST };
}

export function specialtySuccess(json) {
  return { type: GET_SPECIALTY_SUCCESS, payload: json };
}

export function specialtyFailure(error) {
  return { type: GET_SPECIALTY_FAILURE, payload: error };
}

export function getTreatmentDurationRequest() {
  return { type: GET_TREATMENT_DURATION_REQUEST };
}

export function getTreatmentDurationSuccess(json) {
  return { type: GET_TREATMENT_DURATION_SUCCESS, payload: json };
}

export function getTreatmentDurationFailure(error) {
  return { type: GET_TREATMENT_DURATION_FAILURE, payload: error };
}

export function yearsInPracticeRequest() {
  return { type: GET_YEARS_PRACTICE_REQUEST };
}

export function yearsInPracticeSuccess(json) {
  return { type: GET_YEARS_PRACTICE_SUCCESS, payload: json };
}

export function yearsInPracticeFailure(error) {
  return { type: GET_YEARS_PRACTICE_FAILURE, payload: error };
}

export function getCountriesAutoCompleteRequest() {
  return { type: GET_COUNTRIES_REQUEST };
}

export function getCountriesAutoCompleteSuccess(json) {
  return { type: GET_COUNTRIES_SUCCESS, payload: json };
}

export function getCountriesAutoCompleteFailure(error) {
  return { type: GET_COUNTRIES_FAILURE, payload: error };
}

export function educationRequest() {
  return { type: GET_EDUCATION_REQUEST };
}

export function educationSuccess(json) {
  return { type: GET_EDUCATION_SUCCESS, payload: json };
}

export function educationFailure(error) {
  return { type: GET_EDUCATION_FAILURE, payload: error };
}

export function passRegExRequest() {
  return { type: GET_PASS_REGEX_REQUEST };
}

export function passRegExSuccess(json) {
  return { type: GET_PASS_REGEX_SUCCESS, payload: json };
}

export function passRegExFailure(error) {
  return { type: GET_PASS_REGEX_FAILURE, payload: error };
}

/**
 * ## Login actions
 */
export function loginRequest() {
  return { type: LOGIN_REQUEST };
}

export function loginSuccess(json) {
  return { type: LOGIN_SUCCESS, payload: json };
}

export function loginFailure(error) {
  return { type: LOGIN_FAILURE, payload: error };
}
/**
 * ## Login
 * @param {string} username - user's name
 * @param {string} password - user's password
 *
 * After calling Backend, if response is good, save the json
 * which is the currentUser which contains the sessionToken
 *
 * If successful, set the state to logout
 * otherwise, dispatch a failure
 */

export function login(email, password, activeCampaign) {
  return (dispatch) => {
    dispatch(loginRequest());
    return BackendFactory()
      .login({ email, password, activeCampaign })
      .then((json) => saveSessionToken(json).then(() => {
        dispatch(loginSuccess(json));
        dispatch(registerUserToServicesLogin(json, 'login'));
        // push.registerMyIdToOneSignalPushOnLogin(json.result.objectId)
        // analytics.logLogin('email', true)
        router.goToPage('App');
        dispatch(logoutState());
      }))
      .catch((error) => {
        dispatch(loginFailure(error));
      });
  };
}

/**
 * ## ResetPassword actions
 */
export function resetPasswordRequest() {
  return { type: RESET_PASSWORD_REQUEST };
}

export function resetPasswordSuccess() {
  return { type: RESET_PASSWORD_SUCCESS };
}

export function resetPasswordFailure(error) {
  return { type: RESET_PASSWORD_FAILURE, payload: error };
}

export function setFirstTime(isFirstTime) {
  return { type: SET_FIRST_TIME, payload: isFirstTime };
}

export function setUrlParameters(URLSearchParams) {
  return { type: SET_URL_PARAMS, payload: URLSearchParams };
}
/**
 * ## ResetPassword
 *
 * @param {string} email - the email address to reset password
 * *Note* There's no feedback to the user whether the email
 * address is valid or not.
 *
 * This functionality depends on the server set
 * up correctly ie, that emails are verified.
 * With that enabled, an email can be sent w/ a
 * form for setting the new password.
 */
export function resetPassword(email) {
  return (dispatch) => {
    dispatch(resetPasswordRequest());
    return BackendFactory()
      .resetPassword({ email })
      .then(() => {
        dispatch(loginState());
        return dispatch(resetPasswordSuccess());

        //  Actions.Auth()
      })
      .catch((error) => dispatch(resetPasswordFailure(error)));
  };
}

export function getSpecialty() {
  return (dispatch) => {
    dispatch(specialtyRequest());
    return BackendFactory()
      .getSpecialty()
      .then((data) => {
        dispatch(specialtySuccess(data.results));
      })
      .catch((error) => {
        dispatch(specialtyFailure(error));
      });
  };
}
export function getYearsInPractice() {
  return (dispatch) => {
    dispatch(yearsInPracticeRequest());
    return BackendFactory()
      .getYearsInPractice()
      .then((data) => {
        dispatch(yearsInPracticeSuccess(data.results));
      })
      .catch((error) => {
        dispatch(yearsInPracticeFailure(error));
      });
  };
}

export function getLicense() {
  return (dispatch) => {
    dispatch({ type: GET_LICENSE_REQUEST });
    return BackendFactory()
      .getLicense()
      .then((data) => {
        dispatch({ type: GET_LICENSE_SUCCESS, payload: data.results });
      })
      .catch((error) => {
        dispatch({ type: GET_LICENSE_FAILURE, payload: error });
      });
  };
}

export function getTreatmentDuration() {
  return (dispatch) => {
    dispatch(getTreatmentDurationRequest());
    return BackendFactory()
      .getTreatmentDuration()
      .then((data) => {
        dispatch(getTreatmentDurationSuccess(data.results));
      })
      .catch((error) => {
        dispatch(getTreatmentDurationFailure(error));
      });
  };
}

export function getOnBoarding() {
  return (dispatch) => {
    dispatch(onBoardingRequest());
    return BackendFactory()
      .getOnBoarding()
      .then((data) => {
        dispatch(onBoardingSuccess(data.results));
      })
      .catch((error) => {
        dispatch(onBoardingFailure(error));
      });
  };
}

export function getTutorial() {
  return (dispatch) => {
    dispatch(TutorialRequest());
    return BackendFactory()
      .getTutorial()
      .then((data) => {
        // set the results to correct Format
        dispatch(TutorialSuccess(data.results));
      })
      .catch((error) => {
        dispatch(TutorialFailure(error));
      });
  };
}

export function getActiveCampaign() {
  return (dispatch) => {
    dispatch(getActiveCampaignRequest());
    return BackendFactory()
      .getActiveCampaign()
      .then((data) => {
        dispatch(getActiveCampaignSuccess(data.result));
      })
      .catch((error) => {
        dispatch(getActiveCampaignFailure(error));
      });
  };
}

export function getConfig() {
  return (dispatch) => {
    dispatch(configRequest());
    return BackendFactory()
      .getConfig()
      .then((data) => {
        dispatch(configSuccess(data?.result?.config));
      })
      .catch((error) => {
        dispatch(configFailure(error));
      });
  };
}

export function getEducation() {
  return (dispatch) => {
    dispatch(educationRequest());
    return BackendFactory()
      .getEducation()
      .then((data) => {
        dispatch(educationSuccess(data.results));
      })
      .catch((error) => {
        dispatch(educationFailure(error));
      });
  };
}

export function getCountriesAutoComplete(name) {
  return (dispatch) => {
    dispatch(getCountriesAutoCompleteRequest());
    return clinicalApi
      .getCountriesAutoComplete(name)
      .then((data) => {
        dispatch(getCountriesAutoCompleteSuccess(data));
      })
      .catch((error) => {
        dispatch(getCountriesAutoCompleteFailure(error));
      });
  };
}

export function saveFirebaseToken(json) {
  return appAuthToken.saveFirebaseToken(json);
}

export function getPasswordRegex() {
  return (dispatch) => {
    dispatch(passRegExRequest());
    return BackendFactory()
      .getPasswordRegEx()
      .then((data) => {
        dispatch(passRegExSuccess(data.result));
      })
      .catch((error) => {
        dispatch(passRegExFailure(error));
      });
  };
}

export function getFirebaseToken() {
  return (dispatch) => {
    dispatch(getFirebaseTokenRequest());
    return appAuthToken.getSessionToken().then((session) => {
      BackendFactory(session)
        .getFirebaseToken()
        .then((data) => {
          const token = data.result;
          // debugger

          saveFirebaseToken(token).then((t) => dispatch(getFirebaseTokenSuccess(t)));
          // return token
        })
        .catch((error) => {
          console.log('getFirebaseToken error', error);
          if (error && error.code === 209) {
            dispatch(logout());
          }
          return dispatch(getFirebaseTokenFailure(error));
        });
    });
  };
}

export function checkUserEmailExists(email) {
  return (dispatch) => {
    dispatch(checkUserEmailExistsRequest());
    return BackendFactory()
      .checkUserEmailExists(email)
      .then((data) => {
        const exists = data.result;
        dispatch(
          checkUserEmailExistsSuccess(exists),
        );
        return exists;
      })
      .catch((error) => {
        dispatch(checkUserEmailExistsFailure(error));
        console.error(error);
        return true;
      });
  };
}

export function checkUserInvite(email) {
  return (dispatch) => {
    dispatch(checkUserInviteRequest());
    return BackendFactory()
      .checkUserInvite(email)
      .then((data) => {
        const { campaign } = data.result[0];
        const { showBrand, brandLogo, brandSlogan } = campaign;
        dispatch(
          checkUserInviteSuccess({
            campaign,
            showBrand,
            brandLogo,
            brandSlogan,
          }),
        );
        return true;
      })
      .catch((error) => {
        dispatch(checkUserInviteFailure(error));
        return false;
      });
  };
}

export function checkIfProjectOver() {
  return (dispatch) => {
    dispatch(isProjectOverRequest());
    return BackendFactory()
      .checkIfProjectOver()
      .then((data) => {
        const { result } = data;
        dispatch(isProjectOverSuccess(result));
        // check if over and change route|
        if (result.isProjectOver) {
          router.init('ProjectOver');
        }
      })
      .catch((error) => {
        dispatch(isProjectOverFailure(error));
      });
  };
}

export function providerConnect(params) {
  // debugger;
  // console.log('providerConnect start', params);
  return (dispatch) => {
    dispatch(providerConnectRequest());
    return BackendFactory()
      .providerConnect(params)
      .then((json) => {
        const matchLogin = { result: json };
        return saveSessionToken(matchLogin).then(() => {
          dispatch(registerUserToServicesLogin(matchLogin, 'providerConnect'));
          dispatch(providerConnectSuccess(matchLogin));
          dispatch(logoutState());
          return matchLogin;
        // router.goToPage('App');
        });
      })
      .catch((error) => {
        console.warn(error);
        dispatch(providerConnectFailure(error));
        return null;
      });
  };
}

export function singleSignOn(params) {
  return (dispatch) => {
    dispatch(providerConnectRequest());
    return BackendFactory()
      .singleSignOn(params)
      .then((json) => {
        const matchLogin = { result: json };
        return saveSessionToken(matchLogin).then(() => {
          dispatch(registerUserToServicesLogin(matchLogin, 'providerConnect'));
          dispatch(providerConnectSuccess(matchLogin));
          dispatch(logoutState());
          return matchLogin;
        // router.goToPage('App');
        });
      })
      .catch((error) => {
        console.warn(error);
        dispatch(providerConnectFailure(error));
        return null;
      });
  };
}

export async function validateDoctorId(idObj) {
  return appAuthToken
    .getSessionToken()
    .then((token) => BackendFactory(token).validateDoctorId(idObj));
}

export function getMyPromotions() {
  return (dispatch) => {
    dispatch(getMyPromotionsRequest());
    return appAuthToken
      .getSessionToken()
      .then((token) => BackendFactory(token).getMyPromotions()
        .then((data) => {
          dispatch(getMyPromotionsSuccess(data.result));
          return true;
        })
        .catch((error) => {
          dispatch(getMyPromotionsFailure(error));
          return false;
        }));
  };
}

export function loaderHide() {
  return (dispatch) => dispatch({ type: LOADER_HIDE });
}
export function loaderShow() {
  return (dispatch) => dispatch({ type: LOADER_SHOW });
}

export function dynamicSSO(params) {
  return (dispatch) => {
    dispatch(dynamicSSORequest());
    return BackendFactory()
      .dynamicSSO(params)
      .then((json) => {
        const matchLogin = { result: json };
        return saveSessionToken(matchLogin).then(() => {
          dispatch(registerUserToServicesLogin(matchLogin, 'dynamicSSO'));
          dispatch(dynamicSSOSuccess(matchLogin));
          dispatch(logoutState());
          dispatch(patientActions.getGlobal());
          dispatch(loadTranslationFromAPi());
          return matchLogin;
        // router.goToPage('App');
        });
      })
      .catch((error) => {
        console.warn(error);
        dispatch(dynamicSSOFailure(error));
        return null;
      });
  };
}

export function getCountryByIp() {
  return (dispatch) => {
    dispatch(getCountryByIpRequest());
    return clinicalApi
      .getCountryByIp()
      .then((data) => dispatch(getCountryByIpSuccess(data)))
      .catch((error) => dispatch(getCountryByIpFailure(error)));
  };
}

export function azureADConnect(params) {
  return (dispatch) => {
    dispatch(providerConnectRequest());
    return BackendFactory()
      .azureADConnect(params)
      .then((json) => {
        const matchLogin = { result: json };
        return saveSessionToken(matchLogin).then(() => {
          dispatch(registerUserToServicesLogin(matchLogin, 'azureADConnect'));
          dispatch(providerConnectSuccess(matchLogin));
          dispatch(logoutState());
          router.goToPage('App');
          return matchLogin;
        });
      })
      .catch((error) => {
        console.warn(error);
        dispatch(providerConnectFailure(error));
        return null;
      });
  };
}

export function keepAlive() {
  return () => appAuthToken
    .getSessionToken()
    .then((token) => BackendFactory(token).keepAlive().then((json) => json))
    .catch((error) => {
      console.warn(error);
      return null;
    });
}
