import React, { PureComponent } from 'react';
import { StyleSheet } from 'react-native';
import { defaultStyle } from '../../lib/general';
import helper from '../../lib/helper';
import { config } from '../../lib/config';

class HTMLWithStyle extends PureComponent {
  render() {
    const { html, style } = this.props;

    let shouldRTL = false;
    if (config.detectRtlOnText) {
      shouldRTL = helper.isRTL(html); // disable if dir was written;
    }

    const rtlObj = shouldRTL ? { direction: 'rtl', ...StyleSheet.flatten(defaultStyle.webRTLFontFamily) } : {};
    const baseFontStyle = { ...defaultStyle.htmlBaseText, ...StyleSheet.flatten(style), ...rtlObj };
    return (
      // eslint-disable-next-line react/no-danger
      <div style={baseFontStyle} dangerouslySetInnerHTML={{ __html: html }} />
    );
  }
}

export default HTMLWithStyle;
