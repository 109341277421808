const analyticsDictionary = {
  AnalyticsMap: {
    App: 'Clinic',
    ClinicTab: 'Clinic',
    ModuleIntro: 'Patient intro',
    overview: 'OVERVIEW_Tab',
    PHYSICAL: 'PHYSICAL_Tab_results',
    'LABS/IMAGING': 'Lab/imaging_Tab_results',
    DIAGNOSES: 'DIAGNOSES_Tab_list',
    'MGMT/REFERRAL': 'REFERRAL_Tab_list',
    ORDERS: 'ORDERS_Tab_list',
    'END VISIT': 'End_visit_Tab',
    EndEncounterFull: 'End encounter summary',
    AuthStack: 'LOGIN',
  },
  caseStatusSegments: {
    didnt: 'didnt_start',
    started: 'start',
    finishedCase: 'finished_case',
    finishedVisit: 'finished_visit',
  },
  routesEnum: {
    history: 'history',
    overview: 'overview',
    physical: 'physical',
    lab: 'lab',
    diagnosis: 'diagnosis',
    referral: 'referral',
    treatments: 'treatments',
    endVisit: 'endVisit',
    forgotPassword: 'forgotPassword',
    register: 'register',
    clinic: 'Clinic_tab',
  },
  tutorialScreensMAp: {
    clinic: 'clinic',
    App: 'clinic',
    ClinicTab: 'clinic',
    Clinic_tab: 'clinic',
    Clinic: 'clinic',

    patient: 'overview',
    Patient: 'overview',
    overview: 'overview',

    physical: 'physical',
    PHYSICAL: 'physical',

    addPhysical: 'addPhysical',
    AddPhysical: 'addPhysical',

    lab: 'lab',
    'LABS/IMAGING': 'lab',

    endEncounter: 'endEncounter',
    EndEncounterFull: 'endEncounter',

    treatments: 'treatments',

    addTreatment: 'addTreatment',
    ORDERS: 'addTreatment',

    EndModuleChartScreen: 'EndModuleChartScreen',
  },
};
export default analyticsDictionary;
