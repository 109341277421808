import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Image,
  StatusBar,
  TouchableOpacity,
  Platform,
  ScrollView,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-native-modal';
import Swiper from 'react-native-swiper';
import { globalActions } from '../../../reducers/actions';
import { SLIDES } from '../../../lib/slides';
import {
  design,
  I18n,
  TextMontserrat,
  TextSF,
  helper,
} from '../../../lib/general';
import { config } from '../../../lib/config';
import ButtonFull from '../ButtonFull';
import styles from './style';

const Styles = styles();
const isWeb = Platform.OS === 'web';

export default function GlobalTutorial({ resetIdx }) {
  const dispatch = useDispatch();
  const showTutorial = useSelector((s) => s.global.showTutorial);
  const routes = useSelector((s) => s.routes.currentRouteName);
  const tutorialSlides = useSelector((s) => s.auth.form.tutorialSlides);

  const swiperRef = useRef(null);
  const [isLast, setIsLast] = useState(false);
  const [slides, setSlides] = useState([]);
  const [prevRout, setPrevRout] = useState(null);

  const onIndexChanged = (index = 0, tmpSlides) => {
    const toUseSlides = tmpSlides || slides;
    setIsLast(index === toUseSlides.length - 1);
  };
  const resetGlobalTutorials = () => dispatch(globalActions.toggleTutorial(false));
  const globalTutorialState = config.GlobalTutorialState;
  useEffect(() => {
    onIndexChanged();
    return () => resetGlobalTutorials();
  }, []);

  useEffect(() => {
    if (resetIdx) onIndexChanged();
  }, [resetIdx]);

  const getRoute = (routes) => (routes === 'Patient' ? 'overview' : routes);

  useEffect(() => {
    if (tutorialSlides?.length && routes) {
      if (!prevRout || routes !== prevRout) {
        const checkThisRoute = getRoute(routes);
        setPrevRout(checkThisRoute);
        const existingSlides = SLIDES.getSlidesFromAPi(checkThisRoute);
        const isShow = !!existingSlides.length;
        dispatch(globalActions.toggleTutorialIconDisplay(isShow)); // will display icon in actionGroupButton
        if (isShow) {
          SLIDES.checkIfShow(checkThisRoute, globalTutorialState)
            .then((isShowSlides) => {
              const tmpSlides = SLIDES.getSlidesFromAPi(checkThisRoute);
              setSlides(tmpSlides);
              onIndexChanged(0, tmpSlides);
              dispatch(globalActions.toggleTutorial(isShowSlides)); // will popup for the first time 4 each screen if exist
            });
        }
      }
    }
  }, [tutorialSlides, routes]);

  useEffect(() => {
    if (showTutorial) {
      setSlides(SLIDES.getSlidesFromAPi(routes === 'Patient' ? 'overview' : routes));
      onIndexChanged();
    }
  }, [showTutorial]);

  const GetDot = ({ isActive }) => <View style={Styles[isActive ? 'activeDot' : 'nonActiveDot']} />;
  const onPressFinish = () => resetGlobalTutorials();
  const onPress = () => (isLast ? onPressFinish() : swiperRef?.current?.scrollBy(1, true));
  const onRequestClose = () => true;

  return (
    showTutorial && slides?.length > 0 && (
    <Modal
      onRequestClose={onRequestClose}
      backdropColor={design.colors.backdrop}
      backdropOpacity={0.95}
      onBackdropPress={onRequestClose}
      supportedOrientations={['portrait', 'landscape']}
      useNativeDriver
      hideModalContentWhileAnimating
      style={[Styles.modal]}
      isVisible
      hasBackdrop={false}
      animationInTiming={500}
    >
      <View
        style={[Styles.container, isWeb && Styles.containerNoBackground]}
        onPress={onRequestClose}
      >
        <View>
          <StatusBar
            backgroundColor={design.colors.tutorialBg}
            barStyle="light-content"
          />
        </View>
        {!isLast
        && (
        <TouchableOpacity
          style={Styles.closeContainer}
          onPress={onPressFinish}
        >
          <TextSF style={Styles.skip}>
            {I18n.t('Tutorial.skipSmall')}
          </TextSF>
        </TouchableOpacity>
        )}
        <Swiper
          ref={swiperRef}
          showsButtons={false}
          loop={false}
          dot={<GetDot />}
          activeDot={<GetDot isActive />}
          onIndexChanged={onIndexChanged}
        >
          {slides.map((slide, index) => (
            <ScrollView
              key={`slide${slide.screen}${slide.orderInScreen}`}
              style={Styles.slide}
            >
              <View style={Styles.headerContainer}>
                <TextMontserrat style={Styles.header}>
                  {slide.header}
                </TextMontserrat>
              </View>
              <View style={[Styles.imageContainer, slide.imageContainer]}>
                {slide.image !== '' && (
                <Image
                  source={{ uri: slide.image }}
                  style={[Styles.image, slide.imageStyle]}
                />
                )}
              </View>

              <View style={[Styles.slideContainer, slide.slideContainer]}>
                {slide.text.map((txt, i) => {
                  const isRTl = config.detectRtlOnText && helper.isRTL(txt);
                  return (
                    <View
                      key={txt}
                      style={[
                        Styles.sideBySide,
                        isRTl && Styles.sideBySideReverse,
                      ]}
                    >
                      <View style={Styles.numberWrapper}>
                        <TextSF style={Styles.number}>{i + 1}</TextSF>
                      </View>
                      <TextSF style={Styles.text}>{txt}</TextSF>
                    </View>
                  );
                })}
              </View>
              {isWeb && (
              <View style={Styles.dotContainer}>
                {slides.map((s, dotIndex) => <GetDot isActive={dotIndex === index} key={`${s}_${dotIndex}`} />)}
              </View>
              )}
            </ScrollView>
          ))}
        </Swiper>
        {isLast === true ? (
          <ButtonFull
            buttonStyle={Styles.buttonStyle}
            onPress={onPress}
            textStyle={Styles.btnText}
            title={I18n.t('Tutorial.finish')}
          />
        ) : (
          <View style={Styles.sideBySideBtn}>
            <ButtonFull
              buttonStyle={[Styles.buttonStyle]}
              containerStyle={Styles.flexBtn}
              onPress={onPress}
              textStyle={Styles.btnText}
              title={I18n.t('Tutorial.next')}
            />
          </View>
        )}
      </View>
    </Modal>
    )
  );
}
