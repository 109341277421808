import React, { memo } from 'react';
import { Text, StyleSheet, Platform } from 'react-native';
import global from '../../lib/global';

const isAndroid = Platform.OS === 'android';

const TextOswald = memo((props) => {
  const { style, children } = props;
  const rest = { ...props };
  delete rest.style;
  delete rest.children;
  const flattenStyle = StyleSheet.flatten(style);
  let fontFamily = 'Oswald-Regular';
  let fontWeight = 'normal';
  if (flattenStyle) {
    switch (flattenStyle.fontWeight) {
      case '300':
        fontFamily = 'Oswald-Regular';
        fontWeight = 'normal';
        break;
      case '500':
      case '600':
      case '700':
      case '800':
      case 'bold':
        fontFamily = 'Oswald-Medium';
        fontWeight = 'bold';
        break;
      default:
        fontFamily = 'Oswald-Regular';
        fontWeight = 'normal';
        break;
    }
  }
  return (
    <Text
      maxFontSizeMultiplier={global.maxFontSizeMultiplier}
      style={[style, { fontFamily }, isAndroid && { fontWeight }]}
      {...rest}
    >
      {children}
    </Text>
  );
});

export default TextOswald;
