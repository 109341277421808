import { Platform, Dimensions } from 'react-native';
import { createStore, applyMiddleware } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// import immutableTransform from 'redux-persist-transform-immutable';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from '../reducers';
import global from './global';
// import config from './config';

// const persistConfig = {
//   key: config.bundle,
//   storage,
//   stateReconciler: autoMergeLevel2,
//   transforms: [immutableTransform()],
// };

const enhancers = [];
const middleware = [thunk];
let composedEnhancers = null;
const isWeb = Platform.OS === 'web';
const isDesktop = isWeb && Dimensions.get('window').width >= global.mobileMaxWidth;

if (isWeb && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(...middleware), ...enhancers);
} else {
  composedEnhancers = composeWithDevTools(applyMiddleware(...middleware), ...enhancers);
}

export default function configureStore(initialState) {
  // if (config.usePersist) {
  //   const store = createStore(persistedReducer, initialState, composedEnhancers);
  //   const persistor = persistStore(store);
  //   return { store, persistor };
  // }
  const store = createStore(reducer, initialState, composedEnhancers);

  if (isDesktop) {
    const IS_DEV = window.location.href.indexOf('localhost') > -1;
    if (IS_DEV) window.__STORE__ = JSON.parse(JSON.stringify(store.getState()));
  }
  return { store };
}
