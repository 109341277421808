import * as authActions from './auth/authActions';
import * as globalActions from './global/globalActions';
import * as clinicActions from './clinic/clinicActions';
import * as patientActions from './patient/patientActions';
import * as deviceActions from './device/deviceActions';
import * as profileActions from './profile/profileActions';
import * as settingsActions from './settings/settingsActions';
import * as referenceActions from './reference/referenceActions';
import * as notificationActions from './notification/notificationActions';
import * as faqActions from './FAQ/faqActions';
import * as goalActions from './goal/goalActions';
import * as routActions from './routes/routesActions';

export {
  authActions,
  globalActions,
  clinicActions,
  patientActions,
  deviceActions,
  profileActions,
  settingsActions,
  referenceActions,
  notificationActions,
  faqActions,
  goalActions,
  routActions,
};
