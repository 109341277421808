const enums = {
  timeStatusEnum: {
    LONG_TERM: 0,
    SHORT_TERM: 1,
  },
  caseStateSegment: {
    didnt_start: 0,
    start: 1,
    finished_case: 2,
    finished_visit: 3,
  },
};
export default enums;
