/* eslint-disable camelcase */

const added = require('./added.png');

const archive = require('./archive.png');
const back = require('./back.png');

const calendarclinic = require('./calendarclinic.png');
const chat = require('./chat.png');
const clinic = require('./clinic.png');
const complaintOn = require('./complaintOn.png');
const complaints = require('./complaints.png');

const credits = require('./credits.png');
const diagnoses = require('./diagnoses.png');
const diagnosesCopy2 = require('./diagnosesCopy2.png');
const diagnosesOn = require('./diagnosesOn.png');
const email = require('./email.png');
const endMeetingOn = require('./endMeetingOn.png');
const endsession = require('./endsession.png');
const goal = require('./goal.png');
const goal2 = require('./goal2.png');
const help = require('./help.png');
const history = require('./history.png');
const historyOn = require('./historyOn.png');
const info = require('./info.png');
const infoCopy = require('./infoCopy.png');

const lab = require('./lab.png');
const labCopy2 = require('./labCopy2.png');
const labOn = require('./labOn.png');
const labonbig = require('./labonbig.png');
const lock = require('./lock.png');
const logout = require('./logout.png');
const more = require('./more.png');
const notes = require('./notes.png');

const notesCopy = require('./notesCopy.png');
// const notesCopy2 = require('./notesCopy2.png');
// const notesCopy3 = require('./notesCopy3.png');
// const notesCopy4 = require('./notesCopy4.png');
const notesblue = require('./notesblue.png');
const notification = require('./notification.png');
const overview = require('./overview.png');
const overviewOff = require('./overviewOff.png');
const overviewOn = require('./overviewOn.png');
const pdf = require('./pdf.png');
const physical = require('./physical.png');
const physicalCopy2 = require('./physicalCopy2.png');
const physicalOn = require('./physicalOn.png');
const plus = require('./plus.png');
const proTip = require('./proTip.png');
const referance = require('./referance.png');
const referanceblue = require('./referanceblue.png');
const search = require('./search.png');
const send = require('./send.png');
const settings = require('./settings.png');
// const starempty = require('./starempty.png');
const starfull = require('./starfull.png');
const treatments = require('./treatments.png');
const treatmentsOn = require('./treatmentsOn.png');
const x = require('./x.png');
const thermamother = require('./thermamother.png');
const thermamotherCopy = require('./thermamotherCopy.png');
const thermamotherCopy2 = require('./thermamotherCopy2.png');
const calendar = require('./calendar.png');
const clock = require('./clock.png');
// const newclinic = require('./newclinic.png');
// const numbergradient = require('./numbergradient.png');
const readOnly = require('./readOnly.png');
const thermamothergreen = require('./thermamothergreen.png');
const thermamotherred = require('./thermamotherred.png');
const thermamotheryellow = require('./thermamotheryellow.png');
const diagnosisBig = require('./diagnosisBig.png');
const blueBtn = require('./blueBtn.png');
const medal = require('./medal.png');
const diagnosesblack = require('./diagnosesblack.png');
const notesendof = require('./notesendof.png');
const treatmentsblack = require('./treatmentsblack.png');
const vGreen = require('./vGreen.png');
const xRed = require('./xRed.png');
const edocateLogoTransparent = require('./edocateLogoTransparent.png');
const edocatePoweredBy = require('./powered_by.png');

const lock_reverse = require('./lock_reverse.png');
const FAQs = require('./FAQs.png');
const minusIco = require('./minusIco.png');
const plusIco = require('./plusIco.png');

const down = require('./down.png');
const noChange = require('./noChange.png');
const star_empty = require('./star_empty.png');
const up = require('./up.png');
const personWhite = require('./personWhite.png');
const treatments_on_big = require('./treatments_on_big.png');
const lab_on_big = require('./lab_on_big.png');
const notes_big = require('./notes_big.png');
const red_star = require('./red_star.png');
const send_white = require('./send_white.png');
const goalError = require('./goalError.png');
const lamp = require('./lamp.png');
const referenceBlack = require('./referenceBlack.png');
const historyIcon = require('./historyIcon.png');
const v4x = require('./v4x.png');
const v2x = require('./v2x.png');
const x2x = require('./x2x.png');
const empty2x = require('./empty2x.png');
// icons for doctor categories
const bone = require('./bone.png');
const skin = require('./skin.png');
const heart = require('./heart.png');
const other = require('./other.png');
const mouth = require('./mouth.png');
const kids = require('./kids.png');
const germs = require('./germs.png');
const family = require('./family.png');
const brain = require('./brain.png');
const eye = require('./eye.png');
const belly = require('./belly.png');
const goal_end = require('./goal_end.png');
const tips_end = require('./tips_end.png');
const up_end = require('./up_end.png');
// const trash = require('./trash.png');
const trash = require('./trash_dark.png');

const clinic_empty_state = require('./clinic_empty_state.png');
const baseline_done_white_24dp = require('./baseline_done_white_24dp.png');
const baseline_error_white_24dp = require('./baseline_error_white_24dp.png');
const baseline_info_white_24dp = require('./baseline_info_white_24dp.png');
const baseline_warning_white_24dp = require('./baseline_warning_white_24dp.png');
const credit_empty_state = require('./credit_empty_state.png');
const credits_tab_ico = require('./credits_tab_ico.png');
const privacy = require('./privacy2x.png');
const legal = require('./legal2x.png');
const intro = require('./intro2x.png');
const switch2 = require('./switch2x.png');
const notices = require('./notices2x.png');
const tips = require('./tips2x.png');
const pitfalls = require('./pitfalls2x.png');
const trash2 = require('./trash2x.png');
const videoPreview = require('./videoPreview.png');
const referral = require('./referral.png');
const referral_selected = require('./referral_selected.png');
const share2 = require('./share2.png');
const share_blue = require('./share_blue.png');
const imageSkeletonDarkGray2 = require('./imageSkeletonDarkGray2.jpg');
const imageSkeletonLightGray = require('./imageSkeletonLightGray.jpg');
const youtube = require('./outline_play_circle.png');
const clinicalHelp = require('./clinicalHelpIcon.png');
const tutorial = require('./tutorialIcon.png');
const hospitalIcon = require('./hospitalIcon.png');
const hospitalIconSmall = require('./hospitalIconSmall.png');
const clinicIcon = require('./clinicIcon.png');
const clinicIconSmall = require('./clinicIconSmall.png');
const clinic_dark = require('./clinic_dark.png');
const referance_dark = require('./referance_dark.png');
const credits_tab_ico_dark = require('./credits_tab_ico_dark.png');
const more_dark = require('./more_dark.png');
const powered_by_dark = require('./powered_by_dark.png');
const x18 = require('./x18.png');

const icons = {
  imageSkeletonDarkGray2,
  imageSkeletonLightGray,
  share_blue,
  share2,
  referral,
  referral_selected,
  videoPreview,
  trash2,
  tips,
  pitfalls,
  switch2,
  notices,
  intro,
  privacy,
  legal,
  trash,
  goal_end,
  tips_end,
  up_end,
  bone,
  skin,
  heart,
  other,
  mouth,
  kids,
  germs,
  family,
  brain,
  eye,
  belly,
  added,

  archive,
  back,
  // body,
  calendarclinic,
  chat,
  clinic,
  complaintOn,
  complaints,
  // contact,
  // contactUsicon,
  credits,
  diagnoses,
  diagnosesCopy2,
  diagnosesOn,
  email,
  endMeetingOn,
  endsession,
  goal,
  goal2,

  help,
  history,
  historyOn,
  info,
  infoCopy,

  lab,
  labCopy2,
  labOn,
  labonbig,
  lock,
  logout,
  more,
  notes,
  notesCopy,

  notesblue,
  notification,
  overview,
  overviewOff,
  overviewOn,
  pdf,
  physical,
  physicalCopy2,
  physicalOn,
  plus,
  proTip,
  referance,
  referanceblue,
  search,
  send,
  settings,
  // starempty,
  starfull,
  treatments,
  treatmentsOn,
  x,
  thermamother,
  thermamotherCopy,
  thermamotherCopy2,
  calendar,
  clock,
  // newclinic,
  // numbergradient,
  readOnly,
  thermamothergreen,
  thermamotherred,
  thermamotheryellow,
  diagnosisBig,
  blueBtn,
  medal,
  diagnosesblack,
  notesendof,
  treatmentsblack,
  vGreen,
  xRed,
  edocateLogoTransparent,
  edocatePoweredBy,
  lock_reverse,
  FAQs,
  plusIco,
  minusIco,
  down,
  noChange,
  star_empty,
  up,
  personWhite,
  treatments_on_big,
  lab_on_big,
  notes_big,
  red_star,
  send_white,
  goalError,
  lamp,
  referenceBlack,
  historyIcon,
  v4x,
  v2x,
  x2x,
  empty2x,
  // illustration,
  clinic_empty_state,
  baseline_done_white_24dp,
  baseline_error_white_24dp,
  baseline_info_white_24dp,
  baseline_warning_white_24dp,
  credit_empty_state,
  credits_tab_ico,
  youtube,
  clinicalHelp,
  tutorial,
  hospitalIcon,
  hospitalIconSmall,
  clinicIcon,
  clinicIconSmall,
  clinic_dark,
  referance_dark,
  credits_tab_ico_dark,
  more_dark,
  powered_by_dark,
  x18,
};
export default icons;
