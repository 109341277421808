import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, Image, TouchableOpacity, Dimensions,
} from 'react-native';
import { NavTab } from 'react-router-tabs';
import { useSelector } from 'react-redux';
import MediaQuery from '../../src/components/general/MediaQuery';
import {
  I18n,
  design,
  global,
  icons,
  router,
} from '../../src/lib/general';
import { config } from '../../src/lib/config';
import TabIcon from '../../src/components/tab/TabIcon';
import HoverView from '../../src/components/general/hover/HoverView';
import { exportWebFrame } from './WebFrame';
import YoutubeWebView from '../../src/components/general/YoutubeWebView';

const TAB_WIDTH_DESKTOP = 110;
const { width } = Dimensions.get('window');
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subContainer: {
    flex: 1,
    backgroundColor: design.colors.baseGray,
  },
  flex: {
    flex: 1,
  },
  headImage: {
    width: 63,
    height: 25,
  },
  poweredByWrapper: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    paddingBottom: 28,
  },
  pagePadding: {
    padding: 50,
    // paddingTop: 50,
    paddingBottom: 0,
  },
  tabContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: design.colors.tabBG,
  },
  tabContainerLight: {
    backgroundColor: design.colors.white,
  },
  footer: {
    height: 60,
    width: '100%',
    // flex: 'none',
  },

  footerHide: {
    height: 0,
    overflow: 'hidden',
  },
  tabContainerSide: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: design.colors.tabBG,
  },
  sideBySide: {
    flexDirection: 'row',
    flex: 1,
  },
  side: {
    width: TAB_WIDTH_DESKTOP,
    height: '100%',
    paddingTop: 20,
    // flex: 'none',
  },
  hover: {
    opacity: 0.8,
  },
  mainForcedDesktop: {
    width: width - TAB_WIDTH_DESKTOP + 1,
    minWidth: global.mobileMaxWidth - TAB_WIDTH_DESKTOP + 1,
    maxWidth: global.desktopRecommendedWidth - TAB_WIDTH_DESKTOP + 1,
    alignSelf: 'center',
    flex: 1,
  },
  hide: {
    height: 0,
    overflow: 'hidden',
    width: 0,
  },
  poweredByWrapperMobile: {
    alignContent: 'center',
    justifyContent: 'center',
    paddingBottom: 0,
  },
});
const TabFrame = (props) => {
  const {
    match, children, location, ...rest
  } = props;
  // const currentRouteName = useSelector((state) => state.routes.currentRouteName);
  const currentPatient = useSelector((state) => state.patient.currentPatient);
  const { completedCases, completedVisits } = useSelector((state) => state.clinic);
  const {
    showPoweredByMobile, youtubeVideoUrl, youtubeVideoUrlMobile, showVideoTab,
    showCredits, shouldShowReferenceOnlyInPatient, creditsToExternal,
    tabBarLightMode, hideNavigationTabs, minCompleteCasesForCredit, minCompleteVisitsForCredit, showCreditsRedDot,
  } = config;
  const isOverMin = completedCases >= minCompleteCasesForCredit || completedVisits >= minCompleteVisitsForCredit;
  const showCreditsRedDotOverMin = isOverMin && showCreditsRedDot;
  const { path } = match;
  const [latestPatient, setlatestPatient] = useState(`${path}/ClinicTab`);
  const [showYoutube, setshowYoutube] = useState(false);
  useEffect(() => {
    if (location.pathname.indexOf('ClinicTab') > -1) {
      setlatestPatient(location.pathname);
    }
    // console.log('location', location);
  }, [location]);

  const screenName = location.pathname.split('/')[
    location.pathname.split('/').length - 1
  ];
  const refFocused = location.pathname.indexOf('Reference') > -1;
  const creditFocused = location.pathname.indexOf('Credit') > -1;
  const moreFocused = location.pathname.indexOf('More') > -1;

  const clinicTabFocused = !refFocused && !creditFocused && !moreFocused;
  const shouldHide = location.pathname.indexOf('Add') > -1;
  const addPadding = false && global.TAB_SCREENS_WITH_PADDING.indexOf(screenName) > -1;

  const shouldHideCredits = !showCredits;
  const shouldHideReference = shouldShowReferenceOnlyInPatient && !currentPatient;
  const creditNav = creditsToExternal
    ? `${path}/ExternalCredit`
    : `${path}/FullCredit`;
  const lightMode = tabBarLightMode;
  const hideTabs = hideNavigationTabs;
  const toggleSideMenu = () => {
    exportWebFrame.toggleSideMenu();
  };
  const onPressVideoTab = () => {
    setshowYoutube(true);
  };
  return (
    <View style={[styles.container]}>
      <MediaQuery maxWidth={global.mobileMaxWidth}>
        {(matches) => (matches ? (
          <View style={[styles.subContainer]}>
            <View style={[styles.subContainer]}>{children}</View>
            <View
              style={[
                styles.tabContainer,
                styles.footer,
                shouldHide ? styles.footerHide : {},
                hideTabs && styles.hide,
              ]}
            >
              <HoverView hoverStyle={styles.hover}>
                <NavTab to={latestPatient}>
                  <TabIcon
                    title={I18n.t('Edocate.clinic')}
                    focused={clinicTabFocused}
                    sceneKey="Clinic_tab"
                    iconName="stethoscope"
                    imageName="clinic"
                  />
                </NavTab>
              </HoverView>
              {!shouldHideReference && (
              <HoverView hoverStyle={styles.hover}>
                <NavTab to={`${path}/Reference`}>
                  <TabIcon
                    focused={refFocused}
                    sceneKey="Reference"
                    title={I18n.t('Edocate.reference')}
                    imageName="referance"
                  />
                </NavTab>
              </HoverView>
              )}
              {!shouldHideCredits && (
                <HoverView hoverStyle={styles.hover}>
                  <NavTab to={creditNav}>
                    <TabIcon
                      title={I18n.t('Edocate.myCredit')}
                      focused={creditFocused}
                      sceneKey="Notification"
                      imageName="credits_tab_ico"
                      showRedDot={showCreditsRedDotOverMin}
                    />
                  </NavTab>
                </HoverView>
              )}
              {(showVideoTab && youtubeVideoUrlMobile) && (
              <HoverView hoverStyle={styles.hover}>
                <TouchableOpacity onPress={onPressVideoTab}>
                  <TabIcon
                    title={I18n.t('Edocate.videoTab', { locale_en: 'Play tutorial' })}
                    focused={false}
                    sceneKey="video"
                    iconName="slideshow"
                    icoSize={24}
                    // imageName={lightMode ? 'more_dark' : 'more'}
                    lightMode={lightMode}
                    tabStyle={{ marginTop: 9 }}
                  />
                </TouchableOpacity>
                <YoutubeWebView
                  uri={youtubeVideoUrlMobile}
                  modal
                  isDesktop
                  isVisible={showYoutube}
                  onClose={() => { setshowYoutube(false); }}
                />
              </HoverView>
              )}
              <HoverView hoverStyle={styles.hover}>
                <NavTab to={`${path}/More`}>
                  <TabIcon
                    title={I18n.t('Edocate.more')}
                    focused={moreFocused}
                    sceneKey="More"
                    iconName="bars"
                    imageName="more"
                  />
                </NavTab>
              </HoverView>
              {showPoweredByMobile && (
              <View style={[styles.poweredByWrapper, styles.poweredByWrapperMobile]}>
                <Image
                  style={styles.headImage}
                  source={config.poweredByLink ? { uri: config.poweredByLink } : icons.edocatePoweredBy}
                  resizeMode="contain"
                  href="https://edocate.com"
                  hrefAttrs={{
                    target: '_blank',
                  }}
                  accessibilityRole="link"
                />
              </View>
              )}
            </View>
          </View>
        ) : (
          <View style={styles.sideBySide}>
            <View style={[styles.tabContainerSide, styles.side, lightMode && styles.tabContainerLight, hideTabs && styles.hide]}>
              <HoverView hoverStyle={styles.hover}>
                <NavTab to={latestPatient}>
                  <TabIcon
                    title={I18n.t('Edocate.clinic')}
                    focused={clinicTabFocused}
                    sceneKey="Clinic_tab"
                    iconName="stethoscope"
                    imageName={lightMode ? 'clinic_dark' : 'clinic'}
                    lightMode={lightMode}
                  />
                </NavTab>
              </HoverView>
              {!shouldHideReference && (
                <HoverView hoverStyle={styles.hover}>
                  <NavTab to={`${path}/Reference`}>
                    <TabIcon
                      focused={refFocused}
                      sceneKey="Reference"
                      title={I18n.t('Edocate.reference')}
                      imageName={lightMode ? 'referance_dark' : 'referance'}
                      lightMode={lightMode}
                    />
                  </NavTab>
                </HoverView>
              )}
              {!shouldHideCredits && (
                <HoverView hoverStyle={styles.hover}>
                  <NavTab to={creditNav}>
                    <TabIcon
                      title={I18n.t('Edocate.myCredit')}
                      focused={creditFocused}
                      sceneKey="Notification"
                      showRedDot={showCreditsRedDotOverMin}
                      lightMode={lightMode}
                      imageName="credits_tab_ico"
                    />
                  </NavTab>
                </HoverView>
              )}
              {(showVideoTab && youtubeVideoUrl) && (
              <HoverView hoverStyle={styles.hover}>
                <TouchableOpacity onPress={onPressVideoTab}>
                  <TabIcon
                    title={I18n.t('Edocate.videoTab', { locale_en: 'Play tutorial' })}
                    focused={false}
                    sceneKey="video"
                    iconName="slideshow"
                    icoSize={32}
                    // imageName={lightMode ? 'more_dark' : 'more'}
                    lightMode={lightMode}
                  />
                </TouchableOpacity>
                <YoutubeWebView
                  uri={youtubeVideoUrl}
                  modal
                  isDesktop
                  isVisible={showYoutube}
                  onClose={() => { setshowYoutube(false); }}
                />
              </HoverView>
              )}
              {config.hideTopBar && (
                <HoverView hoverStyle={styles.hover}>
                  <TouchableOpacity onPress={toggleSideMenu}>
                    <TabIcon
                      title={I18n.t('Edocate.more')}
                      focused={moreFocused}
                      sceneKey="More"
                      iconName="bars"
                      imageName={lightMode ? 'more_dark' : 'more'}
                      lightMode={lightMode}
                    />
                  </TouchableOpacity>
                </HoverView>
              )}
              <View style={styles.poweredByWrapper}>
                <Image
                  style={styles.headImage}
                  source={lightMode ? icons.powered_by_dark : icons.edocatePoweredBy}
                  resizeMode="contain"
                  href="https://edocate.com"
                  hrefAttrs={{
                    target: '_blank',
                  }}
                  accessibilityRole="link"
                />
              </View>
              {/* <NavTab to="/More">{I18n.t('Edocate.more')}</NavTab> */}
            </View>
            <View style={[styles.subContainer]}>
              <View style={[styles.mainForcedDesktop]}>
                <View style={[styles.flex, addPadding && styles.pagePadding]}>
                  {children.map((child) => {
                    if (!child) return null;
                    return React.cloneElement(child);
                  }, rest)}
                </View>
              </View>
            </View>
          </View>
        ))}
      </MediaQuery>
    </View>
  );
};
export default TabFrame;
