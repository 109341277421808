import React, { useState } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
// import {design} from '../../../lib/general';
const styles = StyleSheet.create({
  defaultHover: {
    opacity: 0.8,
  },
});
const HoverView = (props) => {
  const [hover, setHover] = useState(false);
  const {
    onMouseEnter, onMouseLeave, style, hoverStyle, disable, ...rest
  } = props;
  const mouseEnter = () => {
    if (disable) return;
    setHover(true);
    if (typeof onMouseEnter === 'function') {
      onMouseEnter();
    }
  };
  const mouseLeave = () => {
    if (disable) return;
    setHover(false);
    if (typeof onMouseLeave === 'function') {
      onMouseLeave();
    }
  };
  // const backgroundColor = hoverColor || design.colors.baseGray;
  const theStyle = hoverStyle || styles.defaultHover;
  return (
    <TouchableOpacity
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      style={[style, hover && theStyle]}
      {...rest}
    />
  );
};
export default HoverView;
