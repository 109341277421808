import I18n from './i18n';
import { config } from './config';

const simpleShare = {
  share: async (options) => {
    try {
      await window.navigator.share(options);
    } catch (error) {
      console.log('share catch', error);
    }
  },
  getOptions: (customOptions) => {
    // const shareData = {
    //   title: 'MDN',
    //   text: 'Learn web development on MDN!',
    //   url: 'https://developer.mozilla.org',
    // }
    const defaultOptions = {
      url: I18n.t('Share.appUrl'),
      title: config.appName,
      text: I18n.t('Share.shareTitle'),
    };
    return { ...defaultOptions, ...customOptions };
  },
  shareScore: async (score) => {
    const options = simpleShare.getOptions({ text: I18n.t('Share.shareScoreTitle', { count: score }) });
    simpleShare.share(options);
  },
  shareApp: async () => {
    const options = simpleShare.getOptions();
    simpleShare.share(options);
  },
};
export default simpleShare;
