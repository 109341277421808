/**
 * # authReducer.js
 *
 * The reducer for all the actions from the various log states
 */

/**
 * ## Imports
 * The InitialState for auth

 * formValidation for setting the form's valid flag
 */
const InitialState = require('./authInitialState').default;
const formValidation = require('./authFormValidation').default;

/**
 * ## Auth actions
 */
const {
  SESSION_TOKEN_SUCCESS,
  SESSION_TOKEN_FAILURE,

  DELETE_TOKEN_REQUEST,
  DELETE_TOKEN_SUCCESS,

  LOGOUT,
  REGISTER,
  LOGIN,
  FORGOT_PASSWORD,

  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,

  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,

  SET_STATE,
  GET_CLINIC_REQUEST,
  GET_CLINIC_SUCCESS,
  GET_CLINIC_FAILURE,

  // ON_SETTINGS_FORM_FIELD_CHANGE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,

  SETTINGS_UPDATE_REQUEST,
  SETTINGS_UPDATE_SUCCESS,
  SETTINGS_UPDATE_FAILURE,
  FF_CLINIC_REQUEST,
  FF_CLINIC_SUCCESS,
  FF_CLINIC_FAILURE,
  LOADER_SHOW,
  LOADER_HIDE,
  SET_UPDATES_SUCCESS,
  SET_UPDATES_FAILURE,
  SET_UPDATES_REQUEST,
  GET_TREATMENTS_REQUEST,
  GET_TREATMENTS_SUCCESS,
  GET_TREATMENTS_FAILURE,
  SET_VALUE_FORM,
  SET_COMPLETE_FORM,
  GET_SPECIALTY_SUCCESS,
  GET_PATIENT_REQUEST,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_FAILURE,
  GET_REFERENCE_REQUEST,
  GET_REFERENCE_SUCCESS,
  GET_REFERENCE_FAILURE,
  GET_FIREBASE_SUCCESS,

  GET_FAQ_REQUEST,
  GET_FAQ_SUCCESS,
  GET_FAQ_FAILURE,

  PDF_DOC_START,
  PDF_DOC_END,
  SET_FIRST_TIME,

  // GET_EDUCATION_REQUEST,
  GET_EDUCATION_SUCCESS,
  // GET_EDUCATION_FAILURE,
  GET_ICD_LINK_REQUEST,
  GET_ICD_LINK_SUCCESS,
  GET_ICD_LINK_FAILURE,

  RESTART_SCENARIO_REQUEST,
  RESTART_SCENARIO_SUCCESS,
  RESTART_SCENARIO_FAILURE,

  START_SCENARIO_REQUEST,
  START_SCENARIO_SUCCESS,
  START_SCENARIO_FAILURE,

  GET_GOALS_ARRAY_SUCCESS,
  GET_GOALS_ARRAY_FAILURE,
  GET_PASS_REGEX_SUCCESS,

  GET_POTENTIAL_STORY_REQUEST,
  GET_POTENTIAL_STORY_SUCCESS,
  GET_POTENTIAL_STORY_FAILURE,
  ADD_STORY_REQUEST,
  ADD_STORY_SUCCESS,
  ADD_STORY_FAILURE,
  REMOVE_SCENARIO_REQUEST,
  REMOVE_SCENARIO_SUCCESS,
  REMOVE_SCENARIO_FAILURE,

  GET_HTML_REQUEST,
  GET_HTML_SUCCESS,
  GET_HTML_FAILURE,
  GET_COUNTRIES_SUCCESS,
  GET_QUESTIONNAIRE_REQUEST,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_FAILURE,

  UPDATE_QUESTIONNAIRE_REQUEST,
  UPDATE_QUESTIONNAIRE_SUCCESS,
  UPDATE_QUESTIONNAIRE_FAILURE,
  GET_MY_CREDITS_REQUEST,
  GET_MY_CREDITS_SUCCESS,
  GET_MY_CREDITS_FAILURE,

  GET_AUTOICD10_REQUEST,
  GET_AUTOICD10_SUCCESS,
  GET_AUTOICD10_FAILURE,
  CHECK_USER_INVITE_REQUEST,
  CHECK_USER_INVITE_SUCCESS,
  CHECK_USER_INVITE_FAILURE,
  GET_CAMPAIGN_SUCCESS,
  GET_ON_BOARDING_SUCCESS,
  GET_TUTORIAL_SUCCESS,
  GET_YEARS_PRACTICE_SUCCESS,
  SEND_CERTIFICATE_REQUEST,
  SEND_CERTIFICATE_SUCCESS,
  SEND_CERTIFICATE_FAILURE,
  IS_PROJECT_OVER_SUCCESS,
  GET_ACTIVE_CAMPAIGN_SUCCESS,
  GET_TREATMENT_DURATION_SUCCESS,
  GET_LICENSE_SUCCESS,
  SET_URL_PARAMS,
  PROVIDER_CONNECT_REQUEST,
  PROVIDER_CONNECT_SUCCESS,
  PROVIDER_CONNECT_FAILURE,
  GET_MY_PROMOTIONS_SUCCESS,
  GET_DRUGS_INFO_REQUEST,
  GET_DRUGS_INFO_SUCCESS,
  GET_DRUGS_INFO_FAILURE,
  SINGLE_SIGN_ON_SUCCESS,
  SINGLE_SIGN_ON_REQUEST,
  SINGLE_SIGN_ON_FAILURE,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
  DYNAMIC_SSO_SUCCESS,
  DYNAMIC_SSO_REQUEST,
  DYNAMIC_SSO_FAILURE,
  GET_COUNTRY_BY_IP_SUCCESS,
} = require('../../lib/constants').default;

const initialState = new InitialState();
/**
 * ## authReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function authReducer(state = initialState, action) {
  let form = null;
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    /**
     * ### Requests start
     * set the form to fetching and clear any errors
     */

    case GET_PATIENT_REQUEST:
    case GET_TREATMENTS_REQUEST:
    case SET_UPDATES_REQUEST:
    case LOADER_SHOW:
    case GET_CLINIC_REQUEST:
    case SIGNUP_REQUEST:
    case LOGOUT_REQUEST:
    case LOGIN_REQUEST:
    case GET_SETTINGS_REQUEST:
    case SETTINGS_UPDATE_REQUEST:
    case FF_CLINIC_REQUEST:
    case GET_REFERENCE_REQUEST:
    case GET_FAQ_REQUEST:
    case PDF_DOC_START:
    case RESET_PASSWORD_REQUEST:
    case GET_ICD_LINK_REQUEST:
    case RESTART_SCENARIO_REQUEST:
    case START_SCENARIO_REQUEST:
    case ADD_STORY_REQUEST:
    case REMOVE_SCENARIO_REQUEST:
    case GET_POTENTIAL_STORY_REQUEST:
    case GET_QUESTIONNAIRE_REQUEST:
    case UPDATE_QUESTIONNAIRE_REQUEST:
    case GET_MY_CREDITS_REQUEST:
    case CHECK_USER_INVITE_REQUEST:
    case SEND_CERTIFICATE_REQUEST:
    case GET_HTML_REQUEST:
    case PROVIDER_CONNECT_REQUEST:
    case GET_DRUGS_INFO_REQUEST:
    case SINGLE_SIGN_ON_REQUEST:
    case CONTACT_US_REQUEST:
    case DYNAMIC_SSO_REQUEST:
    {
      const nextState = state.setIn(['form', 'isFetching'], true).setIn(['form', 'error'], null);
      return nextState;
    }
    /**
     * ### Logout state
     * The logged in user logs out
     * Clear the form's error and all the fields
     */
    case GET_MY_PROMOTIONS_SUCCESS:
      return state.setIn(['form', 'promotions'], action.payload);
    case GET_LICENSE_SUCCESS:
      return state.setIn(['form', 'licenseList'], action.payload);
    case GET_TREATMENT_DURATION_SUCCESS:
      return state.setIn(['form', 'treatmentDurationList'], action.payload);
    case GET_ACTIVE_CAMPAIGN_SUCCESS:
      return state.setIn(['form', 'activeCampaignList'], action.payload);
    case GET_CAMPAIGN_SUCCESS:
      return state.setIn(['form', 'campaign'], action.payload);
    case GET_ON_BOARDING_SUCCESS:
      return state.setIn(['form', 'onBoardingSlides'], action.payload);
    case GET_TUTORIAL_SUCCESS:
      return state.setIn(['form', 'tutorialSlides'], action.payload);
    case CHECK_USER_INVITE_SUCCESS:
      return state
        .setIn(['form', 'isFetching'], false)
        .setIn(['form', 'campaign'], action.payload.campaign)
        .setIn(['form', 'showBrand'], action.payload.showBrand)
        .setIn(['form', 'brandLogo'], action.payload.brandLogo)
        .setIn(['form', 'brandSlogan'], action.payload.brandSlogan)
        .setIn(['form', 'userInvited'], true);
    case CHECK_USER_INVITE_FAILURE:
      return state.setIn(['form', 'isFetching'], false).setIn(['form', 'campaign'], []);

    case GET_AUTOICD10_REQUEST:
      return state.setIn(['form', 'isFetchingExternal'], true);
    case GET_AUTOICD10_SUCCESS:
    case GET_AUTOICD10_FAILURE:
      return state.setIn(['form', 'isFetchingExternal'], false);
    case GET_PASS_REGEX_SUCCESS:
      return state.setIn(['form', 'passwordRegEx'], action.payload);

    case LOGOUT:
      return formValidation(
        state
          .setIn(['form', 'state'], action.type)
          .setIn(['form', 'error'], null)
          .setIn(['form', 'fields', 'username'], '')
          .setIn(['form', 'fields', 'email'], '')
          .setIn(['form', 'fields', 'password'], '')
          .setIn(['form', 'fields', 'passwordAgain'], '')
          // for register too
          .setIn(['form', 'register', 'fullName'], '')
          .setIn(['form', 'register', 'email'], '')
          .setIn(['form', 'register', 'username'], '')
          .setIn(['form', 'register', 'password'], '')
          .setIn(['form', 'register', 'passwordAgain'], '')
          .setIn(['form', 'register', 'specialty'], '')
          .setIn(['form', 'register', 'education'], '')
          .setIn(['form', 'register', 'workplace'], '')
          .setIn(['form', 'register', 'country'], ''),
      );
      /**
     * ### Loggin in state
     * The user isn't logged in, and needs to
     * login, register or reset password
     *
     * Set the form state and clear any errors
     */

    case IS_PROJECT_OVER_SUCCESS: {
      const {
        isProjectOver,
        isProjectOverHeader,
        isProjectOverText,
      } = action.payload;
      return state.setIn(['form', 'isProjectOver'], isProjectOver)
        .setIn(['form', 'isProjectOverHeader'], isProjectOverHeader)
        .setIn(['form', 'isProjectOverText'], isProjectOverText);
    }
    case SET_URL_PARAMS: {
      return state.setIn(['form', 'urlParameters'], action.payload);
    }
    case SET_FIRST_TIME: {
      return state.setIn(['form', 'firstTime'], action.payload);
    }
    case GET_FIREBASE_SUCCESS: {
      return state.setIn(['form', 'firebaseToken'], action.payload);
    }
    case GET_SPECIALTY_SUCCESS: {
      return state.setIn(['form', 'specialtyList'], action.payload);
    }

    case GET_EDUCATION_SUCCESS: {
      return state.setIn(['form', 'educationList'], action.payload);
    }
    case GET_YEARS_PRACTICE_SUCCESS: {
      return state.setIn(['form', 'yearsInPracticeList'], action.payload);
    }
    case SET_VALUE_FORM: {
      const { parent, field, value } = action.payload;
      return state.setIn(['form', parent, field], value);
    }
    case SET_COMPLETE_FORM: {
      const { parent, values } = action.payload;
      return state
        .setIn(['form', parent, 'fullName'], values.fullName)
        .setIn(['form', parent, 'email'], values.email)
        .setIn(['form', parent, 'password'], values.password)
        .setIn(['form', parent, 'doctorId'], values.doctorId)
        .setIn(['form', parent, 'selectedCampaign'], values.selectedCampaign)
        .setIn(['form', parent, 'didUserTermsAgree'], values.didUserTermsAgree);
    }

    case GET_COUNTRY_BY_IP_SUCCESS: {
      return state
        .setIn(['form', 'register', 'country'], action.payload);
    }
    case SIGNUP_SUCCESS: {
      return state
        .setIn(['form', 'register', 'username'], '')
        .setIn(['form', 'register', 'email'], '')
        .setIn(['form', 'register', 'password'], '')
        .setIn(['form', 'register', 'passwordAgain'], '')
        .setIn(['form', 'register', 'specialty'], '')
        .setIn(['form', 'register', 'education'], '')
        .setIn(['form', 'register', 'workplace'], '')
        .setIn(['form', 'register', 'zipCode'], '')
        .setIn(['form', 'register', 'country'], '')
        .setIn(['form', 'isFetching'], false);
    }

    case LOGIN:
    case REGISTER:
    case FORGOT_PASSWORD:
      return formValidation(
        state.setIn(['form', 'state'], action.type).setIn(['form', 'error'], null),
      );

    case GET_TREATMENTS_SUCCESS:
    case GET_TREATMENTS_FAILURE:
    case SET_UPDATES_SUCCESS:
    case SET_UPDATES_FAILURE:
    case LOADER_HIDE:
    case GET_CLINIC_SUCCESS:
    case GET_CLINIC_FAILURE:
    case SESSION_TOKEN_SUCCESS:
    case SESSION_TOKEN_FAILURE:
    case LOGIN_SUCCESS:
    case LOGOUT_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case GET_SETTINGS_SUCCESS:
    case SETTINGS_UPDATE_SUCCESS:
    case SETTINGS_UPDATE_FAILURE:
    case FF_CLINIC_SUCCESS:
    case FF_CLINIC_FAILURE:
    case GET_PATIENT_FAILURE:
    case GET_PATIENT_SUCCESS:
    case GET_REFERENCE_SUCCESS:
    case GET_REFERENCE_FAILURE:
    case GET_FAQ_SUCCESS:
    case GET_FAQ_FAILURE:
    case PDF_DOC_END:
    case GET_ICD_LINK_SUCCESS:
    case GET_ICD_LINK_FAILURE:
    case RESTART_SCENARIO_SUCCESS:
    case RESTART_SCENARIO_FAILURE:
    case START_SCENARIO_SUCCESS:
    case START_SCENARIO_FAILURE:
    case GET_GOALS_ARRAY_SUCCESS:
    case GET_GOALS_ARRAY_FAILURE:
    case GET_POTENTIAL_STORY_SUCCESS:
    case GET_POTENTIAL_STORY_FAILURE:
    case ADD_STORY_SUCCESS:
    case ADD_STORY_FAILURE:
    case REMOVE_SCENARIO_SUCCESS:
    case REMOVE_SCENARIO_FAILURE:
    case GET_HTML_SUCCESS:
    case GET_HTML_FAILURE:
    case GET_QUESTIONNAIRE_SUCCESS:
    case GET_QUESTIONNAIRE_FAILURE:
    case UPDATE_QUESTIONNAIRE_SUCCESS:
    case UPDATE_QUESTIONNAIRE_FAILURE:
    case GET_MY_CREDITS_SUCCESS:
    case GET_MY_CREDITS_FAILURE:
    case SEND_CERTIFICATE_SUCCESS:
    case SEND_CERTIFICATE_FAILURE:
    case PROVIDER_CONNECT_SUCCESS:
    case PROVIDER_CONNECT_FAILURE:
    case GET_DRUGS_INFO_FAILURE:
    case GET_DRUGS_INFO_SUCCESS:
    case SINGLE_SIGN_ON_FAILURE:
    case SINGLE_SIGN_ON_SUCCESS:
    case DYNAMIC_SSO_SUCCESS:
    case DYNAMIC_SSO_FAILURE:
    case CONTACT_US_SUCCESS:
    case CONTACT_US_FAILURE:
      return state.setIn(['form', 'isFetching'], false);
    /**
     *
     * The fetching is done, but save the error
     * for display to the user
     */
    case SIGNUP_FAILURE:
    case LOGOUT_FAILURE:
    case LOGIN_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case GET_SETTINGS_FAILURE:
      return state.setIn(['form', 'isFetching'], false).setIn(['form', 'error'], action.payload);
    /**
     * ### Hot Loading support
     *
     * Set all the field values from the payload
     */
    case SET_STATE:
      ({ form } = JSON.parse(action.payload).auth);

      return state
        .setIn(['form', 'state'], form.state)
        .setIn(['form', 'disabled'], form.disabled)
        .setIn(['form', 'error'], form.error)
        .setIn(['form', 'isValid'], form.isValid)
        .setIn(['form', 'isFetching'], form.isFetching)
        .setIn(['form', 'fields', 'username'], form.fields.username)
        .setIn(['form', 'fields', 'usernameHasError'], form.fields.usernameHasError)
        .setIn(['form', 'fields', 'email'], form.fields.email)
        .setIn(['form', 'fields', 'emailHasError'], form.fields.emailHasError)
        .setIn(['form', 'fields', 'password'], form.fields.password)
        .setIn(['form', 'fields', 'passwordHasError'], form.fields.passwordHasError)
        .setIn(['form', 'fields', 'passwordAgain'], form.fields.passwordAgain)
        .setIn(['form', 'fields', 'passwordAgainHasError'], form.fields.passwordAgainHasError);

      // return next;

    case DELETE_TOKEN_REQUEST:
    case DELETE_TOKEN_SUCCESS:
      /**
       * no state change, just an ability to track action requests...
       */
      return state;
    case GET_COUNTRIES_SUCCESS:
      return state.setIn(['form', 'countriesList'], action.payload);
    default:
      return state;
  }

  /**
   * ## Default
   */
  // return state;
}
