import { Record } from 'immutable';

// this is clinic
const InitialState = Record(
  {
    currentClinics: null,
    potentialDefaultStories: [],
    hasOneDone: false,
    completedCases: 0,
    completedVisits: 0,
  },
  'clinic',
);
export default InitialState;
