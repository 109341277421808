import React, { Component } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import Modal from 'react-native-modal';
import {
  design, TextMontserrat, TextSF, global, defaultStyle, I18n,
} from '../../lib/general';
import TextInputWithRTL from '../general/TextInputWithRTL';
import HoverTouchableOpacity from './hover/HoverTouchableOpacity';

const styles = StyleSheet.create({
  container: {
    height: 187,
    width: '90%',
    maxWidth: global.alertMaxWidth,
    backgroundColor: design.colors.white,
    borderRadius: 3,
    overflow: 'hidden',
    alignSelf: 'center',
    // alignItems: 'center',
  },
  modal: {
    flex: 1,
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  textAreaWrap: {
    backgroundColor: design.colors.baseGray,
    padding: 17,
    paddingTop: 15,
    marginBottom: 20,
  },
  header: {
    marginTop: 10,
    color: design.colors.clinicHeader,
    fontSize: design.text.semiReg,
    fontWeight: '500',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  subHeader: {
    marginTop: 18,
    color: design.colors.clinicSub,
    fontSize: design.text.small,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // fontWeight: '500'
  },
  btnContainer: {
    flexDirection: 'row',
    height: 55,
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  btnText: {
    color: design.colors.clinicHeader,
    fontSize: design.text.small,
    fontWeight: 'bold',
  },
  btn: {
    flex: 1,
    height: '100%',
    backgroundColor: design.colors.loginBtn,
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirm: {
    backgroundColor: design.colors.loginBtn,
  },
  cancel: {
    backgroundColor: design.colors.baseGray,
  },
  hover: {
    opacity: 0.75,
  },
  textStyle: {
    // backgroundColor: 'red',
    height: 115,
    textAlignVertical: 'top',
  },
  textStyleDesktop: {
    height: 250,
  },
  textContainerDesktop: {
    height: 250,
  },
  innerContainer:{paddingHorizontal:20, paddingBottom:20}
});
class Alert extends Component {
  constructor(props) {
    super(props);
    this.modal = null;
    this.state = {
      header: '',
      subHeader: '',
      isSingle: true,
      cancelFunc: () => {
        // console.log('cancelFunc');
      },
      cancelText: 'CANCEL',
      confirmFunc: () => {
        // console.log('confirmFunc');
      },
      confirmText: 'OK',
      isVisible: false,
      backdropFunc: () => {
        // console.log('backdropFunc');
      },
      image: '',
      isPrompt:false,
    };
    this.onPressConfirm = this.onPressConfirm.bind(this);
    this.onPressCancel = this.onPressCancel.bind(this);
    this.clearText = this.clearText.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onPressCancel() {
    const { cancelFunc } = this.state;
    this.close(cancelFunc);
    this.clearText();
  }

  onPressConfirm() {
    const { confirmFunc } = this.state;
    this.close(confirmFunc);
    this.clearText();
  }

  onBackdropPress=() => {
    const { onBackdropPress } = this.props;
    const { backdropFunc } = this.state;
    if (onBackdropPress) {
      onBackdropPress();
    } else {
      this.close(backdropFunc);
    }
  }

  clearText() {
    const header = '';
    const subHeader = '';
    this.setState({ header, subHeader, isPrompt: false });
  }

  alert(header, subHeader, btnArr, backdropFunc, image, isPrompt) {
    if (isPrompt){
      this.setState({ isPrompt: true })
    }
    if (header) {
      this.setState({ header });
    }
    if (subHeader) {
      this.setState({ subHeader });
    }
    if (btnArr && btnArr.length === 1) {
      const confirm = btnArr[0];
      this.setState({
        isSingle: true,
        confirmText: confirm.text,
        confirmFunc: confirm.onPress,
      });
    }
    if (btnArr && btnArr.length === 2) {
      const cancel = btnArr[0];
      const confirm = btnArr[1];
      this.setState({
        isSingle: false,
        confirmText: confirm.text,
        confirmFunc: confirm.onPress,
        cancelText: cancel.text,
        cancelFunc: cancel.onPress,
      });
    }
    if (backdropFunc) {
      this.setState({
        backdropFunc,
      });
    }
    if (image) {
      this.setState({ image });
    }
    this.open();
  }

  close(callback) {
    this.setState({ isVisible: false }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  }

  open() {
    this.setState({ isVisible: true });
  }

  toggle() {
    const { isVisible } = this.state;
    this.setState({ isVisible: !isVisible });
  }

  render() {
    const {
      // isDisabled,
      image,
      header,
      subHeader,
      isSingle,
      cancelText,
      confirmText,
      isVisible,
      isPrompt,
    } = this.state;
    const { onChangeText, value, isDesktop } = this.props;
    return (
      <Modal
        isVisible={isVisible}
        style={[styles.modal]}
        backdropColor={design.colors.backdrop}
        backdropOpacity={0.8}
        onBackdropPress={this.onBackdropPress}
        supportedOrientations={['portrait', 'landscape']}
        useNativeDriver
        hideModalContentWhileAnimating
      >
        <View style={[styles.container, isPrompt && {height:280}]}>
          <View style={styles.innerContainer} >
          {
            image !== '' && (
            <View style={styles.imageContainer}>
              <Image source={image} style={styles.image} />
            </View>
            )
          }
          <View style={styles.textContainer}>
            <TextMontserrat style={styles.header}>{header}</TextMontserrat>
            <TextSF style={styles.subHeader}>{subHeader}</TextSF>
          </View>
          {isPrompt && <View style={styles.textAreaWrap}>
            <TextInputWithRTL
              underlineColorAndroid={design.colors.transparent}
              multiline
              style={[
                styles.textStyle,
                defaultStyle.textSF,
                isDesktop && styles.textStyleDesktop,
                { maxHeight:60}
              ]}
              onChangeText={onChangeText}
              placeholder={I18n.t('Patient.notesFreeTextStatusChange')}
              value={value}
              autoGrow
              maxHeight={60}
            />
          </View>}
          </View>
          <View style={styles.btnContainer}>
            {!isSingle && (
              <HoverTouchableOpacity
                style={[styles.btn, styles.cancel]}
                onPress={this.onPressCancel}
                hoverStyle={styles.hover}

              >
                <TextMontserrat style={styles.btnText}>{cancelText}</TextMontserrat>
              </HoverTouchableOpacity>
            )}
            <HoverTouchableOpacity
              style={[styles.btn, styles.confirm]}
              onPress={this.onPressConfirm}
              hoverStyle={styles.hover}
            >
              <TextMontserrat style={styles.btnText}>{confirmText}</TextMontserrat>
            </HoverTouchableOpacity>
          </View>
        </View>
      </Modal>
    );
  }
}

export default Alert;
