import React, { PureComponent } from 'react';
import {
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
} from 'react-native';
// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialIcons';
import orderBy from 'lodash/orderBy';
import {
  design, TextMontserrat, I18n,
} from '../../../lib/general';
import HeaderMonths from '../../general/HeaderMonths';
import HeaderIsClinic from '../../general/HeaderIsClinic';
import HoverTouchableOpacity from '../../general/hover/HoverTouchableOpacity';
import ButtonFull from '../../general/ButtonFull';

function mapStateToProps(state) {
  return {
    patient: {
      defaultStory: state.patient.defaultStory,
      currentPatient: state.patient.currentPatient,
      selectedColor: state.patient.selectedColor,
      currentDefaultStory: state.patient.currentDefaultStory,
      currentDefaultScenario: state.patient.currentDefaultScenario,
    },
    global: {
      currentUser: state.global.currentUser,
      isWeb: state.global.isWeb,
      isDesktop: state.global.isDesktop,
    },
  };
}

const styles = StyleSheet.create({
  person: {
    borderBottomColor: design.colors.baseGray,
    borderBottomWidth: 1,
    borderTopWidth: 2,
    borderTopColor: design.colors.baseGray,
    backgroundColor: design.colors.white,
    alignItems: 'center',
    padding: 20,
  },
  imageContainer: {
    borderRadius: 48,
    height: 96,
    width: 96,
  },
  imageContainerSmaller: {
    borderRadius: 30,
    height: 60,
    width: 60,
  },
  imageContainerDesktop: {
    borderRadius: 48,
    height: 187,
    width: 187,
  },
  imageBorder: {
    backgroundColor: design.colors.borderImageGray,
    borderRadius: 54,
    height: 108,
    width: 108,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  imageBorderSmaller: {
    borderRadius: 35,
    height: 70,
    width: 70,
  },
  imageBorderDesk: {
    backgroundColor: design.colors.borderImageGray,
    borderRadius: 94,
    height: 187,
    width: 187,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  ImageWrapperDesk: {
    // backgroundColor: 'green',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: 81,
    flexShrink: 1,
    flexGrow: 0,
    flexBasis: '250px',
  },
  nameDetailsWrapper: {
    flex: 1,
  },
  headContainer: {
    marginTop: 15,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headContainerSmaller: {
    marginTop: 10,
  },
  headContainerDesktop: {
    marginBottom: 15,
  },
  head: {
    fontSize: design.text.twenty,
    color: design.colors.clinicHeader,
    fontWeight: 'bold',
  },
  headSmaller: {
    fontSize: design.text.eightTeen,
  },
  text: {
    fontSize: design.text.small,
    color: design.colors.clinicSub,
  },
  descriptionDesk: {
    fontSize: design.text.semiReg,
    color: design.colors.clinicSub,
    marginTop: 5,
  },
  monthContainer: {
    position: 'absolute',
    top: 12,
    right: 20,
    flex: 1,
    flexDirection: 'row',
  },
  icoContainer: {
    position: 'absolute',
    top: 9,
    left: 20,
  },
  deskWrapper: {
    // backgroundColor: 'pink',
    width: '100%',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 60,
    justifyContent: 'space-between',
  },
  headDesks: {
    fontSize: design.text.twentyFive,
    color: design.colors.clinicHeader,
    fontWeight: '500',
  },
  patientNameDesk: {
    fontSize: design.text.title2,
    color: design.colors.clinicHeader,
    fontWeight: '600',
  },
  backContainer: {
    flexDirection: 'row',
    paddingRight: 20,
  },
  backText: {
    color: design.colors.clinicHeader,
    fontSize: design.text.small,
    fontWeight: 'normal',
    alignSelf: 'center',
    textAlign: 'center',
  },
  hover: {
    backgroundColor: design.colors.baseGray,
  },
  btnContainer: { alignItems: 'center' },
  btnStyle: { alignSelf: 'center', width: 350, backgroundColor: design.colors.loginBtn },
  btnView: { padding: 20, backgroundColor: design.colors.white },
});

class PatientImageName extends PureComponent {
  backPress = () => {
    const { backPress } = this.props;
    if (backPress) {
      backPress();
    }
  };

  render() {
    const {
      patient,
      maxWidth,
      showMonths,
      showBack,
      isDesktop,
      fromScreen,
      hideDiagnosis,
      smaller,
      disableCaseImage,
      showButton,
      onPress,
      disabledButton,
    } = this.props;
    const { selectedColor, currentPatient } = patient;
    const scenarioTicks = currentPatient
      ? currentPatient.data.sim.data.scenarioTicks
      : 1;
    // debugger;
    const defaultStory = patient.currentDefaultStory;
    const defaultScenario = patient.currentDefaultScenario;
    const orderDiagnosis = orderBy(defaultScenario.diagnosis, ['startDate', 'endDate'], ['desc', 'desc']);
    if (!defaultStory) return null;
    return (
      <>
        <View style={[styles.person, maxWidth && { width: maxWidth }]}>
          {showBack && (
          <HoverTouchableOpacity
            style={styles.icoContainer}
            onPress={this.backPress}
            hoverStyle={styles.hover}
          >
            <View style={styles.backContainer}>
              <Icon
                name="chevron-left"
                size={30}
                color={design.colors.clinicHeader}
              />
              <TextMontserrat style={styles.backText}>
                {I18n.t('Clinic.headerBack')}
              </TextMontserrat>
            </View>
          </HoverTouchableOpacity>
          )}
          {showMonths && (
          <View style={styles.monthContainer}>
            <HeaderMonths
              selectedColor={selectedColor}
              months={scenarioTicks}
            />
          </View>
          )}
          {!isDesktop ? (
            [
              <View
                key="imageBorder"
                style={[
                  styles.imageBorder,
                  smaller && styles.imageBorderSmaller,
                ]}
              >
                <Image
                  style={[
                    styles.imageContainer,
                    smaller && styles.imageContainerSmaller,
                  ]}
                  source={{
                    uri: defaultStory.imageUrl,
                  }}
                />
              </View>,
              <View
                key="headContainer"
                style={[
                  styles.headContainer,
                  smaller && styles.headContainerSmaller,
                ]}
              >
                <HeaderIsClinic disableCaseImage={disableCaseImage} />
                <TextMontserrat
                  style={[styles.head, smaller && styles.headSmaller]}
                  numberOfLines={1}
                >
                  {`${defaultStory.name}, ${I18n.t(
                    'Patient.ageAndGender',
                    {
                      age:
                      defaultStory.age
                      + Math.round(scenarioTicks / 12),
                      gender:
                      defaultStory.gender === 'male'
                        ? I18n.t('Patient.maleAbbreviation')
                        : I18n.t('Patient.femaleAbbreviation'),
                    },
                  )}`}
                </TextMontserrat>
              </View>,
            ]
          ) : (
            <View style={styles.deskWrapper}>
              <View style={styles.nameDetailsWrapper}>
                <View>
                  <TextMontserrat style={[styles.headDesks]} numberOfLines={1}>
                    {I18n.t('Patient.storyIntro')}
                  </TextMontserrat>
                </View>

                <View
                  style={[
                    styles.headContainer,
                    isDesktop && styles.headContainerDesktop,
                  ]}
                >
                  <HeaderIsClinic disableCaseImage={disableCaseImage} />
                  <TextMontserrat
                    style={[styles.patientNameDesk]}
                    numberOfLines={1}
                  >
                    {`${defaultStory.name}, ${I18n.t(
                      'Patient.ageAndGender',
                      {
                        age:
                        defaultStory.age
                        + Math.round(scenarioTicks / 12),
                        gender:
                        defaultStory.gender === 'male'
                          ? I18n.t('Patient.maleAbbreviation')
                          : I18n.t('Patient.femaleAbbreviation'),
                      },
                    )}`}
                  </TextMontserrat>
                </View>
                {!hideDiagnosis && (
                <View>
                  {orderDiagnosis.map((diag) => (
                    <TextMontserrat
                      key={fromScreen + diag.num + diag.text}
                      style={[styles.descriptionDesk]}
                    >
                      {diag.text}
                    </TextMontserrat>
                  ))}
                </View>
                )}
              </View>

              <View style={styles.ImageWrapperDesk}>
                <View style={styles.imageBorderDesk}>
                  <Image
                    style={styles.imageContainerDesktop}
                    source={{
                      uri: defaultStory.imageUrl,
                    }}
                  />
                </View>
              </View>
            </View>
          )}
        </View>
        {
        showButton && onPress && (
        <View style={[styles.btnView]}>
          <ButtonFull
            analyticsData={`${I18n.t('Patient.start')} ${defaultStory.name}`}
            containerStyle={styles.btnContainer}
            buttonStyle={[styles.btnStyle]}
            title={I18n.t('Patient.start')}
            onPress={onPress}
            disabled={disabledButton}
            testID="startPatient"
          />
        </View>
        )
      }
      </>
    );
  }
}

export default connect(mapStateToProps, null)(PatientImageName);
