import { appAuthToken } from '../../lib/AppAuthToken';
import { firebaseApp } from '../../containers/virtual/FirebaseNative';

const BackendFactory = require('../../lib/BackendFactory').default;

const {
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_UNREAD,
  SET_NOTIFICATIONS_LOGGED_IN,
} = require('../../lib/constants').default;

export function setNotificationsAction(val) {
  return {
    type: SET_NOTIFICATIONS,
    payload: val,
  };
}
export function setNotificationLoggedIn(loggedIn) {
  return {
    type: SET_NOTIFICATIONS_LOGGED_IN,
    payload: loggedIn,
  };
}
export function setNotificationsUnReadAction(num) {
  return {
    type: SET_NOTIFICATIONS_UNREAD,
    payload: num,
  };
}

export function setNotifications(notifications) {
  return (dispatch) => {
    dispatch(setNotificationsAction(notifications));
  };
}

export function setNotificationsUnRead(num) {
  return (dispatch) => {
    dispatch(setNotificationsUnReadAction(num));
  };
}

export function firebaseLogin() {
  return (dispatch) => appAuthToken.saveFirebaseToken().then((token) => {
    // debugger
    if (token) {
      const t = token;
      // debugger
      // console.log('firebase login')
      firebaseApp
        .auth()
        .signInWithCustomToken(t)
        .then((a) => {
          // debugger
          dispatch(setNotificationLoggedIn(true));
        })
        .catch((error) => {
          console.log(error);
          // debugger
          appAuthToken.deleteFirebaseToken().then(() => {});
          dispatch(setNotificationLoggedIn(false));
          //  throw error
        });
    }
  });
}

export function firebaseLogout() {
  return (dispatch) => firebaseApp
    .auth()
    .signOut()
    .then(() => {
      // Sign-out successful.
      dispatch(setNotificationLoggedIn(false));
    })
    .catch((error) => {
      console.log(error);
      // An error happened.
    });
}

export function sendEventsToFirebase(event) {
  return appAuthToken
    .getSessionToken()
    .then((token) => {
      BackendFactory(token).sendEventsToFirebase(event).then((json) => json);
    })
    .catch((error) => {
      console.warn('sendEventsToFirebase', error);
      return null;
    });
}
