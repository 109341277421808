import {
  StyleSheet, Platform, Dimensions,
} from 'react-native';
import design from './design';
import global from './global';
import { config } from './config';

const { width } = Dimensions.get('window');
const MAX_WIDTH = width > global.mobileMaxWidth && config.isWeb ? global.modalMaxWidthDesktop : '100%';

const defaultStyle = StyleSheet.create({
  shadow: {
    shadowOffset: { width: 0, height: 7 },
    shadowColor: design.colors.shadow,
    shadowOpacity: 0.74,
    shadowRadius: 9,
    elevation: 4,
    overflow: 'hidden',
    borderRadius: 3,
  },
  // android helper for fonts
  // https://blog.bam.tech/developper-news/add-a-custom-font-to-your-react-native-app
  textMontserrat: {
    fontFamily: 'Montserrat-Regular',
  },
  textSF: {
    fontFamily: 'SFUIText-Regular',
  },
  textOswald: {
    fontFamily: 'Oswald-Medium',
  },
  LoginButton: {
    backgroundColor: design.colors.loginBtn,
    height: 55,
  },
  LoginButtonText: {
    color: design.colors.clinicHeader,
    fontSize: design.text.small,
    fontWeight: 'bold',
  },
  htmlBaseText: {
    fontFamily: 'SFUIText-Regular',
    fontSize: design.text.small,
    color: design.colors.clinicSub,
    fontWeight: 'normal',
    margin: 0,
    padding: 0,
    // lineHeight: design.text.small
    // marginTop: 5
  },
  htmlBaseTextDesktop: {

    fontFamily: 'SFUIText-Regular',
    fontSize: design.text.semiReg,
    color: design.colors.clinicSub,
    fontWeight: 'normal',
    margin: 0,
    padding: 0,

  },
  htmlBaseHeader: {
    color: design.colors.clinicHeader,
    fontSize: design.text.semiReg,
    fontWeight: '500',
    fontFamily: 'Montserrat',
  },
  fullHeightWeb: {
    ...Platform.select({
      default: {},
      web: {
        height: 'inherit',
      },
    }),
  },
  buttonWidthWeb: {
    ...Platform.select({
      default: {},
      web: {
        width: MAX_WIDTH,
        alignSelf: 'center',
      },
    }),
  },
  margin20HorizontalWeb: {
    ...Platform.select({
      default: {},
      web: {
        marginHorizontal: 20,
      },
    }),
  },
  directionLTR: {
    ...Platform.select({
      default: { direction: 'ltr' },
      web: { writingDirection: 'ltr' },
      android: {
        // direction: 'ltr',
        // writingDirection: 'ltr',
        // // layoutDirection: 'ltr',
        // flexDirection: 'row-reverse',
        // textAlign: 'left',
      },
    }),
  },
  collapseContainer: {
    ...Platform.select({
      default: { flex: 1 },
      web: { flex: 'none' },
    }),
  },
  noFlexOnWebContainer: {
    ...Platform.select({
      default: { flex: 1 },
      web: { flex: 'none' },
    }),
  },
  webRTLFontFamily: {
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
  flexOnlyWeb: {
    ...Platform.select({
      default: { },
      web: { flex: 1 },
    }),
  },
});

export default defaultStyle;
