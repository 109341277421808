/**
 * # globalReducer.js
 *
 *
 */

/**
 * ## Imports
 * The InitialState for auth

 * formValidation for setting the form's valid flag
 */
import InitialState from './patientInitialState';

const {
  // GET_PATIENT_REQUEST,
  GET_PATIENT_SUCCESS,
  // GET_PATIENT_FAILURE,
  SET_PATIENT_ID,
  SET_CLINIC_ID,
  SET_UPDATES_INITIAL,
  SET_UPDATES_SUCCESS,
  GET_TREATMENTS_SUCCESS,
  GET_GLOBAL_SUCCESS,
  SET_UPDATES_TEMP,

  GET_AUTOICD10_SUCCESS,
  GET_AUTODRUGS_SUCCESS,
  CREATE_NOTES,
  GET_DRUGS_INFO_SUCCESS,
  SET_UPDATES_MODAL,
  SET_UPDATES_COLOR,
  SET_READ_ONLY,
  SET_DATA_READ_ONLY,
  GET_MY_PEER_STATS_SUCCESS,
  GET_GOALS_ARRAY_SUCCESS,
  GET_DRUGS_INFO_FAILURE,
  SET_FEEDBACK_INITIAL,
  SET_FILTERED_ARRAY_BY_PROFILE,
  SET_PROFILES_ARRAY,
  SET_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_MY_CREDITS_SUCCESS,
  SET_SELECTED_CREDIT,
  GET_CONFIG_SUCCESS,
  GET_EDOCATE_WAY_SUCCESS,
  SET_PARAMETERS_WITH_SCENARIO_DATA,
  CLEAR_PATIENT,
  SET_DEFAULT_SCENARIO,
  SET_DEFAULT_STORY,
} = require('../../lib/constants').default;

const initialState = new InitialState();
/**
 * ## globalReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function patientReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.merge(state);

  switch (action.type) {
    /**
     * ### Save the sessionToken
     */
    // case GET_PATIENT_REQUEST:
    //   return state.set('sessionToken', action.payload)

    case GET_EDOCATE_WAY_SUCCESS:
      return state.set('edocateWay', action.payload);
    case SET_SELECTED_CREDIT:
      return state.set('selectedCredit', action.payload);
    case GET_MY_CREDITS_SUCCESS:
      return state.set('credits', action.payload);
    case SET_QUESTIONNAIRE:
    case GET_QUESTIONNAIRE_SUCCESS:
      return state.set('questionnaire', action.payload);
    case SET_PROFILES_ARRAY: {
      return state.set('profiles', action.payload);
    }
    case SET_FILTERED_ARRAY_BY_PROFILE: {
      const { arr, propertyName } = action.payload;
      return state.set(propertyName, arr);
    }
    case GET_MY_PEER_STATS_SUCCESS: {
      return state.set('peerStats', action.payload);
    }
    case GET_GOALS_ARRAY_SUCCESS: {
      return state.set('goalsArray', action.payload);
    }

    case SET_UPDATES_SUCCESS: {
      // return state.set('setUserUpdatesResponse', action.payload)
      const { data } = action.payload;
      delete action.payload.data;
      const { currentPatient } = state;
      currentPatient.data = data;
      if (data) {
        return state
          .set('currentPatient', currentPatient)
          .set('setUserUpdatesResponse', action.payload);
      }
      return state.set('setUserUpdatesResponse', action.payload);
    }
    case SET_DATA_READ_ONLY: {
      const cp = state.currentPatient;
      cp.data = action.payload.data;
      return state
        .setIn(['currUpdates', 'treatments'], action.payload.updates.treatments)
        .setIn(['currUpdates', 'labs'], action.payload.updates.labs)
        .setIn(['currUpdates', 'diagnosis'], action.payload.updates.diagnosis)
        .setIn(['currUpdates', 'next'], action.payload.updates.next)
        .setIn(['currUpdates', 'tempNumber'], action.payload.updates.tempNumber)
        .setIn(['currUpdates', 'notes'], action.payload.updates.notes)
        .setIn(['currUpdates', 'notesFreeText'], action.payload.updates.notesFreeText)
        .setIn(['currUpdates', 'physicals'], action.payload.updates.physicals)
        .setIn(['currUpdates', 'interviews'], action.payload.updates.interviews)
        .set('currentPatient', cp);
    }

    case GET_PATIENT_SUCCESS:
      return state
        .set('currentPatient', action.payload.preparedUserData)
        .set('reducedLabs', action.payload.preparedGlobalData.labs)
        .set('reducedPhysicals', action.payload.preparedGlobalData.physicals)
        .set('refreshPatient', !state.refreshPatient);
    case CLEAR_PATIENT:
      return state.set('currentPatient', null);
    case SET_PATIENT_ID:
      return state.set('patientId', action.payload);
    case SET_READ_ONLY:
      return state.set('readOnly', action.payload);
    case SET_CLINIC_ID:
      return state.set('currClinic', action.payload);
    case SET_DEFAULT_SCENARIO:
      return state.set('currentDefaultScenario', action.payload);
    case SET_DEFAULT_STORY:
      return state.set('currentDefaultStory', action.payload);
    case CREATE_NOTES:
      return state.setIn(['currUpdates', 'notes'], action.payload);

    case GET_TREATMENTS_SUCCESS:
      return state.set('treatments', action.payload);
    case GET_CONFIG_SUCCESS:
      return state
        .set('serverConfig', action.payload);
    case GET_GLOBAL_SUCCESS:
      return state
        .set('treatments', action.payload.treatment)
        .set('labs', action.payload.lab)
        .set('labsFull', action.payload.labs)
        .set('physicals', action.payload.physical)
        .set('parameters', action.payload.parameter)
        .set('parametersCopy', action.payload.parameter)
        .set('serverConfig', action.payload.config);
    case SET_PARAMETERS_WITH_SCENARIO_DATA:
      return state.set('parameters', action.payload);
    case GET_AUTOICD10_SUCCESS:
      return state.setIn(['autoComplete', 'icd10'], action.payload);
    case GET_AUTODRUGS_SUCCESS:
      return state.setIn(['autoComplete', 'drugs'], action.payload);
    case GET_DRUGS_INFO_FAILURE:
      return state.setIn(['autoComplete', 'drugInfo'], {});
    case GET_DRUGS_INFO_SUCCESS:
      return state.setIn(['autoComplete', 'drugInfo'], action.payload);
    case SET_UPDATES_COLOR:
      return state.set('selectedColor', action.payload);
    case SET_UPDATES_MODAL:
      return state.setIn(['modal', 'showPatientModal'], action.payload);
    case SET_FEEDBACK_INITIAL:
      return state.set('setUserUpdatesResponse', {});
    case SET_UPDATES_INITIAL:
      return state
        .setIn(['currUpdates', 'treatments'], action.payload.treatments)
        .setIn(['currUpdates', 'labs'], action.payload.labs)
        .setIn(['currUpdates', 'diagnosis'], action.payload.diagnosis)
        .setIn(['currUpdates', 'next'], action.payload.next)
        .setIn(['currUpdates', 'tempNumber'], action.payload.tempNumber)
        .setIn(['currUpdates', 'notes'], action.payload.notes)
        .setIn(['currUpdates', 'notesFreeText'], action.payload.notesFreeText)
        .setIn(['currUpdates', 'physicals'], action.payload.physicals)
        .setIn(['currUpdates', 'interviews'], action.payload.interviews)
        .setIn(['currUpdates', 'references'], action.payload.references)
        .set('currentPatient', null)
        .set('peerStats', [])
        .set('goalsArray', null);
    case SET_UPDATES_TEMP:
      return state
        .setIn(['currUpdates', 'treatments'], action.payload.treatments)
        .setIn(['currUpdates', 'labs'], action.payload.labs)
        .setIn(['currUpdates', 'diagnosis'], action.payload.diagnosis)
        .setIn(['currUpdates', 'next'], action.payload.next)
        .setIn(['currUpdates', 'tempNumber'], action.payload.tempNumber)
        .setIn(['currUpdates', 'notes'], action.payload.notes)
        .setIn(['currUpdates', 'notesFreeText'], action.payload.notesFreeText)
        .setIn(['currUpdates', 'physicals'], action.payload.physicals)
        .setIn(['currUpdates', 'interviews'], action.payload.interviews)
        .setIn(['currUpdates', 'references'], action.payload.references)
        .setIn(['currUpdates', 'nextTimeStatus'], action.payload.nextTimeStatus);
    default:
      return state;
  }
}
