/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import { useSelector, useDispatch } from 'react-redux';
import SingleGoal from '../patient/end/SingleGoal';
import ButtonFull from './ButtonFull';
import { design, I18n } from '../../lib/general';
import { referenceActions, patientActions } from '../../reducers/actions';

const styles = StyleSheet.create({
  wrapper: {
    // height: '100%',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: design.colors.white,
    borderRadius: 3,
    overflow: 'hidden',
  },
  modal: {
    flex: 1,
    paddingVertical: 30,

  },
});

const GoalModal = (props) => {
  const { isVisible, closeModal } = props;
  if (!isVisible) return null;
  const [actualGoal, setActualGoal] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const articles = useSelector((state) => state.reference.articles);
  const references = useSelector((state) => state.reference.references);
  const isDesktop = useSelector((state) => state.global.isDesktop);
  const goal = useSelector((state) => state.goal.goal);
  const goalsArray = useSelector((state) => state.patient.goalsArray) || [];

  useEffect(() => {
    if (goal?.__type === 'Pointer') {
      const fromArray = goalsArray.find((item) => item.objectId === goal.objectId);
      if (!fromArray && isFetching !== goal.objectId) {
        dispatch(patientActions.getGoalsArray([(goal.objectId)]));
        setIsFetching(goal.objectId);
      } else {
        setActualGoal(fromArray);
      }
    } else {
      setActualGoal(goal);
    }
  }, [goal, goalsArray, isVisible]);

  const onCloseModal = () => {
    if (closeModal && typeof closeModal === 'function') {
      closeModal();
    }
    // setIsVisible(false);
  };
  const setPDFDocStart = (a, b, c) => dispatch(referenceActions.setPDFDocStart(a, b, c));
  const setPDFDocEnd = (a, b, c) => {
    closeModal();
    return dispatch(referenceActions.setPDFDocEnd(a, b, c));
  };
  const getArticles = (a, b, c) => dispatch(referenceActions.getArticles(a, b, c));
  const setRefItem = (a, b, c) => {
    closeModal();
    return dispatch(referenceActions.setRefItem(a, b, c));
  };
  return (
    <Modal
      style={[styles.modal]}
      isVisible={isVisible}
      coverScreen
      backdropColor={design.colors.backdrop}
      backdropOpacity={0.8}
      backdropPressToClose
      supportedOrientations={['portrait', 'landscape']}
      useNativeDriver
    >
      {isVisible && actualGoal && (
        <View style={styles.wrapper}>
          <SingleGoal
            goal={actualGoal}
            setRefItem={setRefItem}
            references={references}
            setPDFDocStart={setPDFDocStart}
            setPDFDocEnd={setPDFDocEnd}
            getArticles={getArticles}
            isDesktop={isDesktop}
            articles={articles}
            noMargin
          />
          <ButtonFull
            onPress={onCloseModal}
            buttonStyle={{ backgroundColor: design.colors.loginBtn }}
            title={I18n.t('Patient.done').toUpperCase()}
          />
        </View>

      )}
    </Modal>
  );
};
export default GoalModal;
