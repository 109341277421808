import React, { PureComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import MoreSingle from './MoreSingle';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

class MoreList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, onPress, isAdmin } = this.props;
    return (
      <View style={styles.container}>
        {data.map((item) => {
          if (item.adminOnly && !isAdmin) return null;
          return <MoreSingle key={item.name} data={item} onPress={onPress} />;
        })}
      </View>
    );
  }
}

export default MoreList;
