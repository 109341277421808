import { Record } from 'immutable';

// this is clinic
const InitialState = Record(
  {
    currentPatient: null,
    refreshPatient: true,
    patientId: null,
    clinicId: null,
    currClinic: null,
    // currUpdates: {   treatments: { add: [], remove: [] },   diagnosis: { add: [],
    // remove: [] },   labs: { add: [], remove: [] },   next: "" },
    currUpdates: new (Record({
      treatments: { add: [], remove: [] },
      diagnosis: { add: [], remove: [] },
      labs: { add: [], remove: [] },
      physicals: { add: [], remove: [] },
      interviews: { add: [], remove: [] },
      notes: {}, // maybe should generate them on the server?
      notesFreeText: '',
      next: '',
      tempNumber: 0,
      references: { add: [], remove: [] },
      nextTimeStatus: 0,
    }))(),
    treatments: [],
    labs: [],
    labsFull: [],
    physicals: [],
    physicalsFilteredProfiles: [],
    labsFilteredProfiles: [],
    treatmentsProfiles: [],
    profiles: [],
    interviews: [],
    parameters: [],
    parametersCopy: [],
    serverConfig: {},
    autoComplete: new Record({ drugs: [], icd10: [], drugInfo: {} })(),
    modal: new Record({
      summery: {},
      intro: {},
      isOver: false,
      isFirst: false,
      showPatientModal: false,
    })(),
    selectedColor: '#a2e1ff',
    readOnly: false,
    setUserUpdatesResponse: {},
    peerStats: [],
    goalsArray: null,
    questionnaire: null,
    credits: [],
    selectedCredit: null,
    edocateWay: null,
    reducedLabs: [],
    reducedPhysicals: [],
    currentDefaultStory: null,
    currentDefaultScenario: null,
  },
  'patient',
);
export default InitialState;
