import { Record } from 'immutable';
// import Record from 'immutable/dist/immutable/Record';

const InitialState = Record(
  {
    references: [],
    link: '',
    header: '',
    html: '',
    objectId: '',
    articles: [],

  },
  'reference',
);

export default InitialState;
