import { Record } from 'immutable';

const InitialState = Record(
  {
    currentRoute: null,
    currentRouteName: null,
  },
  'routes',
);

export default InitialState;
