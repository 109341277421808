import React, { PureComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import { toast as reactToast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import design from '../../lib/design';
import { TextMontserrat, TextSF } from '../../lib/general';
import { config } from '../../lib/config';
import Alert from './Alert';

// reactToast.configure();
const styles = StyleSheet.create({
  text: {
    color: design.colors.clinicSub,
    fontSize: design.text.midSmall,
    fontWeight: '500',

  },
  head: {
    color: design.colors.clinicHeader,
    fontSize: design.text.small,
    fontWeight: 'bold',
  },
});
let globalAlert = null;

class Toast extends PureComponent {
  render() {
    return [
      <ToastContainer
        position="top-right"
        progressStyle={{ background: design.colors.loginBtn }}
        key="toast"

      />,
      <Alert
        key="alert"
        ref={(alert) => {
          globalAlert = alert;
        }}
      />,
    ];
  }
}
const EdocateToast = (props) => {
  const { type, header, text } = props;
  return (
    <View style={{}}>
      <TextMontserrat style={[styles.head, {}]}>{header}</TextMontserrat>
      <TextSF style={styles.text}>{text}</TextSF>
    </View>
  );
};
const defaultOptions = {
  autoClose: 3000,
};
const toast = {

  alertWithType: (type, header, text, keepOther, options) => {
    const { disableToastNotification } = config;
    if (disableToastNotification) return;
    const progressStyle = type === 'error'
      ? { background: design.colors.errorOpacity } : { background: design.colors.loginBtn };
    if (!keepOther) {
      reactToast.dismiss();
    }
    const otherOptions = { ...defaultOptions, progressStyle, ...options };
    reactToast(
      <EdocateToast type={type} header={header} text={text} />,
      {
        ...otherOptions,
      },
    );
  },
};

export { toast, globalAlert };
export default Toast;
