/* eslint-disable no-template-curly-in-string */
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';
import { Alert as NativeAlert } from 'react-native';
import DeviceInfo from './deviceInfo';
import CONFIG, { getServerConfig } from './config';
import Backend from './Backend';

import {
  global, I18n, router, helper,
} from './general';
import store from '../reducers/store';
import WebAlert from '../../web/components/AlertWeb';
import { appAuthToken } from './AppAuthToken';
// import {authActions} from '../reducers/actions';

const bundleId = DeviceInfo.getBundleId();
const Alert = CONFIG.isWeb ? WebAlert : NativeAlert;
export class Parse extends Backend {
  // for stage \ dev \ production
  changeEnv(env) {
    switch (env) {
      case global.envEnum.PARSE_DEV:
      case global.envEnum.PARSE_STAGE:
      case global.envEnum.PARSE_APP:
        this.env = env;
        break;
      default:
        // changes to support different apps by the bundle ID
        if (bundleId.indexOf(global.bundleSearchPhrase.PARSE_DEV) > -1) {
          this.env = global.envEnum.PARSE_DEV; // take dev as default without any other?
        } else if (
          bundleId.indexOf(global.bundleSearchPhrase.PARSE_STAGE) > -1
        ) {
          this.env = global.envEnum.PARSE_STAGE; // take dev as default without any other?
        } else {
          this.env = global.envEnum.PARSE_APP; // take dev as default without any other?
        }
    }
  }

  /**
   * ## Parse.js client
   *
   *
   * @throws tokenMissing if token is undefined
   */
  initialize(token) {
    if (!isNull(token) && isUndefined(token.sessionToken)) {
      throw new Error('TokenMissing');
    }
    if (store) {
      const state = store.getState();
      this.changeEnv(state.global.env);
    }
    const ENV = this.env || global.envEnum.PARSE_APP; // default env is app
    this.privateSessionToken = isNull(token)
      ? null
      : token.sessionToken.result.sessionToken;
    this.privateRestAPIKey = CONFIG[ENV].restAPIKey;
    this.privateApplicationId = CONFIG[ENV].appId;
    this.API_BASE_URL = CONFIG.backend.parseLocal
      ? CONFIG[ENV].local.url
      : CONFIG[ENV].remote.url;
    this.userId = isNull(token) ? null : token.sessionToken.result.objectId;
  }

  updateToken(sessionToken) {
    this.privateSessionToken = sessionToken;
  }

  //   getToken (){ const temp = store.get(CONFIG.SESSION_TOKEN_KEY) ;     if
  // (temp && temp.sessionToken){         this.privateSessionToken = ||
  // this.privateSessionToken     }   }

  /**
   * ### signup
   *
   * @param data object
   *
   * {username: "barton", email: "foo@gmail.com", password: "Passw0rd!"}
   *
   * @return
   * if ok, res.json={createdAt: "2015-12-30T15:17:05.379Z",
   *   objectId: "5TgExo2wBA",
   *   sessionToken: "r:dEgdUkcs2ydMV9Y9mt8HcBrDM"}
   *
   * if error, {code: xxx, error: 'message'}
   */
  async signup(data) {
    const body = {
      ...data,
      useSlimParameterProps: CONFIG.useSlimParameterProps,
    }; // add useSlimParameterProps to use low mem data version
    delete body.passwordAgain; // just to be sure
    return this.privateFetch({
      method: 'POST',
      url: '/functions/specialSignUp',
      body,
    })
      .then((res) => res.json().then((json) => {
        // debugger
        if (res.status === 200 || res.status === 201) {
          return json;
        }
        if (json && json.error) {
          const msg = isString(json.error.message)
            ? json.error.message
            : json.error;
          setTimeout(() => {
            Alert.alert(msg);
          }, 100);

          throw json;
        } else {
          setTimeout(() => {
            Alert.alert(I18n.t('Login.somethingWrong'));
          }, 100);

          throw json;
        }
      }))
      .catch((error) => {
        // debugger
        throw error;
      });
  }

  /**
   * ### login
   * encode the data and and call privateFetch
   *
   * @param data
   *
   *  {username: "barton", password: "Passw0rd!"}
   *
   * @returns
   *
   * createdAt: "2015-12-30T15:29:36.611Z"
   * updatedAt: "2015-12-30T16:08:50.419Z"
   * objectId: "Z4yvP19OeL"
   * email: "barton@foo.com"
   * sessionToken: "r:Kt9wXIBWD0dNijNIq2u5rRllW"
   * username: "barton"
   *
   */
  async login(data) {
    let formBody = [];
    const keys = Object.keys(data);
    keys.forEach((property) => {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    });
    // for (const property in data) {
    //   const encodedKey = encodeURIComponent(property);
    //   const encodedValue = encodeURIComponent(data[property]);
    //   formBody.push(`${encodedKey}=${encodedValue}`);
    // }
    formBody = formBody.join('&');

    return this.privateFetch({
      method: 'POST',
      url: '/functions/secureLogin',
      body: data,
    })
      .then((res) => res.json().then((json) => {
        if (res.status === 200 || res.status === 201) {
          return json;
        }
        if (json && json.error) {
          const msg = isString(json.error.message)
            ? json.error.message
            : json.error;
          setTimeout(() => {
            Alert.alert(msg);
          }, 100);
        } else {
          setTimeout(() => {
            Alert.alert(I18n.t('Login.somethingWrong'));
          }, 100);
        }
        throw json;
      }))
      .catch((error) => {
        throw error;
      });
  }

  /**
   * ### logout
   * prepare the request and call privateFetch
   */
  async logout() {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/sessionLogout',
      body: {},
    })
      .then((res) => res.json().then((json) => {
        if (
          res.status === 200
          || res.status === 201
          || res.status === 400
          || res.code === 209
        ) {
          return json;
        }
        throw new Error({ code: 404, error: 'unknown error from Parse.com' });
      }))
      .catch((error) => {
        throw error;
      });
  }

  /**
   * ### resetPassword
   * the data is already in a JSON format, so call privateFetch
   *
   * @param data
   * {email: "barton@foo.com"}
   *
   * @returns empty object
   *
   * if error:  {code: xxx, error: 'message'}
   */
  async resetPassword(data) {
    return this.privateFetch({
      method: 'POST',
      url: '/requestPasswordReset',
      body: data,
    })
      .then((res) => res.json().then((json) => {
        if (res.status === 200 || res.status === 201) {
          return {};
        }
        throw json;
      }))
      .catch((error) => {
        throw error;
      });
  }
  /**
   * ### getProfile
   * Using the sessionToken, we'll get everything about
   * the current user.
   *
   * @returns
   *
   * if good:
   * {createdAt: "2015-12-30T15:29:36.611Z"
   *  email: "barton@acclivyx.com"
   *  objectId: "Z4yvP19OeL"
   *  sessionToken: "r:uFeYONgIsZMPyxOWVJ6VqJGqv"
   *  updatedAt: "2015-12-30T15:29:36.611Z"
   *  username: "barton"}
   *
   * if error, {code: xxx, error: 'message'}
   */

  async getProfile() {
    return this.privateFetch({ method: 'GET', url: '/users/me' })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  /**
   * ### updateProfile
   * for this user, update their record
   * the data is already in JSON format
   *
   * @param userId  _id of Parse.com
   * @param data object:
   * {username: "barton", email: "barton@foo.com"}
   */
  async updateProfile(userId, data) {
    return this.privateFetch({
      method: 'PUT',
      url: `/users/${userId}`,
      body: data,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          return {};
        }
        return res.json().then(() => {
          throw res;
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  async getFirebaseToken() {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getFirebaseToken',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async removeScenarioFromUser(scenarioId) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/removeScenarioFromUser/',
      body: { scenarioId },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async addStoryToUser(defaultStoryId) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/addStoryToUser/',
      body: { defaultStoryId, noDuplicates: true },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async addScenarioToUser(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/addScenarioToUser/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getPotentialDefaultStories() {
    const url = (CONFIG.userInvitationOnly || CONFIG.shouldChooseCampaign)
      ? '/functions/getPotentialDefaultStoriesByCampaign/' : '/functions/getPotentialDefaultStories/';
    // const url = '/functions/getPotentialDefaultStories/';// removed campaign at the moment
    return this.privateFetch({
      method: 'POST',
      url,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getClinics(body = {}) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getMyStoriesAndScenario/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getAllScenarios(body = {}) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getAllScenarios/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async updateTimeZone(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/updateTimeZone',
      body,
    })
      .then((res) => res.json().then((json) => json))
      .catch((error) => {
        throw error;
      });
  }

  async updateSettings(objectId, obj) {
    return this.privateFetch({
      method: 'PUT',
      url: `/classes/UserSettings/${objectId}`,
      body: obj,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getReference() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/Reference?where={"active":true}&limit=1000',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getArticles(arrayOfObjectId) {
    return this.privateFetch({
      method: 'GET',
      url: `/classes/Reference?where={"objectId":{"$in":${JSON.stringify(arrayOfObjectId)}}}&limit=1000`,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getSettings() {
    return this.privateFetch({
      method: 'GET',
      url: `/classes/UserSettings?${Parse.getWhereByUserPointerText(
        this.userId,
      )}`,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          // we want just one
          if (res.results && res.results.length > 0) {
            return res.results[0];
          }
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getGoalsArray(arr) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getGoals',
      body: { list: arr },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result || res.results;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getQuestionnaire(questionnaireId) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getQuestionnaireByQuestionnaireId',
      body: { questionnaireId },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result || res.results;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async updateQuestionnaire(questionnaire, creditId) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/updateQuestionnaire',
      body: { questionnaire, creditId },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result || res.results;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getMyCredits() {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getMyCredits',
      body: {},
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result || res.results;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async sendCertificateToMyEmail(creditId) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/sendCertificateToMyEmail',
      body: { creditId },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result || res.results;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async nextScenario(id) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/nextScenario',
      body: { id },
    })
      .then((res) => res.json().then((json) => {
        // console.log('tickScenarioByStory', json)
        if (res.status === 200 || res.status === 201) {
          return json;
        }
        throw json;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async restartScenario(id) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/restartScenario',
      body: {
        id,
        useSlimParameterProps: CONFIG.useSlimParameterProps, // add useSlimParameterProps to use low mem data version
      },
    })
      .then((res) => res.json().then((json) => {
        // console.log('tickScenarioByStory', json)
        if (res.status === 200 || res.status === 201) {
          return json;
        }
        throw json;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async fastForwardClinic(id) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/tickScenarioByStory',
      body: { id },
    })
      .then((res) => res.json().then((json) => {
        // console.log('tickScenarioByStory', json)
        if (res.status === 200 || res.status === 201) {
          return json;
        }
        throw json;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getScenarioByStoryId(id) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getScenarioByStoryId/',
      body: { id },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getSpecialty() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/Specialty?order=name',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getYearsInPractice() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/YearsInPractice?order=order',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getLicense() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/License?order=order',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getTreatmentDuration() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/TreatmentDuration?order=order',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getOnBoarding() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/OnBoarding?order=order',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getTutorial() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/Tutorial?where={"active":true}&limit=1000',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getConfig() {
    const state = store.getState();
    const campaignId = state.profile.form.activeCampaign;
    const body = { campaignId };
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getConfig/',
      body,
    })
      .then((response) => response.json().then((res) => {
        // debugger
        // console.log('getConfig', res);
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        // console.log('getConfig', res);
        throw res;
      }))
      .catch((error) => {
        // debugger
        console.log('getConfig catch', error);

        throw error;
      });
  }

  async getPasswordRegEx() {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getPasswordRegEx',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getEducation() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/Education?order=type',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getTreatments() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/Treatment?include=class',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getFAQ() {
    return this.privateFetch({
      method: 'GET',
      url: '/classes/FAQ?order=order',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getGlobal() {
    const state = store.getState();
    const campaignId = state.profile.form.activeCampaign;
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getGlobal/',
      body: campaignId ? { campaignId } : {},
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async checkIfProjectOver() {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/checkIfProjectOver/',
      body: {},
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getMyPeerStats(scenarioId) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getMyPeerStats/',
      body: {
        scenarioId,
        userInvitationOnly: CONFIG.userInvitationOnly, // tell the server to bring my campings
      },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getEdocateWay(scenarioId) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getEdocateWay/',
      body: { scenarioId },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async startScenario(id) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/startScenario/',
      body: {
        id,
      },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async setUserUpdates(updates, scenarioId, storyId) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/setUserUpdates/',
      // url: '/functions/setUserUpdatesFastForward/', // new method
      body: {
        updates,
        scenarioId,
        storyId,
      },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getMyCampaign() {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getMyCampaign/',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async checkUserInvite(email) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/checkUserInvite/',
      body: {
        email,
      },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async checkUserEmailExists(email) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/checkUserEmailExists/',
      body: {
        email,
      },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getTranslation(locale) {
    const state = store.getState();
    const activeCampaign = state.profile.form.activeCampaign || state.profile.form.fields.activeCampaign;
    const bodyObj = {
      platform: 'app',
      locale,
      campaign: activeCampaign,
    };
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getTranslation/',
      body: bodyObj,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async addTranslation(bodyObj) {
    bodyObj.platform = 'app';
    return this.privateFetch({
      method: 'POST',
      url: '/functions/addTranslation/',
      body: bodyObj,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getActiveCampaign() {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getActiveCampaign/',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getDynamicForm(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getDynamicForm/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async storeVersionCheck(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/storeVersionCheck/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async icdSearch(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/icdSearch/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async providerConnect(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/providerConnect/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async singleSignOn(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/singleSignOn/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async getMyPromotions() {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/getMyPromotions',
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async updateUserProfile(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/updateUserProfile',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async contactUs(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/contactUs',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async dynamicSSO(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/dynamicSSO/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async azureADConnect(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/azureADConnect/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async anonymousLogin(body) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/anonymousLogin/',
      body,
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async validateDoctorId(idObj) {
    // eslint-disable-next-line no-template-curly-in-string
    const { doctorId, prefix } = idObj;
    const urlStr = getServerConfig().validateDoctorIdURL
    || 'https://practitionersapi.health.gov.il/Practitioners/api/Practitioners/GetProfessionsLicense?licenseNum=${prefix}-${doctorId}';
    const urlStrNoPrefix = getServerConfig().validateDoctorIdURLNoPrefix
     || 'https://practitionersapi.health.gov.il/Practitioners/api/Practitioners/GetProfessionsLicense?licenseNum=${prefix}${doctorId}';
    // race
    const urlTemplate = helper.generateTemplateString(urlStr);
    const urlTemplateNoPrefix = helper.generateTemplateString(urlStrNoPrefix);
    const url = urlTemplate({ doctorId, prefix });
    const urlNoPrefix = urlTemplateNoPrefix({ doctorId, prefix });
    const req1 = {
      method: 'POST',
      url: '/functions/corsProxy',
      body: { url, options: { method: 'GET' } },
    };
    const req2 = {
      method: 'POST',
      url: '/functions/corsProxy',
      body: { url: urlNoPrefix, options: { method: 'GET' } },
    };
    const all = [
      this.privateFetch(req1, true).then((response) => response.json()),
      this.privateFetch(req2, true).then((response) => response.json()),
    ];
    const [res, resNoPrefix] = await Promise.all(all);
    return res?.result?.[0]?.licenseDetails?.status?.statusId === 0
       || resNoPrefix?.result?.[0]?.licenseDetails?.status?.statusId === 0;
  }

  async keepAlive() {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/keepAlive',
      body: {},
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result || res.results;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  async sendEventsToFirebase(event) {
    return this.privateFetch({
      method: 'POST',
      url: '/functions/sendEventsToFirebase',
      body: { event, refName: 'userActions/' },
    })
      .then((response) => response.json().then((res) => {
        if (response.status === 200 || response.status === 201) {
          return res.result || res.results;
        }
        throw res;
      }))
      .catch((error) => {
        throw error;
      });
  }

  /**
   * ### privateFetch
   * A generic function that prepares the request
   * @returns object:
   *  {code: response.code
   *   status: response.status
   *   json: reponse.json()
   */
  async privateFetch(options) {
    return new Promise(async (resolve) => {
      const opts = {
        method: 'GET',
        url: null,
        body: null,
        callback: null,
        ...options,
      };
      const reqOpts = {
        method: opts.method,
        headers: {
          'X-Parse-Application-Id': this.privateApplicationId,
          'X-Parse-REST-API-Key': this.privateRestAPIKey,
          // 'X-device-version': VERSION,
          // 'X-device-bundleId': bundleId,
        },
      };
      if (this.privateSessionToken) {
        reqOpts.headers['X-Parse-Session-Token'] = this.privateSessionToken;
      }

      if (opts.method === 'POST' || opts.method === 'PUT') {
        reqOpts.headers.Accept = 'application/json';
        reqOpts.headers['Content-Type'] = 'application/json';
      }

      if (opts.body) {
        reqOpts.body = JSON.stringify(opts.body);
      }
      const response = await fetch(this.API_BASE_URL + opts.url, reqOpts);
      if (response.ok) {
        resolve(response);
      } else {
        const second = response.clone();
        second.json().then((parsedRes) => {
          if (parsedRes.code === 209) {
            // Invalid session token do global logout
            appAuthToken.deleteSessionToken();
            appAuthToken.deleteFirebaseToken();
            if (CONFIG.azureLoginOnly) {
              router.goToPage('AzureADLogout');
            } else if (CONFIG.newAuthPages) {
              router.init('Login');
            } else {
              router.init('Auth');
            }
          }
        });
        resolve(response);
      }
      // return fetch(this.API_BASE_URL + opts.url, reqOpts);
    });
  }

  static getWhereByUserPointerText(userId) {
    return `where={"user":{"__type":"Pointer","className":"_User","objectId":"${userId}"}}&limit=1000`;
  }
}
// The singleton variable
export const parse = new Parse();
