/* eslint-disable no-param-reassign */
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import omit from 'lodash/omit';
import clone from 'lodash/clone';
import isNumber from 'lodash/isNumber';
import helper from './helper';
import enums from './enums';
// import pick from 'lodash/pick';
import store from '../reducers/store';

const userUpdateHelper = {
  addToUserUpdate(curr, action, parent, data, getState) {
    // current = current state
    // action = add \ remove
    // parent = treatments \ labs \ diagnosis
    // data = item to be set {} or array
    // should i check???
    // just not to save that in the DB
    // debugger
    const needInterpolate = ['labs', 'physical', 'physicals'];
    const flatParameters = ['next', 'notesFreeText', 'nextTimeStatus'];
    const current = { ...curr };
    // check id data is array or object
    const state = getState();
    const dataArr = Array.isArray(data) ? data : [data];
    dataArr.forEach((temp) => {
      let updatedData = temp;
      if (needInterpolate.indexOf(parent) > -1) {
        updatedData = userUpdateHelper.prepareHistoryValueForUserUpdates(curr, action, parent, updatedData, getState);
      }
      if (flatParameters.indexOf(parent) > -1) {
        current[parent] = updatedData;
      } else if (parent === 'labs' || parent === 'physical') {
        // another check if exist on current date
        const historyArr = state?.patient?.currentPatient?.data?.[parent]?.data;
        const exist = current[parent][action].find((item) => item.objectId === updatedData.objectId)
        || historyArr.find((item) => item.objectId === updatedData.objectId && item.date === updatedData.date); // check if exist
        if (!exist) {
          current[parent][action].push(updatedData);
        }
      } else {
        current[parent][action].push(updatedData);
      }
      current.tempNumber += 1;
    });
    return current;
  },
  removeFromUserUpdate(curr, action, parent, nameOfField, valueOfField) {
    // current = current state
    // action = add \ remove
    // parent = treatments \ labs \ diagnosis
    // nameOfField of property to find // valueOfField the should match
    // should i check???
    // debugger
    const current = { ...curr };

    if (parent === 'next' || parent === 'notesFreeText') {
      current[parent] = '';
    } else {
      remove(current[parent][action], (item) => item[nameOfField] === valueOfField);
    }
    current.tempNumber += 1;
    return current;
  },
  checkUserUpdatesForChanges(currUpdates) {
    const state = store.getState();
    const sim = state?.patient?.currentPatient?.data?.sim?.data;
    if (!sim) return false;
    const { givenPhysicals = [], givenLabs = [] } = sim;
    let answer = false;
    if (!currUpdates) return answer;
    if (
      currUpdates.treatments.add.length > 0
      || currUpdates.treatments.remove.length > 0
      || currUpdates.labs.add.length > givenLabs.length
      || currUpdates.labs.remove.length > 0
      || currUpdates.diagnosis.add.length > 0
      || currUpdates.diagnosis.remove.length > 0
      || currUpdates.physicals.add.length > givenPhysicals.length
      || currUpdates.physicals.remove.length > 0
      || currUpdates.interviews.add.length > 0
      || currUpdates.interviews.remove.length > 0
      || currUpdates.references.add.length > 0
      || currUpdates.references.remove.length > 0
      || currUpdates.next !== ''
      || !isEmpty(currUpdates.notes)
      || currUpdates.notesFreeText !== ''
    ) {
      answer = true;
    }
    return answer;
  },
  setUserUpdatesToInitialState(curr) {
    const currUpdates = { ...curr };
    currUpdates.treatments.add = [];
    currUpdates.treatments.remove = [];
    currUpdates.labs.add = [];
    currUpdates.labs.remove = [];
    currUpdates.diagnosis.add = [];
    currUpdates.diagnosis.remove = [];
    currUpdates.physicals.add = [];
    currUpdates.physicals.remove = [];
    currUpdates.interviews.add = [];
    currUpdates.interviews.remove = [];
    currUpdates.references.add = [];
    currUpdates.references.remove = [];
    currUpdates.next = '';
    currUpdates.notes = {};
    currUpdates.notesFreeText = '';
    return currUpdates;
  },
  createAutoNotes(currentUserUpdate, complaints, time) {
    // debugger
    // TODO: clean object
    const temp = {
      complaints,
      diagnosis: currentUserUpdate.diagnosis,
      treatments: currentUserUpdate.treatments,
      notesFreeText: currentUserUpdate.notesFreeText,
      date: time,
    };
    return temp;
  },
  cleanDataAfterScenario(currentUserUpdate) {
    const state = store.getState();
    const sim = state?.patient?.currentPatient?.data?.sim?.data;
    // const alwaysDeleteThese = ['dbObj', 'createdAt', 'updatedAt', 'profiles', 'given', 'score', 'infoGoal', 'parameter', 'item'];
    const { isShortTickEnabled, scenarioTicks, timeStatus } = sim;
    let isShortTick = false;
    if (isShortTickEnabled) {
      isShortTick = timeStatus === enums.timeStatusEnum.SHORT_TERM;
    }
    const alwaysDeleteThese = [
      'dbObj', 'createdAt', 'updatedAt', 'profiles',
      'score', 'infoGoal', 'dbItem', 'dbParameter',
    ];
    const hasStartDate = ['treatments', 'diagnosis'];
    const updates = currentUserUpdate.toJS ? currentUserUpdate.toJS() : currentUserUpdate;
    Object.entries(updates).forEach(([prop, val]) => {
      if (Array.isArray(val?.add)) {
        updates[prop].add = val.add.map((item) => {
          const obj = omit(item, alwaysDeleteThese);
          if (isShortTick) {
            if (hasStartDate.includes(prop)) {
              item.startDate = scenarioTicks;
            } else {
              item.date = scenarioTicks;
            }
          }
          return obj;
        });
      }
      if (Array.isArray(val?.remove)) {
        updates[prop].remove = val.remove.map((item) => omit(item, alwaysDeleteThese));
      }

      //
    });
    // notes only
    // console.log(updates);
    return updates;
  },
  prepareUserDataBeforeScenario(scenario, patientState) {
    try {
      const {
        treatments, labsFull, physicals, parameters,
      } = patientState;
      const { data } = scenario;
      // check if short term and filter response accordingly
      const { isShortTickEnabled, scenarioTicks, shortScenarioTicks } = data.sim.data;
      if (isShortTickEnabled) {
        const isShortTick = data.sim.data.timeStatus === enums.timeStatusEnum.SHORT_TERM;
        const diff = {
          labs: data.labs.data.filter((e) => (isShortTick ? !isNumber(e.shortTick) : isNumber(e.shortTick))),
          physical: data.physical.data.filter((e) => (isShortTick ? !isNumber(e.shortTick) : isNumber(e.shortTick))),
          notes: data.notes.data.filter((e) => (isShortTick ? !isNumber(e.shortTick) : isNumber(e.shortTick))),
        };

        data.labs.data = data.labs.data
          .filter((e) => (isShortTick ? isNumber(e.shortTick) && scenarioTicks === e.date : !isNumber(e.shortTick)));
        data.physical.data = data.physical.data
          .filter((e) => (isShortTick ? isNumber(e.shortTick) && scenarioTicks === e.date : !isNumber(e.shortTick)));
        data.notes.data = data.notes.data
          .filter((e) => (isShortTick ? isNumber(e.shortTick) && scenarioTicks === e.date : !isNumber(e.shortTick)));

        if (isShortTick) { // filter and display only current hospital
          const reMap = (arr) => arr.map((d) => { // we need to calculate fake date
            const diffDays = shortScenarioTicks - d.shortTick;
            const fakeDateForDays = scenarioTicks - diffDays;
            return { ...d, date: fakeDateForDays };
          });

          data.labs.data = reMap(data.labs.data);
          data.physical.data = reMap(data.physical.data);
          data.notes.data = reMap(data.notes.data);

          data.treatments.data = data.treatments.data.map((d) => {
            if (isNumber(d.shortStartDate) && d.startDate === scenarioTicks) {
              const diffDays = shortScenarioTicks - d.shortStartDate;
              const fakeDateForDays = scenarioTicks - diffDays;
              return { ...d, startDate: fakeDateForDays };
            }
            delete d.shortStartDate;
            return { ...d };
          });
          data.diagnosis.data = data.diagnosis.data.map((d) => {
            if (isNumber(d.shortStartDate) && d.startDate === scenarioTicks) {
              const diffDays = shortScenarioTicks - d.shortStartDate;
              const fakeDateForDays = scenarioTicks - diffDays;
              return { ...d, startDate: fakeDateForDays };
            }
            delete d.shortStartDate;
            return { ...d };
          });
          // data.sim.data.scenarioTicks = data.sim.data.shortScenarioTicks;
        } else {
          data.treatments.data = data.treatments.data.map((d) => {
            delete d.shortStartDate;
            return { ...d };
          });
          data.diagnosis.data = data.diagnosis.data.map((d) => {
            delete d.shortStartDate;
            return { ...d };
          });
        }
        scenario.diff = diff;
      }
      data.labs.data.forEach((item) => {
        if (!item.dbItem) {
          const dbItem = labsFull.find((element) => element.objectId === item.objectId || element.name === item.name);
          if (dbItem) {
            item.dbItem = dbItem;
          }
        }
        if (!item.dbParameter) {
          const dbParameter = parameters.find((element) => element.objectId === item.dbItem.parameter.objectId);
          if (dbParameter) {
            item.dbParameter = dbParameter;
          }
        }
      });
      // physical
      data.physical.data.forEach((item) => {
        if (!item.dbItem) {
          const dbItem = physicals.find((element) => element.objectId === item.objectId || element.name === item.name);
          if (dbItem) {
            item.dbItem = dbItem;
          }
        }
        if (!item.dbParameter) {
          const dbParameter = parameters.find((element) => element.objectId === item.dbItem.parameter.objectId);
          if (dbParameter) {
            item.dbParameter = dbParameter;
          }
        }
      });
      // treatments
      data.treatments.data.forEach((item) => {
        if (!item.dbItem) {
          const dbItem = treatments.find((element) => element.objectId === item.objectId || element.name === item.name);
          if (dbItem) {
            item.dbItem = dbItem;
          }
        }
      });

      data.notes.data.forEach((note) => {
        const { add = [], remove = [] } = note?.treatments;
        add.forEach((item) => {
          if (!item.dbItem) {
            const dbItem = treatments.find(
              (element) => element.objectId === item.objectId || element.name === item.name,
            );
            if (dbItem) {
              item.dbItem = dbItem;
            }
          }
        });
        remove.forEach((item) => {
          if (!item.dbItem) {
            const dbItem = treatments.find(
              (element) => element.objectId === item.objectId || element.name === item.name,
            );
            if (dbItem) {
              item.dbItem = dbItem;
            }
          }
        });
      });

      return scenario;
    } catch (error) {
      console.warn('prepareUserDataBeforeScenario catch', error);
      return scenario;
    }
  },
  prepareGlobalDataForScenario(scenario, patientState) {
    try {
      const {
        labsFull, physicals, parameters,
      } = patientState;
      const userData = {
        labs: [],
        physicals: [],
      };
      const { data, profiles = [] } = scenario;
      const userParameters = data.parameters.data;
      userData.labs = labsFull.reduce((accumulator, item) => {
        const shouldAddByProfile = item?.profiles?.some((p) => profiles.indexOf(p) > -1);
        if (shouldAddByProfile) {
          const param = userParameters.find((p) => item.parameter.objectId === p.objectId);
          const dbParameter = parameters.find((element) => element.objectId === item.parameter.objectId);
          if (param && dbParameter) {
            const obj = {
              objectId: item.objectId,
              name: item.name,
              valueAfterMed: param.valueAfterMed,
              dbItem: item,
              dbParameter,
            // TODO: value text? what ab0ut mustache ????
            };
            accumulator.push(obj);
          }
        }
        return accumulator;
      }, []);
      userData.physicals = physicals.reduce((accumulator, item) => {
        const shouldAddByProfile = item?.profiles?.some((p) => profiles.indexOf(p) > -1);
        if (shouldAddByProfile) {
          const param = userParameters.find((p) => item.parameter.objectId === p.objectId);
          const dbParameter = parameters.find((element) => element.objectId === item.parameter.objectId);
          if (param && dbParameter) {
            const obj = {
              objectId: item.objectId,
              name: item.name,
              valueAfterMed: param.valueAfterMed,
              dbItem: item,
              dbParameter,
            // TODO: value text? what ab0ut mustache ????
            };
            accumulator.push(obj);
          }
        }
        return accumulator;
      }, []);
      // console.log('prepareGlobalDataForScenario labs', userData);
      return userData;
    } catch (error) {
      console.warn('prepareUserDataBeforeScenario catch', error);
      return scenario;
    }
  },
  prepareHistoryValueForUserUpdates(curr, action, parent, data, getState) {
    try {
      const state = getState();
      const clonedData = clone(data);
      const { parameter } = clonedData;
      const { objectId } = parameter;
      const userParameters = state?.patient?.currentPatient?.data?.parameters?.data;
      const dbParams = state?.patient?.parameters;
      const userParameter = clone(userParameters.find((item) => item.objectId === objectId));
      const dbParam = clone(dbParams.find((item) => item.objectId === objectId));
      const {
        valueType, valueText, valueOutput,
      } = dbParam;
      const { valueAfterMed } = userParameter;
      if (valueType !== 'number' && valueType !== 'numeric' && valueType !== 'link') {
        const str = valueText?.[valueAfterMed]?.text ? valueText?.[valueAfterMed]?.text : valueText?.[valueAfterMed];
        const afterInterpolate = helper.interpolate(str, state?.patient?.currentPatient?.data);
        if (afterInterpolate !== str) { // that means that the interpolate worked
          userParameter.historyValue = afterInterpolate; // save for history
        }
        if (parent === 'labs') {
          clonedData.parameter = userParameter;
        } else {
          clonedData.item = userParameter;
        }
      } else if (valueType === 'link') {
        const arr = valueOutput?.[valueAfterMed];
        const historyArray = [];
        let isChange = false;
        if (arr) {
          arr.forEach((out) => {
            const { text, link } = out;
            const afterInterpolate = helper.interpolate(text, state?.patient?.currentPatient?.data);
            if (afterInterpolate !== text) {
              isChange = true;
            }
            historyArray.push({ text: afterInterpolate, link });
          });
        }
        if (isChange) { // that means that the interpolate worked
          userParameter.historyArray = historyArray; // save for history
        }
        if (parent === 'labs') {
          clonedData.parameter = userParameter;
        } else {
          clonedData.item = userParameter;
        }
      }
      // debugger;
      // console.log('clonedData', clonedData);
      return clonedData;
    } catch (error) {
      return data;
    }
  },

};
export default userUpdateHelper;
