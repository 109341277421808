import keyMirror from 'key-mirror';

export default keyMirror({
  SET_PLATFORM: null,
  SET_VERSION: null,

  SESSION_TOKEN_REQUEST: null,
  SESSION_TOKEN_SUCCESS: null,
  SESSION_TOKEN_FAILURE: null,

  DELETE_TOKEN_REQUEST: null,
  DELETE_TOKEN_SUCCESS: null,

  ON_LOGIN_STATE_CHANGE: null,
  LOGOUT: null,

  ON_AUTH_FORM_FIELD_CHANGE: null,
  SIGNUP_REQUEST: null,
  SIGNUP_SUCCESS: null,
  SIGNUP_FAILURE: null,

  LOGIN_REQUEST: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAILURE: null,

  LOGOUT_REQUEST: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_FAILURE: null,

  LOGGED_IN: null,
  LOGGED_OUT: null,

  SET_SESSION_TOKEN: null,

  RESET_PASSWORD_REQUEST: null,
  RESET_PASSWORD_SUCCESS: null,
  RESET_PASSWORD_FAILURE: null,

  GET_PROFILE_REQUEST: null,
  GET_PROFILE_SUCCESS: null,
  GET_PROFILE_FAILURE: null,

  ON_PROFILE_FORM_FIELD_CHANGE: null,

  SET_PROFILE_CAMPAIGN: null,

  PROFILE_UPDATE_REQUEST: null,
  PROFILE_UPDATE_SUCCESS: null,
  PROFILE_UPDATE_FAILURE: null,

  SET_STATE: null,
  GET_STATE: null,
  SET_STORE: null,

  FORGOT_PASSWORD: null,
  LOGIN: null,
  REGISTER: null,

  GET_CLINIC_REQUEST: null,
  GET_CLINIC_SUCCESS: null,
  GET_CLINIC_FAILURE: null,

  INTRO_SKIP: null,
  INTRO_DONE: null,

  ON_SETTINGS_FORM_FIELD_CHANGE: null,
  GET_SETTINGS_REQUEST: null,
  GET_SETTINGS_SUCCESS: null,
  GET_SETTINGS_FAILURE: null,

  SETTINGS_UPDATE_REQUEST: null,
  SETTINGS_UPDATE_SUCCESS: null,
  SETTINGS_UPDATE_FAILURE: null,

  FF_CLINIC_REQUEST: null,
  FF_CLINIC_SUCCESS: null,
  FF_CLINIC_FAILURE: null,

  GET_PATIENT_REQUEST: null,
  GET_PATIENT_SUCCESS: null,
  GET_PATIENT_FAILURE: null,

  SET_PATIENT_ID: null,
  SET_CLINIC_ID: null,

  GET_TREATMENTS_REQUEST: null,
  GET_TREATMENTS_SUCCESS: null,
  GET_TREATMENTS_FAILURE: null,

  GET_SPECIALTY_REQUEST: null,
  GET_SPECIALTY_SUCCESS: null,
  GET_SPECIALTY_FAILURE: null,

  GET_EDUCATION_REQUEST: null,
  GET_EDUCATION_SUCCESS: null,
  GET_EDUCATION_FAILURE: null,

  GET_PASS_REGEX_REQUEST: null,
  GET_PASS_REGEX_SUCCESS: null,
  GET_PASS_REGEX_FAILURE: null,

  GET_GLOBAL_REQUEST: null,
  GET_GLOBAL_SUCCESS: null,
  GET_GLOBAL_FAILURE: null,

  SET_UPDATES_TEMP: null,

  SET_UPDATES_REQUEST: null,
  SET_UPDATES_SUCCESS: null,
  SET_UPDATES_FAILURE: null,
  SET_UPDATES_INITIAL: null,

  LOADER_SHOW: null,
  LOADER_HIDE: null,

  GET_AUTOICD10_REQUEST: null,
  GET_AUTOICD10_SUCCESS: null,
  GET_AUTOICD10_FAILURE: null,
  GET_AUTODRUGS_REQUEST: null,
  GET_AUTODRUGS_SUCCESS: null,
  GET_AUTODRUGS_FAILURE: null,

  CREATE_NOTES: null,
  SET_VALUE_FORM: null,
  SET_COMPLETE_FORM: null,

  GET_DRUGS_INFO_REQUEST: null,
  GET_DRUGS_INFO_SUCCESS: null,
  GET_DRUGS_INFO_FAILURE: null,

  RESTART_SCENARIO_REQUEST: null,
  RESTART_SCENARIO_SUCCESS: null,
  RESTART_SCENARIO_FAILURE: null,

  SET_UPDATES_MODAL: null,

  NEXT_SCENARIO_REQUEST: null,
  NEXT_SCENARIO_SUCCESS: null,
  NEXT_SCENARIO_FAILURE: null,

  SET_UPDATES_COLOR: null,
  SET_READ_ONLY: null,
  SET_DATA_READ_ONLY: null,

  GET_REFERENCE_REQUEST: null,
  GET_REFERENCE_SUCCESS: null,
  GET_REFERENCE_FAILURE: null,
  SET_REF_ITEM: null,

  GET_FIREBASE_REQUEST: null,
  GET_FIREBASE_SUCCESS: null,
  GET_FIREBASE_FAILURE: null,
  SET_NOTIFICATIONS: null,
  SET_NOTIFICATIONS_UNREAD: null,
  SET_NOTIFICATIONS_LOGGED_IN: null,

  GET_CREDITS_SUCCESS: null,
  GET_CREDITS_REQUEST: null,
  GET_CREDITS_FAILURE: null,

  START_SCENARIO_REQUEST: null,
  START_SCENARIO_SUCCESS: null,
  START_SCENARIO_FAILURE: null,

  GET_MY_PEER_STATS_REQUEST: null,
  GET_MY_PEER_STATS_SUCCESS: null,
  GET_MY_PEER_STATS_FAILURE: null,

  GET_GOALS_ARRAY_REQUEST: null,
  GET_GOALS_ARRAY_SUCCESS: null,
  GET_GOALS_ARRAY_FAILURE: null,

  GET_FAQ_REQUEST: null,
  GET_FAQ_SUCCESS: null,
  GET_FAQ_FAILURE: null,

  PDF_DOC_START: null,
  PDF_DOC_END: null,

  SET_FEEDBACK_INITIAL: null,

  ALERT_ON: null,
  ALERT_OFF: null,

  SET_FIRST_TIME: null,
  SET_ENV: null,

  SET_GOAL: null,
  SET_ENV_APP: null,

  GET_ICD_LINK_REQUEST: null,
  GET_ICD_LINK_SUCCESS: null,
  GET_ICD_LINK_FAILURE: null,
  SET_PROFILES_ARRAY: null,
  SET_FILTERED_ARRAY_BY_PROFILE: null,

  GET_POTENTIAL_STORY_REQUEST: null,
  GET_POTENTIAL_STORY_SUCCESS: null,
  GET_POTENTIAL_STORY_FAILURE: null,

  ADD_STORY_REQUEST: null,
  ADD_STORY_SUCCESS: null,
  ADD_STORY_FAILURE: null,

  REMOVE_SCENARIO_REQUEST: null,
  REMOVE_SCENARIO_SUCCESS: null,
  REMOVE_SCENARIO_FAILURE: null,

  GET_HTML_REQUEST: null,
  GET_HTML_SUCCESS: null,
  GET_HTML_FAILURE: null,

  CLEAR_HTML_SUCCESS: null,

  SET_NEW_ROUTE_STATE: null,

  GET_COUNTRIES_REQUEST: null,
  GET_COUNTRIES_SUCCESS: null,
  GET_COUNTRIES_FAILURE: null,
  SET_QUESTIONNAIRE: null,
  GET_QUESTIONNAIRE_REQUEST: null,
  GET_QUESTIONNAIRE_SUCCESS: null,
  GET_QUESTIONNAIRE_FAILURE: null,
  UPDATE_QUESTIONNAIRE_REQUEST: null,
  UPDATE_QUESTIONNAIRE_SUCCESS: null,
  UPDATE_QUESTIONNAIRE_FAILURE: null,
  GET_MY_CREDITS_REQUEST: null,
  GET_MY_CREDITS_SUCCESS: null,
  GET_MY_CREDITS_FAILURE: null,
  SET_SELECTED_CREDIT: null,
  CHECK_USER_INVITE_REQUEST: null,
  CHECK_USER_INVITE_SUCCESS: null,
  CHECK_USER_INVITE_FAILURE: null,
  CHECK_USER_EMAIL_EXISTS_REQUEST: null,
  CHECK_USER_EMAIL_EXISTS_SUCCESS: null,
  CHECK_USER_EMAIL_EXISTS_FAILURE: null,
  GET_CAMPAIGN_SUCCESS: null,
  GET_CAMPAIGN_REQUEST: null,
  GET_CAMPAIGN_FAILURE: null,
  DELETE_FIREBASE_SUCCESS: null,
  SET_WEB: null,
  SET_DESKTOP: null,
  GET_CONFIG_REQUEST: null,
  GET_CONFIG_SUCCESS: null,
  GET_CONFIG_FAILURE: null,
  GET_ON_BOARDING_REQUEST: null,
  GET_ON_BOARDING_SUCCESS: null,
  GET_ON_BOARDING_FAILURE: null,
  GET_YEARS_PRACTICE_REQUEST: null,
  GET_YEARS_PRACTICE_SUCCESS: null,
  GET_YEARS_PRACTICE_FAILURE: null,
  SEND_CERTIFICATE_REQUEST: null,
  SEND_CERTIFICATE_SUCCESS: null,
  SEND_CERTIFICATE_FAILURE: null,
  GET_TUTORIAL_REQUEST: null,
  GET_TUTORIAL_SUCCESS: null,
  GET_TUTORIAL_FAILURE: null,

  IS_PROJECT_OVER_SUCCESS: null,
  IS_PROJECT_OVER_REQUEST: null,
  IS_PROJECT_OVER_FAILURE: null,

  GET_ACTIVE_CAMPAIGN_REQUEST: null,
  GET_ACTIVE_CAMPAIGN_SUCCESS: null,
  GET_ACTIVE_CAMPAIGN_FAILURE: null,

  GET_TREATMENT_DURATION_REQUEST: null,
  GET_TREATMENT_DURATION_SUCCESS: null,
  GET_TREATMENT_DURATION_FAILURE: null,

  GET_EDOCATE_WAY_REQUEST: null,
  GET_EDOCATE_WAY_SUCCESS: null,
  GET_EDOCATE_WAY_FAILURE: null,

  GET_LICENSE_REQUEST: null,
  GET_LICENSE_SUCCESS: null,
  GET_LICENSE_FAILURE: null,

  GET_ARTICLES_REQUEST: null,
  GET_ARTICLES_SUCCESS: null,
  GET_ARTICLES_FAILURE: null,

  PROVIDER_CONNECT_SUCCESS: null,
  PROVIDER_CONNECT_REQUEST: null,
  PROVIDER_CONNECT_FAILURE: null,

  SET_URL_PARAMS: null,
  CLEAR_CLINIC: null,
  CLINIC_HAS_ONE_DONE: null,

  GET_MY_PROMOTIONS_REQUEST: null,
  GET_MY_PROMOTIONS_FAILURE: null,
  GET_MY_PROMOTIONS_SUCCESS: null,

  SINGLE_SIGN_ON_SUCCESS: null,
  SINGLE_SIGN_ON_REQUEST: null,
  SINGLE_SIGN_ON_FAILURE: null,

  CONTACT_US_REQUEST: null,
  CONTACT_US_SUCCESS: null,
  CONTACT_US_FAILURE: null,

  DYNAMIC_SSO_SUCCESS: null,
  DYNAMIC_SSO_REQUEST: null,
  DYNAMIC_SSO_FAILURE: null,

  CHANGE_CAMPAIGN_TOGGLE: false,

  SET_NEW_ROUTE_NAME: null,

  SHOW_TUTORIAL: false,
  SHOW_TUTORIAL_ICON: null,
  SHOW_CLINICAL_HELP_ICON: null,
  SHOW_CLINICAL_HELP_MODAL: null,

  GET_COUNTRY_BY_IP_REQUEST: null,
  GET_COUNTRY_BY_IP_SUCCESS: null,
  GET_COUNTRY_BY_IP_FAILURE: null,

  SET_PARAMETERS_WITH_SCENARIO_DATA: null,
  SET_TABLET: null,
  CLEAR_PATIENT: null,
  SET_DEFAULT_SCENARIO: null,
  SET_DEFAULT_STORY: null,
});
