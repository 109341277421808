/**
 * # profileReducer.js
 *
 * The reducer user profile actions
 */

/**
 * ## Imports
 *

 * formValidation for setting the form's valid flag
 */

// const formValidation = require('../profile/profileFormValidation').default

/**
 * ## Actions
 *
 */
const {
  // ON_SETTINGS_FORM_FIELD_CHANGE,
  // GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  // GET_SETTINGS_FAILURE,

  // SETTINGS_UPDATE_REQUEST,
  // SETTINGS_UPDATE_SUCCESS
  // SETTINGS_UPDATE_FAILURE,
} = require('../../lib/constants').default;

/**
 * ## Initial State
 *
 */
const InitialState = require('./settingsInitialState').default;

const initialState = new InitialState();

/**
 * ## profileReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function settingsReducer(state = initialState, action) {
  //  let nextProfileState = null

  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    // case SETTINGS_UPDATE_SUCCESS:
    case GET_SETTINGS_SUCCESS:
      return state
        .set('objectId', action.payload.objectId)
        .set('isPaused', action.payload.isPaused)
        .set('receivePush', action.payload.receivePush)
        .set('allowFastForward', action.payload.allowFastForward);
    default:
      break;
  } // switch
  /**
   * # Default
   */
  return state;
}
